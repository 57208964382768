import { Component, Input, NgZone, OnInit, Output, ViewChild } from '@angular/core';
import { ModalController } from '@ionic/angular';

import { Geolocation } from '@capacitor/geolocation';
import { KeyboardService } from '../services/keyboard/keyboard.service';
import { CountryService } from '../services/country/country.service';

declare var google: any;

@Component({
  selector: 'app-address-search-with-map',
  templateUrl: './address-search-with-map.page.html',
  styleUrls: ['./address-search-with-map.page.scss'],
})
export class AddressSearchWithMapPage implements OnInit {

  /** What colour scheme do we need? */
  @Input('colourCoding') colourCoding: 'task' | 'service' | 'shout';

  /** Google Places Search Vars */
  autocompleteItems = [];
  autocomplete;
  service = new google.maps.places.AutocompleteService();

  geo: any;
  latitude: number;
  longitude: number;
  chosenPlace: any;

  address: string;
  postcode: string;
  shortAddress: string;
  showResultsList: boolean = true;
  coordinates: { lat: number; lng: number };
  localAreaName: string;

  chosenAddress: string;
  /** Google Places Search Vars END */

  /** Actual Map Vars */
  @ViewChild('map') mapElement;
  map: any;
  /** Actual Map Vars END */

  constructor(
    private modalCtrl: ModalController,
    private zone: NgZone,
    public keyboardService: KeyboardService,
    private countryService: CountryService
  ) {

  }

  ngOnInit() {
    console.log('Inint colourCoding: ', this.colourCoding);
  }

  ionViewDidEnter() {
    this.initMap(51.46434083239443, -0.11586021555183258);
  }

  initMap(lat, lng) {
    console.log('initMap()')
    let latLng = new google.maps.LatLng(lat, lng);
    let mapOptions = {
      center: latLng,
      zoom: 16.5,
      mapTypeId: google.maps.MapTypeId.ROAD,
      disableDefaultUI: true,
      zoomControlOptions: {
        position: google.maps.ControlPosition.RIGHT_TOP
      }
    };
    this.map = new google.maps.Map(this.mapElement.nativeElement, mapOptions);
  }

  updateSearch() {
    if (this.autocomplete) {
      if (this.autocomplete == "") {
        this.autocompleteItems = [];
        return;
      }
      let me = this;
      this.service.getPlacePredictions(
        {
          input: this.autocomplete,
          componentRestrictions: {
            // country: "uk",
            country: this.countryService.userCountry ? this.countryService.userCountry.country : "uk",
          },
        },
        (predictions, status) => {
          me.autocompleteItems = [];

          me.zone.run(() => {
            if (predictions != null) {
              console.log('predictions: ', predictions);
              this.showResultsList = true;
              predictions.forEach((prediction) => {
                me.autocompleteItems.push(prediction.description);
              });
            }
          });
        }
      );
    }
  }

  chooseItem(item: any) {
    console.log("chooseItem(): ", item);
    this.chosenAddress = item;
    // this.viewCtrl.dismiss(item)
    this.geo = item;
    this.geoCode(this.geo); //convert Address to lat and long
  }

  geoCode(address: any) {
    console.log("geoCode");
    let geocoder = new google.maps.Geocoder();
    geocoder.geocode({ address: address }, (results) => {
      console.log('Geo Code Res: ', results[0]);
      this.latitude = results[0].geometry.location.lat();
      this.longitude = results[0].geometry.location.lng();
      this.chosenPlace = results[0];
      this.autocomplete = results[0].formatted_address;
      this.autocompleteItems = [];
      this.address = results[0].formatted_address;
      this.coordinates = { lat: this.latitude, lng: this.longitude };
      this.autocompleteItems = [];
      this.localAreaName = results[0].address_components[0];
      for (let t of results[0].address_components) {
        if (t.types[0] === "postal_code") {
          this.postcode = t.short_name;
        }
        if (t.types[0] === "postal_town") {
          this.shortAddress = t.short_name;
        }
      }
      console.log("patch value");
      this.autocomplete = this.address;
      // this.autocomplete = [];
      this.autocompleteItems = [];
      this.showResultsList = false;
      this.map.setCenter(results[0].geometry!.location!);
      const marker = new google.maps.Marker({
        position: results[0].geometry!.location!,
        icon: {
          path: "M0-48c-9.8 0-17.7 7.8-17.7 17.4 0 15.5 17.7 30.6 17.7 30.6s17.7-15.4 17.7-30.6c0-9.6-7.9-17.4-17.7-17.4z",
          fillColor: '#1E36F8',
          fillOpacity: 1,
          strokeColor: '',
          strokeWeight: 0
        },
        map_icon_label: '<span class="map-icon map-icon-point-of-interest"></span>',
        map: this.map,
      });
    });
  }

  async currentLocation() {
    let perms = await Geolocation.checkPermissions();
    let newPerms = await Geolocation.requestPermissions();

    console.log('Updated Perms: ', newPerms)

    if (newPerms.location == "granted") {
      let location = await Geolocation.getCurrentPosition();
      this.reverseGeoCode(location);
    }
  }

  reverseGeoCode(location) {
    console.log('My location: ', location);
    let geocoder = new google.maps.Geocoder();
    geocoder.geocode({ location: { lat: location.coords.latitude, lng: location.coords.longitude } }).then(response => {
      console.log('Geo Response: ', response);

      this.latitude = location.coords.latitude;
      this.longitude = location.coords.longitude;
      this.chosenPlace = response.results[0];
      this.autocomplete = response.results[0].formatted_address;
      this.autocompleteItems = [];
      this.address = response.results[0].formatted_address;
      this.coordinates = { lat: this.latitude, lng: this.longitude };

      this.map.setCenter(response.results[0].geometry!.location!);
      const marker = new google.maps.Marker({
        position: response.results[0].geometry!.location!,
        icon: {
          path: "M0-48c-9.8 0-17.7 7.8-17.7 17.4 0 15.5 17.7 30.6 17.7 30.6s17.7-15.4 17.7-30.6c0-9.6-7.9-17.4-17.7-17.4z",
          fillColor: '#1E36F8',
          fillOpacity: 1,
          strokeColor: '',
          strokeWeight: 0
        },
        map_icon_label: '<span class="map-icon map-icon-point-of-interest"></span>',
        map: this.map,
      });


    })
  }

  saveLocation() {
    this.modalCtrl.dismiss({
      address: this.address,
      postcode: this.postcode,
      coordinates: this.coordinates,
      location: this.address,
      mainLocation: this.address,
      shortAddress: this.shortAddress,
      localAreaName: this.localAreaName
    })
  }

  closeModal() {
    this.modalCtrl.dismiss();
  }
}

import { Injectable } from '@angular/core';
import { HttpService } from 'src/app/services/http/http.service';

@Injectable({
  providedIn: 'root'
})
export class QuestionsService {

  constructor(
    private http: HttpService
  ) { }

  postnewQuestion(taskId: string, message: string) {
    return this.http.runHttpCall(
      "POST",
      `api/taskquestions`,
      "application/json",
      {
        taskId: taskId,
        question: message
      }
    )
  }

  replyToQuestion(taskQuestionId: string, answer: string) {
    return this.http.runHttpCall(
      "POST",
      `api/taskquestions/answer`,
      "application/json",
      {
        taskQuestionId: taskQuestionId,
        answer: answer
      }
    )
  }
}

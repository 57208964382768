import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { LandingHirePage } from './landing-hire/landing-hire.page';
import { AuthguardService } from './services/authguard/authguard.service';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'thinking',
    pathMatch: 'full',
  },
  {
    path: 'landing',
    loadChildren: () => import('./landing/landing.module').then((m) => m.LandingPageModule),
  },
  {
    path: 'landing-hire',
    loadChildren: () => import('./landing-hire/landing-hire.module').then((m) => m.LandingHirePageModule),
  },
  {
    path: 'thinking',
    loadChildren: () => import('./thinking/thinking.module').then((m) => m.ThinkingPageModule),
  },
  // {
  //   path: 'home-earn',
  //   // loadChildren: () => import('./home-earn/home-earn.module').then((m) => m.HomeEarnPageModule),
  //   loadChildren: () => import('./student-earn-tabs/student-earn-tabs.module').then( m => m.StudentEarnTabsPageModule)
  // },
  // {
  //   path: 'home-earn/:tab',
  //   // loadChildren: () => import('./home-earn/home-earn.module').then((m) => m.HomeEarnPageModule),
  //   loadChildren: () => import('./student-earn-tabs/student-earn-tabs.module').then( m => m.StudentEarnTabsPageModule)
  // },
  {
    path: 'home-hire',
    loadChildren: () => import('./home-hire/home-hire.module').then((m) => m.HomeHirePageModule),
  },
  {
    path: 'hiring-wizard',
    loadChildren: () => import('./hiring-wizard/hiring-wizard.module').then((m) => m.HiringWizardPageModule),
  },
  {
    path: 'sign-up/:type',
    loadChildren: () => import('./registration-group/sign-up/sign-up.module').then((m) => m.SignUpPageModule),
  },
  {
    path: 'verify-email',
    loadChildren: () =>
      import('./registration-group/verify-email/verify-email.module').then((m) => m.VerifyEmailPageModule),
  },
  {
    path: 'verify-student',
    loadChildren: () =>
      import('./registration-group/verify-student/verify-student.module').then((m) => m.VerifyStudentPageModule),
  },
  {
    path: 'birthday/',
    loadChildren: () => import('./registration-group/birthday/birthday.module').then((m) => m.BirthdayPageModule),
  },
  {
    path: 'reg-with-email/:type',
    loadChildren: () =>
      import('./registration-group/reg-with-email/reg-with-email.module').then((m) => m.RegWithEmailPageModule),
  },
  {
    path: 'student-code/',
    loadChildren: () =>
      import('./registration-group/student-code/student-code.module').then((m) => m.StudentCodePageModule),
  },
  {
    path: 'task-profile/:taskId',
    loadChildren: () => import('./task-profile/task-profile.module').then((m) => m.TaskProfilePageModule),
    // canActivate: [AuthguardService],
  },
  {
    path: 'task-profile/linkedin/:taskId',
    loadChildren: () => import('./task-profile/task-profile.module').then((m) => m.TaskProfilePageModule),
    // canActivate: [AuthguardService],
  },
  {
    path: 'shout-profile/:taskId',
    loadChildren: () => import('./shout-profile/shout-profile.module').then((m) => m.ShoutProfilePageModule),
    canActivate: [AuthguardService],
  },
  {
    path: 'my-activity',
    loadChildren: () => import('./my-activity/my-activity.module').then((m) => m.MyActivityPageModule),
    canActivate: [AuthguardService],
  },
  {
    path: 'sign-in',
    loadChildren: () => import('./sign-in-group/sign-in/sign-in.module').then((m) => m.SignInPageModule),
  },
  {
    path: 'sign-in-email',
    loadChildren: () =>
      import('./sign-in-group/sign-in-email/sign-in-email.module').then((m) => m.SignInEmailPageModule),
  },
  {
    path: 'task-apply/:taskId',
    loadChildren: () => import('./task-apply/task-apply.module').then((m) => m.TaskApplyPageModule),
    canActivate: [AuthguardService],
  },
  {
    path: 'full-size-image-viewer',
    loadChildren: () =>
      import('./modals/full-size-image-viewer/full-size-image-viewer.module').then(
        (m) => m.FullSizeImageViewerPageModule
      ),
  },
  {
    path: 'account',
    loadChildren: () => import('./account/account.module').then((m) => m.AccountPageModule),
    canActivate: [AuthguardService],
  },
  {
    path: 'shout-account',
    loadChildren: () => import('./shout-account/shout-account.module').then((m) => m.ShoutAccountPageModule),
    canActivate: [AuthguardService],
  },
  {
    path: 'cv-builder',
    loadChildren: () => import('./cv-builder/cv-builder.module').then((m) => m.CvBuilderPageModule),
  },
  {
    path: 'skills/:job-type-name',
    loadChildren: () => import('./components/job-type/add-skills/add-skills.module').then((m) => m.AddSkillsPageModule),
  },
  {
    path: 'student-email',
    loadChildren: () => import('./modals/student-email/student-email.module').then((m) => m.StudentEmailPageModule),
  },
  {
    path: 'prize-draw',
    loadChildren: () => import('./prize-draw/prize-draw.module').then((m) => m.PrizeDrawPageModule),
    canActivate: [AuthguardService],
  },
  {
    path: 'student-id-card',
    loadChildren: () => import('./student-id-card/student-id-card.module').then((m) => m.StudentIdCardPageModule),
    canActivate: [AuthguardService],
  },
  {
    path: 'payment-information',
    loadChildren: () =>
      import('./payment-information/payment-information.module').then((m) => m.PaymentInformationPageModule),
    canActivate: [AuthguardService],
  },
  {
    path: 'task-alerts',
    loadChildren: () => import('./task-alerts/task-alerts.module').then((m) => m.TaskAlertsPageModule),
    canActivate: [AuthguardService],
  },
  {
    path: 'referrals',
    loadChildren: () => import('./referrals/referrals.module').then((m) => m.ReferralsPageModule),
    canActivate: [AuthguardService],
  },
  // {
  //   path: 'favourite-services',
  //   loadChildren: () =>
  //     import('./favourite-services/favourite-services.module').then((m) => m.FavouriteServicesPageModule),
  //   canActivate: [AuthguardService],
  // },
  {
    path: 'settings',
    loadChildren: () => import('./settings/settings.module').then((m) => m.SettingsPageModule),
  },
  {
    path: 'notification-settings',
    loadChildren: () =>
      import('./notification-settings/notification-settings.module').then((m) => m.NotificationSettingsPageModule),
    canActivate: [AuthguardService],
  },
  {
    path: 'my-activity-selling',
    loadChildren: () =>
      import('./my-activity-selling/my-activity-selling.module').then((m) => m.MyActivitySellingPageModule),
    canActivate: [AuthguardService],
  },
  {
    path: 'my-activity-buying',
    loadChildren: () =>
      import('./my-activity-buying/my-activity-buying.module').then((m) => m.MyActivityBuyingPageModule),
    canActivate: [AuthguardService],
  },
  {
    path: 'company-profile-shout',
    loadChildren: () =>
      import('./modals/company-profile-shout/company-profile-shout.module').then(
        (m) => m.CompanyProfileShoutPageModule
      ),
  },
  {
    path: 'task-manage/:taskId/:filter',
    loadChildren: () => import('./task-manage/task-manage.module').then((m) => m.TaskManagePageModule),
    canActivate: [AuthguardService],
  },
  {
    path: 'task-questions/:taskId',
    loadChildren: () => import('./task-questions/task-questions.module').then((m) => m.TaskQuestionsPageModule),
    canActivate: [AuthguardService],
  },
  {
    path: 'task-hire/:taskId',
    loadChildren: () => import('./task-hire/task-hire.module').then((m) => m.TaskHirePageModule),
    canActivate: [AuthguardService],
  },
  {
    path: 'task-pay/:taskId',
    loadChildren: () => import('./task-pay/task-pay.module').then((m) => m.TaskPayPageModule),
    canActivate: [AuthguardService],
  },
  {
    path: 'rate-and-review/:userId',
    loadChildren: () => import('./rate-and-review/rate-and-review.module').then((m) => m.RateAndReviewPageModule),
    canActivate: [AuthguardService],
  },
  {
    path: 'task-filters',
    loadChildren: () => import('./task-filters/task-filters.module').then((m) => m.TaskFiltersPageModule),
  },
  {
    path: 'create-task/:taskId',
    loadChildren: () => import('./create-task/create-task.module').then((m) => m.CreateTaskPageModule),
  },
  {
    path: 'address-search-with-map',
    loadChildren: () =>
      import('./address-search-with-map/address-search-with-map.module').then((m) => m.AddressSearchWithMapPageModule),
  },
  // {
  //   path: 'view-service/:id',
  //   loadChildren: () => import('./view-service/view-service.module').then((m) => m.ViewServicePageModule),
  //   canActivate: [AuthguardService],
  // },
  {
    path: 'cap-payments',
    loadChildren: () => import('./cap-payments/cap-payments.module').then((m) => m.CapPaymentsPageModule),
  },
  {
    path: 'create-bus-profile',
    loadChildren: () =>
      import('./registration-group/create-bus-profile/create-bus-profile.module').then(
        (m) => m.CreateBusProfilePageModule
      ),
  },
  {
    path: 'bank-info',
    loadChildren: () => import('./bank-info/bank-info.module').then((m) => m.BankInfoPageModule),
  },
  // {
  //   path: 'sell-service/:serviceId',
  //   loadChildren: () => import('./sell-service/sell-service.module').then((m) => m.SellServicePageModule),
  // },
  {
    path: 'account-business',
    loadChildren: () => import('./account-business/account-business.module').then((m) => m.AccountBusinessPageModule),
    canActivate: [AuthguardService],
  },
  {
    path: 'task-application-sent',
    loadChildren: () =>
      import('./task-application-sent/task-application-sent.module').then((m) => m.TaskApplicationSentPageModule),
  },
  {
    path: 'verify-student-complete',
    loadChildren: () =>
      import('./registration-group/verify-student-complete/verify-student-complete.module').then(
        (m) => m.VerifyStudentCompletePageModule
      ),
  },
  // {
  //   path: 'my-services',
  //   loadChildren: () => import('./my-services/my-services.module').then((m) => m.MyServicesPageModule),
  //   canActivate: [AuthguardService],
  // },
  {
    path: 'logout',
    loadChildren: () => import('./logout/logout.module').then((m) => m.LogoutPageModule),
  },
  {
    path: 'request-notifications',
    loadChildren: () =>
      import('./modals/request-notifications/request-notifications.module').then(
        (m) => m.RequestNotificationsPageModule
      ),
  },
  {
    path: 'add-skills',
    loadChildren: () => import('./components/job-type/add-skills/add-skills.module').then((m) => m.AddSkillsPageModule),
  },
  {
    path: 'pro-landing',
    loadChildren: () => import('./pro-landing/pro-landing.module').then((m) => m.ProLandingPageModule),
  },
  {
    path: 'forbidden',
    loadChildren: () => import('./forbidden/forbidden.module').then((m) => m.ForbiddenPageModule),
  },
  {
    path: 'set-up',
    loadChildren: () => import('./set-up/set-up.module').then((m) => m.SetUpPageModule),
  },
  {
    path: 'education',
    loadChildren: () => import('./set-up/set-up.module').then((m) => m.SetUpPageModule),
  },
  {
    path: 'shout-applied',
    loadChildren: () => import('./shout-applied/shout-applied.module').then((m) => m.ShoutAppliedPageModule),
  },
  {
    path: 'basic-details',
    loadChildren: () =>
      import('./registration-group/basic-details/basic-details.module').then((m) => m.BasicDetailsPageModule),
  },
  {
    path: 'student-profile/:userId',
    loadChildren: () => import('./student-profile/student-profile.module').then((m) => m.StudentProfilePageModule),
    canActivate: [AuthguardService],
  },
  {
    path: 'student-profile/:userId/:offerId',
    loadChildren: () => import('./student-profile/student-profile.module').then((m) => m.StudentProfilePageModule),
    canActivate: [AuthguardService],
  },
  {
    path: 'manual-verification',
    loadChildren: () =>
      import('./registration-group/manual-verification/manual-verification.module').then(
        (m) => m.ManualVerificationPageModule
      ),
  },
  {
    path: 'add-profile-pic',
    loadChildren: () => import('./add-profile-pic/add-profile-pic.module').then((m) => m.AddProfilePicPageModule),
  },
  {
    path: 'cv-builder-new',
    loadChildren: () => import('./cv-builder-new/cv-builder-new.module').then((m) => m.CvBuilderNewPageModule),
    canActivate: [AuthguardService],
  },
  {
    path: 'cv-builder-education',
    loadChildren: () =>
      import('./cv-builder-education/cv-builder-education.module').then((m) => m.CvBuilderEducationPageModule),
    canActivate: [AuthguardService],
  },
  {
    path: 'cv-builder-experience',
    loadChildren: () =>
      import('./cv-builder-experience/cv-builder-experience.module').then((m) => m.CvBuilderExperiencePageModule),
    canActivate: [AuthguardService],
  },
  {
    path: 'forgot-password/:email',
    loadChildren: () => import('./forgot-password/forgot-password.module').then((m) => m.ForgotPasswordPageModule),
  },
  {
    path: 'reset-password-legacy/:guuid',
    loadChildren: () =>
      import('./reset-password-legacy/reset-password-legacy.module').then((m) => m.ResetPasswordLegacyPageModule),
  },
  {
    path: 'chat-blaze/:channel_id',
    loadChildren: () => import('./chat-blaze/chat-blaze.module').then((m) => m.ChatBlazePageModule),
    canActivate: [AuthguardService],
  },
  {
    path: 'hire-blaze/:taskId/:channel_id',
    loadChildren: () => import('./hire-blaze/hire-blaze.module').then((m) => m.HireBlazePageModule),
    canActivate: [AuthguardService],
  },
  {
    path: 'pay-blaze/:taskId/:channel_id',
    loadChildren: () => import('./pay-blaze/pay-blaze.module').then((m) => m.PayBlazePageModule),
    canActivate: [AuthguardService],
  },
  {
    path: 'home-hire-tabs',
    loadChildren: () => import('./home-hire-tabs/home-hire-tabs.module').then((m) => m.HomeHireTabsPageModule),
    // canActivate: [AuthguardService]
  },
  {
    path: 'deeplink/:businessId/:campaignId',
    loadChildren: () => import('./deeplink/deeplink.module').then((m) => m.DeeplinkPageModule),
  },
  {
    path: 'facebook-success',
    loadChildren: () => import('./facebook-success/facebook-success.module').then((m) => m.FacebookSuccessPageModule),
  },
  {
    path: 'create-job',
    loadChildren: () => import('./create-job/create-job.module').then((m) => m.CreateJobPageModule),
  },
  {
    path: 'my-activity-blaze',
    loadChildren: () => import('./my-activity-blaze/my-activity-blaze.module').then((m) => m.MyActivityBlazePageModule),
    canActivate: [AuthguardService],
  },
  // {
  //   path: 'my-services-modal',
  //   loadChildren: () =>
  //     import('./modals/my-services-modal/my-services-modal.module').then((m) => m.MyServicesModalPageModule),
  // },
  {
    path: 'error-logs',
    loadChildren: () => import('./error-logs/error-logs.module').then((m) => m.ErrorLogsPageModule),
  },
  {
    path: 'on-its-way',
    loadChildren: () => import('./modals/on-its-way/on-its-way.module').then((m) => m.OnItsWayPageModule),
  },
  {
    path: 'request-camera',
    loadChildren: () => import('./modals/request-camera/request-camera.module').then((m) => m.RequestCameraPageModule),
  },
  {
    path: 'image-cropper',
    loadChildren: () => import('./image-cropper/image-cropper.module').then((m) => m.ImageCropperPageModule),
  },
  {
    path: 'test-analytics',
    loadChildren: () => import('./test-analytics/test-analytics.module').then((m) => m.TestAnalyticsPageModule),
  },
  {
    path: 'new-create-task/:taskId',
    loadChildren: () => import('./new-create-task/new-create-task.module').then((m) => m.NewCreateTaskPageModule),
    canActivate: [AuthguardService],
  },
  {
    path: 'sign-in-code',
    loadChildren: () =>
      import('./registration-group/sign-in-code/sign-in-code.module').then((m) => m.SignInCodePageModule),
  },
  {
    path: 'account-update-email',
    loadChildren: () =>
      import('./account-update-email/account-update-email.module').then((m) => m.AccountUpdateEmailPageModule),
  },
  {
    path: 'reg-profile-completeness',
    loadChildren: () =>
      import('./registration-group/reg-profile-completeness/reg-profile-completeness.module').then(
        (m) => m.RegProfileCompletenessPageModule
      ),
  },
  {
    path: 'complete-shout-profile',
    loadChildren: () =>
      import('./modals/complete-shout-profile/complete-shout-profile.module').then(
        (m) => m.CompleteShoutProfilePageModule
      ),
  },
  {
    path: 'adjust-consent',
    loadChildren: () => import('./adjust-consent/adjust-consent.module').then((m) => m.AdjustConsentPageModule),
  },
  {
    path: 'pro-modal',
    loadChildren: () => import('./modals/pro-modal/pro-modal.module').then((m) => m.ProModalPageModule),
  },
  {
    path: 'plan/:type',
    loadChildren: () => import('./plan/plan.module').then((m) => m.PlanPageModule),
    canActivate: [AuthguardService],
  },
  {
    path: 'upgrade-existing-plan',
    loadChildren: () =>
      import('./modals/upgrade-existing-plan/upgrade-existing-plan.module').then(
        (m) => m.UpgradeExistingPlanPageModule
      ),
    canActivate: [AuthguardService],
  },
  {
    path: 'create-job-form/:taskId',
    loadChildren: () => import('./create-job-form/create-job-form.module').then((m) => m.CreateJobFormPageModule),
    canActivate: [AuthguardService],
  },
  {
    path: 'upgrade-plan',
    loadChildren: () => import('./modals/upgrade-plan/upgrade-plan.module').then((m) => m.UpgradePlanPageModule),
  },
  {
    path: 'task-invite/:taskId',
    loadChildren: () => import('./task-invite/task-invite.module').then((m) => m.TaskInvitePageModule),
  },
  {
    path: 'manage-task',
    loadChildren: () => import('./modals/manage-task/manage-task.module').then((m) => m.ManageTaskPageModule),
  },
  {
    path: 'view-cv/:userId',
    loadChildren: () => import('./view-cv/view-cv.module').then((m) => m.ViewCvPageModule),
    canActivate: [AuthguardService],
  },
  {
    path: 'view-cv',
    loadChildren: () => import('./view-cv/view-cv.module').then((m) => m.ViewCvPageModule),
    canActivate: [AuthguardService],
  },
  {
    path: 'job-filters',
    loadChildren: () => import('./job-filters/job-filters.module').then((m) => m.JobFiltersPageModule),
  },

  {
    path: 'sign-in-code',
    loadChildren: () =>
      import('./registration-group/sign-in-code/sign-in-code.module').then((m) => m.SignInCodePageModule),
  },
  {
    path: 'account-update-email',
    loadChildren: () =>
      import('./account-update-email/account-update-email.module').then((m) => m.AccountUpdateEmailPageModule),
  },
  {
    path: 'reg-profile-completeness',
    loadChildren: () =>
      import('./registration-group/reg-profile-completeness/reg-profile-completeness.module').then(
        (m) => m.RegProfileCompletenessPageModule
      ),
  },
  {
    path: 'complete-shout-profile',
    loadChildren: () =>
      import('./modals/complete-shout-profile/complete-shout-profile.module').then(
        (m) => m.CompleteShoutProfilePageModule
      ),
  },
  {
    path: 'adjust-consent',
    loadChildren: () => import('./adjust-consent/adjust-consent.module').then((m) => m.AdjustConsentPageModule),
  },
  // {
  //   path: 'inbox-stream-ng/:id',
  //   loadChildren: () => import('./inbox-stream-ng/inbox-stream-ng.module').then((m) => m.InboxStreamNgPageModule),
  //   canActivate: [AuthguardService],
  // },
  {
    path: 'price-change-respond/:pcrId/:offerId',
    loadChildren: () =>
      import('./price-change-respond/price-change-respond.module').then((m) => m.PriceChangeRespondPageModule),
    canActivate: [AuthguardService],
  },
  {
    path: 'messaging/:id',
    loadChildren: () => import('./messaging/messaging.module').then((m) => m.MessagingPageModule),
    canActivate: [AuthguardService],
  },
  {
    path: 'app-update',
    loadChildren: () => import('./app-update/app-update.module').then((m) => m.AppUpdatePageModule),
  },
  {
    path: 'app-unavailable',
    loadChildren: () => import('./app-unavailable/app-unavailable.module').then((m) => m.AppUnavailablePageModule),
  },
  {
    path: 'commission-reason',
    loadChildren: () =>
      import('./modals/commission-reason/commission-reason.module').then((m) => m.CommissionReasonPageModule),
  },
  {
    path: 'reverse-deeplink',
    loadChildren: () => import('./reverse-deeplink/reverse-deeplink.module').then((m) => m.ReverseDeeplinkPageModule),
  },
  {
    path: 'get-app',
    loadChildren: () => import('./modals/get-app/get-app.module').then((m) => m.GetAppPageModule),
  },
  {
    path: 'desktop-activity',
    loadChildren: () => import('./desktop-activity/desktop-activity.module').then((m) => m.DesktopActivityPageModule),
    canActivate: [AuthguardService],
  },
  {
    path: 'desktop-activity/:taskId',
    loadChildren: () => import('./desktop-activity/desktop-activity.module').then((m) => m.DesktopActivityPageModule),
    canActivate: [AuthguardService],
  },
  {
    path: 'desktop-activity/:taskId/:offerId',
    loadChildren: () => import('./desktop-activity/desktop-activity.module').then((m) => m.DesktopActivityPageModule),
    canActivate: [AuthguardService],
  },
  {
    path: 'li-community-builder',
    loadChildren: () =>
      import('./li-community-builder/li-community-builder.module').then((m) => m.LiCommunityBuilderPageModule),
  },
  {
    path: 'connect-linkedin',
    loadChildren: () => import('./connect-linkedin/connect-linkedin.module').then((m) => m.ConnectLinkedinPageModule),
  },
  {
    path: 'more-task-options',
    loadChildren: () =>
      import('./modals/more-task-options/more-task-options.module').then((m) => m.MoreTaskOptionsPageModule),
  },
  {
    // path: 'linkedin-success/:id',
    path: 'linkedin-success',
    loadChildren: () => import('./linkedin-success/linkedin-success.module').then((m) => m.LinkedinSuccessPageModule),
  },
  {
    path: 'getstarted/:questionnaireID',
    loadChildren: () => import('./getstarted/getstarted.module').then((m) => m.GetstartedPageModule),
  },
  {
    path: 'getstarted-login',
    loadChildren: () => import('./getstarted-login/getstarted-login.module').then((m) => m.GetstartedLoginPageModule),
  },
  {
    path: 'welcome',
    loadChildren: () => import('./welcome/welcome.module').then((m) => m.WelcomePageModule),
  },
  {
    path: 'getstarted-email',
    loadChildren: () => import('./getstarted-email/getstarted-email.module').then((m) => m.GetstartedEmailPageModule),
  },
  {
    path: 'getstarted-type',
    loadChildren: () => import('./getstarted-type/getstarted-type.module').then((m) => m.GetstartedTypePageModule),
  },
  {
    path: 'getstarted-student',
    loadChildren: () =>
      import('./getstarted-student/getstarted-student.module').then((m) => m.GetstartedStudentPageModule),
  },
  {
    path: 'getstarted-business',
    loadChildren: () =>
      import('./getstarted-business/getstarted-business.module').then((m) => m.GetstartedBusinessPageModule),
  },
  {
    path: 'getstarted-public',
    loadChildren: () =>
      import('./getstarted-public/getstarted-public.module').then((m) => m.GetstartedPublicPageModule),
  },
  {
    path: 'verify-email',
    loadChildren: () =>
      import('./modals/verify-email-modal/verify-email-modal.module').then((m) => m.VerifyEmailModalPageModule),
  },
  {
    path: 'mobile-activity-buying',
    loadChildren: () =>
      import('./mobile-activity-buying/mobile-activity-buying.module').then((m) => m.MobileActivityBuyingPageModule),
    canActivate: [AuthguardService],
  },
  {
    path: 'mobile-activity-buying/:taskId',
    loadChildren: () =>
      import('./mobile-activity-buying/mobile-activity-buying.module').then((m) => m.MobileActivityBuyingPageModule),
    canActivate: [AuthguardService],
  },
  {
    path: 'mobile-activity-buying/:taskId/:offerId',
    loadChildren: () =>
      import('./mobile-activity-buying/mobile-activity-buying.module').then((m) => m.MobileActivityBuyingPageModule),
    canActivate: [AuthguardService],
  },
  {
    path: 'student-inbox',
    loadChildren: () => import('./student-inbox/student-inbox.module').then((m) => m.StudentInboxPageModule),
    canActivate: [AuthguardService],
  },
  {
    path: 'student-inbox/:taskId',
    loadChildren: () => import('./student-inbox/student-inbox.module').then((m) => m.StudentInboxPageModule),
    canActivate: [AuthguardService],
  },
  {
    path: 'auth-action',
    loadChildren: () => import('./auth-action/auth-action.module').then(m => m.AuthActionPageModule)
  },
  {
    path: 'sign-in-code',
    loadChildren: () =>
      import('./registration-group/sign-in-code/sign-in-code.module').then((m) => m.SignInCodePageModule),
  },
  {
    path: 'account-update-email',
    loadChildren: () =>
      import('./account-update-email/account-update-email.module').then((m) => m.AccountUpdateEmailPageModule),
  },
  {
    path: 'reg-profile-completeness',
    loadChildren: () =>
      import('./registration-group/reg-profile-completeness/reg-profile-completeness.module').then(
        (m) => m.RegProfileCompletenessPageModule
      ),
  },
  {
    path: 'complete-shout-profile',
    loadChildren: () =>
      import('./modals/complete-shout-profile/complete-shout-profile.module').then(
        (m) => m.CompleteShoutProfilePageModule
      ),
  },
  {
    path: 'adjust-consent',
    loadChildren: () => import('./adjust-consent/adjust-consent.module').then((m) => m.AdjustConsentPageModule),
  },
  // {
  //   path: 'inbox-stream-ng/:id',
  //   loadChildren: () => import('./inbox-stream-ng/inbox-stream-ng.module').then((m) => m.InboxStreamNgPageModule),
  //   canActivate: [AuthguardService],
  // },
  {
    path: 'price-change-respond/:pcrId/:offerId',
    loadChildren: () =>
      import('./price-change-respond/price-change-respond.module').then((m) => m.PriceChangeRespondPageModule),
    canActivate: [AuthguardService],
  },
  {
    path: 'messaging/:id',
    loadChildren: () => import('./messaging/messaging.module').then((m) => m.MessagingPageModule),
    canActivate: [AuthguardService],
  },
  {
    path: 'app-update',
    loadChildren: () => import('./app-update/app-update.module').then((m) => m.AppUpdatePageModule),
  },
  {
    path: 'app-unavailable',
    loadChildren: () => import('./app-unavailable/app-unavailable.module').then((m) => m.AppUnavailablePageModule),
  },
  {
    path: 'commission-reason',
    loadChildren: () =>
      import('./modals/commission-reason/commission-reason.module').then((m) => m.CommissionReasonPageModule),
  },
  {
    path: 'reverse-deeplink',
    loadChildren: () => import('./reverse-deeplink/reverse-deeplink.module').then((m) => m.ReverseDeeplinkPageModule),
  },
  {
    path: 'get-app',
    loadChildren: () => import('./modals/get-app/get-app.module').then((m) => m.GetAppPageModule),
  },
  {
    path: 'desktop-activity',
    loadChildren: () => import('./desktop-activity/desktop-activity.module').then((m) => m.DesktopActivityPageModule),
    canActivate: [AuthguardService],
  },
  {
    path: 'desktop-activity/:taskId',
    loadChildren: () => import('./desktop-activity/desktop-activity.module').then((m) => m.DesktopActivityPageModule),
    canActivate: [AuthguardService],
  },
  {
    path: 'li-community-builder',
    loadChildren: () =>
      import('./li-community-builder/li-community-builder.module').then((m) => m.LiCommunityBuilderPageModule),
  },
  // {
  //   path: 'connect-linkedin',
  //   loadChildren: () => import('./connect-linkedin/connect-linkedin.module').then( m => m.ConnectLinkedinPageModule)
  // },
  {
    path: 'more-task-options',
    loadChildren: () =>
      import('./modals/more-task-options/more-task-options.module').then((m) => m.MoreTaskOptionsPageModule),
  },
  {
    path: 'pro-student',
    loadChildren: () => import('./pro-student/pro-student.module').then((m) => m.ProStudentPageModule),
    canActivate: [AuthguardService],
  },
  {
    path: 'top-up-wallet',
    loadChildren: () => import('./top-up-wallet/top-up-wallet.module').then((m) => m.TopUpWalletPageModule),
    canActivate: [AuthguardService],
  },
  {
    path: 'pro-student-upgrade',
    loadChildren: () =>
      import('./home-earn/modals/pro-student-upgrade/pro-student-upgrade.module').then(
        (m) => m.ProStudentUpgradePageModule
      ),
  },
  {
    path: 'student-deals',
    loadChildren: () => import('./student-deals/student-deals.module').then((m) => m.StudentDealsPageModule),
  },
  // {
  //   path: 'student-earn-tabs',
  //   loadChildren: () => import('./student-earn-tabs/student-earn-tabs.module').then( m => m.StudentEarnTabsPageModule)
  // },
  {
    path: 'creator-portfolio',
    loadChildren: () => import('./creator-portfolio/creator-portfolio.module').then(m => m.CreatorPortfolioPageModule),
    canActivate: [AuthguardService],
  },
  {
    path: 'connect-tiktok',
    loadChildren: () => import('./connect-tiktok/connect-tiktok.module').then(m => m.ConnectTiktokPageModule),
    // canActivate: [AuthguardService],
  },
  {
    path: 'student-earn-btns',
    loadChildren: () => import('./student-earn-btns/student-earn-btns.module').then(m => m.StudentEarnBtnsPageModule)
  },
  {
    path: 'brief-profile/:ugcBriefId/:tab',
    loadChildren: () => import('./student-earn-btns/pages/brief-profile/brief-profile.module').then(m => m.BriefProfilePageModule)
  },
  {
    path: 'brief-profile/:ugcBriefId',
    loadChildren: () => import('./student-earn-btns/pages/brief-profile/brief-profile.module').then(m => m.BriefProfilePageModule)
  },
  {
    path: 'tiktok-connect-app-confirm',
    loadChildren: () => import('./connect-tiktok-app-confirm/connect-tiktok-app-confirm.module').then( m => m.ConnectTiktokAppConfirmPageModule),
    canActivate: [AuthguardService],
  },
  {
    path: 'start-connect-tiktok/:creatorId',
    loadChildren: () => import('./connect-tiktok-get-started/connect-tiktok-get-started.module').then( m => m.ConnectTiktokGetStartedPageModule)
  },
  {
    path: 'chat/:chatId',
    loadChildren: () => import('./chat/chat.module').then( m => m.ChatPageModule)
  },
  {
    path: 'chat-hire-mobile/:chatId',
    loadChildren: () => import('./chat-hire-mobile/chat-hire-mobile.module').then( m => m.ChatHireMobilePageModule)
  }

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule],
})
export class AppRoutingModule { }

import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ActivityResponse } from 'src/app/my-activity/service/activity.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-activity-item-selling',
  templateUrl: './activity-item-selling.component.html',
  styleUrls: ['./activity-item-selling.component.scss'],
})
export class ActivityItemSellingComponent implements OnInit {
  /** Which item are we displaying? */
  @Input('activityItem') focussedItem;

  /** Is this selling item completed? */
  @Input('isCompleted') isCompleted = false;

  /** Filter Option - Show items with this status*/
  @Input('filterStatus') filterStatus: string;

  jobType = ['FULL TIME', 'PART TIME', 'INTERNSHIP', 'TASK'];

  constructor(private router: Router) {}

  ngOnInit() {
    // console.log('focussedItem focussedItem focussedItem : ', this.focussedItem);
  }

  getItemType(): string {
    if (this.focussedItem.isService) {
      return 'service';
    } else if (this.focussedItem.isShout) {
      return 'shout';
    } else {
      return 'task';
    }
  }

  getImageSource(image, location: string) {
    if (image && image.includes('http')) {
      return image;
    } else if (image == 'string') {
      return '../../../assets/images/placeholder.jpeg';
    } else {
      let availWidth = screen.availWidth;

      if (availWidth > 250) {
        availWidth = 240;
      }

      let imageWidth: number;
      let imageHeight: number;
      if (location === 'focussed') {
        imageWidth = 2 * 0.95 * availWidth;
        imageHeight = 2 * 240;
      } else if (location === 'gallery') {
        imageWidth = 106;
        imageHeight = 74;
      }

      let cdnRequestObject = {
        bucket: 'unitaskr-web-cdn',
        key: image.key, // "Screenshot 2021-11-17 at 11.10.24.png",
        edits: {
          resize: {
            width: imageWidth,
            height: imageHeight,
            fit: 'cover',
          },
        },
      };

      let base64 = btoa(JSON.stringify(cdnRequestObject));
      return `${environment.uniTaskrApi.cdnBaseUrl}${base64}`;
    }
  }

  openItem() {
    if (this.getItemType() === 'task') {
      this.router.navigate([`/task-profile/${this.focussedItem.taskId}`]);
    } else if (this.getItemType() === 'shout') {
      this.router.navigate([`/shout-profile/${this.focussedItem.taskId}`]);
    } else if (this.getItemType() === 'service') {
      this.router.navigate([`/sell-service/${this.focussedItem.taskId}`]);
    }
  }

  shoutTaskLabel() {
    if (this.focussedItem.isService) {
      return 'SERVICE';
    } else if (this.focussedItem.isShout) {
      return 'SHOUT';
    } else {
      return 'TASK';
    }
  }

  getStatus() {
    if (this.focussedItem.isPaid) {
      return `Earned £${(this.focussedItem.amount * 0.9 + this.focussedItem.tip).toFixed(2)}`;
      // return 'Paid';
    } else if (this.focussedItem.isAccepted) {
      return 'Hired';
    } else if (!this.focussedItem.isAccepted) {
      return 'Applied';
    }
  }

  isIncludedInFilter() {
    if (!this.filterStatus) {
      return true;
    } else if (this.filterStatus.toLowerCase() == 'all') {
      return true;
    } else if (this.filterStatus.toLowerCase() == this.getStatus().toLowerCase()) {
      return true;
    } else if (this.filterStatus.toLowerCase() == 'paid' && this.getStatus().toLowerCase().startsWith('earned')) {
      return true;
    }
  }
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { RangeCustomEvent } from '@ionic/angular';
import { CurrencyService } from 'src/app/services/currency/currency.service';
import { FirebaseService } from 'src/app/services/firebase/firebase.service';
import { SubscriptionService } from 'src/app/services/subscription/subscription.service';
import { LocalUserService } from 'src/app/welcome/services/local-user/local-user.service';

@Component({
  selector: 'app-custom-calculator',
  templateUrl: './custom-calculator.component.html',
  styleUrls: ['./custom-calculator.component.scss'],
})
export class CustomCalculatorComponent implements OnInit {
  noOfJobPosts: any = 10;
  pricePerMonth: number = 680;
  pricePerJob: number = 68;

  @Output() noOfJobPostsChanged = new EventEmitter<number>();
  @Output() pricePerMonthChanged = new EventEmitter<number>();
  @Output() pricePerJobChanged = new EventEmitter<number>();

  @Input() paymentFrequency: number;
  myCurrencyCode = this.currencyService.getCurrencyCode();

  // eslint-disable-next-line max-len
  constructor(
    private subscription: SubscriptionService,
    public firebaseService: FirebaseService,
    public localUserService: LocalUserService,
    private currencyService: CurrencyService
  ) {}

  ngOnInit() {
    this.subscription.getSingleCount(4).subscribe((countRes) => {
      console.log(countRes.totalAvailable);
      if (countRes.totalAvailable !== null && countRes.totalAvailable >= 10) {
        this.noOfJobPosts = countRes.totalAvailable;
        this.updateCurrentPreview(this.noOfJobPosts);
      }
      if (this.paymentFrequency == 1) {
        this.updateCurrentPreview(this.noOfJobPosts);
      }
    });
  }

  getCustomPreview(ev: Event) {
    this.noOfJobPosts = (ev as RangeCustomEvent).detail.value;
    this.updateCurrentPreview(this.noOfJobPosts);
  }

  updateCurrentPreview(jobPosts: number) {
    this.subscription.getCustomPreview(jobPosts, this.paymentFrequency).subscribe((customRes) => {
      console.log('customRes: ', customRes);
      if (this.paymentFrequency == 1) {
        this.pricePerMonth = customRes.price / 12;
      } else {
        this.pricePerMonth = customRes.price;
      }
      this.pricePerJob = customRes.pricePerJob;
      this.noOfJobPostsChanged.emit(this.noOfJobPosts);
      this.pricePerMonthChanged.emit(this.pricePerMonth);
      this.pricePerJobChanged.emit(this.pricePerJob);
    });
  }
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { ChatBlazeService } from 'src/app/chat-blaze/service/chat-blaze.service';
import { CurrencyService } from 'src/app/services/currency/currency.service';
import { Channel } from 'stream-chat';
import { ChannelService } from 'stream-chat-angular';
import { MessagingService } from '../../service/messaging.service';

@Component({
  selector: 'app-custom-footer',
  templateUrl: './custom-footer.component.html',
  styleUrls: ['./custom-footer.component.scss'],
})
export class CustomFooterComponent implements OnInit {

  @Input() channel!: Channel;

  @Output('updateApplicantListOrder') updateApplicantListOrder = new EventEmitter()

  activeChannel: Channel | undefined;

  activeChannelSub: Subscription;

  /** Focussed Task offer for channel */
  focussedTaskOffer;
  /** Focussed Student for channel */
  focussedStudent;
  /** Focussed Task for channel */
  focussedTask;

  amHirer: boolean = false;

  paymentSummary;

  myCurrencyCode;
  myConversionRate;

  newMessage: string;

  /** Updated profile picture files */
  selectedFiles: FileList;

  /** What device are we on? */
  isMobile: boolean = true;


  constructor(
    private platform: Platform,
    private channelService: ChannelService,
    public chatService: ChatBlazeService,
    private currencyService: CurrencyService,
    private messagingService: MessagingService,
  ) {
    this.activeChannelSub = this.channelService.activeChannel$.subscribe(chanRes => {
      this.activeChannel = chanRes;
    });
  }

  async ngOnInit() {
    if (this.platform.is('mobile')) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }
  }

  async sendMessage() {
    console.log('sendMessage()');
    let converted = this.stringToHtml(this.newMessage);
    if (this.newMessage && this.newMessage.length > 0 && this.newMessage !== '' && this.newMessage !== ' ' && this.newMessage !== undefined && converted !== this.stringToHtml('undefined ')) {
      const message = await this.channelService.sendMessage(
        this.newMessage
      );
      this.newMessage = undefined;
      /**
       * Move latest applicant to the top of the list.
       */
      this.updateApplicantListOrder.emit()
    }
  }

  stringToHtml(str: string) {
    var dom = document.createElement('div');
    dom.innerHTML = str;
    return dom;
  }

  sendMessageEnter() {
    console.log('sendMessageEnter()');
    if (this.platform.is('desktop')) {
      this.sendMessage();
    }
  }


  selectFile(event) {
    this.selectedFiles = event.target.files;
    this.sendAttachment();
  }

  async sendAttachment() {
    console.log('sendAttachment(): ', this.selectedFiles[0]);

    // const response = await this.activeChannel.sendFile(this.selectedFiles[0]);
    const response = await this.messagingService.activeChannel.sendFile(this.selectedFiles[0]);
    console.log('Attachment Sent: ', response);
    if (response.file.includes('mp4') || response.file.includes('mov') || response.file.includes('MOV')) {
      const message = await this.channelService.sendMessage(
        '📹 video',
        [{
          file: response.file,
          type: 'video',
          thumb_url: response.file
        }]
      );
    } else if (response.file.includes('doc') || response.file.includes('docx') || response.file.includes('pdf')) {
      const message = await this.channelService.sendMessage(
        '📄 document',
        [{
          file: response.file,
          type: 'document',
          thumb_url: response.file
        }]
      );
    } else if (response.file.includes('xlsx') || response.file.includes('xls')) {
      const message = await this.channelService.sendMessage(
        '📄 spreadsheet',
        [{
          file: response.file,
          type: 'spreadsheet',
          thumb_url: response.file
        }]
      );
    }
    else {
      const message = await this.channelService.sendMessage(
        '📸 image',
        [{
          file: response.file,
          type: 'image',
          thumb_url: response.file
        }]
      );
    }
  }

  async attachFile() {
    console.log('attachFile()');
  }

  getMyRates() {
    if (this.focussedTask && this.focussedTask.currencyCode == '') {
      this.focussedTask.currencyCode = 'GBP';
    } else if (this.focussedTask) {
      this.currencyService.getConversionRate(this.focussedTask.currencyCode, this.myCurrencyCode).subscribe(rateRes => {
        this.myConversionRate = rateRes.rate ? rateRes.rate : 1;
      });
    }
  }

  async getSize() {
    return 8;
  }

  ngOnDestroy() {
    this.activeChannelSub.unsubscribe()
  }
}

import { Injectable } from '@angular/core';
import { collection, getDocs, getFirestore, onSnapshot, or, orderBy, query, where } from 'firebase/firestore';
import { StorageService } from '../storage/storage.service';
import { environment } from 'src/environments/environment';
import { initializeApp } from 'firebase/app';

@Injectable({
  providedIn: 'root'
})
export class FirebaseChatService {

  /** All Firebase chats */
  firebaseChats: any[] = [];
  displayFirebaseChats: any[] = [];

  /** Search query text */
  queryText: string | undefined;

  constructor(
    private storageService: StorageService
  ) { }

  async getMyChats() {
    // console.log('getMyChats(): ', this.storageService.firebaseUser?.uid);
    let app;

    try {
      app = initializeApp(environment.firebaseConfig);
    } catch (e) {
      console.log('Init FB app error: ', e);
    }

    if (app && this.storageService.firebaseUser) {
      const db = getFirestore(app);
      // console.log('>>>> Firestore Query')
      // console.log('User ID: ', this.storageService.firebaseUser.uid)
      // console.log('UT User ID: ', this.storageService.uniTaskrUser.userId);
      // console.log('>>>> Firestore Query')

      // const q = query(collection(db, environment.firebase_chat.channels), where("users", "array-contains", `${this.storageService.firebaseUser.uid}`), orderBy("last_message_sent_time", "desc"));

      // const q = query(collection(db, environment.firebase_chat.channels),
      //   or(where('creatorUID', '==', this.storageService.uniTaskrUser.userId),
      //     where('hirerUID', '==', `${this.storageService.firebaseUser.uid}`)), 
      //     orderBy("last_message_time", "desc"));

      const q = query(collection(db, environment.firebase_chat.channels), or(
        where("creatorUID", "==", this.storageService.firebaseUser.uid),
        where("creatorUID", "==", this.storageService.uniTaskrUser.userId),
        where("hirerUID", "==", this.storageService.uniTaskrUser.userId),
        where("hirerUID", "==", this.storageService.firebaseUser.uid)),
        orderBy("last_message_time", "desc"));

      // const q = query(collection(db, environment.firebase_chat.channels));

      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        console.log('$$$$$ querySnapshot: ', querySnapshot);
        this.firebaseChats = []
        this.displayFirebaseChats = []
        querySnapshot.forEach((doc) => {
          // console.log('chat doc: ', doc.data());
          this.firebaseChats.push({
            reference: doc.ref.path,
            data: doc.data()
          });

          this.displayFirebaseChats.push({
            reference: doc.ref.path,
            data: doc.data()
          });
        });
      });
    }
  }

  getChatByOfferId(taskOfferId: number) {
    // console.log('getChatByOfferId(): ', taskOfferId);
    if (this.firebaseChats && this.firebaseChats.length > 0) {
      // console.log('GCO this.firebaseChats: ', this.firebaseChats);
      let foundChat;
      for (let chat of this.firebaseChats) {
        if (chat.data.taskOfferId == taskOfferId) {
          foundChat = chat;
          break;
        }
      }
      if (foundChat) {
        return foundChat;
      } else {
        return { last_message: 'No chat...' }
      }
    } else {
      return { last_message: 'No chat...' }
    }
  }
}

// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `image-cropper {
  --cropper-outline-color: rgba(0, 0, 0, 0.642);
  --cropper-overlay-color: var(--ion-background-color);
}

.existing-account-row {
  width: 100%;
  justify-content: center;
}

.continueBtn {
  --background: linear-gradient(
    90deg,
    rgba(132, 22, 244, 1) 0%,
    rgba(7, 206, 213, 1) 100%
  );
  --border-radius: 2rem;
  width: 80%;
  --color: #fff;
}

.crop_image_row {
  margin: 8px;
  height: 25%;
  justify-content: center;
  align-content: center;
}
@media only screen and (min-width: 768px) {
  .crop_image_row {
    height: 50%;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/image-cropper/image-cropper.page.scss"],"names":[],"mappings":"AAAA;EACE,6CAAA;EACA,oDAAA;AACF;;AAEA;EACE,WAAA;EACA,uBAAA;AACF;;AAEA;EACE;;;;GAAA;EAKA,qBAAA;EACA,UAAA;EACA,aAAA;AACF;;AAEA;EACE,WAAA;EACA,WAAA;EACA,uBAAA;EACA,qBAAA;AACF;AAAE;EALF;IAMI,WAAA;EAGF;AACF","sourcesContent":["image-cropper {\n  --cropper-outline-color: rgba(0, 0, 0, 0.642);\n  --cropper-overlay-color: var(--ion-background-color);\n}\n\n.existing-account-row {\n  width: 100%;\n  justify-content: center;\n}\n\n.continueBtn {\n  --background: linear-gradient(\n    90deg,\n    rgba(132, 22, 244, 1) 0%,\n    rgba(7, 206, 213, 1) 100%\n  );\n  --border-radius: 2rem;\n  width: 80%;\n  --color: #fff;\n}\n\n.crop_image_row {\n  margin: 8px;\n  height: 25%;\n  justify-content: center;\n  align-content: center;\n  @media only screen and (min-width: 768px) {\n    height: 50%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

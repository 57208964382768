// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.skills_btns_row {
  width: 100%;
  justify-content: start;
  overflow-x: scroll;
  flex-wrap: nowrap;
}
.skills_btns_row .skills_btn {
  --background: var(--accent-light-grey-chat);
  --color: #000;
}
.skills_btns_row .skills_btn_selected {
  --background: var(--shout-steps-purple-light);
  --color: #000;
}`, "",{"version":3,"sources":["webpack://./src/app/view-cv/components/sub-skills/sub-skills.component.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,sBAAA;EAGA,kBAAA;EACA,iBAAA;AADJ;AAGI;EACI,2CAAA;EACA,aAAA;AADR;AAGI;EACI,6CAAA;EACA,aAAA;AADR","sourcesContent":[".skills_btns_row {\n    width: 100%;\n    justify-content: start;\n    // padding-left: 5%;\n    // padding-right: 5%;\n    overflow-x: scroll;\n    flex-wrap: nowrap;\n\n    .skills_btn {\n        --background: var(--accent-light-grey-chat);\n        --color: #000;\n    }\n    .skills_btn_selected {\n        --background: var(--shout-steps-purple-light);\n        --color: #000;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

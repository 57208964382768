
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TaskResponse } from 'src/app/home-earn/service/task.service';
import { DomSanitizer } from '@angular/platform-browser';
import DOMPurify from "dompurify";

@Component({
  selector: 'app-standard-task-card',
  templateUrl: './standard-task-card.component.html',
  styleUrls: ['./standard-task-card.component.scss'],
})
export class StandardTaskCardComponent implements OnInit {

  /** Task information */
  @Input('task') task: TaskResponse;

  /** Shout information */
  @Input('shout') shout: any;

  /* Main Card Action Output */
  @Output('mainCardAction') mainAction = new EventEmitter();

  @Input('categories') categories: any;
  @Input('jobCategories') jobCategories: any;
  @Input('filterSelected') filterSelected: any;
  @Input('jobTypes') jobTypes: any;

  categoryName: string;
  categoryImage: string;

  /** Shortened Address */
  shortAddress: string;

  constructor(
    private sanitizer: DomSanitizer,
  ) { }

  ngOnInit() {
    this.getCategory();
  }

  clickWholeCardAction() {
    console.log('clickWholeCardAction()')
    this.mainAction.emit(this.task ? this.task : this.shout);
  }

  getPlaceholderImage(): string {
    return "../../../assets/images/placeholder.jpeg"
  }

  getSanitized(htmlString: string) {
    let output = htmlString.replace("\n", "<br> <br>");
    const sanitizedContent = DOMPurify.sanitize(output);
    return this.sanitizer.bypassSecurityTrustHtml(sanitizedContent);
  }

  getCategory() {
    for (let category of this.categories.businessCategories) {
      if (category.categoryId == this.shout.categoryId) {
        this.categoryName = category.name;
        this.categoryImage = category.src;
      }
    }
    for (let category of this.categories.homeCategories) {
      if (category.categoryId == this.shout.categoryId) {
        this.categoryName = category.name;
        this.categoryImage = category.src;
      }
    }
  }

}

import { Injectable } from '@angular/core';
import { AlertController, LoadingController } from '@ionic/angular';
import { FirebaseService } from '../firebase/firebase.service';
import { Subject, of, throwError } from 'rxjs';
import { StorageService } from 'src/app/services/storage/storage.service';
import { DeviceService } from '../device/device.service';
import { UniUserService } from '../uni-user/uni-user.service';
import { map } from 'rxjs/operators';
import { Auth, createUserWithEmailAndPassword, getAuth, signInWithCustomToken, signInWithEmailAndPassword } from 'firebase/auth';
import { ErrorLogService } from 'src/app/error-logs/service/error-log.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    private firebaseService: FirebaseService,
    private uniUserService: UniUserService,
    private storageService: StorageService,
    private loadingController: LoadingController,
    private deviceService: DeviceService,
    private logService: ErrorLogService,
    private readonly alertController: AlertController
  ) {
  }
  loading: HTMLIonLoadingElement;

  emailCheckRes;

  consumerReg = false;
  consumerRegChange: Subject<boolean> = new Subject<boolean>();
  consumerLogin = false;
  consumerLoginChange: Subject<boolean> = new Subject<boolean>();

  uniUserLoginRes;

  private auth: Auth;

  setAppId(appId) {
    this.uniUserService.appId = appId;
    this.emailCheckRes = undefined;
  }

  checkEmail(email: string) {
    return this.uniUserService.checkEmailAppId(email)
  }

  signInWithCustomToken(token){
    this.auth = getAuth(this.firebaseService.firebaseApp);
    return signInWithCustomToken(this.auth, token).then(
      async (firebaseLoginRes: any) => {
        console.log('Firebase loginRes: ', firebaseLoginRes);
        return await this.completeUserLogin(firebaseLoginRes, firebaseLoginRes._tokenResponse.idToken);
      },
      (err) => {
        console.log('Firebase registerRes Error: ', err);
        this.logService.addLog(`Firebase registerRes Error ${JSON.stringify(err)}`, 'welcome/services/auth/auth.service.ts');
        return of(err);
      }
    );
  }


  loginWithEmailAndPassword(email, password) {

    this.auth = getAuth(this.firebaseService.firebaseApp);
    return signInWithEmailAndPassword(this.auth, email, password).then(
      async (firebaseLoginRes: any) => {
        console.log('Firebase loginRes: ', firebaseLoginRes);
        return await this.completeUserLogin(firebaseLoginRes, firebaseLoginRes._tokenResponse.idToken);
      },
      (err) => {
        console.log('Firebase registerRes Error: ', err);
        this.logService.addLog(`Firebase registerRes Error ${JSON.stringify(err)}`, 'welcome/services/auth/auth.service.ts');
        return of(err);
      }
    );
  }

  async completeUserLogin(firebaseUser, firebaseToken) {
    // this.loading.message = 'Checking your details...';
    // set token
    this.storageService.token = firebaseToken;
    this.storageService['uni-user-token'] = firebaseToken;
    // set firebase user
    this.firebaseService.firebaseUser = firebaseUser;

    // get device
    var device = await this.deviceService.getDevice();

    // then uniUser which uses details from token
    return this.uniUserService.login(device).pipe(map(uniUserLoginRes => {
      console.log('uniUserLoginRes', uniUserLoginRes);
      if (!uniUserLoginRes.consumerComplete) {
        //consumer reg
        //ready for consumer reg
        this.consumerReg = true; // maybe emit an event to reg consumer
        this.consumerRegChange.next(this.consumerReg);
      } else {
        this.consumerLogin = true; // maybe emit an event to login consumer
        this.uniUserLoginRes = uniUserLoginRes;
        this.storageService.uniUser = uniUserLoginRes;
        this.consumerLoginChange.next(this.consumerLogin);
      }
      return uniUserLoginRes;
    }))
  }

  registerWithEmailAndPassword(email, password, firstName, lastName) {
    console.log('registerWithEmailAndPassword', email, password, firstName, lastName);

    this.auth = getAuth(this.firebaseService.firebaseApp);

    return createUserWithEmailAndPassword(this.auth, email, password).then(
      async (firebaseRegisterRes: any) => {
        console.log('Firebase registerRes: ', firebaseRegisterRes);
        return await this.completeUserRegister(firebaseRegisterRes.user, firebaseRegisterRes._tokenResponse.idToken, { firstName, lastName });
      },
      (err) => {
        console.log('Firebase registerRes Error 1: ', err);
        this.logService.addLog(`Firebase registerRes Error 1 ${JSON.stringify(err)}`, 'welcome/services/auth/auth.service.ts');
        return err;
      }
    );
  }

  async signInWithGoogle() {
    this.firebaseService.signInWithGoogle().then(async googleRes => {
      this.loading = await this.loadingController.create({
        cssClass: 'my-custom-class',
        message: 'Signing you up...',
        // duration: 5000
      });
      await this.loading.present();
      console.log('googleRes ', googleRes);
      // get a firebase token 
      const token = await this.firebaseService.getIdToken();
      this.socialSignIn(googleRes.user, token, googleRes.additionalUserInfo.profile.given_name, googleRes.additionalUserInfo.profile.family_name);
    });
  }

  async signInWithApple() {
    throw 'Not Implemented';
  }

  socialSignIn(firebaseUser, token, firstName, lastName) {
    this.uniUserService.checkEmailAppId(firebaseUser.email).subscribe(
      async (emailCheckRes: any) => {
        console.log('emailCheckRes', emailCheckRes);
        if (!emailCheckRes.email) {
          //full register
          console.log('full register')
          await this.completeUserRegister(firebaseUser, token,
            {
              firstName,
              lastName
            });
        }
        else if (!emailCheckRes.appStarted) {
          //app register
          console.log('app register')
          // Send in user = null to reg with new app id and current user
          await this.completeUserRegister(firebaseUser, token, null);
        }
        else if (!emailCheckRes.appCompleted) {
          // set firebase user
          this.firebaseService.firebaseUser = firebaseUser;
          // then set token
          this.storageService['uni-user-token'] = token;
          //consumer reg
          console.log('consumer app register')
          //ready for consumer reg
          this.consumerReg = true; // maybe emit an event to reg consumer
          this.consumerRegChange.next(this.consumerReg);
          this.loading.dismiss();
        }
        else
          this.completeUserLogin(firebaseUser, token);
      });
  }



  async completeUserRegister(firebaseUser, firebaseToken, userDto) {
    // set token
    this.storageService['uni-user-token'] = firebaseToken;
    // set firebase user
    this.firebaseService.firebaseUser = firebaseUser;
    // get device
    var device = await this.deviceService.getDevice();

    // reg with user service

    return this.uniUserService.register(device, userDto).pipe(map(uniUserRegRes => {
      console.log('uniUserRegRes', uniUserRegRes);
        // this.loading.message = 'Almost there';

        //consumer reg
        //ready for consumer reg
        this.consumerReg = true; // maybe emit an event to reg consumer
        this.consumerRegChange.next(this.consumerReg);
        // this.loading.dismiss();

        return uniUserRegRes;
    }));
  }

  async userModuleMarkComplete() {
    this.uniUserService.completeRegistration().subscribe(
      async (completeRegRes: any) => {
        console.log('completeRegRes', completeRegRes);
        if (this.emailCheckRes)
          this.emailCheckRes.consumerComplete = completeRegRes.consumerComplete;

        this.uniUserLoginRes = completeRegRes;
        this.storageService.uniUser = completeRegRes;
        this.loading.dismiss();
      },
      (err) => {
        this.loading.dismiss();
        console.log('UniUser regRes Error: ', err);
        this.logService.addLog(`UniUser regRes Error ${JSON.stringify(err)}`, 'welcome/services/auth/auth.service.ts');
      }
    );
  }

  async loginAndRegisterWithNewAppId(email, password) {
    console.log('loginAndRegisterWithNewAppId', email, password);
    this.loading = await this.loadingController.create({
      cssClass: 'my-custom-class',
      message: 'Signing you up...',
      // duration: 5000
    });
    await this.loading.present();
    this.firebaseService.signInWithEmailAndPassword(email, password).then(async (firebaseLoginRes: any) => {
      // Send in user = null to reg with new app id and current user
      await this.completeUserRegister(firebaseLoginRes.user, firebaseLoginRes._tokenResponse.idToken, null);
    });
  }

  async loginAndRegisterConsumerApp(email, password) {
    console.log('loginAndRegisterConsumerApp', email, password);
    this.loading = await this.loadingController.create({
      cssClass: 'my-custom-class',
      message: 'Signing you up...',
      // duration: 5000
    });
    await this.loading.present();
    this.firebaseService.signInWithEmailAndPassword(email, password).then(async (firebaseLoginRes: any) => {
      // set firebase user
      this.firebaseService.firebaseUser = firebaseLoginRes.user;
      // then set token
      this.storageService['uni-user-token'] = firebaseLoginRes._tokenResponse.idToken;
      //consumer reg
      //ready for consumer reg
      this.consumerReg = true; // maybe emit an event to reg consumer
      this.consumerRegChange.next(this.consumerReg);
      this.loading.dismiss();
    });
  }

  async loginAndRegisterUniUser(email, password, firstName, lastName) {
    console.log('loginAndAndRegisterUniUser', email, password);
    this.loading = await this.loadingController.create({
      cssClass: 'my-custom-class',
      message: 'Signing you up...',
      // duration: 5000
    });
    await this.loading.present();
    this.firebaseService.signInWithEmailAndPassword(email, password).then(async (firebaseLoginRes: any) => {
      await this.completeUserRegister(firebaseLoginRes.user, firebaseLoginRes._tokenResponse.idToken, { firstName, lastName });
    }, (err) => {
      this.loading.dismiss();
      console.log('signInWithEmailAndPassword Error: ', err);
      this.logService.addLog(`signInWithEmailAndPassword Error ${JSON.stringify(err)}`, 'welcome/services/auth/auth.service.ts');
      
      if (err.includes('(auth/wrong-password)')) {
        console.log('firebase mix up as entered password doesnt match previous');
      }
    });
  }

  logout() {
    console.log('logout');
    this.storageService['uni-user-token'] = undefined;
    this.emailCheckRes = undefined;
    this.consumerReg = false;
    this.consumerRegChange.next(this.consumerReg);
    this.consumerLogin = false;
    this.uniUserLoginRes = undefined;
    this.firebaseService.firebaseUser = undefined;
  }
}

import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { StorageService } from 'src/app/services/storage/storage.service';
import { Platform } from '@ionic/angular';
import { TaskService } from 'src/app/home-earn/service/task.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-hire-card',
  templateUrl: './hire-card.component.html',
  styleUrls: ['./hire-card.component.scss'],
})
export class HireCardComponent implements OnInit {
  @Input() card: {
    header: string;
    subHeader: string;
    postTask?: boolean;
    image?: string;
    stepOne: string[];
    stepTwo: string[];
    stepThree: string[];
  };

  stepsOpen: boolean;

  alreadyClosed: boolean;

  /** How many students do we have on here? */
  liveTicker: number = 0;

  constructor(
    private storageService: StorageService,
    private router: Router,
    public platform: Platform,
    private taskService: TaskService
  ) {}

  ngOnInit() {
    this.storageService
      .get(this.card.header)
      .then((res) => {
        // console.log('Has it been closed? ', res.value);
        this.alreadyClosed = res.value;
        if (this.alreadyClosed == true) {
          // console.log('hello');
          this.stepsOpen = false;
        } else if (this.alreadyClosed == null) {
          // console.log('steps open? ', this.stepsOpen);
          this.stepsOpen = true;
        }
      })
      .catch((err) => {
        console.log('closedRes error: ', err);
      });
    this.getLiveTicker();
  }

  postTask() {
    // console.log('postTask() home hire card');
    this.router.navigate(['/new-create-task/0']);
  }

  openSteps() {
    // console.log('opening steps...');
    this.stepsOpen = true;
  }

  closeSteps() {
    // console.log('closing steps...');
    this.stepsOpen = false;
    this.storageService.set(this.card.header, true);
  }

  getLiveTicker() {
    setTimeout(() => {
      // console.log('getLiveTicker() home-hire;');
      this.taskService
        .getLiveTicker()
        .pipe(take(1))
        .subscribe((tickerRes) => {
          // console.log('tickerRes: ', tickerRes);
          this.liveTicker = tickerRes.all;
        });
    }, 1250);
  }
}

import { CommonModule, DatePipe, DecimalPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { SwiperModule } from 'swiper/angular';
import { ManageTaskMoreOptionsComponent } from '../popovers/manage-task-more-options/manage-task-more-options.component';
import { ActivityItemBoughtComponent } from './activity-item-bought/activity-item-bought.component';
import { ActivityItemSellingComponent } from './activity-item-selling/activity-item-selling.component';
import { ActivityItemComponent } from './activity-item/activity-item.component';
import { AddLinkArrayComponent } from './add-link-array/add-link-array.component';
import { AddressSearchComponent } from './address-search/address-search.component';
import { AmazonStyleAccordionComponent } from './amazon-style-accordion/amazon-style-accordion.component';
import { AttachFileArrayComponent } from './attach-file-array/attach-file-array.component';
import { AvatarComponent } from './avatar/avatar.component';
import { CompanyReviewsComponent } from './company-reviews/company-reviews.component';
import { CountdownClockComponent } from './countdown-clock/countdown-clock.component';
import { DoughnutComponent } from './doughnut/doughnut.component';
import { FullWidthViewerComponent } from './full-width-viewer/full-width-viewer.component';
import { GalleryViewComponent } from './gallery-view/gallery-view.component';
import { GenderEthnicityComponent } from './gender-ethnicity/gender-ethnicity.component';
import { ImageCarouselComponent } from './image-carousel/image-carousel.component';
import { ListFieldsComponent } from './list-fields/list-fields.component';
import { LoadingSpinnerComponent } from './loading-spinner/loading-spinner.component';
import { ManageTaskOverviewComponent } from './manage-task-overview/manage-task-overview.component';

import { PlusMinusInputComponent } from './plus-minus-input/plus-minus-input.component';
import { ResultLinkComponent } from './result-link/result-link.component';
import { ResultScreenshotAfterComponent } from './result-screenshot-after/result-screenshot-after.component';
import { ResultScreenshotComponent } from './result-screenshot/result-screenshot.component';
import { ResultTiktokEngagementComponent } from './result-tiktok-engagement/result-tiktok-engagement.component';
import { ServiceSearchResultComponent } from './service-search-result/service-search-result.component';
import { ServicesCardRowComponent } from './services-card-row/services-card-row.component';
import { ShoutResultsAfterComponent } from './shout-results-after/shout-results-after.component';
import { ShoutResultsComponent } from './shout-results/shout-results.component';
import { ShoutStepsHorizComponent } from './shout-steps-horiz/shout-steps-horiz.component';
import { StandardShoutGridCardComponent } from './standard-shout-grid-card/standard-shout-grid-card.component';
import { StandardTaskCardComponent } from './standard-task-card/standard-task-card.component';
import { TaskCommentComponent } from './task-comment/task-comment.component';
import { TitleAndChipsComponent } from './title-and-chips/title-and-chips.component';
import { TitleAvatarRowComponent } from './title-avatar-row/title-avatar-row.component';
import { ToCompanyProfileRowComponent } from './to-company-profile-row/to-company-profile-row.component';
import { JobTypeComponent } from './job-type/job-type.component';
// import { MyShoutRowComponent } from './my-shout-row/my-shout-row.component';
import { IntroMessageComponent } from './intro-message/intro-message.component';
import { BasicInformationComponent } from './basic-information/basic-information.component';
import { EducationComponent } from './education/education.component';
import { ManageTaskListItemComponent } from './manage-task-list-item/manage-task-list-item.component';
import { ResultVideoComponent } from './result-video/result-video.component';
import { NewTaskCardComponent } from './new-task-card/new-task-card.component';
import { LandingCardComponent } from './landing-card/landing-card.component';
import { HorizTaskRowComponent } from '../home-hire-tabs/components/horiz-task-row/horiz-task-row.component';
import { HireCardComponent } from './hire-card/hire-card.component';
import { HorizRecentRolesComponent } from '../home-hire-tabs/components/horiz-recent-roles/horiz-recent-roles.component';
import { PipesModule } from '../pipes/pipes.module';
import { OfferMessageComponent } from '../chat-blaze/components/offer-message/offer-message.component';
import { ImageLoadingComponent } from '../popovers/image-loading/image-loading/image-loading.component';
import { ServiceCardComponent } from '../my-activity-blaze/components/service-card/service-card.component';
import { MyJobsComponent } from '../my-activity-blaze/components/my-jobs/my-jobs.component';
import { DatePickerComponent } from './date-picker/date-picker.component';
import { OfferAttachmentsComponent } from '../chat-blaze/components/offer-attachments/offer-attachments.component';
import { RecruitTaskFilterComponent } from './recruit-task-filter/recruit-task-filter.component';
import { PopoverComponent } from '../services/appflow/popover/popover.component';

import { NgxEditorModule } from 'ngx-editor';
import { LottieModule } from 'ngx-lottie';
import player from 'lottie-web';
import { CustomCalculatorComponent } from './custom-calculator/custom-calculator.component';
import { ProSideMenuButtonComponent } from './pro-side-menu-button/pro-side-menu-button.component';
import { ExperienceCardComponent } from '../view-cv/components/experience-card/experience-card.component';
import { SkillsComponent } from '../view-cv/components/skills/skills.component';
import { SubSkillsComponent } from '../view-cv/components/sub-skills/sub-skills.component';
import { NewTaskCardLinkedinComponent } from './new-task-card-linkedin/new-task-card-linkedin.component';
import { CountUpDirective } from './hire-card/count-up.directive';
import { LinkedinTaskProfileHeaderComponent } from './linkedin-task-profile-header/linkedin-task-profile-header.component';
import { JobAlertPromptComponent } from './job-alert-prompt/job-alert-prompt.component';
import { DinosaurGameComponent } from './dinosaur-game/dinosaur-game.component';
import { GallerySwiperComponent } from './gallery-swiper/gallery-swiper.component';
import { HireCardCategoriesComponent } from './hire-card-categories/hire-card-categories.component';
import { AdhocDateofbirthComponent } from './adhoc-dateofbirth/adhoc-dateofbirth.component';
import { StudentInboxItemComponent } from '../student-inbox/molecules/student-inbox-item/student-inbox-item.component';
import { MobileActivityInboxItemComponent } from '../mobile-activity-buying/molecules/mobile-activity-inbox-item/mobile-activity-inbox-item.component';
import { FirebaseChatBlockComponent } from '../desktop-activity/components/firebase-chat-block/firebase-chat-block.component';
// import { StudentEarnComponentsModule } from '../student-earn-tabs/components/student-earn-components.module';

export function playerFactory() {
  return player;
}
@NgModule({
  providers: [DatePipe, DecimalPipe],
  declarations: [
    ImageCarouselComponent,
    StandardTaskCardComponent,
    StandardShoutGridCardComponent,
    LoadingSpinnerComponent,
    AmazonStyleAccordionComponent,
    AvatarComponent,
    DoughnutComponent,
    TaskCommentComponent,
    AttachFileArrayComponent,
    AddLinkArrayComponent,
    ListFieldsComponent,
    GenderEthnicityComponent,
    ActivityItemSellingComponent,
    ActivityItemComponent,
    ActivityItemBoughtComponent,
    ToCompanyProfileRowComponent,
    FullWidthViewerComponent,
    TitleAvatarRowComponent,
    ShoutStepsHorizComponent,
    CountdownClockComponent,
    ManageTaskOverviewComponent,
    ManageTaskMoreOptionsComponent,
    CompanyReviewsComponent,
    ServiceSearchResultComponent,
    GalleryViewComponent,
    PlusMinusInputComponent,
    AddressSearchComponent,
    ServicesCardRowComponent,
    TitleAndChipsComponent,
    ShoutResultsComponent,
    ShoutResultsAfterComponent,
    ResultScreenshotComponent,
    ResultScreenshotAfterComponent,
    ResultLinkComponent,
    ResultTiktokEngagementComponent,
    ResultVideoComponent,
    JobTypeComponent,
    // MyShoutRowComponent,
    IntroMessageComponent,
    BasicInformationComponent,
    EducationComponent,
    ManageTaskListItemComponent,
    NewTaskCardComponent,
    NewTaskCardLinkedinComponent,
    LandingCardComponent,
    HorizTaskRowComponent,
    HireCardComponent,
    HireCardCategoriesComponent,
    HorizRecentRolesComponent,
    OfferMessageComponent,
    ImageLoadingComponent,
    ServiceCardComponent,
    MyJobsComponent,
    DatePickerComponent,
    OfferAttachmentsComponent,
    RecruitTaskFilterComponent,
    PopoverComponent,
    CustomCalculatorComponent,
    ProSideMenuButtonComponent,
    ExperienceCardComponent,
    SkillsComponent,
    SubSkillsComponent,
    CountUpDirective,
    LinkedinTaskProfileHeaderComponent,
    JobAlertPromptComponent,
    DinosaurGameComponent,
    GallerySwiperComponent,
    AdhocDateofbirthComponent,
    StudentInboxItemComponent,
    MobileActivityInboxItemComponent,
    FirebaseChatBlockComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    IonicModule,
    SwiperModule,
    PipesModule,
    NgxEditorModule,
    // StudentEarnComponentsModule,
    LottieModule.forRoot({ player: playerFactory }),
  ],
  exports: [
    ImageCarouselComponent,
    StandardTaskCardComponent,
    StandardShoutGridCardComponent,
    LoadingSpinnerComponent,
    AmazonStyleAccordionComponent,
    AvatarComponent,
    DoughnutComponent,
    TaskCommentComponent,
    AttachFileArrayComponent,
    AddLinkArrayComponent,
    ListFieldsComponent,
    GenderEthnicityComponent,
    ActivityItemSellingComponent,
    ActivityItemComponent,
    ActivityItemBoughtComponent,
    ToCompanyProfileRowComponent,
    FullWidthViewerComponent,
    TitleAvatarRowComponent,
    ShoutStepsHorizComponent,
    CountdownClockComponent,
    ManageTaskOverviewComponent,
    ManageTaskMoreOptionsComponent,
    CompanyReviewsComponent,
    ServiceSearchResultComponent,
    GalleryViewComponent,
    PlusMinusInputComponent,
    AddressSearchComponent,
    ServicesCardRowComponent,
    TitleAndChipsComponent,
    ShoutResultsComponent,
    ShoutResultsAfterComponent,
    ResultScreenshotComponent,
    ResultScreenshotAfterComponent,
    ResultLinkComponent,
    ResultTiktokEngagementComponent,
    ResultVideoComponent,
    JobTypeComponent,
    // MyShoutRowComponent,
    IntroMessageComponent,
    BasicInformationComponent,
    EducationComponent,
    ManageTaskListItemComponent,
    NewTaskCardComponent,
    NewTaskCardLinkedinComponent,
    LandingCardComponent,
    HorizTaskRowComponent,
    HireCardComponent,
    HireCardCategoriesComponent,
    HorizRecentRolesComponent,
    OfferMessageComponent,
    ImageLoadingComponent,
    ServiceCardComponent,
    MyJobsComponent,
    DatePickerComponent,
    OfferAttachmentsComponent,
    RecruitTaskFilterComponent,
    PopoverComponent,
    CustomCalculatorComponent,
    ProSideMenuButtonComponent,
    ExperienceCardComponent,
    SkillsComponent,
    SubSkillsComponent,
    LinkedinTaskProfileHeaderComponent,
    JobAlertPromptComponent,
    DinosaurGameComponent,
    GallerySwiperComponent,
    AdhocDateofbirthComponent,
    StudentInboxItemComponent,
    MobileActivityInboxItemComponent,
    FirebaseChatBlockComponent
  ],
})
export class ComponentsModule {}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AddressSearchWithMapPage } from 'src/app/address-search-with-map/address-search-with-map.page';


declare var google: any;

@Component({
  selector: 'app-address-search',
  templateUrl: './address-search.component.html',
  styleUrls: ['./address-search.component.scss'],
})
export class AddressSearchComponent implements OnInit {

  /** Has a default address been provided? */
  @Input('defaultAddress') defaultAddress: any;

  /** Emit the final address */
  @Output('selectedAddress') finalAddress = new EventEmitter();

  /** Finalised address */
  selectedAddress: string;

  constructor(
    private modalController: ModalController,
  ) { }

  ngOnInit() {
    setTimeout(() => {
      console.log("defaultAddress", this.defaultAddress);
      this.getAddress(this.defaultAddress);
    }, 1000);
  }

  async openLocationPage() {
    console.log('openLocationPage()');
    const modal = await this.modalController.create({
      component: AddressSearchWithMapPage,
      cssClass: 'my-custom-class',
      componentProps: {
        colourCoding: "service"
      }
    });
    await modal.present();

    modal.onDidDismiss().then(data => {
      console.log('Data: ', data);
      this.selectedAddress = data.data.address;
      
      this.finalAddress.emit(data.data);
    })
  }

  getAddress(coords) {
    console.log('getAddress(): ', coords);
    console.log('selectedAddress: ', this.selectedAddress);

    let type = typeof coords;
    console.log('type: ', type);
    if (typeof coords === "string") {
      this.selectedAddress = coords;
    } else if (coords) {
      let geocoder = new google.maps.Geocoder();
      geocoder.geocode({ location: { lat: coords.lat, lng: coords.lng } }, (results) => {
        this.selectedAddress = results && results.length ? results[0].formatted_address : "";
        if (results && results.length) {
          return results[0].formatted_address;
        } else {
          return 'empty'
        }
      })
    }
  }
}

import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { StorageService } from 'src/app/services/storage/storage.service';
import { MessageResponse } from '../../service/chat-blaze.service';
import DOMPurify from "dompurify";

@Component({
  selector: 'app-offer-message',
  templateUrl: './offer-message.component.html',
  styleUrls: ['./offer-message.component.scss'],
})
export class OfferMessageComponent implements OnInit {

  @Input('focussedApplicant') focussedApplicant: any;

  /** Focussed Message */
  focussedMessage: MessageResponse

  constructor(
    private storageService: StorageService, 
    private datePipe: DatePipe,
    private sanitizer: DomSanitizer
    ) { }

  ngOnInit() {
    console.log('message: ', this.focussedApplicant)
    if (this.focussedApplicant.message.trim().length > 0) {
      this.focussedMessage = this.focussedApplicant.message;
    }
  }

  isMyMessage() {
    if (this.focussedMessage && (this.storageService.uniTaskrUser.userId === this.focussedApplicant.userId)) {
      return true;
    } else {
      return false;
    }
  }

  getNameColour() {
    let hexString = this.focussedApplicant.userId ? this.focussedApplicant.userId.toString(16) : this.focussedApplicant.adminUserId.toString(16);
    let intColour = parseInt(hexString, 16);
    return `color: #${intColour}; font-weight: bold;`
  }

  getIsAdminChatter() {
    if (this.focussedMessage.adminUserId) {
      return `(UniTaskr Admin)`
    }
  }

  getSentTime() {
    if (this.focussedMessage && this.focussedApplicant.offerDt) {
      if (this.isToday(this.focussedApplicant.offerDt)) {
        return this.datePipe.transform(this.focussedApplicant.offerDt, 'HH:mm');
      } else {
        return this.datePipe.transform(this.focussedApplicant.offerDt, 'EE dd/MM HH:mm');
      }
    }
  }

  isToday(dateValue: string): boolean {
    let today = new Date();
    if (today.toISOString().split('T')[0] == new Date(dateValue).toISOString().split('T')[0]) {
      return true;
    } else {
      return false;
    }
  }

  getSanitized(htmlString: string) {
    let output = htmlString.replace("\n", "<br> <br>");
    const sanitizedContent = DOMPurify.sanitize(output);
    return this.sanitizer.bypassSecurityTrustHtml(sanitizedContent);
  }

}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-plus-minus-input',
  templateUrl: './plus-minus-input.component.html',
  styleUrls: ['./plus-minus-input.component.scss'],
})
export class PlusMinusInputComponent implements OnInit {

  /** Do we need placeholder text? */
  @Input('placeholder') placeholder: string;

  /** Are we inputting a currency value? */
  @Input('currency') currency: string;

  /** Default assigned value */
  @Input('defaultValue') currentValue: number;

  /** Tell the consumer the decided value */
  @Output('finalValue') finalValue = new EventEmitter();

  constructor() { }

  ngOnInit() { }

  getCurrency(): string {
    switch (this.currency) {
      case "GBP":
        return "£";
      case "USD":
        return "$";
    }
  }

  updatedValue() {
    this.finalValue.emit(this.currentValue);
  }

  adjustTotal(direction) {
    if (direction == "minus" && this.currentValue > 0) {
      this.currentValue --;
    } else {
      this.currentValue ++;
    }
  }

}

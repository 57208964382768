import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';


export interface ServicesState {
  services: any;
  servicesLastUpdated: string;
}

export function createInitialState(): ServicesState {
  return {
    services: null,
    servicesLastUpdated: '',
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'services' })
export class ServicesStore extends Store<ServicesState> {
  constructor() {
    super(createInitialState());
  }
}

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { doc, getFirestore, setDoc } from '@firebase/firestore';

import { ActionPerformed, PushNotificationSchema, PushNotifications, Token } from '@capacitor/push-notifications';
import { AlertController, Platform } from '@ionic/angular';
import { first, take, tap } from 'rxjs/operators';
import { HttpService } from '../http/http.service';

@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  /** My FCM Token */
  myFCMToken: string;

  /** Notification Counter Object  */
  notificationConuter: {
    notifications: number;
    messages: number;
  };

  /** Total notification count for display */
  totalNotifications: number = 0;

  /** Notifications request 30 sec timeout */
  notificationsTimeout: any;

  /** Should we not ask to register notifications again */
  hasAsked: boolean = false;
  
  constructor(private router: Router, private http: HttpService, private platform: Platform) {}

  checkPermission() {
    if (this.platform.is('capacitor')) {
      return PushNotifications.checkPermissions();
    } else {
      return Promise.reject();
      // return null;
    }
  }

  checkForNewNotifications() {
    this.getNotificationCount()
      .pipe(take(1))
      .subscribe((counterRes) => {
        this.notificationConuter = counterRes;
        this.totalNotifications = counterRes.notifications + counterRes.messages;
        this.notificationsTimeout = setTimeout(() => {
          this.checkForNewNotifications();
        }, 10000);
      });
  }

  async addListeners() {
    await PushNotifications.addListener('registration', (token: any) => {
      console.log('Push registration success, token: ', token);
      // alert('Push registration success, token: ' + token.value);
      this.myFCMToken = token.value;

      this.updateFCMToken(token.value).subscribe(
        (fcmRes) => {
          console.log('1 fcmRes updated token: ', fcmRes);
        },
        (err) => {
          console.log('fcmRes error updating token: ', err);
        }
      );
    });

    await PushNotifications.addListener('registrationError', (err) => {
      console.error('Registration error: ', err.error);
    });

    await PushNotifications.addListener('pushNotificationReceived', (notification) => {
      console.log('Push notification received: ', notification);
    });

    await PushNotifications.addListener('pushNotificationActionPerformed', (notification) => {
      console.log('Push notification action performed', notification.actionId, notification.inputValue);
    });
  }

  async registerNotifications() {
    console.log('NNN Notification Service: registerNotifications()');
    let permStatus = await PushNotifications.checkPermissions();

    if (permStatus.receive === 'prompt') {
      permStatus = await PushNotifications.requestPermissions();
    }

    if (permStatus.receive !== 'granted') {
      throw new Error('User denied permissions!');
    }

    if (permStatus.receive === 'granted') {
      // Register with Apple / Google to receive push via APNS/FCM
      console.log('*** please reg for Notifications');
      PushNotifications.register();
    }

    // return PushNotifications.register();
  }

  async getDeliveredNotifications() {
    const notificationList = await PushNotifications.getDeliveredNotifications();
    console.log('delivered notifications', notificationList);
  }

  async requestPermission() {
    // Request permission to use push notifications
    // iOS will prompt user and return if they granted permission or not
    // Android will just grant without prompting
    return PushNotifications.requestPermissions().then((result) => {
      console.log('Permission request result: ', result);
      if (result.receive === 'granted') {
        // Register with Apple / Google to receive push via APNS/FCM
        PushNotifications.register();
        this.initNotifications();
      } else {
        // Show some error
      }
    });
  }

  androidInitNotifications() {
    if (this.platform.is('capacitor')) {
      PushNotifications.register();
      this.initNotifications();
    }
  }

  checkAndSubscribe() {
    try {
      this.checkPermission().then((perms) => {
        if (perms.receive == 'granted') {
          this.initNotifications();
        }
      });
    } catch (e) {
      // console.log('No cap')
    }
  }

  initNotifications() {
    console.log('Initializing Notifications');

    if (this.platform.is('capacitor')) {
      // On success, we should be able to receive notifications
      PushNotifications.addListener('registration', (token: any) => {
        console.log('Push registration success, token: ', token);
        // alert('Push registration success, token: ' + token.value);
        this.myFCMToken = token.value;

        this.updateFCMToken(token.value).subscribe(
          (fcmRes) => {
            console.log('2 fcmRes updated token: ', fcmRes);
          },
          (err) => {
            console.log('fcmRes error updating token: ', err);
          }
        );
      });

      // Some issue with our setup and push will not work
      PushNotifications.addListener('registrationError', (error: any) => {
        console.log('Error on registration: ', error);
        alert('Error on registration: ' + JSON.stringify(error));
      });

      // Show us the notification payload if the app is open on our device
      PushNotifications.addListener('pushNotificationReceived', (notification: PushNotificationSchema) => {
        console.log('Push received: ' + JSON.stringify(notification));
        // alert('Push received: ' + JSON.stringify(notification));
      });

      // Method called when tapping on a notification
      PushNotifications.addListener('pushNotificationActionPerformed', (notification: any) => {
        console.log('Push action performed: ' + JSON.stringify(notification));
        console.log('Push action performed: ', notification);
        this.router.navigate([`/${notification.notification.data.path}`]);
      });
    }
  }

  newInitNotifications() {
    console.log('newInitNotifications()');
    // Request permission to use push notifications
    // iOS will prompt user and return if they granted permission or not
    // Android will just grant without prompting
    PushNotifications.requestPermissions()
      .then((result) => {
        console.log('requestPermissions result: ', result);

        if (result.receive === 'granted' && !this.hasAsked) {
          this.hasAsked = true;
          // Register with Apple / Google to receive push via APNS/FCM
          console.log('Register push notifications please...');
          PushNotifications.register();
        } else {
          // Show some error
        }
      })
      .catch((err) => {
        console.log('requestPermissions result ERROR: ', err);
      });

    // On success, we should be able to receive notifications
    PushNotifications.addListener('registration', (token: Token) => {
      console.log('Notifications new token place found: ', token);

      // alert('Push registration success, token: ' + token.value);
      this.myFCMToken = token.value;

      this.updateFCMToken(token.value).subscribe(
        (fcmRes) => {
          console.log('3 fcmRes updated token: ', fcmRes);
        },
        (err) => {
          console.log('fcmRes error updating token: ', err);
        }
      );
    });

    // Some issue with our setup and push will not work
    PushNotifications.addListener('registrationError', (error: any) => {
      alert('Error on registration: ' + JSON.stringify(error));
    });

    // Show us the notification payload if the app is open on our device
    PushNotifications.addListener('pushNotificationReceived', (notification: PushNotificationSchema) => {
      // alert('Push received: ' + JSON.stringify(notification));
      console.log('Push received: ' + JSON.stringify(notification));
    });

    // Method called when tapping on a notification
    PushNotifications.addListener('pushNotificationActionPerformed', (notification: ActionPerformed) => {
      // alert('Push action performed: ' + JSON.stringify(notification));
      this.router.navigate([`/${notification.notification.data.path}`]);
    });
  }

  sendUserNotification(message: string, userId: number, title: string, path: string) {
    return this.http.runHttpCall('POST', `api/notification/push`, 'application/json', {
      userId,
      message,
      title,
      path,
    });
  }

  updateFCMToken(token: string) {
    let deviceType = 1;
    if (this.platform.is('android')) {
      deviceType = 0;
    }
    if (this.platform.is('ios')) {
      deviceType = 1;
    }

    return this.http.runHttpCall('PUT', `api/usertoken`, 'application/json', {
      token: token,
      deviceType: deviceType,
    });
  }

  getMyInAppNotifications(skip: number) {
    return this.http.runHttpCall('POST', 'api/notification/inbox/in-app', 'application/json', {
      take: 10,
      skip: skip,
    });
  }

  sendNotification(notification: any) {
    return this.http.runHttpCall('POST', 'api/notification/in-app-push', 'application/json', notification);
  }

  getNotificationCount() {
    return this.http.runHttpCall('POST', 'api/notification/inbox/in-app/notification-counter', 'application/json');
  }

  markRead(notificationId: number) {
    return this.http
      .runHttpCall('POST', `api/notification/inbox/in-app/${notificationId}/mark-as-read`, 'application/json')
      .pipe(
        tap(() => {
          this.getNotificationCount()
            .pipe(take(1))
            .subscribe((counterRes) => {
              this.notificationConuter = counterRes;
              this.totalNotifications = counterRes.notifications + counterRes.messages;
            });
        })
      );
  }

  markAllRead() {
    PushNotifications.removeAllDeliveredNotifications();
    return this.http.runHttpCall('POST', `api/notification/inbox/in-app/mark-as-read`, 'application/json');
  }

  resetNotifications(userId: number) {
    console.log('****** resetNotifications() ******: ', userId);
    const db = getFirestore();
    try {
      setDoc(
        doc(db, 'app-badge', `${userId}`),
        {
          count: 0,
          timestamp: new Date(),
        },
        { merge: true }
      )
        .then((docSet) => {
          console.log('Reset notifications Doc set: ', docSet);
          PushNotifications.removeAllDeliveredNotifications();
          console.log('1 reset notifications');
        })
        .catch((err) => {
          console.log('Error setting doc: ', err);
        });

      console.log('2 reset notifications');
      PushNotifications.removeAllDeliveredNotifications();
    } catch (e) {
      console.error('Error adding document: ', e);
    }
  }
}

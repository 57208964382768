import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Browser } from '@capacitor/browser';
import DOMPurify from "dompurify";
import { DomSanitizer } from '@angular/platform-browser';
import { ImageService } from 'src/app/services/image/image.service';

@Component({
  selector: 'app-company-profile-shout',
  templateUrl: './company-profile-shout.page.html',
  styleUrls: ['./company-profile-shout.page.scss'],
})
export class CompanyProfileShoutPage implements OnInit {

  /** Incoming company information */
  @Input('businessInfo') businessInfo;

  constructor(
    private modalController: ModalController,
    private sanitizer: DomSanitizer,
    public imageService: ImageService
  ) { }

  ngOnInit() {
  }


  getImage(imageUrl: string): string {
    if (imageUrl && imageUrl.length > 0) {
      return imageUrl;
    }
    else {
      return "../../../assets/images/placeholder.jpeg";
    }
  }

  getFlag(countryCode) {
    switch (countryCode) {
      case "GB":
        return "🇬🇧";
      case "US":
        return "🇺🇸";
    }
  }

  close() {
    this.modalController.dismiss();
  }

  getSanitized(htmlString: string) {
    let output = htmlString.replace("\n", "<br> <br>");
    const sanitizedContent = DOMPurify.sanitize(output);
    return this.sanitizer.bypassSecurityTrustHtml(sanitizedContent);
  }

  async goToInstagram() {
    await Browser.open({ url: `https://www.instagram.com/${this.businessInfo.instagram.replace('@', '')}` });
  }

  async goToWebsite() {
    console.log('Browser URL: ', `https://${this.businessInfo.website}/`);
    await Browser.open({ url: `https://${this.businessInfo.website}/` });
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { CvService, JobTypeResponse } from 'src/app/cv-builder-new/service/cv.service';
import { StorageService } from 'src/app/services/storage/storage.service';

@Component({
  selector: 'app-sub-skills',
  templateUrl: './sub-skills.component.html',
  styleUrls: ['./sub-skills.component.scss'],
})
export class SubSkillsComponent implements OnInit {

  @Input('jobType') jobType;
  @Input('selectedSkills') selectedSkills;

  /** Which jobtype is focussed and are we adding skills for? */
  focussedJobType: JobTypeResponse;

  /** Skills for the focussed job type */
  focussedJobSkills: any;

  constructor(
    private cvService: CvService,
    private storageService: StorageService
  ) { }

  ngOnInit() {
    this.focusJobType(this.jobType);
  }

  focusJobType(jobType) {
    this.focussedJobType = jobType;

    // Get MY skills for this job type
    this.cvService.viewCv(this.storageService.uniTaskrUser.userId).subscribe(skillRes => {
      console.log('mySkills: ', skillRes);
    })

    // Get skills for this job type.
    this.cvService.getJobSkills(jobType.jobIndustriesId).subscribe(skillsRes => {
      console.log('skillsRes: ', skillsRes);
      this.focussedJobSkills = skillsRes;
    })
  }

}

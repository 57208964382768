// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-row {
  width: 100%;
}
ion-row app-avatar {
  width: 100%;
  position: relative;
  top: 35px;
}
ion-row ion-chip {
  --background: var(--ion-color-dark-contrast);
  --color: var(--ion-color-dark);
}
ion-row ion-chip .category_image {
  height: 100%;
  width: 7vw;
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1439px) {
  ion-row ion-chip .category_image {
    width: 3vw;
  }
}
@media only screen and (min-device-width: 1440px) {
  ion-row ion-chip .category_image {
    width: 2vw;
  }
}
ion-row .header_row {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  min-height: 190px;
  background: var(--ion-color-light);
  border-radius: 15px;
  padding-top: 48px !important;
}
ion-row .header_row ion-row {
  min-height: 21px;
  width: 100%;
  justify-content: center;
}
ion-row h3 {
  margin: 0;
  color: var(--landing-text);
  font-family: Poppins-medium;
  font-size: 0.9rem;
  padding-bottom: 10px;
}
ion-row p {
  margin: 0;
  text-align: center;
}
ion-row .title_p {
  font-family: Poppins-semibold;
  margin: 5px 0;
}
ion-row .hired_p {
  font-size: 0.8rem;
  color: var(--bg-light-grey);
}`, "",{"version":3,"sources":["webpack://./src/app/components/linkedin-task-profile-header/linkedin-task-profile-header.component.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;AACF;AAAE;EACE,WAAA;EACA,kBAAA;EACA,SAAA;AAEJ;AAAE;EACE,4CAAA;EACA,8BAAA;AAEJ;AADI;EACE,YAAA;EAEA,UAAA;AAEN;AADM;EAJF;IAKI,UAAA;EAIN;AACF;AAHM;EAPF;IAQI,UAAA;EAMN;AACF;AAHE;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,yBAAA;EACA,iBAAA;EACA,kCAAA;EACA,mBAAA;EACA,4BAAA;AAKJ;AAJI;EACE,gBAAA;EACA,WAAA;EACA,uBAAA;AAMN;AAHE;EACE,SAAA;EACA,0BAAA;EACA,2BAAA;EACA,iBAAA;EACA,oBAAA;AAKJ;AAHE;EACE,SAAA;EACA,kBAAA;AAKJ;AAHE;EACE,6BAAA;EACA,aAAA;AAKJ;AAHE;EACE,iBAAA;EACA,2BAAA;AAKJ","sourcesContent":["ion-row {\n  width: 100%;\n  app-avatar {\n    width: 100%;\n    position: relative;\n    top: 35px;\n  }\n  ion-chip {\n    --background: var(--ion-color-dark-contrast);\n    --color: var(--ion-color-dark);\n    .category_image {\n      height: 100%;\n      // margin-right: 4px;\n      width: 7vw;\n      @media only screen and (min-device-width: 768px) and (max-device-width: 1439px) {\n        width: 3vw;\n      }\n      @media only screen and (min-device-width: 1440px) {\n        width: 2vw;\n      }\n    }\n  }\n  .header_row {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: flex-end;\n    min-height: 190px;\n    background: var(--ion-color-light);\n    border-radius: 15px;\n    padding-top: 48px !important;\n    ion-row {\n      min-height: 21px;\n      width: 100%;\n      justify-content: center;\n    }\n  }\n  h3 {\n    margin: 0;\n    color: var(--landing-text);\n    font-family: Poppins-medium;\n    font-size: 0.9rem;\n    padding-bottom: 10px;\n  }\n  p {\n    margin: 0;\n    text-align: center;\n  }\n  .title_p {\n    font-family: Poppins-semibold;\n    margin: 5px 0;\n  }\n  .hired_p {\n    font-size: 0.8rem;\n    color: var(--bg-light-grey);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

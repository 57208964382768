import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StudentProfileService {

  /** Application Attachments */
  applicationAttachments: any[];

  /** Application Links */
  applicationLinks: string;

  constructor() { }
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { LoadingController, ToastController } from '@ionic/angular';
import { initializeApp } from 'firebase/app';
import { collection, doc, getFirestore, onSnapshot, orderBy, query, where } from 'firebase/firestore';
import { FirebaseChatService } from 'src/app/services/firebase-chat/firebase-chat-service.service';
import { ImageService } from 'src/app/services/image/image.service';
import { StorageService } from 'src/app/services/storage/storage.service';
import { ApplicantsService } from 'src/app/task-manage/service/applicant.service';
import { LocalUserService } from 'src/app/welcome/services/local-user/local-user.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-mobile-activity-inbox-item',
  templateUrl: './mobile-activity-inbox-item.component.html',
  styleUrls: ['./mobile-activity-inbox-item.component.scss'],
})
export class MobileActivityInboxItemComponent implements OnInit {

  @Input('applicant') applicant: any;
  @Input('desktopMode') desktopMode: boolean = false;

  @Output('selectedChatOut') selectedChatOut: any = new EventEmitter<any>();

  assocChat: any;

  firebaseChatChannelReference: any;


  app = initializeApp(environment.firebaseConfig);
  db = getFirestore(this.app);

  constructor(
    private router: Router,
    private loadingController: LoadingController,
    private toastController: ToastController,
    private storageService: StorageService,
    public imageService: ImageService,
    public localUserService: LocalUserService,
    private firebaseChatService: FirebaseChatService,
    private applicantService: ApplicantsService
  ) { }

  ngOnInit() {
    this.assocChat = this.firebaseChatService.getChatByOfferId(this.applicant.taskOfferId);
    // console.log('assocChat: ', this.assocChat);
    if (this.assocChat.reference) {
      this.listenToChanges(this.assocChat.reference)
    }
  }

  async listenToChanges(chatChannelReference: any) {
    // console.log('>>> listenToChatChanges() <<<: ', chatChannelReference);
    this.firebaseChatChannelReference = chatChannelReference;

    // this.$chatSub = onSnapshot(doc(this.db, environment.firebase_chat.channels, this.applicant.taskOfferId), (doc) => {
    // this.$chatSub = onSnapshot(chatChannelReference, (doc) => {
    //   console.log("Current data: ", doc.data());
    //   this.assocChat = doc.data();
    // });
  }

  ngOnDestroy() {
    // this.$chatSub();
  }


  convertTimestamp(fbTime: any) {
    try {
      return fbTime.toDate();
    } catch {
      return fbTime;
    }
  }

  isUnseen() {
    if (this.assocChat && this.assocChat.data && this.assocChat.data.readBy && this.assocChat.data.readBy.indexOf(this.storageService.firebaseUser.uid) == -1) {
      return true;
    }
    return false;
  }

  openChat() {
    // console.log('openChat(): ', this.assocChat);
    if (this.assocChat) {
      /** Nav to chat-hire-mobile */
      if (this.desktopMode == false) {
        // console.log('Nav to: chat-hire-mobile')
        this.router.navigate([`/chat-hire-mobile/${this.applicant.taskOfferId}`])
      } else {
        console.log('Emit desktop chat task offer: ', this.applicant.taskOfferId);
        this.selectedChatOut.emit({
          applicant: this.applicant,
          chat: this.assocChat
        });
      }
    } else {
      /** Create new chat */
      console.log('No assoc chat -> create new one..')
    }
  }

  async shortListOffer(selectedApplicant) {
    const loading = await this.loadingController.create({
      cssClass: 'my-custom-class',
      message: 'Please wait...',
      duration: 2000,
    });
    await loading.present();
    let value = selectedApplicant.isShorted ? false : true;

    this.applicantService.shortList(selectedApplicant.taskOfferId, value).subscribe(
      async (shortRes) => {
        const shortToast = await this.toastController.create({
          message: 'Added to Shortlist',
          duration: 2000,
          position: 'middle',
        });
        const unShortToast = await this.toastController.create({
          message: 'Removed from Shortlist',
          duration: 2000,
          position: 'middle',
        });
        // console.log('shortRes: ', shortRes);
        selectedApplicant.isShorted = value;
        loading.dismiss();
        if (shortRes.isShorted == 1) {
          await shortToast.present();
        } else if (shortRes.isShorted == 0) {
          await unShortToast.present();
        }
      },
      async (err) => {
        console.log('shortRes error: ', err);
        loading.dismiss();
      }
    );
  }
}

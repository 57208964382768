import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from 'src/app/services/http/http.service';
import { ShoutStepsResponse } from '../shout-profile.page';

@Injectable({
  providedIn: 'root'
})
export class ShoutDetailsService {

  /** The focussed shout we're looking at */
  focussedShout;
  focussedTaskId;

  constructor(
    private http: HttpService
  ) { }

  getShoutDashboard() {
    return this.http.runHttpCall(
      "GET",
      `api/shout/dashboard`,
      "application/json")
  }

  getShoutInviteList() {
    return this.http.runHttpCall(
      "GET",
      `api/shout/invite-list`,
      "application/json")
  }

  getShoutSteps(taskId: number): Observable<ShoutStepsResponse> {
    return this.http.runHttpCall(
      "GET",
      `api/shout/${taskId}/steps`,
      "application/json")
  }

  joinShout(taskId: number): Observable<any> {
    return this.http.runHttpCall(
      "GET",
      `api/shout/${taskId}/join`,
      "application/json")
  }

  quitShout(taskId: number): Observable<any> {
    return this.http.runHttpCall(
      "GET",
      `api/shout/${taskId}/quit`,
      "application/json")
  }

  cancelApplication(taskId: number): Observable<any> {
    return this.http.runHttpCall(
      "GET",
      `api/shout/${taskId}/cancel-application`,
      "application/json")
  }

  undoDecline(taskId: number): Observable<any> {
    return this.http.runHttpCall(
      "GET",
      `api/shout/${taskId}/undo-decline`,
      "application/json")
  }

  getUniTaskrFacts() {
    return this.http.runHttpCall(
      "GET",
      `api/unitaskrfact`,
      "application/json",
    )
  }

  setStudentCheck(taskId, stepId, value) {
    return this.http.runHttpCall(
      "POST",
      `api/shout/${taskId}/step/${stepId}/set-student-check`,
      "application/json",
      {
        isStudentCheck: value
      }
    )
  }

  getFormRef(taskId) {
    return this.http.runHttpCall(
      "GET",
      `api/shout/${taskId}/formref`,
      "application/json")
  }

  getFormDisplay(taskId) {
    return this.http.runHttpCall(
      "GET",
      `api/shout/${taskId}/form-display`,
      "application/json")
  }

  getShoutResults(taskId) {
    return this.http.runHttpCall(
      "GET",
      `api/shout/${taskId}/result`,
      "application/json")
  }

  getShoutManagers(taskId) {
    return this.http.runHttpCall(
      "GET",
      `api/shout/${taskId}/campaign-managers`,
      "application/json",
    )
  }

  markShoutResultsComplete(taskId: number) {
    return this.http.runHttpCall(
      "GET",
      `api/shout/${taskId}/mark-complete`,
      "application/json",
    )
  }

  /**
   * Submit Shout results
   */

  resultScreenshot(taskId, displayOrder, formRefId, imgLocation) {
    return this.http.runHttpCall(
      "POST",
      `api/shout/${taskId}/result/screenshot`,
      "application/json",
      {
        displayOrder: displayOrder,
        url: imgLocation,
        formRefId: formRefId
      }
    )
  }

  removeResultScreenshot(screenId, taskId) {
    return this.http.runHttpCall(
      "DELETE",
      `api/shout/${taskId}/result/screenshot/${screenId}`,
      "application/json"
    )
  }

  resultSpotifyInsight(taskId, displayOrder, formRefId, views, impressions, stickerTaps, websiteClicks, profileVisits) {
    return this.http.runHttpCall(
      "POST",
      `api/shout/${taskId}/result/spotify-insight`,
      "application/json",
      {
        displayOrder,
        views,
        impressions,
        stickerTaps,
        websiteClicks,
        profileVisits,
        formRefId
      }
    )
  }

  resultPoll(taskId, displayOrder, formRefId, A, B, C, D) {
    return this.http.runHttpCall(
      "POST",
      `api/shout/${taskId}/result/poll`,
      "application/json",
      {
        displayOrder,
        pollA: A ? A : 0,
        pollB: B ? B : 0,
        pollC: C ? C : 0,
        pollD: D ? D : 0,
        formRefId
      }
    )
  }

  resultTikTokEngagement(taskId, displayOrder, formRefId, views, shares, comments, likes) {
    return this.http.runHttpCall(
      "POST",
      `api/shout/${taskId}/result/tiktokengagement`,
      "application/json",
      {
        displayOrder: Number(displayOrder),
        views: Number(views),
        likes: Number(likes),
        shares: Number(shares),
        formRefId: Number(formRefId),
        comments: Number(comments),
      }
    )
  }

  resultOther(taskId, displayOrder, formRefId, text) {
    return this.http.runHttpCall(
      "POST",
      `api/shout/${taskId}/result/other`,
      "application/json",
      {
        displayOrder,
        text,
        formRefId
      }
    )
  }

  seenSteps(taskId) {
    return this.http.runHttpCall(
      "POST",
      `api/shout/${taskId}/steps/seen`,
      "application/json",
    )
  }
}

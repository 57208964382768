// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.heading-2 {
  text-align: center;
  font-weight: bold;
  margin-top: 5%;
  margin-left: 2.5%;
  margin-right: 2.5%;
  color: var(--ion-color-secondary-contrast);
}

.p {
  text-align: center;
  margin-top: 3%;
  font-weight: normal;
  margin-left: 2.5%;
  margin-right: 2.5%;
  color: var(--ion-color-secondary-contrast);
}

.content {
  --padding-top: 12%;
  --background: linear-gradient(
    90deg,
    rgba(132, 22, 244, 1) 0%,
    rgba(7, 206, 213, 1) 100%
  );
}

input[type=file] {
  display: none;
}

.custom-file-upload {
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
}

ion-card {
  background: var(--ion-color-secondary-contrast);
}
ion-card ion-card-title {
  text-align: center;
}

.attachment_btn {
  --background: var(--bg-very-light-grey);
  --color: #000;
}
.attachment_btn label {
  padding: 0;
  font-size: 0.975rem;
}

.custom-file {
  display: flex;
  justify-content: center;
}`, "",{"version":3,"sources":["webpack://./src/app/registration-group/manual-verification/manual-verification.page.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,iBAAA;EACA,cAAA;EACA,iBAAA;EACA,kBAAA;EACA,0CAAA;AACF;;AACA;EACE,kBAAA;EACA,cAAA;EACA,mBAAA;EACA,iBAAA;EACA,kBAAA;EACA,0CAAA;AAEF;;AAAA;EACE,kBAAA;EACA;;;;GAAA;AAOF;;AADA;EACE,aAAA;AAIF;;AAFA;EACE,qBAAA;EACA,iBAAA;EACA,eAAA;AAKF;;AAHA;EACE,+CAAA;AAMF;AALE;EACE,kBAAA;AAOJ;;AAJA;EACE,uCAAA;EACA,aAAA;AAOF;AALE;EACE,UAAA;EACA,mBAAA;AAOJ;;AAJA;EACE,aAAA;EACA,uBAAA;AAOF","sourcesContent":[".heading-2 {\n  text-align: center;\n  font-weight: bold;\n  margin-top: 5%;\n  margin-left: 2.5%;\n  margin-right: 2.5%;\n  color: var(--ion-color-secondary-contrast);\n}\n.p {\n  text-align: center;\n  margin-top: 3%;\n  font-weight: normal;\n  margin-left: 2.5%;\n  margin-right: 2.5%;\n  color: var(--ion-color-secondary-contrast);\n}\n.content {\n  --padding-top: 12%;\n  --background: linear-gradient(\n    90deg,\n    rgba(132, 22, 244, 1) 0%,\n    rgba(7, 206, 213, 1) 100%\n  );\n}\ninput[type=\"file\"] {\n  display: none;\n}\n.custom-file-upload {\n  display: inline-block;\n  padding: 6px 12px;\n  cursor: pointer;\n}\nion-card {\n  background: var(--ion-color-secondary-contrast);\n  ion-card-title {\n    text-align: center;\n  }\n}\n.attachment_btn {\n  --background: var(--bg-very-light-grey);\n  --color: #000;\n\n  label {\n    padding: 0;\n    font-size: 0.975rem;\n  }\n}\n.custom-file {\n  display: flex;\n  justify-content: center;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.doodle_bg {
  --background: url('doodle_png.png'),
      linear-gradient(0deg, var(--landing-grad-light) 100%, var(--landing-grad-dark) 0%);
}

input[type=file] {
  display: none;
}

.grow-wrap {
  /* easy way to plop the elements on top of each other and have them both sized based on the tallest one's height */
  display: grid;
}

.grow-wrap::after {
  /* Note the weird space! Needed to preventy jumpy behavior */
  content: attr(data-replicated-value) " ";
  /* This is how textarea text behaves */
  white-space: pre-wrap;
  /* Hidden from view, clicks, and screen readers */
  visibility: hidden;
}

.grow-wrap > textarea {
  /* You could leave this, but after a user resizes, then it ruins the auto sizing */
  resize: none;
  /* Firefox shows scrollbar on growth, you can hide like this. */
  overflow: hidden;
}

.grow-wrap > textarea,
.grow-wrap::after {
  /* Identical styling required!! */
  border: 1px solid black;
  padding: 0.5rem;
  font: inherit;
  /* Place on top of each other */
  grid-area: 1/1/2/2;
}

.NgxEditor__Wrapper {
  min-height: 150px;
}`, "",{"version":3,"sources":["webpack://./src/app/desktop-activity/components/firebase-chat-block/firebase-chat-block.component.scss"],"names":[],"mappings":"AAAA;EACI;wFAAA;AAEJ;;AAEA;EACI,aAAA;AACJ;;AAEA;EACI,kHAAA;EACA,aAAA;AACJ;;AAEA;EACI,4DAAA;EACA,wCAAA;EAEA,sCAAA;EACA,qBAAA;EAEA,iDAAA;EACA,kBAAA;AADJ;;AAIA;EACI,kFAAA;EACA,YAAA;EAEA,+DAAA;EACA,gBAAA;AAFJ;;AAKA;;EAEI,iCAAA;EACA,uBAAA;EACA,eAAA;EACA,aAAA;EAEA,+BAAA;EACA,kBAAA;AAHJ;;AAMA;EACI,iBAAA;AAHJ","sourcesContent":[".doodle_bg {\n    --background: url(\"../../../../assets/images/doodle_png.png\"),\n        linear-gradient(0deg, var(--landing-grad-light) 100%, var(--landing-grad-dark) 0%);\n}\n\ninput[type=\"file\"] {\n    display: none;\n}\n\n.grow-wrap {\n    /* easy way to plop the elements on top of each other and have them both sized based on the tallest one's height */\n    display: grid;\n}\n\n.grow-wrap::after {\n    /* Note the weird space! Needed to preventy jumpy behavior */\n    content: attr(data-replicated-value) \" \";\n\n    /* This is how textarea text behaves */\n    white-space: pre-wrap;\n\n    /* Hidden from view, clicks, and screen readers */\n    visibility: hidden;\n}\n\n.grow-wrap > textarea {\n    /* You could leave this, but after a user resizes, then it ruins the auto sizing */\n    resize: none;\n\n    /* Firefox shows scrollbar on growth, you can hide like this. */\n    overflow: hidden;\n}\n\n.grow-wrap > textarea,\n.grow-wrap::after {\n    /* Identical styling required!! */\n    border: 1px solid black;\n    padding: 0.5rem;\n    font: inherit;\n\n    /* Place on top of each other */\n    grid-area: 1 / 1 / 2 / 2;\n}\n\n.NgxEditor__Wrapper {\n    min-height: 150px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

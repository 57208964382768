// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.edu_card {
  width: 100%;
  margin-inline: 0;
  margin-top: 0;
  --background: var(--bg-light-blue);
  box-shadow: unset;
}
.edu_card ion-item {
  --padding-start: 0;
  --background: transparent;
}
.edu_card .edu_school_name {
  font-size: 14px;
}
.edu_card .edu_subtitle {
  font-size: 12px;
  color: var(--ion-color-primary-contrast);
  text-transform: capitalize;
}
.edu_card .edu_subtitle .date_bit {
  color: var(--ion-color-medium);
  margin-left: 20px;
}`, "",{"version":3,"sources":["webpack://./src/app/view-cv/components/experience-card/experience-card.component.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,gBAAA;EACA,aAAA;EAEA,kCAAA;EACA,iBAAA;AAAJ;AAEI;EACI,kBAAA;EACA,yBAAA;AAAR;AAGI;EACE,eAAA;AADN;AAGI;EACE,eAAA;EACA,wCAAA;EACA,0BAAA;AADN;AAEM;EACE,8BAAA;EACA,iBAAA;AAAR","sourcesContent":[".edu_card {\n    width: 100%;\n    margin-inline: 0;\n    margin-top: 0;\n\n    --background: var(--bg-light-blue);\n    box-shadow: unset;\n\n    ion-item {\n        --padding-start: 0;\n        --background: transparent;\n    }\n\n    .edu_school_name {\n      font-size: 14px;\n    }\n    .edu_subtitle {\n      font-size: 12px;\n      color: var(--ion-color-primary-contrast);\n      text-transform: capitalize;\n      .date_bit {\n        color: var(--ion-color-medium);\n        margin-left: 20px;\n      }\n    }\n  }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

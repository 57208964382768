// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-header ion-toolbar {
  --background: transparent;
  --border-width: 0;
}

.question-row {
  width: 100%;
}
.question-row p {
  color: var(--ion-color-dark);
}

.bold_number {
  font-family: "Poppins-bold";
}

.heading-2 {
  font-family: "Poppins-semibold";
  text-align: center;
  font-size: 2rem;
}

.continueBtn {
  --background: linear-gradient(90deg, rgba(132, 22, 244, 1) 0%, rgba(7, 206, 213, 1) 100%);
  --border-radius: 2rem;
  width: 80%;
  --color: #fff;
  font-family: "Poppins-bold";
}

.close_btn {
  --background: transparent;
  --background-activated: transparent;
  --color: var(--text-almost-black);
}

.students_row p {
  text-align: center;
  margin: 0;
  font-family: "Poppins-medium";
}

.upgrade_row {
  display: flex;
  justify-content: center;
}
.upgrade_row p {
  text-align: center;
  display: block;
  margin: 0;
  width: 100%;
  color: var(--unitaskr-color-blue);
  font-family: "Poppins-Bold";
  font-size: 1.2rem;
}

.trial_row p {
  font-size: 0.9rem;
  color: var(--bg-medium-grey);
}

img {
  height: auto;
  width: 200px;
}`, "",{"version":3,"sources":["webpack://./src/app/modals/pro-modal/pro-modal.page.scss"],"names":[],"mappings":"AACE;EACE,yBAAA;EACA,iBAAA;AAAJ;;AAIA;EACE,WAAA;AADF;AAEE;EACE,4BAAA;AAAJ;;AAIA;EACE,2BAAA;AADF;;AAIA;EACE,+BAAA;EACA,kBAAA;EACA,eAAA;AADF;;AAIA;EACE,yFAAA;EACA,qBAAA;EACA,UAAA;EACA,aAAA;EACA,2BAAA;AADF;;AAIA;EACE,yBAAA;EACA,mCAAA;EACA,iCAAA;AADF;;AAKE;EACE,kBAAA;EACA,SAAA;EACA,6BAAA;AAFJ;;AAMA;EACE,aAAA;EACA,uBAAA;AAHF;AAIE;EACE,kBAAA;EACA,cAAA;EACA,SAAA;EACA,WAAA;EACA,iCAAA;EACA,2BAAA;EACA,iBAAA;AAFJ;;AAOE;EACE,iBAAA;EACA,4BAAA;AAJJ;;AAQA;EACE,YAAA;EACA,YAAA;AALF","sourcesContent":["ion-header {\n  ion-toolbar {\n    --background: transparent;\n    --border-width: 0;\n  }\n}\n\n.question-row {\n  width: 100%;\n  p {\n    color: var(--ion-color-dark);\n  }\n}\n\n.bold_number {\n  font-family: 'Poppins-bold';\n}\n\n.heading-2 {\n  font-family: 'Poppins-semibold';\n  text-align: center;\n  font-size: 2rem;\n}\n\n.continueBtn {\n  --background: linear-gradient(90deg, rgba(132, 22, 244, 1) 0%, rgba(7, 206, 213, 1) 100%);\n  --border-radius: 2rem;\n  width: 80%;\n  --color: #fff;\n  font-family: 'Poppins-bold';\n}\n\n.close_btn {\n  --background: transparent;\n  --background-activated: transparent;\n  --color: var(--text-almost-black);\n}\n\n.students_row {\n  p {\n    text-align: center;\n    margin: 0;\n    font-family: 'Poppins-medium';\n  }\n}\n\n.upgrade_row {\n  display: flex;\n  justify-content: center;\n  p {\n    text-align: center;\n    display: block;\n    margin: 0;\n    width: 100%;\n    color: var(--unitaskr-color-blue);\n    font-family: 'Poppins-Bold';\n    font-size: 1.2rem;\n  }\n}\n\n.trial_row {\n  p {\n    font-size: 0.9rem;\n    color: var(--bg-medium-grey);\n  }\n}\n\nimg {\n  height: auto;\n  width: 200px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-gallery-swiper',
  templateUrl: './gallery-swiper.component.html',
  styleUrls: ['./gallery-swiper.component.scss'],
})
export class GallerySwiperComponent implements OnInit {
  @Input('images') images: any;

  selectedImage: any;
  selectedIndex = 0;

  constructor(private modalCtrl: ModalController) {}

  ngOnInit() {
    this.getSelectedIndex();
  }

  getSelectedIndex() {
    this.images.forEach((image, index) => {
      if (image.isSelected) {
        this.selectedIndex = index;
      }
    });
    this.selectedImage = this.images[this.selectedIndex];
  }

  prevSlide() {
    this.selectedIndex = this.selectedIndex - 1;
    if (this.selectedIndex < 0) {
      this.selectedIndex = this.images.length - 1;
    }
    this.selectedImage = this.images[this.selectedIndex];
  }

  nextSlide() {
    this.selectedIndex = this.selectedIndex + 1;
    if (this.selectedIndex > this.images.length - 1) {
      this.selectedIndex = 0;
    }
    this.selectedImage = this.images[this.selectedIndex];
  }

  getImageSource(image, location: string) {
    // console.log('getImageSource(): ', image);
    if (
      (image && typeof image == 'string' && image.includes('http')) ||
      (image && image.key && typeof image.key == 'string' && image.key.includes('http'))
    ) {
      if (image.key) {
        return image.key;
      } else {
        return image;
      }
    } else if (image == 'string' || image == '') {
      return '../../../assets/images/placeholder.jpeg';
    } else {
      let availWidth = screen.availWidth;

      if (availWidth > 250) {
        availWidth = 240;
      }

      let imageWidth: number;
      let imageHeight: number;
      if (location === 'focussed') {
        imageWidth = 2 * 0.95 * availWidth;
        imageHeight = 2 * 240;
      } else if (location === 'gallery') {
        imageWidth = 106;
        imageHeight = 74;
      }

      let cdnRequestObject = {
        bucket: 'unitaskr-web-cdn',
        key: image && image.key ? image.key : image && image.url ? image.url : image, // "Screenshot 2021-11-17 at 11.10.24.png",
        edits: {
          resize: {
            // width: imageWidth,
            // height: imageHeight,
            // fit: 'cover',
          },
        },
      };

      let base64 = btoa(JSON.stringify(cdnRequestObject));
      return `${environment.uniTaskrApi.cdnBaseUrl}${base64}`;
    }
  }

  // Close the modal when the user clicks outside of the Swiper gallery
  onCloseModal() {
    this.modalCtrl.dismiss();
  }
}

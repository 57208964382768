import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

import DOMPurify from 'dompurify';
import { DomSanitizer } from '@angular/platform-browser';
import { ChatChannelService } from 'src/app/inbox(del)/service/channel.service';
import { Router } from '@angular/router';
import { PresenceService } from 'src/app/services/presence/presence.service';
import { StorageService } from 'src/app/services/storage/storage.service';
import { LoadingController, ModalController, ToastController } from '@ionic/angular';
import { ApplicantsService } from 'src/app/task-manage/service/applicant.service';
import { ChatBlazeService } from 'src/app/chat-blaze/service/chat-blaze.service';
import { environment } from 'src/environments/environment';
import { StudentProfileService } from 'src/app/student-profile/service/student-profile.service';
import { TrackableType } from '../../plan/plan-trackable-type.enum';
import { ProModalPage } from '../../modals/pro-modal/pro-modal.page';
import { SubscriptionService } from '../../services/subscription/subscription.service';
import { take } from 'rxjs/operators';

import { Channel, StreamChat } from 'stream-chat';

// import { MessagingService } from 'src/app/messaging/service/messaging.service';

@Component({
  selector: 'app-manage-task-list-item',
  templateUrl: './manage-task-list-item.component.html',
  styleUrls: ['./manage-task-list-item.component.scss'],
})
export class ManageTaskListItemComponent implements OnInit {
  /** Focussed applicant for this list item */
  @Input('applicant') applicant;

  /** Which subset of the list are we looking at? */
  @Input('selectedApplicantFilter') selectedApplicantFilter;

  /** Which task is in focus? */
  @Input('focussedTask') focussedTask;

  @Output('changeFilter') changeFilter = new EventEmitter();

  /** Current presence status of list item member */
  presence: any;

  itemChannel: Channel | null;

  showMore: boolean = false;

  constructor(
    private router: Router,
    private loadingController: LoadingController,
    private sanitizer: DomSanitizer,
    private channelService: ChatChannelService,
    public presenceService: PresenceService,
    public storageService: StorageService,
    private applicantService: ApplicantsService,
    private chatService: ChatBlazeService,
    private studentProfileService: StudentProfileService,
    private subscription: SubscriptionService,
    private modalCtrl: ModalController, // private messagingService: MessagingService
    private toastController: ToastController
  ) {}

  ngOnInit() {
    // console.log('applicant applicant applicant applicant applicant applicant : ', this.applicant);

    this.checkChannel();

    this.getPresence();
  }

  async checkChannel() {
    // console.log('checkChannel()');
    const chatClient = StreamChat.getInstance<any>(environment.stream_io.apiKey);
    const filter = { type: 'messaging', id: `${this.applicant.taskOfferId}` };
    const channels = await chatClient.queryChannels(
      filter,
      {},
      {
        watch: true, // this is the default
        state: true,
      },
      {}
    );
    this.itemChannel = channels[0];

    // this.itemChannel.on(event => {
    //   console.log(`Channel event - ${this.itemChannel.data.name}: `, event);
    // })

    console.log('itemChannel: ', this.itemChannel);
    // console.log('itemChannel count: ', this.itemChannel.state.unreadCount);
  }

  showApplicant(applicant) {
    if (applicant.isShorted && this.selectedApplicantFilter == 'shortlisted') {
      return true;
    } else if (applicant.isAccepted && !applicant.isBuyerCompleted && this.selectedApplicantFilter == 'hired') {
      return true;
    } else if (applicant.isBuyerCompleted && this.selectedApplicantFilter == 'completed') {
      return true;
    } else if (!applicant.isAccepted && !applicant.isCompleted && this.selectedApplicantFilter == 'applied') {
      return true;
    }
  }

  showMeMore() {
    this.showMore = !this.showMore;
  }

  getSanitized(htmlString: string) {
    let output = htmlString.replace('\n', '<br> <br>');
    const sanitizedContent = DOMPurify.sanitize(output);
    return this.sanitizer.bypassSecurityTrustHtml(sanitizedContent);
  }

  isEllipsisActive() {
    const ele = document.getElementById(this.applicant.userId);
    if (ele.offsetWidth < ele.scrollWidth) {
      return true;
    } else if (this.showMore == true) {
      return true;
    } else {
      return false;
    }
  }

  openStudentProfile() {
    this.studentProfileService.applicationLinks = this.applicant.links;
    this.router.navigate([`/student-profile/${this.applicant.userId}/${this.applicant.taskOfferId}`]);
  }

  async openMesssaging(offer) {
    console.log('openMesssaging(): ', offer);
    const loading = await this.loadingController.create({
      cssClass: 'my-custom-class',
      message: 'Loading chat...',
      duration: 5000,
    });
    await loading.present();

    let chatData = {
      name: this.focussedTask.title,
      image: this.focussedTask.taskAttachments.length
        ? this.getImageSource(this.focussedTask.taskAttachments[0].url, 'focussed')
        : undefined,
    };
    if (this.focussedTask && this.focussedTask.jobType == 4) {
      this.channelService
        .createStreamChannel(
          'messaging',
          offer.taskOfferId.toString(),
          this.storageService.uniTaskrUser.userId.toString(),
          [this.storageService.uniTaskrUser.userId.toString(), offer.userId.toString()],
          chatData
        )
        .subscribe(
          (channelRes) => {
            console.log('MESSAGING channel created: ', channelRes);
            // fetch updated chat started count
            this.subscription
              .getCounts()
              .pipe(take(1))
              .subscribe((countRes) => {
                console.log('countRes: ', countRes);
                loading.dismiss();
                this.subscription.countChanged.next(countRes);
                this.router.navigate([`/messaging/${offer.taskOfferId}`]);
              });
          },
          async (err) => {
            console.log('ERROR MESSAGING channel NOT created: ', err);
            loading.dismiss();
            if (err.status == 403) {
              const modal = await this.modalCtrl.create({
                component: ProModalPage,
                initialBreakpoint: 0.5,
                breakpoints: [0, 0, 5],
                // swipeToClose: true,
                componentProps: {
                  trackableItemType: TrackableType.TaskChatStarted,
                },
              });
              modal.present();
            }
          }
        );
    } else {
      this.channelService
        .createStreamRecruitChannel(
          'messaging',
          offer.taskOfferId.toString(),
          this.storageService.uniTaskrUser.userId.toString(),
          [this.storageService.uniTaskrUser.userId.toString(), offer.userId.toString()],
          chatData
        )
        .subscribe(
          (channelRes) => {
            console.log('MESSAGING recruit channel created: ', channelRes);
            // fetch updated chat started count
            this.subscription
              .getCounts()
              .pipe(take(1))
              .subscribe((countRes) => {
                console.log('countRes: ', countRes);
                loading.dismiss();
                this.subscription.countChanged.next(countRes);
                this.router.navigate([`/messaging/${offer.taskOfferId}`]);
              });
          },
          async (err) => {
            console.log('ERROR MESSAGING channel NOT created: ', err);
            loading.dismiss();
            if (err.status == 403) {
              const modal = await this.modalCtrl.create({
                component: ProModalPage,
                initialBreakpoint: 0.5,
                breakpoints: [0, 0, 5],
                // swipeToClose: true,
                componentProps: {
                  trackableItemType: TrackableType.RecruitChatStarted,
                },
              });
              modal.present();
            }
          }
        );
    }

    // }
  }

  getImageSource(image, location: string) {
    // console.log('getImageSource(): ', image);
    if (
      (image && typeof image == 'string' && image.includes('http')) ||
      (image && image.key && typeof image.key == 'string' && image.key.includes('http'))
    ) {
      if (image.key) {
        return image.key;
      } else {
        return image;
      }
    } else if (image == 'string' || image == '') {
      return '../../../assets/images/placeholder.jpeg';
    } else {
      let availWidth = screen.availWidth;
      if (availWidth > 250) {
        availWidth = 240;
      }

      let imageWidth: number;
      let imageHeight: number;
      if (location === 'focussed') {
        imageWidth = 2 * 0.95 * availWidth;
        imageHeight = 2 * 240;
      } else if (location === 'gallery') {
        imageWidth = 106;
        imageHeight = 74;
      }

      let cdnRequestObject = {
        bucket: 'unitaskr-web-cdn',
        key: image && image.key ? image.key : image && image.url ? image.url : image, // "Screenshot 2021-11-17 at 11.10.24.png",
        edits: {
          resize: {
            width: imageWidth,
            height: imageHeight,
            fit: 'cover',
          },
        },
      };

      let base64 = btoa(JSON.stringify(cdnRequestObject));
      return `${environment.uniTaskrApi.cdnBaseUrl}${base64}`;
    }
  }

  async getPresence() {
    this.presence = this.presenceService.getPresence(this.applicant.userId);
    // this.channelService.getFireChannel(this.applicant.channelId);
  }

  getMessageContent() {
    if (!this.itemChannel) {
      return this.getSanitized(this.applicant.message);
    } else {
      return this.itemChannel.state.messageSets[0].messages[this.itemChannel.state.messageSets[0].messages.length - 1]
        .html;
    }
  }

  getTimeSince() {
    if (!this.itemChannel) {
      if (
        this.presenceService.presence &&
        this.presenceService.presence[this.applicant.userId] &&
        this.presenceService.presence[this.applicant.userId].timestamp
      ) {
        return this.presenceService.presence[this.applicant.userId].timestamp.toDate();
      } else {
        return this.applicant.offerDt;
      }
    } else {
      return this.itemChannel.state.last_message_at;
    }
  }

  async shortListOffer(slidingItem) {
    const loading = await this.loadingController.create({
      cssClass: 'my-custom-class',
      message: 'Please wait...',
      duration: 2000,
    });
    await loading.present();
    let value = this.applicant.isShorted ? false : true;

    this.applicantService.shortList(this.applicant.taskOfferId, value).subscribe(
      async (shortRes) => {
        const shortToast = await this.toastController.create({
          message: 'Added to Shortlist',
          duration: 2000,
          position: 'top',
          buttons: [
            {
              text: 'View',
              handler: () => {
                this.changeFilter.emit('shortlisted');
              },
            },
          ],
        });
        const unShortToast = await this.toastController.create({
          message: 'Removed from Shortlist',
          duration: 2000,
          position: 'top',
        });
        console.log('shortRes: ', shortRes);
        this.applicant.isShorted = value;
        slidingItem.close();
        loading.dismiss();
        if (shortRes.isShorted == 1) {
          await shortToast.present();
        } else if (shortRes.isShorted == 0) {
          await unShortToast.present();
        }
      },
      async (err) => {
        console.log('shortRes error: ', err);
        slidingItem.close();
        loading.dismiss();
      }
    );
  }

  getMessageButtonStyle() {
    if (!this.itemChannel) {
      return 'width: 35vw; --background: var(--unitaskr-color-blue); --background-hover: var(--bg-purple-accent); --color: #fff; font-size: 12px; height: 28px; margin: 5px;';
    } else {
      return 'width: 35vw; --background: transparent; --background-hover: var(--bg-purple-accent); --color: var(--unitaskr-color-blue); --border-style: solid; --border-width: 1px; --border-color: var(--unitaskr-color-blue); font-size: 12px; height: 28px; margin: 5px;';
    }
  }
}

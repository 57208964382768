import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ActionSheetController, LoadingController, ModalController } from '@ionic/angular';
import { TaskService } from 'src/app/home-earn/service/task.service';
import { ProModalPage } from 'src/app/modals/pro-modal/pro-modal.page';
import { TrackableType } from 'src/app/plan/plan-trackable-type.enum';
import { StorageService } from 'src/app/services/storage/storage.service';
import { LocalUserService } from 'src/app/welcome/services/local-user/local-user.service';

@Component({
  selector: 'app-manage-task-overview',
  templateUrl: './manage-task-overview.component.html',
  styleUrls: ['./manage-task-overview.component.scss'],
})
export class ManageTaskOverviewComponent implements OnInit {
  /** Focussed Task */
  @Input('task') focussedTask;

  /** Selected filter */
  @Input('selectedApplicantFilter') selectedApplicantFiter;

  /** Change the filter */
  @Output('changeFilter') changeFilter = new EventEmitter();

  /** The chat count */
  @Input('chatCount') chatCount;

  actionClicked = false;

  constructor(
    private router: Router,
    private taskService: TaskService,
    private loadingController: LoadingController,
    private actionSheetCtrl: ActionSheetController,
    private modalController: ModalController,
    public storageService: StorageService,
    public localUserService: LocalUserService,
  ) {}

  ngOnInit() {
    console.log('filter: ', this.selectedApplicantFiter);
    console.log('chatCount: ', this.chatCount);
  }

  async pause() {
    const loading = await this.loadingController.create({
      cssClass: 'my-custom-class',
      message: 'Please wait...',
      duration: 2000,
    });
    await loading.present();
    let value;
    if (this.focussedTask.isPaused) {
      value = false;
    } else {
      value = true;
    }

    this.taskService.updatePause(value, this.focussedTask.taskId).subscribe(
      (updateRes) => {
        console.log('updateRes: ', updateRes);
        this.focussedTask = updateRes;
        loading.dismiss();
      },
      (err) => {
        console.log('updateRes error: ', err);
        loading.dismiss();
      }
    );
  }

  goToUpgrade() {
    if (this.focussedTask?.jobType == 4) {
      this.router.navigate(['/plan/tasking']);
    } else {
      this.router.navigate(['/plan/recruiting']);
    }
  }

  async upgradeModal() {
    if (this.focussedTask?.jobType == 4) {
      const modal = await this.modalController.create({
        component: ProModalPage,
        initialBreakpoint: 0.5,
        breakpoints: [0, 0.5],
        componentProps: {
          trackableItemType: TrackableType.TaskChatStarted,
        },
      });
      await modal.present();
    } else {
      const modal = await this.modalController.create({
        component: ProModalPage,
        initialBreakpoint: 0.5,
        breakpoints: [0, 0.5],
        componentProps: {
          trackableItemType: TrackableType.RecruitChatStarted,
        },
      });
      await modal.present();
    }
  }

  async openOptionsPopover(ev) {
    this.actionClicked = true;

    // if (this.focussedTask.jobType == 4) {
    const actionSheet = await this.actionSheetCtrl.create({
      cssClass: 'my-activity-class',
      buttons: [
        {
          text: 'Applied',
          handler: () => {
            this.actionClicked = false;
            this.changeFilter.emit('applied');
          },
        },
        {
          text: 'Shortlisted',
          handler: () => {
            this.actionClicked = false;
            this.changeFilter.emit('shortlisted');
          },
        },
        {
          text: 'Hired',
          handler: () => {
            this.actionClicked = false;
            this.changeFilter.emit('hired');
          },
        },
        {
          text: 'Paid',
          handler: () => {
            this.actionClicked = false;
            this.changeFilter.emit('completed');
          },
        },
        {
          text: 'Cancel',
          role: 'cancel',
          handler: () => (this.actionClicked = false),
          data: {
            action: 'cancel',
          },
        },
      ],
    });
    actionSheet.present();
    // } else {
    // const actionSheet = await this.actionSheetCtrl.create({
    //   cssClass: 'my-activity-class',
    //   buttons: [
    //     {
    //       text: 'Applied',
    //       handler: () => {
    //         this.actionClicked = false;
    //         this.changeFilter.emit('applied');
    //       },
    //     },
    //     {
    //       text: 'Shortlisted',
    //       handler: () => {
    //         this.actionClicked = false;
    //         this.changeFilter.emit('shortlisted');
    //       },
    //     },
    //     {
    //       text: 'Hired',
    //       handler: () => {
    //         this.actionClicked = false;
    //         this.changeFilter.emit('hired');
    //       },
    //     },
    //     {
    //       text: 'Cancel',
    //       role: 'cancel',
    //       handler: () => (this.actionClicked = false),
    //       data: {
    //         action: 'cancel',
    //       },
    //     },
    //   ],
    // });
    // actionSheet.present();
    // }
  }
}

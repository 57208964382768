import { Injectable } from '@angular/core';
import { App } from '@capacitor/app';
import { Device } from '@capacitor/device';
import { doc, getFirestore, setDoc, onSnapshot, addDoc, collection } from '@firebase/firestore';
import { Platform, ToastController } from '@ionic/angular';
import { merge } from 'rxjs';
import { ErrorLogService } from 'src/app/error-logs/service/error-log.service';
import { HttpService } from '../http/http.service';
import { StorageService } from '../storage/storage.service';
import { environment } from 'src/environments/environment';
import { serverTimestamp } from 'firebase/database';
import { FirebaseAuthentication } from '@capacitor-firebase/authentication';
import { Smartlook } from '@awesome-cordova-plugins/smartlook/ngx';

@Injectable({
  providedIn: 'root',
})
export class PresenceService {
  /** Current presence status of list item member */
  presence = {
    id: 0,
  };

  /** Do not show the re-auth toast when first time login */
  isFirstTimeToken: boolean = true;


  constructor(
    private storageService: StorageService,
    private http: HttpService,
    private platform: Platform,
    private errorLogService: ErrorLogService,
    private toastController: ToastController,
    private smartlook: Smartlook
  ) {
    this.updateOnAway();
    this.updateOnStateChange();
  }

  getUser() {
    return this.storageService.firebaseUser;
  }

  get timestamp() {
    return Date();
  }

  async setStatus(status: string) {

    /** refresh token */
    
    if (status == 'online') {

      if (this.isFirstTimeToken) {
        // console.log('_____ First time token -> Ignore _____');
        this.isFirstTimeToken = false;
      } else {
        // console.log('_____ refresh ID Token _____');
        try {
          const token = await FirebaseAuthentication.getIdToken({
            forceRefresh: true,
          });
          this.storageService.token = token.token;
          // console.log('New token response: ', this.storageService.token);

          const toast = await this.toastController.create({
            message: 'Session Refreshed!',
            duration: 1500,
            position: 'top',
            icon: "checkmark-circle"
          });
      
          await toast.present();

          // window.location.reload();

        } catch (e) {
          console.log('Error getting new token: ', e);
        }
      }
    }

    const db = getFirestore();
    return setDoc(doc(db, "status_uid", this.storageService.firebaseUser.uid), {
      status: status,
      timestamp: new Date()
    });

    /** API version to sync with UniStatz - UniStatz now deprecated */
    // return this.http.runHttpCall('POST', 'api/account/presence/set-status', 'application/json', {
    //   status: status,
    // });
  }

  async setPresence(status: string) {
    // console.log('>>>>>><<<<<<<')
    // console.log('>>>>>><<<<<<<')
    // console.log('setPresence(): ', this.storageService.firebaseUser.uid);
    // console.log('>>>>>><<<<<<<')
    // console.log('>>>>>><<<<<<<')
    const db = getFirestore();
    if (this.storageService.firebaseUser) {
      // console.log('... Start set presence ...')
      this.setStatus(status).then((update) => {
        // console.log('... Presence set ...: ', update);
       }),
        (err) => {
          console.log('... Presence not set ... : ', err);
        };
    } else {
      console.log('no user...');
    }
  }

  newLastMessage(userId: number, message: string) {
    const db = getFirestore();
    try {
      setDoc(
        doc(db, 'status', `${userId}`),
        {
          lastMessage: message,
          read: false,
          sender: this.storageService.uniTaskrUser.userId,
          timestamp: new Date(),
        },
        { merge: true }
      )
        .then((docSet) => { })
        .catch((err) => {
          console.log('Error setting doc: ', err);
        });
    } catch (e) {
      console.error('Error adding document: ', e);
    }
  }

  readLastMessage(userId: number) {
    const db = getFirestore();
    try {
      setDoc(
        doc(db, 'status', `${userId}`),
        {
          read: true,
          timestamp: new Date(),
        },
        { merge: true }
      )
        .then((docSet) => { })
        .catch((err) => {
          console.log('Error setting doc: ', err);
        });
    } catch (e) {
      console.error('Error adding document: ', e);
    }
  }

  getPresence(userId: number) {
    const db = getFirestore();
    let docRef = doc(db, 'status', `${userId}`);
    const unsub = onSnapshot(docRef, (doc) => {
      this.presence[userId] = doc.data();
    });
  }

  updateOnStateChange() {
    App.addListener('appStateChange', ({ isActive }) => {
      // console.log('App state changed. Is active?', isActive);
      if (!isActive) {
        this.setPresence('away');
        this.smartlook.stop()
      }
    });
  }

  updateOnAway() {
    document.onvisibilitychange = (e) => {
      // console.log('***********')
      // console.log('***********')
      // console.log('document.onvisibilitychange: ', e);
      // console.log('document.visibilityState: ', document.visibilityState);
      // console.log('***********')
      // console.log('***********')
      if (document.visibilityState === 'hidden') {
        this.setPresence('away');
      } else {
        this.setPresence('online');
      }
    };
  }

  async logLoginTransaction(firebaseUser) {
    const db = getFirestore();

    let deviceId = await Device.getId();
    let platformnName = this.platform.platforms();

    try {
      const docRef = await addDoc(collection(db, 'login_transactions'), {
        timestamp: new Date(),
        uid: firebaseUser.uid,
        deviceId: deviceId,
        platform: platformnName,
        // appVersion: this.errorLogService.appVersion,
        appVersion: environment.applicationVersion,
      });
    } catch (e) {
      console.log('Error setting doc: ', e);
    }
  }

  async logRegisterTransaction(firebaseUser) {
    const db = getFirestore();

    let deviceId = await Device.getId();
    let platformnName = this.platform.platforms();

    try {
      const docRef = await addDoc(collection(db, 'register_transactions'), {
        timestamp: new Date(),
        uid: firebaseUser.uid,
        deviceId: deviceId,
        platform: platformnName,
        // appVersion: this.errorLogService.appVersion,
        appVersion: environment.applicationVersion,
      });
    } catch (e) {
      console.log('Error setting doc: ', e);
    }
  }

  async logLinkedInView(jobId) {
    console.log('logLinkedInView(): ', jobId);
    const db = getFirestore();

    let deviceId = await Device.getId();
    let platformnName = this.platform.platforms();

    try {
      const docRef = await addDoc(collection(db, 'linkedin_job_views'), {
        timestamp: new Date(),
        jobId: jobId,
        deviceId: deviceId,
        platform: platformnName,
      });
      console.log('Job View: ', docRef);
    } catch (e) {
      console.log('Error setting LinkedIn view doc: ', e);
    }
  }

  async logLinkedInClicks(jobId) {
    console.log('logLinkedInClicks(): ', jobId);
    const db = getFirestore();

    let deviceId = await Device.getId();
    let platformnName = this.platform.platforms();

    try {
      const docRef = await addDoc(collection(db, 'linkedin_job_clicks'), {
        timestamp: new Date(),
        jobId: jobId,
        deviceId: deviceId,
        platform: platformnName,
      });
      console.log('Job Click: ', docRef);
    } catch (e) {
      console.log('Error setting LinkedIn click doc: ', e);
    }
  }
}

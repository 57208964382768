import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { CompanyProfileShoutPage } from 'src/app/modals/company-profile-shout/company-profile-shout.page';
import { StudentProfilePage } from 'src/app/student-profile/student-profile.page';

@Component({
  selector: 'app-to-company-profile-row',
  templateUrl: './to-company-profile-row.component.html',
  styleUrls: ['./to-company-profile-row.component.scss'],
})
export class ToCompanyProfileRowComponent implements OnInit {

  /** The basic info we need to operate this component */
  @Input('profilePhoto') profilePhoto: string;
  @Input('businessName') businessName: string;
  @Input('businessDescription') businessDescription: string;
  @Input('website') website: string;
  @Input('instagram') instagram: string;
  @Input('country') country: string;
  @Input('reviews') reviews: string;
  @Input('userRating') userRating: string;
  @Input('userReviewCount') userReviewCount: string;

  @Input('isShout') isShout: boolean = false;

  /** Sometimes we need to pad/margin the top */
  @Input('marginTop') marginTop: string;
  @Input('paddingBottom') paddingBottom: string;
  @Input('paddingTop') paddingTop: string;
  @Input('borderBottom') borderBottom: string;
  @Input('borderTop') borderTop: string;

  /** User's student ID */
  @Input('studentUserId') studentUserId: number;

  constructor(
    private router: Router,
    private modalController: ModalController
  ) { }

  ngOnInit() {
    if (this.marginTop) {
      document.getElementById("wholeCompanyItem").style.marginTop = this.marginTop;
      document.getElementById("wholeCompanyItem").style.paddingBottom = this.paddingBottom;
      document.getElementById("wholeCompanyItem").style.paddingTop = this.paddingTop;
      document.getElementById("wholeCompanyItem").style.borderBottom = this.borderBottom;
      document.getElementById("wholeCompanyItem").style.borderTop = this.borderTop;
    }
  }

  getImage(imageUrl: string): string {
    if (imageUrl && imageUrl.length > 0) {
      return imageUrl;
    }
    else {
      return "../../../assets/images/placeholder.jpeg";
    }
  }

  async openProfileModal() {
    console.log('openProfileModal()');
    if (!this.isShout) {
      this.router.navigate([`/student-profile/${this.studentUserId}`]);
      // const modal = await this.modalController.create({
      //   component: StudentProfilePage,
      //   initialBreakpoint: 0.8,
      //   breakpoints: [0, 0.8, 1],
      //   componentProps: {
      //     studentUserId: this.studentUserId
      //   }
      // });
      // await modal.present();
    } else {
      const modal = await this.modalController.create({
        component: CompanyProfileShoutPage,
        initialBreakpoint: 0.8,
        breakpoints: [0, 0.8, 1],
        componentProps: {
          businessInfo: {
            profilePhoto: this.profilePhoto,
            businessName: this.businessName,
            businessDescription: this.businessDescription,
            website: this.website,
            instagram: this.instagram,
            country: this.country,
            reviews: this.reviews,
            rating: this.userRating,
            userReviewCount: this.userReviewCount
          }
        }
      });
      await modal.present();
    }
  }
}

import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import DOMPurify from "dompurify";
import { LoadingController, Platform, ToastController } from '@ionic/angular';
import SwiperCore, { Autoplay, Pagination, Navigation, SwiperOptions } from "swiper";
import { SwiperComponent } from 'swiper/angular';
import { ShoutDetailsService } from 'src/app/shout-profile/service/shout-details.service';
import { Observable, Observer } from 'rxjs';
import { Photo } from '@capacitor/camera';
import { Directory, Filesystem } from '@capacitor/filesystem';
import { Browser } from '@capacitor/browser';

const IMAGE_DIR = 'stored-images';

@Component({
  selector: 'app-shout-steps-horiz',
  templateUrl: './shout-steps-horiz.component.html',
  styleUrls: ['./shout-steps-horiz.component.scss'],
})
export class ShoutStepsHorizComponent implements OnInit {

  /** The list of steps required to complete this task */
  @Input('steps') shoutSteps;

  /** Shout task ID */
  @Input('shoutID') shoutID;

  /** Shout task duration */
  @Input('duration') duration;

  /** Update the container with the check marks */
  @Output('stepUpdated') updatedStep = new EventEmitter();

  /** Let the consumer know when we're on a different step */
  @Output('focusStep') focusStep = new EventEmitter();

  /* Default initial slide */
  public currentSlide: number = 0;

  /* Handle on the slider element */
  @ViewChild('shoutStepsSlider', { static: false }) shoutStepsSlider?: SwiperComponent;

  config: SwiperOptions = {
    slidesPerView: 1,
    spaceBetween: 30,
    navigation: false,
    pagination: { clickable: true },
    scrollbar: { draggable: false },
    allowSlideNext: false,
    allowSlidePrev: false
  };


  name = "Mr";
  base64Image: any;

  constructor(
    private toastController: ToastController,
    private sanitizer: DomSanitizer,
    private shoutDetailsService: ShoutDetailsService
  ) {

  }

  ngOnInit() {
    console.log('Shout Steps: ', this.shoutSteps);
    // this.shoutStepsSlider.swiperRef.allowSlideNext = false;
  }

  markComplete(focussedStep) {
    console.log('markComplete(): ', focussedStep)
    this.shoutDetailsService.setStudentCheck(this.shoutID, focussedStep.stepId, !focussedStep.isStudentCheck).subscribe(checkRes => {
      console.log('checkRes: ', checkRes);
      focussedStep = checkRes;
      this.updatedStep.emit(checkRes);

    }, err => {
      console.log('checkRes error: ', err);
    });
  }

  onSlideChange() {
    this.currentSlide = this.shoutStepsSlider.swiperRef.activeIndex;
  }

  slideNext() {
    // this.allowProgress = true;
    this.shoutStepsSlider.swiperRef.allowSlideNext = true;
    this.shoutStepsSlider.swiperRef.slideNext(100);
    // this.currentSlide++;
    this.shoutStepsSlider.swiperRef.allowSlideNext = false;
    this.focusStep.emit({
      slideIndex: this.shoutStepsSlider.swiperRef.activeIndex,
      step: this.shoutSteps[this.shoutStepsSlider.swiperRef.activeIndex]
    });
  }

  slidePrev() {
    // this.allowProgress = true;
    this.shoutStepsSlider.swiperRef.allowSlidePrev = true;
    this.shoutStepsSlider.swiperRef.slidePrev(100);
    // this.currentSlide--;
    this.shoutStepsSlider.swiperRef.allowSlidePrev = false;
    this.focusStep.emit({
      slideIndex: this.shoutStepsSlider.swiperRef.activeIndex,
      step: this.shoutSteps[this.shoutStepsSlider.swiperRef.activeIndex]
    });
  }

  getType(focussedStep) {
    if (focussedStep.isAttachmnet) {
      return "attachment";
    }
    else if (focussedStep.isExample) {
      return "example";
    }
    else if (focussedStep.isImage) {
      return "image";
    }
    else if (focussedStep.isLink) {
      return "link";
    }
    else if (focussedStep.isPaused) {
      return "paused";
    }
    // else if (focussedStep.isStudentCheck) {
    //   return "studentCheck";
    // }
    else if (focussedStep.isVideo) {
      return "video";
    }
    else if (focussedStep.pauseShoutMsg) {
      return "pauseShoutMsg";
    } else {
      return "basic"
    }
  }

  getSanitized(htmlString: string) {
    if (htmlString) {
      let output = htmlString.replace("\n", "<br> <br>");
      const sanitizedContent = DOMPurify.sanitize(output);
      return this.sanitizer.bypassSecurityTrustHtml(sanitizedContent);
    }
  }

  async copyToClipboard(focussedStep) {
    let copiedText = await navigator.clipboard.writeText(focussedStep.stepInstruction);
    console.log('copiedText: ', copiedText);
    const toast = await this.toastController.create({
      message: 'Link copied!',
      duration: 2000
    });
    toast.present();
  }

  openImageInBrowser(focussedStep) {
    Browser.open({url: focussedStep.stepInstruction });
  }

}

import { Component, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { AnimationOptions } from 'ngx-lottie';
import { S3UploadService } from 'src/app/services/s3-upload/s3-upload.service';

@Component({
  selector: 'app-image-loading',
  templateUrl: './image-loading.component.html',
  styleUrls: ['./image-loading.component.scss'],
})
export class ImageLoadingComponent implements OnInit {

  /** No Channels Lottie Config */
  noChannelsOptions: AnimationOptions = {
    path: 'https://assets10.lottiefiles.com/packages/lf20_GxMZME.json',
    loop: true,
    autoplay: true,
  };

  constructor(
    public uploadService: S3UploadService,
    private popoverController: PopoverController
  ) { }

  ngOnInit() {
  }

  
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import DOMPurify from 'dompurify';
import { ServicesService } from 'src/app/hiring-wizard/service/services.service';
import { CategoryService } from './service/category.service';

@Component({
  selector: 'app-task-linkedin',
  templateUrl: './new-task-card-linkedin.component.html',
  styleUrls: ['./new-task-card-linkedin.component.scss'],
})
export class NewTaskCardLinkedinComponent implements OnInit {

  /** job information */
  @Input('job') job: any;
  @Input('filterSelected') filterSelected: any;

  @Input('categories') categories: any;
  @Input('jobCategories') jobCategories: any;

  /* Main Card Action Output */
  @Output('mainCardAction') mainAction = new EventEmitter();

  categoryName: string;
  categoryImage: string;


  constructor(
    private sanitizer: DomSanitizer,
    public catService: CategoryService
  ) { }

  ngOnInit() {
    let category = this.catService.getLabel(this.job.industries[0].split(','))
    this.categoryName = category[0].jobName;
    this.categoryImage = category[0].imageUrl;
  }

  clickWholeCardAction(job) {
    this.mainAction.emit(job);
  }


  getSanitized(htmlString: string) {
    let output = htmlString.replace('\n', '<br> <br>');
    const sanitizedContent = DOMPurify.sanitize(output);
    return this.sanitizer.bypassSecurityTrustHtml(sanitizedContent);
  }

  getEmploymentType() {
    switch (this.job.employmentStatus) {
      case 'INTERNSHIP':
        return 'Internship'
      case 'PART_TIME':
        return 'Part Time';
      case 'FULL_TIME':
        return 'Full Time';
    }
  }
}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-intro-message',
  templateUrl: './intro-message.component.html',
  styleUrls: ['./intro-message.component.scss'],
})
export class IntroMessageComponent implements OnInit {

  isClicked = false;

  constructor() { }

  ngOnInit() { }

  toggleIntroMessage() {
    this.isClicked = !this.isClicked
  }

}

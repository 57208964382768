import { Injectable } from '@angular/core';
import { Device } from '@capacitor/device';
import { Platform } from '@ionic/angular';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DeviceService {

  constructor(private platform: Platform) { }

  device: any;
  deviceChanged = new Subject();

  async getDevice() {
    if (this.device)
      return this.device;
    
    const deviceId = (await Device.getId());
    const platformnName = this.platform.platforms();
    const deviceJson = await Device.getInfo();

    this.device = {
      // timestamp: new Date(),
      deviceId: deviceId,
      platform: platformnName,
      deviceJson
    };
    this.deviceChanged.next(this.device);
    return this.device;
  }
}

import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { forkJoin } from 'rxjs';
import { ShoutDetailsService } from 'src/app/shout-profile/service/shout-details.service';

@Component({
  selector: 'app-shout-results-after',
  templateUrl: './shout-results-after.component.html',
  styleUrls: ['./shout-results-after.component.scss'],
})
export class ShoutResultsAfterComponent implements OnInit {

  /** Input form ref details */
  @Input('shoutFormRef') shoutFormRef;

  /** Input form display */
  @Input('formDisplay') formDisplay;

  /** Which category of form fields are we displaying? */
  @Input('category') category;

  /** Results that have already been posted by the user */
  @Input('existingResults') existingResults;

  /** List of insights values */
  updatedInsightsArray: any[] = [];

  /** List of poll response values */
  updatedPollsArray: any[] = [];

  /** List of uploaded screenshots for display and delete */
  uploadedScreenshots: any[] = [];

  /** List of existing Spotify Insights data */
  existingSpotifyInsights: any[] = [];

  constructor(
    private shoutDetailsService: ShoutDetailsService
  ) { }

  ngOnInit() {

    // Existing Screenshots
    if (this.existingResults && this.existingResults.formScreens) {
      this.uploadedScreenshots = this.existingResults.formScreens;
    }

    // Existing Spotify Insights
    if (this.existingResults && this.existingResults.spotify_Insights) {
      this.existingSpotifyInsights = this.existingResults.spotify_Insights;
    }
  }

  isRowHidden(formRow) {
    if (formRow.category !== this.category) {
      return true; // Row will be hidden
    }
  }

  getType(formRow) {
    switch (formRow.formCode) {
      // Screenshot cases
      case "Pre_Screenshot":
        return "screenshot";
      case "Post_Screenshot_Of_Results":
        return "screenshot";
      case "Pre_Screenshot_of_your_TikTok_Profile":
        return "screenshot"


      // IG Insights cases
      case "Post_Spotify_Insights":
        return "ig_insights";

      // Poll A&B cases
      case "Post_Poll_With_A_and_B":
        return "poll_a_b";


    }
  }

  pollUpdate(field, ev, formRow) {
    // console.log('pollUpdate()');
    // console.log('field: ', field);
    // console.log('ev: ', ev.detail.value);
    // console.log('formRow: ', formRow);

    let updatedPoll = false;
    for (let pollSet of this.updatedPollsArray) {
      if (pollSet.formRow.id == formRow.id && pollSet.formRow.order == formRow.order) {
        pollSet[field] = ev.detail.value;
        updatedPoll = true;
        break;
      }
    }
    if (!updatedPoll) {
      let newSet = {
        formRow: null,
        A: undefined,
        B: undefined,
        C: undefined,
        D: undefined,
      };
      newSet[field] = ev.detail.value
      newSet.formRow = JSON.parse(JSON.stringify(formRow));
      this.updatedPollsArray.push(JSON.parse(JSON.stringify(newSet)));
    }

    // console.log('updatedPollsArray: ', this.updatedPollsArray);
    let fjRequests = [];

    for (let insightSet of this.updatedPollsArray) {
      // If all the fields are valid then post if off...
      let tempSet = JSON.parse(JSON.stringify(insightSet));
      if (tempSet.A && tempSet.B) {
        fjRequests.push(
          this.shoutDetailsService.resultPoll(
            tempSet.formRow.taskId,
            tempSet.formRow.order,
            tempSet.formRow.id,
            tempSet.A,
            tempSet.B,
            tempSet.C,
            tempSet.D
          ));
      }
    }

    forkJoin(fjRequests).subscribe(fjRes => {
      console.log('POLL fjRes: ', fjRes);
    }, err => {
      console.log('POLL fjRes error: ', err);
    })
  }

  getInsightValue(field, formRow) {

    if (this.existingSpotifyInsights) {
      for (let insightSet of this.existingSpotifyInsights) {
        if (insightSet.formRefId === formRow.formId && insightSet.displayOrder === formRow.order) {
          return insightSet[field];
        }
      }
    }

  }

  insightUpdate(field, ev, formRow) {
    // console.log('insightUpdate()');
    // console.log('field: ', field);
    // console.log('ev: ', ev.detail.value);
    // console.log('formRow: ', formRow);
    let updatedInsight = false;
    for (let insightSet of this.updatedInsightsArray) {
      if (insightSet.formRow.id == formRow.id && insightSet.formRow.order == formRow.order) {
        insightSet[field] = ev.detail.value;
        updatedInsight = true;
        break;
      }
    }
    if (!updatedInsight) {
      let newSet = {
        formRow: null,
        views: undefined,
        impressions: undefined,
        stickerTaps: undefined,
        websiteClicks: undefined,
        profileVisits: undefined
      };
      newSet[field] = ev.detail.value
      newSet.formRow = JSON.parse(JSON.stringify(formRow));
      this.updatedInsightsArray.push(JSON.parse(JSON.stringify(newSet)));
    }
    // console.log('updatedInsightsArray: ', this.updatedInsightsArray);
    let fjRequests = [];

    for (let insightSet of this.updatedInsightsArray) {
      // If all the fields are valid then post if off...
      let tempSet = JSON.parse(JSON.stringify(insightSet));
      if (tempSet.views && tempSet.impressions && tempSet.stickerTaps && tempSet.websiteClicks && tempSet.profileVisits) {
        fjRequests.push(
          this.shoutDetailsService.resultSpotifyInsight(
            tempSet.formRow.taskId,
            tempSet.formRow.order,
            tempSet.formRow.id,
            tempSet.views,
            tempSet.impressions,
            tempSet.stickerTaps,
            tempSet.websiteClicks,
            tempSet.profileVisits
          ));
      }
    }

    forkJoin(fjRequests).subscribe(fjRes => {
      console.log('fjRes: ', fjRes);
    }, err => {
      console.log('fjRes error: ', err);
    })
  }

  sceenshotLocation(ev, formRow) {
    // Submit this screenshot as a result.
    this.shoutDetailsService.resultScreenshot(
      formRow.taskId,
      formRow.order,
      formRow.id,
      ev
    ).subscribe(screenshotRes => {
      console.log('screenshotRes: ', screenshotRes);
    }, err => {
      console.log('screenshotRes: ', err);
    })
  }

  removeImage(ev) {
    // console.log('removeImage(): ', ev);

    // this.shoutDetailsService.removeResultScreenshot()
  }

}

import { Component, Input, OnInit } from '@angular/core';
import { ActivityService } from 'src/app/my-activity/service/activity.service';
import DOMPurify from 'dompurify';
import { DomSanitizer } from '@angular/platform-browser';
import { take } from 'rxjs/operators';
import { TaskService } from 'src/app/home-earn/service/task.service';
import { Router } from '@angular/router';
import { CurrencyService } from 'src/app/services/currency/currency.service';
import { CountryService } from 'src/app/services/country/country.service';
import { StorageService } from 'src/app/services/storage/storage.service';
import { FirebaseService } from 'src/app/services/firebase/firebase.service';
import { Subscription } from 'rxjs';
import { Platform } from '@ionic/angular';
import { LocalUserService } from 'src/app/welcome/services/local-user/local-user.service';

@Component({
  selector: 'app-horiz-task-row',
  templateUrl: './horiz-task-row.component.html',
  styleUrls: ['./horiz-task-row.component.scss'],
})
export class HorizTaskRowComponent implements OnInit {
  /** Are we showing recent tasks or my tasks? */
  @Input('isMyTasks') isMyTasks: boolean | string = true;

  /** Label for which tasks we're showing */
  @Input('headingText') headingText: string = 'My jobs';

  /** My Active Tasks */
  myTasks;

  /** My Currency Code */
  @Input('myCurrencyCode') myCurrencyCode: string;

  /** My Conversion Rate if I am from USA */
  myConversionRateUsd: Object;

  /** My Conversion Rate if I am from UK */
  myConversionRateUk: Object;

  /** Filter request for major consistency! */
  filterRequest = {
    page: {
      take: 8,
      skip: 0,
    },
    location: null,
    categoryIds: null,
    subCategoryIds: null,
    searchQuery: null,
    task: {
      isShout: false,
    },
    excludePostedByRole: 3,
    countryCode:
      this.firebaseService?.myProfile?.countryCode == 'GB'
        ? 'UK'
        : this.firebaseService?.myProfile?.countryCode == 'US'
        ? this.firebaseService.myProfile.countryCode
        : this.countryService?.userCountry?.country == 'GB'
        ? 'UK'
        : this.countryService?.userCountry?.country == 'US'
        ? this.countryService?.userCountry?.country
        : 'any',
  };

  profileSub: Subscription;

  countrySubscription: Subscription;

  constructor(
    private router: Router,
    private platform: Platform,
    private activityService: ActivityService,
    private taskService: TaskService,
    private sanitizer: DomSanitizer,
    private currencyService: CurrencyService,
    private countryService: CountryService,
    private storageService: StorageService,
    public firebaseService: FirebaseService,
    public localUserSevice: LocalUserService
  ) {}

  ngOnInit() {
    // console.log('VBCEFUIYBVYERUBGHVYREF', this.firebaseService?.myProfile?.countryCode);
    // console.log('REIUVBREYVUREBYVYCREBYUVRE', this.countryService?.userCountry);

    this.countrySubscription = this.countryService.userCountryChanged.subscribe((countryRes: any) => {
      this.filterRequest.location = {
        locationType: 0,
        lat: Number(countryRes.loc.split(',')[0]),
        lon: Number(countryRes.loc.split(',')[1]),
        distance: 200,
      };
      this.getMyTasks();
      this.getMyRates();
    });
    this.profileSub = this.firebaseService.getProfileUpdateListener().subscribe((res) => {
      if (this.countryService.userCountry) {
        this.getMyTasks();
        this.getMyRates();
      }
    });
    this.taskService.shouldRefresh.subscribe((refresh) => {
      if (refresh.shouldRefresh) {
        this.getMyTasks(refresh.spinner);
        this.getMyRates();
      }
    });
  }

  ngOnDestroy() {
    this.profileSub.unsubscribe();
    this.countrySubscription.unsubscribe();
  }

  getMyTasks(ev?) {
    // console.log('getMyTasks(): ', this.firebaseService.myProfile);
    if (this.isMyTasks == true && this.firebaseService.myProfile) {
      this.taskService.getTasksIPosted().subscribe(
        (myTasksRes) => {
          // console.log('myTasksRes: ', myTasksRes);
          this.myTasks = myTasksRes.data;
          if (ev) ev.target.complete();
          this.taskService.shouldRefresh.next({
            shouldRefresh: false,
            spinner: undefined,
          });
        },
        (err) => {
          console.log('myTasksRes error: ', err);
          if (ev) ev.target.complete();
        }
      );
    } else if (this.isMyTasks == true && !this.firebaseService.myProfile) {
      this.myTasks = [];
    } else {
      this.filterRequest.page = {
        take: 12,
        skip: 0,
      };
      // console.log('filterRequest', this.filterRequest);
      this.taskService
        .getFilterTasksv2(this.filterRequest)
        .pipe(take(1))
        .subscribe(
          (filterRes) => {
            // console.log('filterRes: ', filterRes);
            this.myTasks = filterRes;
            for (let task of this.myTasks) {
              if (this.storageService.uniTaskrUser && this.storageService.uniTaskrUser.userId == task.userId) {
                task.isPinTask = true;
              }
              if (task.locationType == 1 && task.location !== '') {
                this.countryService.getShortAddress(task.lat, task.lon).subscribe((res: any) => {
                  task.location = res.results[0] ? res.results[0].address_components[2].short_name : '';
                });
              }
            }
          },
          (err) => {
            console.log('taskRes error: ', err);
          }
        );
    }
  }

  getMyRates() {
    this.currencyService.getConversionRate('USD', 'GBP').subscribe((rateRes) => {
      // console.log('$ > £ rate res: ', rateRes);
      this.myConversionRateUk = rateRes.rate;
    });
    this.currencyService.getConversionRate('GBP', 'USD').subscribe((rateRes) => {
      // console.log('£ > $ rate res: ', rateRes);
      this.myConversionRateUsd = rateRes.rate;
    });
  }

  openManageTasks() {
    // console.log('openManageTasks()');
    // this.router.navigate(['/my-activity-buying']);
    if (this.platform.is('desktop')) {
      this.router.navigate([`/desktop-activity`]);
    } else {
      // this.router.navigate(['/my-activity-buying']);
      this.router.navigate(['/mobile-activity-buying']);
    }
  }

  getRecruitRoleType(recruitRoleType) {
    switch (recruitRoleType) {
      case 1:
        return 'Full-Time';
      case 2:
        return 'Part-Time';
      case 3:
        return 'Internship';
      case 4:
        return 'Task';
    }
  }

  getTaskColor(recruitRoleType) {
    switch (recruitRoleType) {
      case 1:
        return 'tertiary';
      case 2:
        return 'success';
      case 3:
        return 'warning';
      case 4:
        return 'primary';
    }
  }

  async openTask(task) {
    // console.log('openTask(): ', task);
    if (this.firebaseService.myProfile) {
      if (this.isMyTasks == true) {
        if (this.platform.is('desktop')) {
          this.router.navigate([`/desktop-activity/${task.taskId}`]);
        } else {
          // this.router.navigate([`/task-manage/${task.taskId}/applied`]);
          this.router.navigate([`/mobile-activity-buying/${task.taskId}`]);
        }
      } else {
        this.router.navigate([`/task-profile/${task.taskId}`]);
      }
    } else {
      this.storageService.setAndString('target-task', { type: 'task', id: task.taskId });
      // this.router.navigate(['/reg-with-email/business']);
      this.router.navigate(['/getstarted-email']);
    }
  }

  getSanitized(htmlString: string) {
    let output = htmlString.replace('\n', '<br> <br>');
    const sanitizedContent = DOMPurify.sanitize(output);
    return this.sanitizer.bypassSecurityTrustHtml(sanitizedContent);
  }

  whichRate(taskCode) {
    if (taskCode == '') {
      taskCode = 'GBP';
    }

    if (this.myCurrencyCode == taskCode) {
      return 1;
    } else if (this.localUserSevice.myCurrencyCode !== taskCode && this.myCurrencyCode == 'GBP') {
      return this.myConversionRateUk;
    } else if (this.localUserSevice.myCurrencyCode !== taskCode && this.myCurrencyCode == 'USD') {
      return this.myConversionRateUsd;
    }
  }
}

import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-title-and-chips',
  templateUrl: './title-and-chips.component.html',
  styleUrls: ['./title-and-chips.component.scss'],
})
export class TitleAndChipsComponent implements OnInit {

  /** Input text for the title */
  @Input('title') title: string;

  /** List of words to be show as chips. */
  @Input('chips') chips: string;

  /** Chips in a proper array */
  // chipsList: string[] = ["English", "AfrikaansAfrikaans", "AfrikaansGerman", "French", "Swahili", "Hindi", "Somali"]
  chipsList: string[];

  constructor() { }

  ngOnInit() {
    this.listifyChips()
  }

  listifyChips() {
    if (this.chips) {
      this.chipsList = this.chips.split(',');
    }
  }

}

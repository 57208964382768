import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from '../http/http.service';
import { StorageService } from '../storage/storage.service';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  /** Have deposit listeners been loaded? */
  depositListeners: boolean = false;

  /** Have pay listeners been loaded? */
  payListeners: boolean = false;

  constructor(
    private http: HttpService,
    private storageService: StorageService
  ) { }

  depositSuccess(offerId: number) {
    return this.http.runHttpCall(
      "POST",
      "api/payment/task/deposit/success",
      "application/json", {
      taskOfferId: offerId
    })
  }

  depositAttempt(request): Observable<any> {
    return this.http.runHttpCall(
      "POST",
      "api/payment/task/deposit/attempt",
      "application/json",
      request);
  }

  getDepositSummary(offerId) {
    return this.http.runHttpCall(
      "POST",
      "api/payment/task/deposit/summary",
      "application/json", {
      taskOfferId: offerId
    })
  }

  finalAttempt(request): Observable<any> {
    return this.http.runHttpCall(
      "POST",
      "api/payment/task/final/attempt",
      "application/json",
      request);
  }

  getFinalSummary(offerId) {
    return this.http.runHttpCall(
      "POST",
      "api/payment/task/final/summary",
      "application/json", {
      taskOfferId: offerId
    })
  }

  /**
   * Methods for 
   * SERVICES
   */


  depositSuccessService(offerId: number) {
    return this.http.runHttpCall(
      "POST",
      "api/payment/service/deposit/success",
      "application/json", {
      serviceOfferId: offerId
    })
  }

  depositAttemptService(request): Observable<any> {
    return this.http.runHttpCall(
      "POST",
      "api/payment/service/deposit/attempt",
      "application/json",
      request);
  }

  getDepositSummaryService(offerId) {
    return this.http.runHttpCall(
      "POST",
      "api/payment/service/deposit/summary",
      "application/json", {
      serviceOfferId: offerId
    })
  }

  finalAttemptService(request): Observable<any> {
    return this.http.runHttpCall(
      "POST",
      "api/payment/service/final/attempt",
      "application/json",
      request);
  }

  getFinalSummaryService(offerId) {
    return this.http.runHttpCall(
      "POST",
      "api/payment/service/final/summary",
      "application/json", {
      serviceOfferId: offerId
    })
  }

  /** 
   * Sundry services
   */

  getMyCards() {
    return this.http.runHttpCall(
      "GET",
      "api/payment/user/cards",
      "application/json")
  }

  getMyBankDetails() {
    return this.http.runHttpCall(
      "GET",
      "api/accountDetails",
      "application/json")
  }

  sendMyBankDetails(detailsRequest) {
    return this.http.runHttpCall(
      "PUT",
      "api/accountDetails",
      "application/json",
      detailsRequest)
  }

  deleteCard(cardId) {
    return this.http.runHttpCall(
      "DELETE",
      `api/payment/user/card/${cardId}`,
      "application/json"
    )
  }


  /**
   * Save USA Payment deatils
   */

  createUSAtellerooDetails(newDetails: any) {
    return this.http.runHttpCallUniCreateApi(
      "POST",
      "telleroo-usa-detail",
      "application/json", 
      newDetails)
  }

  getUSAtellerooDetails() {
    return this.http.runHttpCallUniCreateApi(
      "GET",
      `telleroo-usa-detail/creator/${this.storageService.firebaseUser.uid}`,
      "application/json")
  }


}

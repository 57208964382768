// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.slide-options {
  --color: #fff;
  --background: var(--unitaskr-color-blue);
}

.turquoise_bg {
  --background: var(--ion-color-light-tint);
}

.online {
  color: green;
  position: absolute;
  bottom: 10px;
  left: 36px;
}

.shorted {
  position: absolute;
  top: 10px;
  left: 0px;
}

.unread {
  position: absolute;
  top: 10px;
  left: 36px;
}

.list_label_h2 {
  font-size: 16px;
}

.list_label_h3 {
  font-size: 13px;
  margin-bottom: 0;
}

.list_label_p {
  font-size: 11px;
}

.show-more {
  white-space: normal !important;
}

ion-item ion-label a {
  font-size: 0.9rem;
  cursor: pointer;
}
@media only screen and (min-device-width: 575px) and (max-device-width: 991px) {
  ion-item ion-buttons ion-button {
    width: 25vw !important;
  }
}
@media only screen and (min-device-width: 992px) and (max-device-width: 1199px) {
  ion-item ion-buttons ion-button {
    width: 20vw !important;
  }
}
@media only screen and (min-device-width: 1200px) {
  ion-item ion-buttons ion-button {
    width: 16vw !important;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/components/manage-task-list-item/manage-task-list-item.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,wCAAA;AACF;;AAEA;EACE,yCAAA;AACF;;AAEA;EACE,YAAA;EACA,kBAAA;EACA,YAAA;EACA,UAAA;AACF;;AACA;EACE,kBAAA;EACA,SAAA;EACA,SAAA;AAEF;;AAAA;EACE,kBAAA;EACA,SAAA;EACA,UAAA;AAGF;;AAAA;EACE,eAAA;AAGF;;AADA;EACE,eAAA;EACA,gBAAA;AAIF;;AAFA;EACE,eAAA;AAKF;;AAFA;EACE,8BAAA;AAKF;;AAAI;EACE,iBAAA;EACA,eAAA;AAGN;AAEM;EADF;IAEI,sBAAA;EACN;AACF;AAAM;EAJF;IAKI,sBAAA;EAGN;AACF;AAFM;EAPF;IAQI,sBAAA;EAKN;AACF","sourcesContent":[".slide-options {\n  --color: #fff;\n  --background: var(--unitaskr-color-blue);\n}\n\n.turquoise_bg {\n  --background: var(--ion-color-light-tint);\n}\n\n.online {\n  color: green;\n  position: absolute;\n  bottom: 10px;\n  left: 36px;\n}\n.shorted {\n  position: absolute;\n  top: 10px;\n  left: 0px;\n}\n.unread {\n  position: absolute;\n  top: 10px;\n  left: 36px;\n}\n\n.list_label_h2 {\n  font-size: 16px;\n}\n.list_label_h3 {\n  font-size: 13px;\n  margin-bottom: 0;\n}\n.list_label_p {\n  font-size: 11px;\n}\n\n.show-more {\n  white-space: normal !important;\n}\n\nion-item {\n  ion-label {\n    a {\n      font-size: 0.9rem;\n      cursor: pointer;\n    }\n  }\n  ion-buttons {\n    ion-button {\n      @media only screen and (min-device-width: 575px) and (max-device-width: 991px) {\n        width: 25vw !important;\n      }\n      @media only screen and (min-device-width: 992px) and (max-device-width: 1199px) {\n        width: 20vw !important;\n      }\n      @media only screen and (min-device-width: 1200px) {\n        width: 16vw !important;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

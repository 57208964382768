import { Component, Input, OnInit } from '@angular/core';
import { initializeApp } from 'firebase/app';
import { DocumentData, DocumentReference, Timestamp, addDoc, collection, doc, getDoc, getFirestore, onSnapshot, orderBy, query, serverTimestamp, setDoc, updateDoc, where } from 'firebase/firestore';
import { TaskService } from 'src/app/home-earn/service/task.service';
import { StorageService } from 'src/app/services/storage/storage.service';
import { environment } from 'src/environments/environment';
import { DesktopChatService } from '../../services/desktop-chat/desktop-chat.service';
import { ImageService } from 'src/app/services/image/image.service';
import { getAuth } from 'firebase/auth';
import { DatePipe } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';
import DOMPurify from 'dompurify';
import { PriceChangeRespondPage } from 'src/app/price-change-respond/price-change-respond.page';
import { AlertController, ModalController } from '@ionic/angular';
import { NotificationsService } from '../../../services/notifications/notifications.service';

import { Editor, Toolbar, toHTML, toDoc } from 'ngx-editor';

@Component({
  selector: 'app-firebase-chat-block',
  templateUrl: './firebase-chat-block.component.html',
  styleUrls: ['./firebase-chat-block.component.scss'],
})
export class FirebaseChatBlockComponent  implements OnInit {

  @Input('chatDetails') chatDetails: any;

  /** Chat ID */
  chatId: string | null;

  app = initializeApp(environment.firebaseConfig);
  db = getFirestore(this.app);

  newMessage: string | undefined;

  /** Channel Messages */
  channelMessages: DocumentData[] = [];

  /** Focussed Chat */
  focussedFirebaseChat: any;

  /** Chat Reference */
  chatReference: any;
  focussedFirebaseChatReference: any;
  currentChatID: string | undefined;

  /** Today's Date */
  today: Date = new Date();

  chattingToUid: string | undefined;

  firebaseChatChannelReference: any;

  detailedTaskOffer: any;
  focussedTask: any;


  isSendingMessage: boolean = false;

  /** Wig Object -> has a JSON structure.. */
  wysiwygObject: any;
  /** New Wysiwyg Editor */
  msgEditor: Editor;
  toolbar: Toolbar = [
    ['bold', 'italic'],
    ['underline'],
    // ['code',] 
    // ['align_left', 'align_center', 'align_right', 'align_justify'], 
  ];
  // colorPresets = ['red', '#FF0000', 'rgb(255, 0, 0)'];

  /** Wysiwyg html content */
  htmlContent: string | undefined;
  
  constructor(
    private modalCtrl: ModalController,
    private alertController: AlertController,
    private storageService: StorageService,
    private taskService: TaskService,
    private desktopService: DesktopChatService,
    public imageService: ImageService,
    private datePipe: DatePipe,
    private sanitizer: DomSanitizer,
    private readonly notificationsService: NotificationsService
  ) { }

  async ngOnInit() {

    this.msgEditor = new Editor({
      keyboardShortcuts: true,
    });

    console.log('------>>')
    console.log('FB Chat Block: ', this.chatDetails);
    console.log('------>>')
    this.chatId = this.chatDetails.data.taskOfferId;
    this.firebaseChatChannelReference = this.chatDetails.reference;

    if (this.storageService.firebaseUser) {
      const checkUserRef = doc(this.db, "users", this.storageService.firebaseUser.uid);
      const userDocSnap = await getDoc(checkUserRef);

      if (userDocSnap.exists()) {
        // console.log("CM has FB User data:", userDocSnap.data());
      } else {
        console.log("No such USER document!");

        let cmUser = {

        }

        const userReference = await addDoc(collection(this.db, "users"), cmUser);

        await setDoc(doc(this.db, "users", this.storageService.firebaseUser.uid), {
          name: `${this.storageService.uniTaskrUser.firstName} ${this.storageService.uniTaskrUser.lastName}`
        });
      }

      this.initConversation();
      this.getTaskApplication();
    }
  }

  async initConversation() {
    // console.log('initConversation(): ', this.chatId);
    let chatChannelRef;
    this.chatReference = collection(this.db, environment.firebase_chat.channels);
    if (this.chatId) {

      // console.log('FB DB: ', this.db)
      chatChannelRef = doc(this.db, environment.firebase_chat.channels, `${this.chatId}`);
      const docSnap = await getDoc(chatChannelRef);
      // console.log('initConversation() - docSnap: ', docSnap);

      if (docSnap.exists()) {
        // console.log("Chat Channel data:", docSnap.data());
        this.focussedFirebaseChat = docSnap.data();
        // console.log('chatChannelRef: ', chatChannelRef)
        this.listenToChatChanges(chatChannelRef);
      } else {
        console.log("No such document!");
      }
    }
  }

  async listenToChatChanges(chatChannelReference: any) {
    console.log('>>> listenToChatChanges() <<<: ', chatChannelReference);
    this.firebaseChatChannelReference = chatChannelReference;
    const q = query(collection(this.db, environment.firebase_chat.chat_messages), where("chat", "==", chatChannelReference), orderBy('timestamp', 'asc'));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      this.channelMessages = []
      querySnapshot.forEach((doc) => {
        // console.log('messge doc: ', doc.data());
        this.channelMessages.push(doc.data());
      });
    });
  }

  getTaskApplication() {
    // console.log('getTaskApplication(): ', this.chatId);
    if (this.chatId) {
      this.taskService.getDetailedOffer(Number(this.chatId)).subscribe(detailedOfferRes => {
        // console.log('detailedOfferRes: ', detailedOfferRes);
        this.detailedTaskOffer = detailedOfferRes;
        this.getTask();
      }, err => {
        console.log('detailedOfferRes error: ', err);
      })
    }
  }

  getTask() {
    this.taskService.getTaskById(this.detailedTaskOffer.taskId).subscribe(completeTaskRes => {
      // console.log('completeTaskRes: ', completeTaskRes);
      this.focussedTask = completeTaskRes;
      this.desktopService.focussedJob = completeTaskRes;
    }, err => {
      console.log('completeTaskRes error: ', err);
    });
  }

  getApplicationStatus() {
    if (
      !this.detailedTaskOffer.isAccepted &&
      !this.detailedTaskOffer.isCompleted &&
      !this.detailedTaskOffer.isRejected
    ) {
      return 'Applied';
    } else if (
      this.detailedTaskOffer.isAccepted &&
      !this.detailedTaskOffer.isBuyerCompleted &&
      !this.detailedTaskOffer.isRejected
    ) {
      return 'Hired';
    } else if (
      this.detailedTaskOffer.isAccepted &&
      this.detailedTaskOffer.isBuyerCompleted &&
      !this.detailedTaskOffer.isRejected
    ) {
      return 'Completed';
    } else if (
      !this.detailedTaskOffer.isAccepted &&
      !this.detailedTaskOffer.isCompleted &&
      !this.detailedTaskOffer.isRejected
    ) {
      return 'Applied';
    } else {
      return 'Applied';
    }
  }

  async sendMessage() {
    console.log('---- >> sendMessage(): ', this.newMessage);
    console.log('---- >> chatId: ', this.chatId);

    this.isSendingMessage = true;
    let htmlPreEdit;

    if (this.htmlContent) {
      htmlPreEdit = this.htmlContent.replace('<br><br>', ' ')
      htmlPreEdit = this.htmlContent.replace('<br>', ' ')
      this.newMessage = htmlPreEdit.replace(/<[^>]+>/g, '');
      // console.log('sendMessage(): ', this.newMessageText);
      // console.log('sendMessage(): ', this.htmlContent);
    }

    const auth = getAuth();
    const user = auth.currentUser;
    const userDocRef = doc(this.db, "users", this.storageService.firebaseUser.uid)
    // let chatDocRef;
    // try {
    //   chatDocRef = doc(this.db, "chats", this.chatId);
    // } catch (e) {
    //   console.log('---> Chat doc error: ', e);
    // }

    if (this.storageService.uniTaskrUser && this.newMessage) {
      let newChatMessage: {
        user: DocumentReference,
        chat: DocumentReference,
        timestamp: Timestamp,
        text: string,
        htmlContent: string,
        sentByUid: string,
      } = {
        chat: this.firebaseChatChannelReference,
        text: this.htmlContent ? this.htmlContent : this.newMessage,
        htmlContent: this.htmlContent ? this.htmlContent : '',
        timestamp: Timestamp.fromDate(new Date()),
        user: userDocRef,
        sentByUid: this.storageService.firebaseUser.uid,
      }
      // console.log('>>> newChatMessage: ', newChatMessage);

      const sentMessageRef = await addDoc(collection(this.db, environment.firebase_chat.chat_messages), newChatMessage);

      // console.log('sentMessage: ', sentMessageRef.id);

      /** 
       * Update the overall chat object
       */
      // let updatedChat = await updateDoc(this.chatDetails.reference, {
      //   last_message_sent_by: userDocRef,
      //   last_message_time: new Date(),
      //   last_message: this.newMessage,
      //   readBy: [`${this.storageService.firebaseUser.uid}`]
      // });

      this.htmlContent = undefined;
      this.msgEditor.setContent('')
      this.isSendingMessage = false;

      let notificationUID = this.focussedFirebaseChat.creatorUID;
      let path = '/student-inbox';
      if (this.storageService.firebaseUser.uid == this.focussedFirebaseChat.creatorUID) {
        // send to hirer
        notificationUID = this.focussedFirebaseChat.hirerUID;
        path = '/mobile-activity-buying';
      }

      this.notificationsService.sendNotification({
        UserId: notificationUID,
        Message: 'You have a new message',
        Title: 'You have a new message',
        Path: path
      }).subscribe(x => {
        console.log('💌💌💌 Message Sent');
      }
      );

      /**
       * Update latest message info on the channel obhect...
       */

      if (this.focussedFirebaseChat && this.chatId) {
        console.log('Update the overall channel: ', this.focussedFirebaseChat);
        // let chatChannelReference = doc(this.db, environment.firebase_chat.channels, this.chatId);
        // const userDocRef = doc(this.db, "users", this.storageService.firebaseUser.uid)
        await updateDoc(this.firebaseChatChannelReference, {
          last_message: this.newMessage ? this.newMessage : 'New message 💬',
          last_message_sent_by: userDocRef,
          last_message_time: serverTimestamp()
        });
      } else {
        console.warn('Unable to update channel: ')
        console.warn('focussedFirebaseChat: ', this.focussedFirebaseChat)
        console.warn('chatId: ', this.chatId)
      }

      this.newMessage = undefined;
    }
  }

  isMyMessage(message: any) {
    if (this.storageService.firebaseUser && message.user && message.user.id == this.storageService.firebaseUser.uid) {
      return true;
    } else {
      return false;
    }
  }

  positionMessage(message: any) {
    if (this.storageService.firebaseUser && message.sentByUid == this.storageService.firebaseUser.uid) {
      return "col-start-6 col-end-13 p-3 rounded-lg"
    } else {
      return "col-start-1 col-end-8 p-3 rounded-lg"
    }
  }

  reverseMessage(message: any) {
    if (this.storageService.firebaseUser && message.sentByUid == this.storageService.firebaseUser.uid) {
      return "flex items-center justify-start flex-row-reverse"
    } else {
      return "flex flex-row items-center"
    }
  }

  messageSpacing(message: any) {
    if (this.storageService.firebaseUser && message.sentByUid == this.storageService.firebaseUser.uid) {
      return "relative mr-3 text-sm bg-blue-600 py-2 px-4 shadow rounded-xl text-gray-100"
    } else {
      return "relative ml-3 text-sm bg-gray-300 py-2 px-4 shadow rounded-xl text-gray-900"
    }
  }

  readColour(message: any) {
    if (message.read_by && message.read_by.length > 0) {
      return 'text-blue-700';
    } else {
      return 'text-gray-400';
    }
  }

  getSentTime(fbTimestamp: Timestamp) {
    let updated_at = fbTimestamp.toDate().toISOString();
    if (this.isToday(updated_at)) {
      return this.datePipe.transform(updated_at, 'HH:mm');
    } else {
      return this.datePipe.transform(updated_at, 'EE dd/MM HH:mm');
    }
  }

  isToday(dateValue: string): boolean {
    if (this.today.toISOString().split('T')[0] == new Date(dateValue).toISOString().split('T')[0]) {
      return true;
    } else {
      return false;
    }
  }

  getMessageText(message: any) {
    if (message.text.includes('<b>') && (!message.htmlContent || message.htmlContent && message.htmlContent == '')) {
      let output = message.text.replace('\n', '<br> <br>').replace('&#8226;', '');
      const sanitizedContent = DOMPurify.sanitize(output);
      return this.sanitizer.bypassSecurityTrustHtml(sanitizedContent);
    } else {
      if (message.htmlContent) {
        return message.htmlContent
      }
      return message.text
    }
  }
  
  async actOnBotMessage(message: any) {
    // console.log('actOnBotMessage(): ', message);
    if (message.sentByUid !== this.storageService.firebaseUser.uid) {
      // console.log('Am allowed to respond')
      let actionCue = JSON.parse(message.botMessageCue);
      // console.log('Action Cue: ', actionCue);
      if (actionCue.priceRequest) {
        const modal = await this.modalCtrl.create({
          component: PriceChangeRespondPage,
          componentProps: {
            offerId: actionCue.offerId,
            pcrId: actionCue.pcrId,
          }
        });
        modal.present();

        const { data, role } = await modal.onWillDismiss();
        // console.log('Dismiss action modal: ', data);
        if (data.response) {
          let botMessageText = `Price change <b>${data.response == 'accept' ? 'Accepted!' : 'Declined!'}</b>`;
          this.sendBotMessage(botMessageText);
        }

      }
    } else {
      this.iCreatedThis()
    }
  }

  updatedWig(ev: any) {
    this.htmlContent = toHTML(this.wysiwygObject);
  }

  async sendBotMessage(botMessageText: string, botMessageCue?: any) {
    // console.log('sendBotMessage()')
    const botUserDocRef = doc(this.db, "users", 'RLpHnUfpFzSl0dHVqyCK')
    let newChatMessage: {
      user: DocumentReference,
      chat: DocumentReference,
      timestamp: Timestamp,
      text: string,
      // image: string,
      sentByUid: string,
      botMessageCue: string | ''
    } = {
      chat: this.firebaseChatChannelReference,
      text: botMessageText,
      timestamp: Timestamp.fromDate(new Date()),
      user: botUserDocRef,
      sentByUid: this.storageService.firebaseUser.uid,
      botMessageCue: botMessageCue ? JSON.stringify(botMessageCue) : ''
    }
    // console.log('>>> newChatMessage: ', newChatMessage);

    if (newChatMessage.botMessageCue == '') {
      delete newChatMessage.botMessageCue;
    }

    const sentMessageRef = await addDoc(collection(this.db, environment.firebase_chat.chat_messages), newChatMessage);
    // console.log('sent BOT Message: ', sentMessageRef.id);
  }
  
  async iCreatedThis() {
    const alert = await this.alertController.create({
      header: 'Whoops!',
      message: 'You created this. You cannot respond to your own requests.',
      buttons: ['Okay'],
    });

    await alert.present();
  }
}

import { Component, OnInit } from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular';
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';

@Component({
  selector: 'app-request-camera',
  templateUrl: './request-camera.page.html',
  styleUrls: ['./request-camera.page.scss'],
})
export class RequestCameraPage implements OnInit {

  constructor(
    private modalController: ModalController,
    private alertController: AlertController
  ) { }

  ngOnInit() {
  }

  async enableCamera() {
    console.log('enableCamera()');
    this.modalController.dismiss({ next: "continue" });
  }

  cancel() {
    console.log('cancel camera request');
    this.modalController.dismiss({ next: "stop" });
  }

}

// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.screenshot_card {
  margin-top: 4px;
  margin-bottom: 4px;
}
.screenshot_card .screenshot_item {
  --background: transparent;
}
.screenshot_card .screenshot_item .display_text_label {
  overflow: unset;
  font-size: 1rem;
}
.screenshot_card input[type=file] {
  display: none;
}
.screenshot_card .add_another_image {
  background: var(--bg-light-blue);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.screenshot_card .custom-file-upload {
  display: inline-block;
  cursor: pointer;
}
.screenshot_card .custom-file-upload .attachment_button {
  width: 100%;
  --background: var(--ion-color-light-shade);
}
.screenshot_card ion-icon {
  font-size: large;
  color: var(custom-file-upload);
}
.screenshot_card .screenshot_img_row {
  width: 100%;
  justify-content: center;
  margin-right: 2.5%;
  margin-bottom: 5%;
  margin-top: 5%;
}
.screenshot_card .screenshot_img_row img {
  max-width: 80%;
  max-height: 230px;
}
.screenshot_card .screenshot_img_row .delete_badge {
  position: absolute;
  right: 4px;
  border-radius: 12px;
}
.screenshot_card .screenshot_img_row .delete_badge ion-icon {
  font-size: larger;
}`, "",{"version":3,"sources":["webpack://./src/app/components/result-screenshot/result-screenshot.component.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;EACA,kBAAA;AACJ;AACI;EACI,yBAAA;AACR;AAAQ;EACI,eAAA;EACA,eAAA;AAEZ;AAEI;EACI,aAAA;AAAR;AAGI;EACI,gCAAA;EACA,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,mBAAA;AADR;AAII;EACI,qBAAA;EACA,eAAA;AAFR;AAMQ;EACI,WAAA;EACA,0CAAA;AAJZ;AAQI;EACI,gBAAA;EACA,8BAAA;AANR;AASI;EACI,WAAA;EACA,uBAAA;EACA,kBAAA;EACA,iBAAA;EACA,cAAA;AAPR;AAQQ;EACI,cAAA;EACA,iBAAA;AANZ;AASQ;EACI,kBAAA;EACA,UAAA;EACA,mBAAA;AAPZ;AAQY;EACI,iBAAA;AANhB","sourcesContent":[".screenshot_card {\n    margin-top: 4px;\n    margin-bottom: 4px;\n\n    .screenshot_item {\n        --background: transparent;\n        .display_text_label {\n            overflow: unset;\n            font-size: 1rem;\n        }\n    }\n\n    input[type=\"file\"] {\n        display: none;\n    }\n\n    .add_another_image {\n        background: var(--bg-light-blue);\n        display: flex;\n        flex-direction: column;\n        justify-content: center;\n        align-items: center;\n    }\n\n    .custom-file-upload {\n        display: inline-block;\n        cursor: pointer;\n        // position: absolute;\n        // right: 5%;\n        // width: 25%;\n        .attachment_button {\n            width: 100%;\n            --background: var(--ion-color-light-shade);\n        }\n    }\n\n    ion-icon {\n        font-size: large;\n        color: var(custom-file-upload);\n    }\n\n    .screenshot_img_row {\n        width: 100%;\n        justify-content: center;\n        margin-right: 2.5%;\n        margin-bottom: 5%;\n        margin-top: 5%;\n        img {\n            max-width: 80%;\n            max-height: 230px;\n        }\n\n        .delete_badge {\n            position: absolute;\n            right: 4px;\n            border-radius: 12px;\n            ion-icon {\n                font-size: larger;\n            }\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.doughnut {
  background: #000;
  display: block;
  width: 110px;
  height: 110px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 10px solid red;
  border-radius: 100%;
}`, "",{"version":3,"sources":["webpack://./src/app/components/doughnut/doughnut.component.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;EACA,cAAA;EACA,YAAA;EACA,aAAA;EACA,kBAAA;EACA,QAAA;EACA,SAAA;EACA,gCAAA;EACA,sBAAA;EACA,mBAAA;AACJ","sourcesContent":[".doughnut {\n    background: #000;\n    display: block;\n    width: 110px;\n    height: 110px;\n    position: absolute;\n    top: 50%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n    border: 10px solid red;\n    border-radius: 100%;\n}\n\n// .item {\n//     position: relative;\n//     float: left;\n// }\n\n// .item h2 {\n//     text-align: center;\n//     position: absolute;\n//     line-height: 125px;\n//     width: 100%;\n// }\n\n// svg {\n//     -webkit-transform: rotate(-90deg);\n//     transform: rotate(-90deg);\n// }\n\n// .circle_animation {\n//     stroke-dasharray: 440; /* this value is the pixel circumference of the circle */\n//     stroke-dashoffset: 440;\n// }\n\n// .html .circle_animation {\n//     -webkit-animation: html 1s ease-out forwards;\n//     animation: html 1s ease-out forwards;\n// }\n\n// .css .circle_animation {\n//     -webkit-animation: css 1s ease-out forwards;\n//     animation: css 1s ease-out forwards;\n// }\n\n// @-webkit-keyframes html {\n//     to {\n//         stroke-dashoffset: 80; /* 50% would be 220 (half the initial value specified above) */\n//     }\n// }\n\n// @keyframes html {\n//     to {\n//         stroke-dashoffset: 80;\n//     }\n// }\n\n// @-webkit-keyframes css {\n//     to {\n//         stroke-dashoffset: 160;\n//     }\n// }\n\n// @keyframes css {\n//     to {\n//         stroke-dashoffset: 160;\n//     }\n// }\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

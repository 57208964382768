import { Component, EventEmitter, Input, NgZone, OnInit, Output } from '@angular/core';
import { LoadingController } from '@ionic/angular';
import { awsImageUploadResponse, S3UploadService } from 'src/app/services/s3-upload/s3-upload.service';
import { ShoutDetailsService } from 'src/app/shout-profile/service/shout-details.service';

@Component({
  selector: 'app-result-screenshot',
  templateUrl: './result-screenshot.component.html',
  styleUrls: ['./result-screenshot.component.scss'],
})
export class ResultScreenshotComponent implements OnInit {

  /** Unique identifier for this screenshot. */
  @Input('imgId') imageId: number;

  /** List of uploaded screenshots for display and delete */
  @Input('uploadedScreenshots') uploadedScreenshots;

  /** The formRow we're trying to fill or get filled */
  @Input('formRow') formRow;

  /** What text would like you to be displayed above the screenshot? */
  @Input('headingText') headingText: string = "Screenshot";

  /** Return the image location */
  @Output('imageLocation') imageLocation = new EventEmitter();

  /** Updated profile picture files */
  selectedFiles: FileList;
  /** URL of the uploadedImage */
  imageUrl: string;

  imageObject: any;

  constructor(
    private loadingController: LoadingController,
    private uploadService: S3UploadService,
    private shoutDetailsService: ShoutDetailsService,
    private zone: NgZone
  ) { }

  ngOnInit() { 
    // console.log('Hello result screenshot!');
    // console.log('uploadedScreenshots: ', this.uploadedScreenshots);
  }

  selectFile(event) {
    this.selectedFiles = event.target.files;
    this.upload();
  }

  getId() {
    return `shout-result-screenshot-${this.imageId}`
  }

  deleteImg() {
    console.log('deleteImg(): ', this.uploadedScreenshots);

    let focussedScreen = this.getImage();

    console.log('focussedScreen: ', focussedScreen);

    for (let screen of this.uploadedScreenshots) {
      if (screen.url === focussedScreen) {
        // Need to delete this guy!

        this.shoutDetailsService.removeResultScreenshot(screen.id, this.formRow.taskId).subscribe(deleteRes => {
          console.log('delete screen deleteRes: ', deleteRes);
          
          for (let screen of this.uploadedScreenshots) {
            if (screen.id === deleteRes.id) {
              this.uploadedScreenshots.splice(this.uploadedScreenshots.indexOf(screen), 1);
              this.imageObject = undefined;
              break;
            }
          }
          
        }, err => {
          console.log('deleteRes error: ', err);
        });
      }
    }
  }

  async upload() {
    this.zone.run(async () => {
      const loading = await this.loadingController.create({
        cssClass: 'my-custom-class',
        message: 'Uploading image...',
        duration: 10000
      });
      await loading.present();

      const file = this.selectedFiles.item(0);
      console.log('upload() file: ', file);

      this.uploadService.browserCompressUpload(file, 'shout-result-images').then((res: awsImageUploadResponse) => {
        console.log('Res: ', res);
        this.imageUrl = res.Location;

        // this.imageObject[this.getId()] = res.Location;

        this.imageObject = {
          [this.getId()]: res.Location
        }


        console.log('Set image URL: ', this.imageUrl);
        this.imageLocation.emit(res.Location);
        this.selectedFiles = undefined;
        loading.dismiss();

      }).catch(err => {
        console.log('Catch: ', err);
        loading.dismiss();
      })
    })
  }

  getImage() {
    if (this.imageObject) {
      return this.imageObject[this.getId()];
    }

    if (this.uploadedScreenshots) {
      for (let screen of this.uploadedScreenshots) {
        if (screen.formRefId === this.formRow.formId && screen.displayOrder === this.formRow.order) {
          return screen.url;
        }
      }
    }
  }

}

// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Header in About Section */
.About_Profile_head {
  width: 100%;
  --background: transparent;
}
.About_Profile_head ion-avatar {
  margin-right: 10px;
  height: 80px;
  width: 80px;
}
.About_Profile_head ion-icon {
  font-size: 0.9375rem;
}
.About_Profile_head ion-label {
  font-size: 1.125rem;
  font-family: Poppins-Regular !important;
}

/* Header in About Section END */`, "",{"version":3,"sources":["webpack://./src/app/components/title-avatar-row/title-avatar-row.component.scss"],"names":[],"mappings":"AAAA,4BAAA;AACA;EACI,WAAA;EAEA,yBAAA;AAAJ;AACI;EACI,kBAAA;EACA,YAAA;EACA,WAAA;AACR;AACI;EACI,oBAAA;AACR;AACI;EACI,mBAAA;EACA,uCAAA;AACR;;AAEA,gCAAA","sourcesContent":["/* Header in About Section */\n.About_Profile_head {\n    width: 100%;\n    // --padding-start: 0px;\n    --background: transparent;\n    ion-avatar {\n        margin-right: 10px;\n        height: 80px;\n        width: 80px;\n    }\n    ion-icon {\n        font-size: 0.9375rem;\n    }\n    ion-label {\n        font-size: 1.125rem;\n        font-family: Poppins-Regular !important;\n    }\n}\n/* Header in About Section END */"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { NgChartsModule } from 'ng2-charts';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { StoreModule } from '@ngrx/store';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { metaReducers, ROOT_REDUCERS } from './reducers';
import { I18nModule } from './i18n/i18n.module';
import { RouterState, StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { AkitaNgDevtools } from '@datorama/akita-ngdevtools';
import { environment } from '../environments/environment';
import { ComponentsModule } from './components/components.module';

import { EmailComposer } from '@awesome-cordova-plugins/email-composer/ngx';
import { NativeAudio } from '@awesome-cordova-plugins/native-audio/ngx';

import { IonicStorageModule } from '@ionic/storage-angular';
import { Drivers } from '@ionic/storage';
import * as CordovaSQLiteDriver from 'localforage-cordovasqlitedriver';

import { customAnimation } from './animations/custom.animations';
import { TokenInterceptor } from './interceptors/token-interceptor';

import { Adjust } from '@awesome-cordova-plugins/adjust/ngx';
import { ChannelsModule } from './messaging/channels/channels.module';
import { FirebaseDynamicLinks } from '@ionic-native/firebase-dynamic-links/ngx';
import { ProStudentButtonModule } from './pro-student/components/pro-student-button/pro-student-button.module';

// import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { OpenNativeSettings } from '@ionic-native/open-native-settings/ngx';
// import { StudentEarnComponentsModule } from './student-earn-tabs/components/student-earn-components.module';

import { Smartlook } from '@awesome-cordova-plugins/smartlook/ngx';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/app/', '.json');
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    IonicModule.forRoot({ navAnimation: customAnimation }),
    // IonicModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    ComponentsModule,
    // StudentEarnComponentsModule,
    ChannelsModule,
    I18nModule,
    NgChartsModule,
    StoreModule.forRoot(ROOT_REDUCERS, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
        strictStateSerializability: true,
        strictActionSerializability: true,
      },
    }),
    StoreRouterConnectingModule.forRoot({
      routerState: RouterState.Minimal,
    }),
    StoreDevtoolsModule.instrument({
      name: 'NgRx Book Store App',
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
      isolate: true,
    }),
    environment.production ? [] : AkitaNgDevtools.forRoot(),
    IonicStorageModule.forRoot({
      driverOrder: [CordovaSQLiteDriver._driver, Drivers.IndexedDB, Drivers.LocalStorage],
    }),
    ProStudentButtonModule,
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    OpenNativeSettings,
    Adjust,
    EmailComposer,
    NativeAudio,
    FirebaseDynamicLinks,
    InAppBrowser,
    // InAppBrowser,
    Smartlook
  ],
  bootstrap: [AppComponent],
  exports: [TranslateModule],
})
export class AppModule {}

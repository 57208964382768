import { Component, Input, OnInit } from '@angular/core';
import { forkJoin } from 'rxjs';
import { CvService, JobTypeResponse } from 'src/app/cv-builder-new/service/cv.service';
import { StorageService } from 'src/app/services/storage/storage.service';

@Component({
  selector: 'app-skills',
  templateUrl: './skills.component.html',
  styleUrls: ['./skills.component.scss'],
})
export class SkillsComponent implements OnInit {

  jobTypes: JobTypeResponse[];
  selectedJobTypes: JobTypeResponse[];
  /** What skills have I selected for this job type */
  selectedSkills = [];

  allReleventSkills: any[];

  /** Whose skills are we getting? */
  @Input('userId') userId: number;

  constructor(
    private cvService: CvService,
    private storageService: StorageService
  ) { }

  ngOnInit() { 
    this.getJobTypes();
    // this.getMySkills();
  }

  getJobTypes() {
    this.cvService.getUserJobTypes(this.userId).subscribe(jobTypesRes => {
      console.log('jobTypesRes: ', jobTypesRes);
      this.selectedSkills = jobTypesRes[0].isInterested && jobTypesRes[0].isInterested.split(',').length ? jobTypesRes[0].isInterested.split(',') : [jobTypesRes[0].isInterested];
      console.log('selectedSkills: ', this.selectedSkills);
      this.jobTypes = jobTypesRes;

      this.setSelectedJobTypes();
    })
  }

  setSelectedJobTypes() {
    this.selectedJobTypes = this.jobTypes.filter(type => {
      if (type.hasSkills) {
        return type;
      }
    });

    this.getJobSkills();
  }

  getJobSkills() {

    let fjArray = [];
    for (let jobType of this.selectedJobTypes) {
      fjArray.push(this.cvService.getJobSkills(jobType.jobIndustriesId))
    }

    forkJoin(fjArray).subscribe(fjRes => {
      console.log('fjRes: ', fjRes);
      this.allReleventSkills = fjRes;
    }, err => {
      console.log('fjRes error: ', err);
    });
  }

  getMySkills() {
    // Get MY skills for this job type
    // this.cvService.viewCv(this.storageService.uniTaskrUser.userId).subscribe(skillRes => {
    this.cvService.viewCv(this.userId).subscribe(skillRes => {
      console.log('mySkills: ', skillRes);
    })
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { ProStudentButtonComponent } from './pro-student-button.component';

@NgModule({
  declarations: [ProStudentButtonComponent],
  imports: [CommonModule, IonicModule],
  exports: [ProStudentButtonComponent],
})
export class ProStudentButtonModule {}

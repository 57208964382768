import { Injectable } from '@angular/core';
import { ServicesService } from 'src/app/hiring-wizard/service/services.service';

@Injectable({
  providedIn: 'root'
})
export class CategoryService {

  industryJobs;

  constructor(
    private servicesService: ServicesService,
  ) { }

  getAllJobTypes() {
    console.log('getAllJobTypes()');

    if (!this.industryJobs) {
      this.servicesService.getAllJobIndustries().subscribe(
        (jobTypeRes) => {
          console.log('jobTypeRes: ', jobTypeRes);
          this.industryJobs = jobTypeRes;
        },
        (err) => {
          console.log('jobTypeRes error: ', err);
        }
      );
    } else {
      console.log('ELSE jobTypeRes: ', this.industryJobs);
    }
  }

  getUniTaskrIndustryNameById(id: number) {
    if (this.industryJobs) {
      let industries = this.industryJobs.filter(industry => {
        if (industry.jobIndustriesId == id) {
          return industry;
        }
      })

      return industries
    } else {
      return 'Loading...'
    }
  }

  getLabel(industries) {

    /** Accounting, banking & finance */
    if (
      industries.indexOf('Finance') > -1 ||
      industries.indexOf('Capital Markets') > -1 ||
      industries.indexOf('Financial Services') > -1 ||
      industries.indexOf('Insurance') > -1 ||
      industries.indexOf('Investment Banking') > -1 ||
      industries.indexOf('Investment Management') > -1 ||
      industries.indexOf('Venture Capital & Private Equity') > -1 ||
      industries.indexOf('Management Consulting') > -1 ||
      industries.indexOf('Finance')   > -1
    ) {
      console.log('this.industryJobs: ', this.industryJobs);
      let industry = this.industryJobs.filter(indus => {
        if (indus.jobIndustriesId == 1) {
          return indus;
        }
      })
      return industry;
    }

    /** Business, consulting & management */
    if (
      industries.indexOf('Corporate Services') > -1 ||
      industries.indexOf('Business Supplies & Equipment') > -1 ||
      industries.indexOf('Executive Office') > -1 ||
      industries.indexOf('Facilities Services') > -1 || 
      industries.indexOf('Information Services') > -1 || 
      industries.indexOf('Management Consulting') > -1 || 
      industries.indexOf('Outsourcing/Offshoring') > -1 || 
      industries.indexOf('Professional Training & Coaching') > -1
    ) {
      let industry = this.industryJobs.filter(indus => {
        if (indus.jobIndustriesId == 2) {
          return indus;
        }
      })
      return industry;
    }

    /** Charity & voluntary Work*/
    if (
      industries.indexOf('Corporate Services') > -1
    ) {
      let industry = this.industryJobs.filter(indus => {
        if (indus.jobIndustriesId == 3) {
          return indus;
        }
      })
      return industry;
    }


    /** Creative arts & design */
    if (
      industries.indexOf('Design') > -1 ||
      industries.indexOf('Arts') > -1 ||
      industries.indexOf('Music') > -1 ||
      industries.indexOf('Fine Art') > -1 ||
      industries.indexOf('Performing Arts') > -1 ||
      industries.indexOf('Photography') > -1 ||
      industries.indexOf('Graphic Design') > -1
    ) {
      let industry = this.industryJobs.filter(indus => {
        if (indus.jobIndustriesId == 4) {
          return indus;
        }
      })
      return industry;
    }
   
    /** Energy and utilities */
    if (
      industries.indexOf('Energy & Mining') > -1 ||
      industries.indexOf('Oil & Energy') > -1 ||
      industries.indexOf('Utilities') > -1
    ) {
      let industry = this.industryJobs.filter(indus => {
        if (indus.jobIndustriesId == 5) {
          return indus;
        }
      })
      return industry;
    }

     /** Engineering and manufacturing */
     if (
      industries.indexOf('Civil Engineering') > -1 ||
      industries.indexOf('Manufacturing') > -1 ||
      industries.indexOf('Aviation & Aerospace') > -1 ||
      industries.indexOf('Defense & Space') > -1 ||
      industries.indexOf('Electrical & Electronic Manufacturing') > -1 ||
      industries.indexOf('Food Production') > -1 ||
      industries.indexOf('Industrial Automation') > -1 ||
      industries.indexOf('Machinery') > -1 ||
      industries.indexOf('Mechanical or Industrial Engineering') > -1 ||
      industries.indexOf('Packaging & Containers') > -1 ||
      industries.indexOf('Paper & Forest Products') > -1 ||
      industries.indexOf('Plastics') > -1 ||
      industries.indexOf('Railroad Manufacture') > -1 ||
      industries.indexOf('Shipbuilding') > -1 ||
      industries.indexOf('Textiles')   > -1
    ) {
      let industry = this.industryJobs.filter(indus => {
        if (indus.jobIndustriesId == 6) {
          return indus;
        }
      })
      return industry;
    }


    /** Environment and agriculture */
    if (
      industries.indexOf('Environmental Services') > -1 ||
      industries.indexOf('Tobacco') > -1 ||
      industries.indexOf('Agriculture') > -1 ||
      industries.indexOf('Farming') > -1 ||
      industries.indexOf('Fishery') > -1 ||
      industries.indexOf('Ranching') > -1 ||
      industries.indexOf('Renewables & Environment')   > -1
    ) {
      let industry = this.industryJobs.filter(indus => {
        if (indus.jobIndustriesId == 7) {
          return indus;
        }
      })
      return industry;
    }

    /** Healthcare */
    if (
      industries.indexOf('Health Care') > -1 ||
      industries.indexOf('Hospital & Health Care') > -1 ||
      industries.indexOf('Medical Device') > -1 ||
      industries.indexOf('Medical Practice') > -1 ||
      industries.indexOf('Mental Health Care') > -1 ||
      industries.indexOf('Veterinary') > -1
    ) {
      let industry = this.industryJobs.filter(indus => {
        if (indus.jobIndustriesId == 8) {
          return indus;
        }
      })
      return industry;
    }

    /** Hospitality & events management */
    if (
      industries.indexOf('Recreation & Travel') > -1 ||
      industries.indexOf('Events Services') > -1 ||
      industries.indexOf('Hospitality') > -1 ||
      industries.indexOf('Entertainment') > -1 ||
      industries.indexOf('Leisure, Travel & Tourism') > -1 ||
      industries.indexOf('Restaurants') > -1 ||
      industries.indexOf('Recreational Facilities & Services') > -1 ||
      industries.indexOf('Sports') > -1 ||
      industries.indexOf('Wine and Spirits') > -1 ||
      industries.indexOf('Food & Beverages') > -1 ||
      industries.indexOf('Gambling & Casinos')   > -1
    ) {
      let industry = this.industryJobs.filter(indus => {
        if (indus.jobIndustriesId == 9) {
          return indus;
        }
      })
      return industry;
    }

    /** Law */
    if (
      industries.indexOf('Legal') > -1 ||
      industries.indexOf('Legislative Office') > -1 ||
      industries.indexOf('Judiciary') > -1 ||
      industries.indexOf('Legal Services') > -1 ||
      industries.indexOf('Law Practice')   > -1
    ) {
      let industry = this.industryJobs.filter(indus => {
        if (indus.jobIndustriesId == 10) {
          return indus;
        }
      })
      return industry;
    }

    /** Law enforcement & security */
    if (
      industries.indexOf('Security & Investigations') > -1 ||
      industries.indexOf('Public Safety') > -1 ||
      industries.indexOf('Military')   > -1
    ) {
      let industry = this.industryJobs.filter(indus => {
        if (indus.jobIndustriesId == 11) {
          return indus;
        }
      })
      return industry;
    }

     /** Leisure, sport & tourism */
     if (
      industries.indexOf('Recreation & Travel') > -1 ||
      industries.indexOf('Health, Wellness & Fitnesss') > -1 ||
      industries.indexOf('Wellness & Fitness') > -1
    ) {
      let industry = this.industryJobs.filter(indus => {
        if (indus.jobIndustriesId == 12) {
          return indus;
        }
      })
      return industry;
    }

     /** Marketing, advertising & PR */
     if (
      industries.indexOf('Media & Communications') > -1 ||
      industries.indexOf('Public Relations & Communications') > -1 ||
      industries.indexOf('Media') > -1 ||
      industries.indexOf('Advertisements') > -1
    ) {
      let industry = this.industryJobs.filter(indus => {
        if (indus.jobIndustriesId == 13) {
          return indus;
        }
      })
      return industry;
    }

     /** Media & internet */
     if (
      industries.indexOf('Media & Communications') > -1 ||
      industries.indexOf('Newspapers') > -1 ||
      industries.indexOf('Printing') > -1 ||
      industries.indexOf('Motion Pictures & Film') > -1 ||
      industries.indexOf('Internet') > -1 ||
      industries.indexOf('Media Production') > -1 ||
      industries.indexOf('Broadcast Media') > -1 ||
      industries.indexOf('Publishing') > -1 ||
      industries.indexOf('Writing & Editing') > -1 ||
      industries.indexOf('Translation & Localization') > -1 ||
      industries.indexOf('Online Media') > -1
    ) {
      let industry = this.industryJobs.filter(indus => {
        if (indus.jobIndustriesId == 14) {
          return indus;
        }
      })
      return industry;
    }

    /** Property & construction */
    if (
      industries.indexOf('Construction') > -1 ||
      industries.indexOf('Glass, Ceramics & Concrete') > -1 ||
      industries.indexOf('Real Estate')   > -1
    ) {
      let industry = this.industryJobs.filter(indus => {
        if (indus.jobIndustriesId == 15) {
          return indus;
        }
      })
      return industry;
    }

    /** Public services & administration */
    if (
      industries.indexOf('Nonprofit') > -1 ||
      industries.indexOf('Government Relations') > -1 ||
      industries.indexOf('International Affairs') > -1 ||
      industries.indexOf('Public Administration') > -1 ||
      industries.indexOf('Individual & Family Services') > -1 ||
      industries.indexOf('International Trade & Development') > -1 ||
      industries.indexOf('Libraries') > -1 ||
      industries.indexOf('Public Policy') > -1 ||
      industries.indexOf('Political Organization') > -1 ||
      industries.indexOf('Museums & Institutions') > -1 ||
      industries.indexOf('Non-Profit Organization Management') > -1 ||
      industries.indexOf('Program Development') > -1 ||
      industries.indexOf('Religious Institutions') > -1 ||
      industries.indexOf('Think Tanks')   > -1
    ) {
      let industry = this.industryJobs.filter(indus => {
        if (indus.jobIndustriesId == 16) {
          return indus;
        }
      })
      return industry;
    }

    /** Recruitment & HR */
    if (
      industries.indexOf('Staffing & Recruiting')
    ) {
      let industry = this.industryJobs.filter(indus => {
        if (indus.jobIndustriesId == 17) {
          return indus;
        }
      })
      return industry;
    }

    /** Retail */
    if (
      industries.indexOf('Retail') > -1 ||
      industries.indexOf('Supermarkets') > -1 ||
      industries.indexOf('Wholesale') > -1 ||
      industries.indexOf('Consumer Electronics') > -1 ||
      industries.indexOf('Consumer Goods') > -1 ||
      industries.indexOf('Consumer Services') > -1 ||
      industries.indexOf('Cosmetics') > -1 ||
      industries.indexOf('Furniture') > -1 ||
      industries.indexOf('Sporting Goods') > -1 ||
      industries.indexOf('Luxury Goods & Jewelry') > -1 ||
      industries.indexOf('Consumer Goods')   > -1
    ) {
      let industry = this.industryJobs.filter(indus => {
        if (indus.jobIndustriesId == 18) {
          return indus;
        }
      })
      return industry;
    }

    /** Sales */
    if (
      industries.indexOf('Retail')   > -1
    ) {
      let industry = this.industryJobs.filter(indus => {
        if (indus.jobIndustriesId == 19) {
          return indus;
        }
      })
      return industry;
    }
    
    /** Science & pharmaceuticals */
    if (
      industries.indexOf('Chemicals') > -1 ||
      industries.indexOf('Pharmaceuticals') > -1
    ) {
      let industry = this.industryJobs.filter(indus => {
        if (indus.jobIndustriesId == 20) {
          return indus;
        }
      })
      return industry;
    }
   
    
    /** Social care */
    if (
      industries.indexOf('Fundraising') > -1 ||
      industries.indexOf('Philanthropy')   > -1
    ) {
      let industry = this.industryJobs.filter(indus => {
        if (indus.jobIndustriesId == 21) {
          return indus;
        }
      })
      return industry;
    }
   

    /** Software development */
    if (
      industries.indexOf('Hardware & Networking') > -1 ||
      industries.indexOf('Computer Networking') > -1 ||
      industries.indexOf('Nanotechnologie') > -1 ||
      industries.indexOf('Semiconductors') > -1 ||
      industries.indexOf('Telecommunications') > -1 ||
      industries.indexOf('Software & IT Services') > -1 ||
      industries.indexOf('Computer Software') > -1 ||
      industries.indexOf('Information Technology & Services') > -1 ||
      industries.indexOf('Computer Games') > -1 ||
      industries.indexOf('Mobile Games') > -1 ||
      industries.indexOf('Wireless') > -1
    ) {
      let industry = this.industryJobs.filter(indus => {
        if (indus.jobIndustriesId == 22) {
          return indus;
        }
      })
      return industry;
    }

    /** Teacher training and education */
    if (
      industries.indexOf('Education') > -1 ||
      industries.indexOf('E-Learning') > -1 ||
      industries.indexOf('Higher Education') > -1 ||
      industries.indexOf('Primary/Secondary Education') > -1 ||
      industries.indexOf('Research') > -1
    ) {
      let industry = this.industryJobs.filter(indus => {
        if (indus.jobIndustriesId == 23) {
          return indus;
        }
      })
      return industry;
    }

    /** Transport & logistics */
    if (
      industries.indexOf('Transportation & Logistics') > -1 ||
      industries.indexOf('Logistics & Supply Chain') > -1 ||
      industries.indexOf('Maritime') > -1 ||
      industries.indexOf('Package/Freight Delivery') > -1 ||
      industries.indexOf('Transportation/Trucking/Railroad') > -1 ||
      industries.indexOf('Warehousing') > -1 ||
      industries.indexOf('Transportation') > -1 ||
      industries.indexOf('Transportation') > -1 ||
      industries.indexOf('Think Tanks')   > -1
    ) {
      let industry = this.industryJobs.filter(indus => {
        if (indus.jobIndustriesId == 24) {
          return indus;
        }
      })
      return industry;
    }


  }
}

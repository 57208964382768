import { Component, ElementRef, Input, OnInit, Output, ViewChild, EventEmitter } from '@angular/core';
import { MessagingService } from '../../service/messaging.service';
import { StorageService } from '../../../services/storage/storage.service';
import { DatePipe } from '@angular/common';
import { ModalController, Platform } from '@ionic/angular';
import { FullSizeImageViewerPage } from 'src/app/modals/full-size-image-viewer/full-size-image-viewer.page';
import { Browser } from '@capacitor/browser';
import { ImageService } from 'src/app/services/image/image.service';
import { PriceChangeRespondPage } from 'src/app/price-change-respond/price-change-respond.page';
// import emojiRegex from "emoji-regex"

@Component({
  selector: 'app-custom-message-list',
  templateUrl: './custom-message-list.component.html',
  styleUrls: ['./custom-message-list.component.scss'],
})
export class CustomMessageListComponent implements OnInit {
  @ViewChild('scrollComplete', { static: true }) scrollComplete: any;

  @Input('mobileMode') mobileMode: boolean = false;
  @Input('focussedApplicant') focussedApplicant;
  @Output('backToApplicantsList') backToApplicantsList = new EventEmitter();

  @Output('hireStudent') hireStudentOut = new EventEmitter();
  @Output('payStudent') payStudentOut = new EventEmitter();
  @Output('showCvOut') showCvOut = new EventEmitter();

  @Output('uniBotMessage') uniBotMessage = new EventEmitter();

  /** What device are we on? */
  isMobile: boolean = true;

  /** Today's Date */
  today = new Date();

  constructor(
    private platform: Platform,
    private modalController: ModalController,
    public messagingService: MessagingService,
    private storageService: StorageService,
    public imageService: ImageService,
    private datePipe: DatePipe,
    // private eregex: RegExp
  ) { }

  ngOnInit() {

    console.log('focussedApplicant')
    console.log('focussedApplicant: ', this.focussedApplicant)
    console.log('focussedApplicant')

    setTimeout(() => {
      let element = document.getElementById(
        this.messagingService.activeChannel.state.messageSets[0].messages[
          this.messagingService.activeChannel.state.messageSets[0].messages.length - 1
        ].id
      );
      element.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }, 600);

    if (this.platform.is('mobile')) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }

    this.messagingService.activeChannel.on((event) => {
      if (event.type == 'message.new' && event.cid == this.messagingService.activeChannel.cid) {
        if (event.user.id == 'uniBot') {
          this.uniBotMessage.emit(true);
        }
        setTimeout(() => {
          console.log('##########');
          console.log('New message on channel: ', event);
          let element = document.getElementById(event.message.id);
          element.scrollIntoView({ behavior: 'smooth', block: 'end' });
          console.log('##########');
        }, 125);
      }
    });
  }

  setMineOrYours(message) {
    if (message.user.id == this.storageService.firebaseUser.uid) {
      return 'my_message';
    } else {
      return 'your_message';
    }
  }

  getSentTime(updated_at) {
    if (this.isToday(updated_at)) {
      return this.datePipe.transform(updated_at, 'HH:mm');
    } else {
      return this.datePipe.transform(updated_at, 'EE dd/MM HH:mm');
    }
  }

  isToday(dateValue: string): boolean {
    if (this.today.toISOString().split('T')[0] == new Date(dateValue).toISOString().split('T')[0]) {
      return true;
    } else {
      return false;
    }
  }

  getShortDateDisplay(focusDate) {
    let date: Date = new Date('Feb 5, 2021');
    let dateVal: number = date.getTime();
    let datePipe: DatePipe = new DatePipe('en-GB');
    return datePipe.transform(dateVal, 'shortDate');
  }

  getReadMsg(message) {
    if (message.readBy && message.readBy.length > 0) {
      return 'primary';
    } else {
    }
  }

  getNameColour(user) {
    let hexString = user.id ? user.id.toString(16) : '000';
    let intColour = parseInt(hexString, 16);
    return `color: #${intColour}; font-weight: bold;`;
  }

  getBgColour() {
    const prefersDark = window.matchMedia('(prefers-color-scheme: dark)');
    if (prefersDark.matches) {
      // return 'width: 100%; --background: var(--dsk-pale_blue);';
      return 'width: 100%;';
    } else {
      // return 'width: 100%; --background: var(--dsk-pale_blue);';
      return 'width: 100%;';
    }
  }

  getLabelMargin() {
    // if (this.isMobile) {
    //   return 'margin-left: 40px;'
    // } else {
    //   return 'margin-left: 48px;'
    // }
    return 'margin-left: 0;';
  }

  async openFullScreen(message) {
    console.log('openFullScreen(): ', message);
    const modal = await this.modalController.create({
      component: FullSizeImageViewerPage,
      // swipeToClose: true,
      componentProps: {
        images: [{ url: message.attachments[0].file }],
        focussed: message.attachments[0],
      },
    });
    await modal.present();
  }

  downloadDocument(message) {
    console.log('downloadDocument(): ', message);
    Browser.open({ url: message.attachments[0].file });
  }

  addTargetBlank(htmlString: string): string {
    const regex = /<a\s/i;
    if (regex.test(htmlString)) {
      return htmlString.replace(regex, '<a target="_blank" ');
    } else {
      return this.emojiToUnicode(htmlString);
    }
  }

  getApplicationStatus() {

    if (!this.focussedApplicant.isAccepted && !this.focussedApplicant.isCompleted && !this.focussedApplicant.isRejected) {
      return "Applied"
    } else if (this.focussedApplicant.isAccepted && !this.focussedApplicant.isBuyerCompleted && !this.focussedApplicant.isRejected) {
      return "Hired"
    } else if (this.focussedApplicant.isAccepted && this.focussedApplicant.isBuyerCompleted && !this.focussedApplicant.isRejected) {
      return "Completed"
    } else if (!this.focussedApplicant.isAccepted && !this.focussedApplicant.isCompleted && !this.focussedApplicant.isRejected) {
      return "Applied"
    }
  }

  backToInbox() {
    this.backToApplicantsList.emit();
  }

  hireStudent() {
    console.log('hireStudent()');
    this.hireStudentOut.emit();
  }

  payStudent() {
    console.log('payStudent()');
    this.payStudentOut.emit();
  }

  showCv() {
    this.showCvOut.emit();
  }

  async actOnMessage(message) {
    console.log('actOnMessage(): ', message);
    if (message.priceRequest) {
      // alert(`Price change: ${message.priceRequestUrl}`);

      const modal = await this.modalController.create({
        component: PriceChangeRespondPage,
        initialBreakpoint: 0.9,
        breakpoints: [0, 0.9],
        componentProps: {
          offerId: message.offerId,
          pcrId: message.pcrId
        }
      });
      modal.present();

    }
  }

  emojiToUnicode(str) {
    // const regex = emojiRegex();
    // str.replaceAll()
    // for (const match of str.matchAll(regex)) {
    //   const emoji = match[0];
    //   str.replaceAll(regex, emoji);
    // }
    return str;
  }
}

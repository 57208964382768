// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.question-row {
  width: 100%;
}

.blue_text {
  color: var(--landing-text);
  font-family: "Poppins-bold";
  text-align: start;
  font-size: 1.3rem;
}

h3 {
  font-size: 1.2rem;
  margin-bottom: 16px !important;
}

.heading-2 {
  font-family: "Poppins-regular";
  text-align: start;
  color: var(--text-almost-black);
}

.continueBtn {
  --background: linear-gradient(90deg, rgba(132, 22, 244, 1) 0%, rgba(7, 206, 213, 1) 100%);
  --border-radius: 2rem;
  width: 80%;
  --color: #fff;
  font-family: "Poppins-bold";
}

.close_btn {
  --background: transparent;
  --background-activated: transparent;
  --color: var(--text-almost-black);
}`, "",{"version":3,"sources":["webpack://./src/app/modals/upgrade-plan/upgrade-plan.page.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;AACF;;AAEA;EACE,0BAAA;EACA,2BAAA;EACA,iBAAA;EACA,iBAAA;AACF;;AAEA;EACE,iBAAA;EACA,8BAAA;AACF;;AAEA;EACE,8BAAA;EACA,iBAAA;EACA,+BAAA;AACF;;AAEA;EACE,yFAAA;EACA,qBAAA;EACA,UAAA;EACA,aAAA;EACA,2BAAA;AACF;;AAEA;EACE,yBAAA;EACA,mCAAA;EACA,iCAAA;AACF","sourcesContent":[".question-row {\n  width: 100%;\n}\n\n.blue_text {\n  color: var(--landing-text);\n  font-family: 'Poppins-bold';\n  text-align: start;\n  font-size: 1.3rem;\n}\n\nh3 {\n  font-size: 1.2rem;\n  margin-bottom: 16px !important;\n}\n\n.heading-2 {\n  font-family: 'Poppins-regular';\n  text-align: start;\n  color: var(--text-almost-black);\n}\n\n.continueBtn {\n  --background: linear-gradient(90deg, rgba(132, 22, 244, 1) 0%, rgba(7, 206, 213, 1) 100%);\n  --border-radius: 2rem;\n  width: 80%;\n  --color: #fff;\n  font-family: 'Poppins-bold';\n}\n\n.close_btn {\n  --background: transparent;\n  --background-activated: transparent;\n  --color: var(--text-almost-black);\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

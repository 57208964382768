// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.intro-message {
  width: 100%;
}

ion-card {
  margin-bottom: 0;
}`, "",{"version":3,"sources":["webpack://./src/app/components/education/education.component.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACI,8BAAA;EACA,mBAAA;AACR;;AAEA;EACI,WAAA;AACJ;;AAEA;EACI,gBAAA;AACJ","sourcesContent":[".flex {\n    display: flex;\n        justify-content: space-between;\n        align-items: center;\n}\n\n.intro-message {\n    width: 100%;\n}\n\nion-card {\n    margin-bottom: 0;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

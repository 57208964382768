import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe } from '@angular/common';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { StreamAutocompleteTextareaModule, StreamChatModule } from 'stream-chat-angular';
import { NewChannelComponent } from './new-channel/new-channel.component';
import { InviteButtonComponent } from './invite-button/invite-button.component';
import { IonicModule } from '@ionic/angular';
import { CustomHeaderComponent } from './custom-header/custom-header.component';
import { CustomChannelListComponent } from './custom-channel-list/custom-channel-list.component';
import { MajorMessagingComponent } from '../major/major-messaging/major-messaging.component';
import { CustomFooterComponent } from './custom-footer/custom-footer.component';
import { TheCurrencyPipe } from 'src/app/pipes/the-currency.pipe';
import { CustomMessageListComponent } from './custom-message-list/custom-message-list.component';



@NgModule({
  declarations: [
    NewChannelComponent,
    InviteButtonComponent,
    CustomHeaderComponent,
    CustomChannelListComponent,
    MajorMessagingComponent,
    CustomFooterComponent,
    CustomMessageListComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    StreamChatModule,
    IonicModule,
    StreamAutocompleteTextareaModule,
  ],
  providers: [
    CurrencyPipe,
    TheCurrencyPipe
  ],
  exports: [
    NewChannelComponent,
    InviteButtonComponent,
    CustomHeaderComponent,
    CustomChannelListComponent,
    MajorMessagingComponent,
    CustomFooterComponent,
    CustomMessageListComponent
  ]
})
export class ChannelsModule { }

import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AnimationOptions } from 'ngx-lottie';
import { StorageService } from 'src/app/services/storage/storage.service';
import { AlertRequest, TaskAlertsService } from 'src/app/task-alerts/service/task-alerts.service';

@Component({
  selector: 'app-job-alert-prompt',
  templateUrl: './job-alert-prompt.component.html',
  styleUrls: ['./job-alert-prompt.component.scss'],
})
export class JobAlertPromptComponent implements OnInit {
  @Input('filterSelected') filterSelected;
  @Input('taskAlerts') taskAlerts;

  willShowCard: boolean = false;
  selectedAlerts = [];

  constructor(
    private taskAlertService: TaskAlertsService,
    private router: Router,
    private storageService: StorageService
  ) {}

  ngOnInit() {
    console.log(this.taskAlerts);
    this.getTaskAlerts();
    this.storageService.get('secondTimedismissed').then((secondTimeRes) => {
      console.log(secondTimeRes);
      if (!secondTimeRes.value) {
        this.willShowCard = true;
      }
    });
  }

  options: AnimationOptions = {
    path: 'https://assets9.lottiefiles.com/packages/lf20_sqqmfx5u.json',
    loop: true,
    autoplay: true,
  };

  jobalert1: AnimationOptions = {
    path: 'https://assets1.lottiefiles.com/temp/lf20_7BmGsm.json',
    loop: true,
    autoplay: true,
  };
  
  getTaskAlerts() {
    this.taskAlertService.getTaskAlerts().subscribe(
      (taskAlertRes) => {
        console.log(taskAlertRes);
        this.taskAlerts = taskAlertRes;
        this.selectedAlerts = taskAlertRes.alerts;
      },
      (err) => {
        console.log('Task alerts not working!!!!', err);
      }
    );
  }
  takeMeToJobAlerts() {
    if (this.filterSelected == 'tasks') {
      this.router.navigate(['/task-alerts'], { queryParams: { section: 'first' } });
    } else {
      this.router.navigate(['/task-alerts'], { queryParams: { section: 'second' } });
    }
  }
  formatFilter() {
    if (this.filterSelected == 'FullTime' || this.filterSelected == 'PartTime') {
      return this.filterSelected.slice(0, 4) + ' ' + this.filterSelected.slice(4);
    } else {
      return this.filterSelected + 's';
    }
  }

  getIsChecked(task) {
    if (this.selectedAlerts.indexOf(task.categoryId) > -1) {
      return true;
    } else {
      return false;
    }
  }

  toggleTask(ev, task) {
    if (this.selectedAlerts.indexOf(task.categoryId) > -1) {
      this.selectedAlerts.splice(this.selectedAlerts.indexOf(task.categoryId), 1);
    } else {
      this.selectedAlerts.push(task.categoryId);
    }
    this.saveUpdates();
  }

  async saveUpdates() {
    // Convert array to comma separated string.
    let alertString: string = '';
    for (let a of this.selectedAlerts) {
      alertString += a + ',';
    }

    let requestShape: AlertRequest = {
      alerts: alertString.replace(/,\s*$/, ''),
      recruitAlerts: this.taskAlerts.recruitAlerts ? this.taskAlerts.recruitAlerts : '',
      location: this.taskAlerts.location ? this.taskAlerts.location : '',
      mainLocation: this.taskAlerts.mainLocation ? this.taskAlerts.mainLocation : '',
      lat: this.taskAlerts.lat,
      lon: this.taskAlerts.lon,
      shouterInstagram: this.taskAlerts.shouterInstagram ? this.taskAlerts.shouterInstagram : '',
      shouterAlertId: 0,
    };

    console.log('requestShape: ', requestShape);

    this.taskAlertService.setAlerts(requestShape).subscribe(
      async (alertRes) => {
        console.log('alertRes: ', alertRes);

        this.taskAlertService
          .setEmailPushAlerts(this.taskAlerts.hasPushAlerts, this.taskAlerts.hasEmailAlerts)
          .subscribe(
            async (updateRes) => {
              console.log('updateRes: ', updateRes);
            },
            (err) => {
              console.log('updateRes error: ', err);
            }
          );
      },
      (err) => {
        console.log('alertRes Err: ', err);
      }
    );
  }

  dismissCard() {
    this.willShowCard = false;
    this.storageService.get('firstTimedismissed').then((firstTimeRes) => {
      if (!firstTimeRes.value) {
        this.storageService.set('firstTimedismissed', new Date().toISOString());
      } else if (firstTimeRes.value) {
        this.storageService.get('secondTimedismissed').then((secondTimeRes) => {
          if (!secondTimeRes.value) {
            this.storageService.set('secondTimedismissed', new Date().toISOString());
          }
        });
      }
    });
  }
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { ShoutResponse, TaskResponse } from 'src/app/home-earn/service/task.service';
import { CurrencyService } from 'src/app/services/currency/currency.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-standard-shout-grid-card',
  templateUrl: './standard-shout-grid-card.component.html',
  styleUrls: ['./standard-shout-grid-card.component.scss'],
})
export class StandardShoutGridCardComponent implements OnInit {

  /** Shout information */
  @Input('shout') focussedShout: any;

  /* Main Card Action Output */
  @Output('mainCardAction') mainAction = new EventEmitter();

  /* My Currency Code */
  @Input('myCurrencyCode') myCurrencyCode: string;

  @Input('shoutOther') focussedShoutOther: any;

  /* My conversion rate */
  myConversionRate: Object;

  constructor(private currencyService: CurrencyService) { }

  ngOnInit() {
    this.getMyRates();
  }

  clickWholeCardAction(shout) {
    this.mainAction.emit(shout);
  }

  getPlaceholderImage(): string {
    return "../../../assets/images/placeholder.jpeg"
  }

  getStatusText(shout: ShoutResponse) {
    let statusList = [
      "All",
      "Invited",
      "Applied",
      "Hired",
      "Completed",
      "Paid",
      "Started",
      "Declined"
    ]
    return statusList[shout.status];
  }

  getStatusClass(shout) {
    let statusList = [
      "all_status_badge",
      "invited_status_badge",
      "applied_status_badge",
      "hired_status_badge",
      "completed_status_badge",
      "paid_status_badge",
      "started_status_badge",
      "declined_status_badge",
    ]

    return statusList[shout.status];
  }

  getCardImageId() {
    return `${this.focussedShout.taskId}_cardImage`;
  }

  getImageSource(image) {
    if (image && image.includes('http')) {
      return image;
    } else if (image == "string" || image == "") {
      return "../../../assets/images/placeholder.jpeg";
    } else {
      let availWidth = screen.availWidth;
      if (availWidth > 250) {
        availWidth = 240;
      }

      let imageWidth: number;
      let imageHeight: number;
      imageWidth = 2 * 0.95 * availWidth;
      imageHeight = 2 * 240;


      let cdnRequestObject = {
        bucket: "unitaskr-web-cdn",
        key: image, // "Screenshot 2021-11-17 at 11.10.24.png",
        edits: {
          resize: {
            width: imageWidth,
            height: imageHeight,
            fit: "cover"
          }
        }
      }

      let base64 = btoa(JSON.stringify(cdnRequestObject))
      return `${environment.uniTaskrApi.cdnBaseUrl}${base64}`;
    }
  }

  getUrl() {
    return `url(${this.getImageSource(this.focussedShout.featured.url)})`;
  }

  getMyRates() {
    if (this.focussedShout.currencyCode == '') {
      this.focussedShout.currencyCode = 'GBP';
    }
    this.currencyService.getConversionRate(this.focussedShout.currencyCode, this.myCurrencyCode).subscribe(rateRes => {
      this.myConversionRate = rateRes.rate ? rateRes.rate : 1;
    });
  }

  whichRate() {
    return this.myConversionRate;
  }
}

import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-title-avatar-row',
  templateUrl: './title-avatar-row.component.html',
  styleUrls: ['./title-avatar-row.component.scss'],
})
export class TitleAvatarRowComponent implements OnInit {

  /** heading text sent im */
  @Input('headingText') headingText: string;

  /** image url sent in */
  @Input('imageUrl') imageUrl: string;

  /** Sometimes we need to pad/margin the top */
  @Input('marginTop') marginTop: string;

  constructor() { }

  ngOnInit() {
    if (this.marginTop) {
      document.getElementById("avatarItem").style.marginTop = this.marginTop;
    }
  }

}

import { Injectable } from '@angular/core';
import { doc, getFirestore, updateDoc, setDoc, arrayUnion } from '@firebase/firestore';
import { Device } from '@capacitor/device';
import { StorageService } from '../storage/storage.service';
import { environment } from 'src/environments/environment';
import { HttpService } from '../http/http.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { ConfigService } from '../config-service/config-service.service';

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {

  /** Device info saved */
  deviceInfo: any;

  /** Device ID saved */
  deviceId: any;

  /** 
   * 
   * 
   * Critical values for Dynamic Link Tracking 
   * 
   * */
  backHalf: string;
  businessAccountId: string = 'sEDYVzlWAQGzMvtg9Vsf';
  campaign_id: string = 'gfeqInCFJCf3Q2m9W2i6';
  appLink: string = 'web.unitaskr.com/deeplink/sEDYVzlWAQGzMvtg9Vsf/gfeqInCFJCf3Q2m9W2i6/02021408';

  launchLink: string;

  convertedUrl = {
    first: '',
    link: 'web.unitaskr.com/deeplink/sEDYVzlWAQGzMvtg9Vsf/gfeqInCFJCf3Q2m9W2i6/02021408',
    apn: 'com.unidosh',
    ibi: 'com.unidosh'
  }

  constructor(
    private router: Router,
    private storageService: StorageService,
    private http: HttpClient,
    private configService: ConfigService
  ) { }

  async recordPageEntry(location: string, message?: string) {
    // if (!this.storageService.uniTaskrUser) {
    //   if (!this.deviceInfo || !this.deviceId) {
    //     const info = await Device.getInfo();
    //     const deviceId = await Device.getId();
    //     this.deviceInfo = info;
    //     this.deviceId = deviceId.uuid;
    //     this.doTheUpdate(location, this.deviceId, message);
    //   } else {
    //     this.doTheUpdate(location, this.deviceId, message);
    //   }

    // } else {
    //   this.doTheUpdate(location, `${ this.storageService.uniTaskrUser.userId }`, message)
    // }
  }

  async doTheUpdate(location: string, userIdentifier: string, message?: string) {

    /** Which environment are we in? */
    let currentEnv = this.configService.environment.uniTaskrApi.url.replace('https://', '').replace('.unitaskr.com/', '')
    console.log('Current Env: ', currentEnv);

    const db = getFirestore();
    const userVisitsRef = doc(db, currentEnv !== "api-stg" ? "pageVisits" : "pageVisits-dev", `${this.storageService.uniTaskrUser ? this.storageService.uniTaskrUser.userId : 'unregistered'}`);
    // const userVisitsRef = doc(db, "pageVisits", userIdentifier);

    updateDoc(userVisitsRef, {
      visits: arrayUnion({
        when: (new Date()).toISOString(),
        where: location,
        device: this.deviceId ? this.deviceId : '0',
        user: this.storageService.uniTaskrUser ? `${this.storageService.uniTaskrUser.userId}` : 'unknown',
        message: message ? message : 'no message'
      })
    }).then(() => {

    }).catch(err => {
      console.log('Add pageVisits error: ', err);

      setDoc(doc(db, "pageVisits", userIdentifier),
        {
          visits: [{
            when: (new Date()).toISOString(),
            where: location,
            device: this.deviceId ? this.deviceId : '0',
            user: this.storageService.uniTaskrUser ? `${this.storageService.uniTaskrUser.userId}` : 'unknown',
            message: message ? message : 'no message'
          }]
        }, { merge: true }).then(() => {
          // console.log('pageVisits Set! ');
        }).catch(err => {
          console.log('Error setting pageVisits: ', err);
        })
    })
  }

  async uniTaskrEventTrack(eventName: string) {

    console.log('Track Track Track Track Track Track ');
    console.log('uniTaskrEventTrack(): ', eventName);
    console.log('Track Track Track Track Track Track ');

    let deviceId = await Device.getId();
    let deviceInfo = await Device.getInfo();
    console.log('deviceInfo: ', deviceInfo);
    let request = {
      userIdentifier: deviceId,
      link: 'https://us-central1-unitaskr-test.cloudfunctions.net/addNewWebsiteClick/?suffix=utjan23&campaign=Y9NpPDQ4FXJYmRgdyiZF',
      actionIdentifier: eventName,
      date: (new Date()).toISOString(),
      platform: deviceInfo.platform,
      data: ''
    }

    this.http.post('https://tracactionold-2lb2rpnjvq-uc.a.run.app', request).subscribe(evRes => {
      console.log('evRes: ', evRes);
    }, err => {
      console.log('evRes error: ', err);
    });
  }

  async dynamicLinkTrackEvent(actionIdentifier: string) {
    console.log('Track Track Track Track Track Track ');
    console.log('dynamicLinkTrackEvent(): ', actionIdentifier);
    console.log('Track Track Track Track Track Track ');
    let device_id = await Device.getId();
    console.log('device_id: ', device_id);
    let device_platform = (await Device.getInfo()).platform

    let baseUrl = 'https://trackdynamiclinkaction-2lb2rpnjvq-uc.a.run.app?';
    let completeUrl = `${baseUrl}&account=${this.businessAccountId}&campaign=${this.campaign_id}&actionIdentifier=${actionIdentifier}&appLink=${this.appLink}&userIdentifier=${device_id}&platform=${device_platform}`

    this.http.get(completeUrl).subscribe(trackRes => {
      console.log('trackRes: ', trackRes);
    }, err => {
      console.log('trackRes error: ', err);
      // alert(`Failed... ${JSON.stringify(err)}`);
    });
  }

  async newAgeEventTracking(eventName: string) {
    let url = "https://us-central1-unitrackingflicker.cloudfunctions.net/trackevent";
    let device_id = await Device.getId();
    console.log('device_id: ', device_id);

    let request = {
      dynamicLink: this.launchLink,
      eventName: eventName,
      deviceId: device_id
    }

    // alert(`newAge: ${JSON.stringify(request)}`);

    this.http.post('https://tracactionold-2lb2rpnjvq-uc.a.run.app', request).subscribe(evRes => {
      console.log('evRes: ', evRes);
    })
  }

  getAppDestination(loading) {
    let request = {
      dynamicLink: this.launchLink
    }

    this.http.post('https://us-central1-unitrackingflicker.cloudfunctions.net/getappdestination', request).subscribe((evRes: any) => {
      console.log('evRes: ', evRes);
      if (evRes && evRes.result !== 'No matching documents') {
        this.router.navigate([`/${evRes.result}`]);
        loading.dismiss();
      }
    }, err => {
      console.log('evRes error: ', err);
      loading.dismiss();
    });
  }
}

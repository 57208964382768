import { Component, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject, Subscription } from 'rxjs';
// import { FirebaseService } from './services/firebase/firebase.service';

import * as fromI18n from './i18n/reducers';
import { Store } from '@ngrx/store';
import { I18nComponent } from './i18n/container/i18n.component';
import { StudentIdCardPage } from './student-id-card/student-id-card.page';
import { LoadingController, MenuController, ModalController, Platform, PopoverController } from '@ionic/angular';
import { Stripe } from '@capacitor-community/stripe';
import { Router } from '@angular/router';

import { StatusBar, Style } from '@capacitor/status-bar';
import { Keyboard, KeyboardInfo } from '@capacitor/keyboard';
import { PresenceService } from './services/presence/presence.service';
import { CountryService } from './services/country/country.service';
import { KeyboardService } from './services/keyboard/keyboard.service';

import { App, URLOpenListenerEvent } from '@capacitor/app';
import { CurrencyService } from './services/currency/currency.service';
import countryToCurrency from 'country-to-currency';
import { Storage } from '@ionic/storage-angular';
import cordovaSQLiteDriver from 'localforage-cordovasqlitedriver';
import { NetworkService } from './services/network/network.service';
import { ErrorLogService } from './error-logs/service/error-log.service';
import { environment } from 'src/environments/environment';
import { StorageService } from './services/storage/storage.service';
import { AppflowService } from './services/appflow/appflow.service';
import { MessagingService } from './messaging/service/messaging.service';

import { SubscriptionService } from './services/subscription/subscription.service';
import { TaskService } from './home-earn/service/task.service';
import { AppUnavailableService } from './app-unavailable/service/app-unavailable.service';

import {
  FirebaseDynamicLinks,
  LinkConfig,
} from '@pantrist/capacitor-firebase-dynamic-links';

import { AnalyticsService } from './services/analytics/analytics.service';
import { GetAppPage } from './modals/get-app/get-app.page';
import { FirebaseService } from './welcome/services/firebase/firebase.service';
import { LocalUserService } from './welcome/services/local-user/local-user.service';
import { ConfigService } from './services/config-service/config-service.service';
import { initializeApp } from 'firebase/app';
import { Smartlook } from '@awesome-cordova-plugins/smartlook/ngx';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent extends I18nComponent {
  @ViewChild('listTop') listTop;

  /** Pages for students */
  public primaryAppPages = [
    { title: 'sideMenu.home', url: '/student-earn-btns', icon: 'home-outline' },
    ...(this.platform.is('desktop') && this.storageService.uniTaskrUser && this.storageService.uniTaskrUser.isBusiness
      ? [{ title: 'sideMenu.my_activity', url: '/desktop-activity', icon: 'list-circle-outline' }]
      : [{ title: 'sideMenu.my_activity', url: '/my-activity-blaze', icon: 'list-outline' }]),
    { title: 'sideMenu.profile', url: '/account', icon: 'person-outline' },
    {
      title: 'sideMenu.shout_profile',
      url: '/shout-account',
      icon: 'megaphone-outline',
    },
    {
      title: 'sideMenu.my_cv',
      url: '/cv-builder-new',
      icon: 'newspaper-outline',
    },
    { title: 'sideMenu.prize_draw', url: '/prize-draw', icon: 'gift-outline' },
    // { title: 'sideMenu.my_inbox', url: '/messaging/0', icon: 'chatbubble-outline' },
  ];

  public secondaryAppPages = [
    {
      title: 'sideMenu.task_alerts',
      url: '/task-alerts',
      icon: 'notifications-outline',
    },
    {
      title: 'sideMenu.referrals_and_codes',
      url: '/referrals',
      icon: 'arrow-redo-outline',
    },
  ];

  public ternaryAppPages = [{ title: 'sideMenu.settings', url: '/settings', icon: 'settings-outline' }];
  /** Pages for students END */

  /** Pages for business */
  public primaryAppPagesBusiness = [
    {
      title: 'sideMenu.home_business',
      url: '/home-hire-tabs/tasks',
      icon: 'home-outline',
    },
    ...(this.platform.is('desktop')
      ? [{ title: 'sideMenu.my_activity', url: '/desktop-activity', icon: 'list-circle-outline' }]
      : [{ title: 'sideMenu.my_activity', url: '/mobile-activity-buying', icon: 'list-outline' }]),
    {
      title: 'sideMenu.profile',
      url: '/account-business',
      icon: 'person-outline',
    },
    // { title: 'sideMenu.my_inbox', url: '/messaging/0', icon: 'chatbubble-outline' },
    // ...(this.platform.is('desktop')
    //   ? [{ title: 'sideMenu.my_inbox', url: '/desktop-activity', icon: 'list-circle-outline' }]
    //   : [{ title: 'sideMenu.my_inbox', url: '/mobile-activity-buying', icon: 'list-outline' }]),
  ];

  public secondaryAppPagesBusiness = [
    {
      title: 'sideMenu.payment_info',
      url: '/payment-information',
      icon: 'wallet-outline',
    },
  ];

  /** Pages for business END */

  lang = new Subject<string>();
  lang$: Observable<string>;

  currentPlan: any;
  chatsLeft: any;
  tasksLeft: any;

  currentPlanSubscription: Subscription;
  countSubscription: Subscription;

  taskCount: any;
  totalTaskCount: any;
  taskChatCount: any;
  totalChatCount: any;
  jobCount: any;
  totalJobCount: any;
  jobChatCount: any;
  totalJobChatCount: any;
  count: any;

  currentPlan$: Observable<any>;
  appVersion = environment.applicationVersion;

  constructor(
    private router: Router,
    // public firebaseService: FirebaseService,
    public newFirebaseService: FirebaseService,
    private modalController: ModalController,
    private menuCtrl: MenuController,
    private loadingCtrl: LoadingController,
    readonly translate: TranslateService,
    readonly store: Store<fromI18n.State>,
    private platform: Platform,
    private presenceService: PresenceService,
    private countryService: CountryService,
    private keyboardService: KeyboardService,
    private storage: Storage,
    private currencyService: CurrencyService,
    public networkService: NetworkService,
    public logService: ErrorLogService,
    public storageService: StorageService,
    public subscriptionService: SubscriptionService,
    public messagingService: MessagingService,
    private taskService: TaskService,
    private appUnavailableService: AppUnavailableService,
    private analyticsService: AnalyticsService,
    public localUserService: LocalUserService,
    private configService: ConfigService,
    private smartlook: Smartlook
  ) {
    super(store, translate);

    // this.consoleToggleService.disableConsoleInProduction();
    this.newFirebaseService.firebaseApp = initializeApp(environment.firebaseConfig);
    this.configService.loadEnvConfig();

    Stripe.initialize({
      publishableKey: environment.stripe.public_key,
    });


    this.appUnavailableService
      .getLatestAppVersionDetails()
      .then((versionStatus) => {
        // console.log('status status status status status: ', versionStatus);
        // console.log('status environment.applicationVersion: ', versionStatus);
        if (versionStatus.status == false && versionStatus.version !== environment.applicationVersion) {
          this.router.navigate(['/app-unavailable']);
        } else {
          // this.performManualUpdate();
          this.initializeApp();
          // // this.initializeDeeplinking();
          this.listenToDeepLinkOpen();
          this.checkSubscription();
          this.checkToShowGetApp();
          // this.storageService.migrateOldStorage();
        }
      })
      .catch((err) => {
        console.log('status status status status status ERROR: ', err);
      });
  }

  async ngOnInit() {
    this.currentPlan$ = this.subscriptionService.getStudentSubscription();
    // console.log('>>>> ngOnInit() App Component <<<<')

    // this.platform.ready().then(async () => {
      try{
        this.smartlook.setProjectKey({ key: "64d251869a5c459dab662a85854315197850a8e1" });
        this.smartlook.start();
    
        let isRecording = await this.smartlook.isRecording();
        // console.log('$$$ SmartLook - isRecording: ', isRecording);
        let recordingStatus = await this.smartlook.getRecordingStatus();
        // console.log('$$$ SmartLook - recordingStatus: ', recordingStatus);
        let userProperty = await this.smartlook.getUserProperty({ propertyName: 'userId' });
        // console.log('$$$ SmartLook - userProperty: ', userProperty);
      } catch (err) {
        console.log('Smartlook catch: ', err);
      }
    // });
  }

  checkSubscription() {
    this.currentPlanSubscription = this.subscriptionService.currentProPlanChanged.subscribe((currentPlanRes: any) => {
      this.currentPlan = currentPlanRes;
      this.storageService.setAndString('currentSub', currentPlanRes);
    });
    this.countSubscription = this.subscriptionService.countChanged.subscribe((countRes: any) => {
      this.count = countRes;
      this.setCounts();
    });

    this.taskService.shouldRefresh.subscribe((refresh) => {
      if (refresh.shouldRefresh && this.storageService.uniTaskrUser) {
        this.subscriptionService.getCounts().subscribe((countRes) => {
          // console.log('countRes: ', countRes);
          this.count = countRes;
          this.setCounts();
        });
      }
    });
  }

  ngOnDestroy() {
    this.currentPlanSubscription.unsubscribe();
    this.countSubscription.unsubscribe();
    // this.initializeDeeplinking();
    // this.performManualUpdate();
  }

  setCounts() {
    for (let count of this.count) {
      if (count.trackableType == 1) {
        this.taskCount = count.count;
        this.totalTaskCount = count.totalAvailable;
      } else if (count.trackableType == 2) {
        this.taskChatCount = count.count;
        this.totalChatCount = count.totalAvailable;
      } else if (count.trackableType == 4) {
        this.jobCount = count.count;
        this.totalJobCount = count.totalAvailable;
      } else if (count.trackableType == 5) {
        this.jobChatCount = count.count;
        this.totalJobChatCount = count.totalAvailable;
      }
    }
  }

  async initializeApp() {
    try {
      const setStyle = await StatusBar.setStyle({ style: Style.Dark });
    } catch { }

    this.checkIpBits(0);

    // if (this.platform.is('ios')) {
      this.networkService.checkNetwork();
      this.networkService.listenToNetwork();
    // }

    /**
     * This is this OLD firebase service
     * this.firebaseService.startListeningToAuthStateChange();
     */

    /**
     * This is this NEW firebase service
     *
     */
    this.newFirebaseService.startListeningToAuthStateChange();

    this.keyboardConfig();

    this.listenForAppClose();

    await this.storage.defineDriver(cordovaSQLiteDriver);
    await this.storage.create();

    

    this.subscriptionService.currentProPlanChanged.next(true);
  }

  checkIpBits(count: number) {
    console.log('🥗🥗🥗🥗 checkIpBits() 🥗🥗🥗🥗');
    this.countryService.checkNetworkStatus().subscribe((contryRes) => {
      // this.filterTrigger.getAllJobTypes()

      contryRes.subscribe((resRes) => {
        console.log('🥇🥇🥇 >>> country res: ', resRes);
        this.countryService.userCountry = resRes;
        this.countryService.userCountryChanged.next(resRes);
        this.newFirebaseService.myCurrencyCode = countryToCurrency[resRes.country];
        this.localUserService.myCurrencyCode = countryToCurrency[resRes.country];
        this.currencyService.myCurrencyCode.next(countryToCurrency[resRes.country]);
        this.currencyService.getConversionRate('GBP', 'USD').subscribe((gbpRes) => {
          this.currencyService.gbpToUsd.next(gbpRes.rate);
        });
        this.currencyService.getConversionRate('USD', 'GBP').subscribe((usdRes) => {
          this.currencyService.usdToGbp.next(usdRes.rate);
        });
        // console.log('Set currency code: ', this.firebaseService.myCurrencyCode);
      }, err => {
        console.warn('❌❌❌>>> IP location Error: ', err);
        if (count < 3) {
          setTimeout(() => {
            this.checkIpBits(count++)
          }, 300);
        }
      });
    });
  }

  async initializeDeeplinking() {
    // console.log('initializeDeeplinking()');
    this.platform.ready().then(async () => {
      // console.log('DL Platform Ready!');
      let launchUrl = await App.getLaunchUrl();
      // console.log('DL launchUrl: ', launchUrl);

      let navUrl = launchUrl.url.replace('https://web.unitaskr.com', '');

      // console.log('navUrl: ', navUrl);

      this.router.navigate([navUrl]);

      let storedTrackDynamicLink = await this.storageService.getAndParse('trackDynamicLink');
      // console.log('storedTrackDynamicLink: ', storedTrackDynamicLink);
      if (storedTrackDynamicLink && this.dateDifference(storedTrackDynamicLink.date) <= 30) {
        this.analyticsService.appLink = storedTrackDynamicLink.linkDetails.appLink;
        this.analyticsService.businessAccountId = storedTrackDynamicLink.linkDetails.businessAccountId;
        this.analyticsService.campaign_id = storedTrackDynamicLink.linkDetails.campaign_id;
      } else if (storedTrackDynamicLink && this.dateDifference(storedTrackDynamicLink.date) > 30) {
        let removed = await this.storageService.Remove('trackDynamicLink');
      }

      App.addListener('appRestoredResult', (event) => {
        alert('App Restored!');
      });

      App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
        alert('appUrlOpen');
        this.actOnDeepLink(event);
      });
    });
  }

  listenToDeepLinkOpen() {
    // console.log('==== listenToDeepLinkOpen() ====');

    FirebaseDynamicLinks.addListener('deepLinkOpen', (data) => {

      this.logService.addLog(JSON.stringify(data), 'listenToDeepLinkOpen');

      // let desitnation = data.url.replace('https:/', '');
      // desitnation = data.url.replace('https://web.unitaskr.com', '');
      let destination = data.url.split('https://web.unitaskr.com')[1];
      // alert(`desitnation: ${JSON.stringify(desitnation)}`);
      this.router.navigate([`${destination}`]);

      this.localUserService.targetPath = destination;
    });
  }

  dateDifference(dateSaved) {
    const date1 = new Date(dateSaved);
    const date2 = new Date();
    const diffTime = Math.abs(date2.getMilliseconds() - date1.getMilliseconds());
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    // console.log(diffDays + ' days');
    return diffDays;
  }

  async keyboardConfig() {
    if (this.platform.is('capacitor')) {
      this.keyboardService.isCapacitor = true;

      Keyboard.addListener('keyboardWillShow', async (info: KeyboardInfo) => {
        this.keyboardService.hideFooter = true;
      });

      Keyboard.addListener('keyboardWillHide', () => {
        this.keyboardService.hideFooter = false;
      });
    }
  }

  listenForAppClose() {
    this.platform.pause.subscribe(async () => {
      this.presenceService.setPresence('offline');
    });
  }

  async openStudentIdCard() {
    const modal = await this.modalController.create({
      component: StudentIdCardPage,
      cssClass: 'my-custom-class',
    });
    this.menuCtrl.toggle('main-content');
    await modal.present();
  }

  postTask() {
    this.router.navigate(['/new-create-task/0']);
  }

  postService() {
    this.router.navigate(['/sell-service/0']);
  }

  openAccount() {
    // if (this.firebaseService.myProfile.isStudent) {
    //   this.menuCtrl.toggle();
    //   this.router.navigate(['/account']);
    // } else {
    //   this.menuCtrl.toggle();
    // this.router.navigate(['/account-business']);
    // }
  }

  scrollToTop() {
    this.listTop.scrollToTop({ behavior: 'smooth', block: 'start' });
  }

  navigateHome() {
    // if (this.firebaseService.myProfile.isStudent) {
    //   this.menuCtrl.toggle();
    //   this.router.navigate(['/home-earn']);
    // } else {
    //   this.menuCtrl.toggle();
    //   this.router.navigate(['/home-hire-tabs/tasks']);
    // }
  }

  navigatePlan() {
    this.router.navigate(['/plan/tasking']);
    this.menuCtrl.toggle();
  }

  actOnDeepLink(event) {
    this.router.navigate(['/deeplink'], { queryParams: { deeplinkBody: JSON.stringify(event) } });
  }

  async checkToShowGetApp() {
    if (this.platform.is('mobileweb') && !location.href.includes('linkedin-success')) {
      const modal = await this.modalController.create({
        component: GetAppPage,
        initialBreakpoint: 0.25,
        breakpoints: [0, 0.25, 0.5],
        mode: 'ios',
      });
      modal.present();
    }
  }

  showActivityMessageCount(url: string) {
    if (url == '/desktop-activity' && this.messagingService.unreadMessageCount > 0) {
      return true;
    } else {
      return false;
    }
  }

  // async performManualUpdate() {
  //   console.log('%%%%%%%%%%%%%%%%%%')
  //   console.log('%%%%%%%%%%%%%%%%%%')
  //   console.log('%%%% APP FLOW TOP %%%%')
  //   const update = await Deploy.checkForUpdate();

  //   console.log('Update: ', update);

  //   if (update.available) {
  //     console.log('update.available: ', update.available);
  //     const popover = await this.popoverController.create({
  //       cssClass: "rating_popover_class",
  //       size: "cover",
  //       component: PopoverComponent,
  //       backdropDismiss: false
  //     });
  //     await popover.present();
  //   } else {
  //     console.log('No update available...');
  //   }

  //   console.log('%%%% APP FLOW END %%%%')
  //   console.log('%%%%%%%%%%%%%%%%%%')
  //   console.log('%%%%%%%%%%%%%%%%%%')
  // }

  listenerFunc(ev) { }

  goToPro() {
    this.router.navigate(['/pro-student-upgrade']);
    this.menuCtrl.toggle();
  }

  goToDeals() {
    this.router.navigate(['/student-deals']);
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MenuController } from '@ionic/angular';
import { TaskService } from 'src/app/home-earn/service/task.service';
import { FirebaseService } from 'src/app/services/firebase/firebase.service';

@Component({
  selector: 'app-pro-side-menu-button',
  templateUrl: './pro-side-menu-button.component.html',
  styleUrls: ['./pro-side-menu-button.component.scss'],
})
export class ProSideMenuButtonComponent implements OnInit {
  @Input('currentPlan') currentPlan;
  @Input('taskCount') taskCount;
  @Input('totalTaskCount') totalTaskCount;
  @Input('count') count;
  @Input('taskChatCount') taskChatCount;
  @Input('jobCount') jobCount;
  @Input('totalJobCount') totalJobCount;
  @Input('totalJobChatCount') totalJobChatCount;
  @Input('jobChatCount') jobChatCount;
  @Input('totalChatCount') totalChatCount;

  constructor(public firebaseService: FirebaseService, private router: Router, private menuCtrl: MenuController) {}

  ngOnInit() {
    // console.log('task count: ', this.taskCount);
    // console.log('job count: ', this.jobCount);
    // console.log('total task count: ', this.totalTaskCount);
    // console.log('total job count: ', this.totalJobCount);
    // console.log('task chat count: ', this.taskChatCount);
    // console.log('job chat count: ', this.jobChatCount);
    // console.log('total task chat count: ', this.totalChatCount);
    // console.log('total job chat count: ', this.totalJobChatCount);
  }

  setCounts() {
    for (let count of this.count) {
      if (count.trackableType == 1) {
        this.taskCount = count.count;
        this.totalTaskCount = count.totalAvailable;
      } else if (count.trackableType == 2) {
        this.taskChatCount = count.count;
        this.totalChatCount = count.totalAvailable;
      } else if (count.trackableType == 4) {
        this.jobCount = count.count;
        console.log(this.jobCount);
        this.totalJobCount = count.totalAvailable;
      } else if (count.trackableType == 5) {
        this.jobChatCount = count.count;
        console.log(this.jobChatCount);
        this.totalJobChatCount = count.totalAvailable;
      }
    }
  }

  navigatePlan() {
    this.router.navigate([`/plan/${this.currentPlan.paymentFrequency == 1 ? 'annual' : 'monthly'}`]);
    this.menuCtrl.toggle();
  }
}

import { Injectable } from '@angular/core';
import { HttpService } from 'src/app/services/http/http.service';

@Injectable({
  providedIn: 'root',
})
export class ApplicantsService {
  /** Focussed task */
  focussedTask;

  /** Focussed applicant */
  focussedApplicant;

  /** Focussed Offer */
  focussedOffer;

  constructor(private http: HttpService) { }

  getTaskOffers(taskId: number) {
    return this.http.runHttpCall('POST', 'api/taskoffers/task/detailed', 'application/json', {
      taskId: taskId,
      page: {
        take: 10,
        skip: 0,
      },
    });
  }

  getDetailedTaskOffers(taskId: number) {
    return this.http.runHttpCall('POST', 'api/taskoffers/task/detailed/profiles', 'application/json', {
      taskId: taskId,
      page: {
        take: 10,
        skip: 0,
      },
    });
  }

  getDetailedTaskOffersFilter(taskId: number, page: number, status:ApplicationStatusEnum = ApplicationStatusEnum.All, searchQuery: string = '') {
    return this.http.runHttpCall('POST', 'api/taskoffers/task/detailed/profiles/v2', 'application/json', {
      taskId,
      filter: {
        page: {
          take: 10,
          skip: (page - 1) * 10,
        },
        status,
        searchQuery
      }

    });
  }

  getDetailedExternalClickers(taskId: number) {
    return this.http.runHttpCall('GET', `api/tasks/external/clicks/${taskId}`, 'application/json');
  }

  getTaskOfferById(offerId: number) {
    return this.http.runHttpCall('GET', `api/taskoffers/detailed/${offerId}`, 'application/json');
  }

  getServiceOfferById(offerId: number) {
    console.log('getServiceOfferById(): ', offerId);
    return this.http.runHttpCall('GET', `api/serviceoffers/detailed/${offerId}`, 'application/json');
  }

  shortList(taskOfferId: number, isShort: boolean) {
    return this.http.runHttpCall('PUT', `api/taskoffers/${taskOfferId}/is-shorted`, 'application/json', {
      isShorted: isShort,
    });
  }

  getRefundStatus(taskOfferId: number) {
    return this.http.runHttpCall('GET', `api/refund/task-offer/${taskOfferId}`, 'application/json');
  }

  requestRefund(taskOfferId: number, reason: string) {
    return this.http.runHttpCall('POST', `api/refund/task-offer/${taskOfferId}`, 'application/json', {
      reason,
    });
  }

  /** Re-Bookings */
  rebook(taskOfferId) {
    return this.http.runHttpCall('POST', `api/rebooking/task-offer/${taskOfferId}`, 'application/json');
  }

  rebookService(serviceOfferId) {
    return this.http.runHttpCall('POST', `api/rebooking/service-offer/${serviceOfferId}`, 'application/json');
  }

  acceptRebook(rebookingRequestId) {
    return this.http.runHttpCall('POST', `api/rebooking/${rebookingRequestId}/task-offer/accept`, 'application/json');
  }

  declineRebook(rebookingRequestId) {
    return this.http.runHttpCall('POST', `api/rebooking/${rebookingRequestId}/task-offer/decline`, 'application/json');
  }

  hireApplicantForJob(taskOfferId) {
    return this.http.runHttpCall('POST', `api/taskoffers/${taskOfferId}/hired`, 'application/json');
  }

  /** Re-Bookings END */
}

export enum ApplicationStatusEnum  {
  All = 0,
  Applied = 1,
  ShortListed = 2,
  Hired = 3,
  Completed = 4,
}
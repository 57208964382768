import { Injectable } from '@angular/core';
import { HttpService } from 'src/app/services/http/http.service';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UniUserService {
  appId = environment.uniUser.appId;

  constructor(private readonly http: HttpService) {}

  checkEmail(email:string) {
    return this.http.runHttpCallUserModule(
      "POST",
      "auth/check/user",
      "application/json",
      {
        email
      }
    )
  }

  checkEmailAppId(email:string) {
    return this.http.runHttpCallUserModule(
      "POST",
      "auth/check/user-app",
      "application/json",
      {
        email,
        appId: this.appId
      }
    )
  }

  login(device?) {
    return this.http.runHttpCallUserModule(
      "POST",
      "auth/login",
      "application/json",
      {
        device,
        appId: this.appId
      }
    )
  }

  register(device?, user?) {
    return this.http.runHttpCallUserModule(
      "POST",
      "auth/register",
      "application/json",
      {
        device,
        appId: this.appId,
        user
      }
    )
  }

  completeRegistration() {
    return this.http.runHttpCallUserModule(
      "POST",
      "auth/register/complete",
      "application/json",
      {
        appId: this.appId
      }
    )
  }
}


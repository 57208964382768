import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TaskResponse } from 'src/app/home-earn/service/task.service';
import { DomSanitizer } from '@angular/platform-browser';
import DOMPurify from 'dompurify';
import { CurrencyService } from 'src/app/services/currency/currency.service';
import { StorageService } from '../../services/storage/storage.service';
import { Router } from '@angular/router';
import { AnalyticsService } from 'src/app/services/analytics/analytics.service';
import { LocalUserService } from 'src/app/welcome/services/local-user/local-user.service';

@Component({
  selector: 'app-new-task-card',
  templateUrl: './new-task-card.component.html',
  styleUrls: ['./new-task-card.component.scss'],
})
export class NewTaskCardComponent implements OnInit {
  /** Task information */
  @Input('task') task: any;

  // /** Shout information */
  // @Input('shout') shout: any;

  /* Main Card Action Output */
  @Output('mainCardAction') mainAction = new EventEmitter();

  /* My Currency Code */
  @Input('myCurrencyCode') myCurrencyCode: string;
  @Input('gbpToUsd') gbpToUsd: number;
  @Input('usdToGbp') usdToGbp: number;
  @Input('categories') categories: any;
  @Input('jobCategories') jobCategories: any;
  @Input('filterSelected') filterSelected: any;
  @Input('jobTypes') jobTypes: any;

  /* My conversion rate */
  myConversionRate: Object;

  /** What is th short address? */
  shortAddress: string;

  categoryName: string;
  categoryImage: string;

  constructor(
    private sanitizer: DomSanitizer,
    private currencyService: CurrencyService,
    public storageService: StorageService,
    public localUserService: LocalUserService
  ) {}

  async ngOnInit() {
    this.getMyRates();
    this.getCategory();
  }

  clickWholeCardAction(task) {
    this.mainAction.emit(task);
  }

  getPlaceholderImage(): string {
    return '../../../assets/images/placeholder.jpeg';
  }

  getSanitized(htmlString: string) {
    let output = htmlString.replace('\n', '<br> <br>');
    const sanitizedContent = DOMPurify.sanitize(output);
    return this.sanitizer.bypassSecurityTrustHtml(sanitizedContent);
  }

  getMyRates() {
    if (this.task.currencyCode == '') {
      this.task.currencyCode = 'GBP';
    }
    if (this.myCurrencyCode == 'GBP' && this.task.currencyCode == 'USD') {
      this.myConversionRate = this.usdToGbp;
    } else if (this.myCurrencyCode == 'USD' && this.task.currencyCode == 'GBP') {
      this.myConversionRate = this.gbpToUsd;
    } else if (this.myCurrencyCode == this.task.currencyCode) {
      this.myConversionRate = 1;
    } else {
      this.currencyService.getConversionRate(this.task.currencyCode, this.myCurrencyCode).subscribe((rateRes) => {
        this.myConversionRate = rateRes.rate;
      });
    }
  }

  taskAssigned(task) {
    if (task.hiredCount >= task.taskerCount) {
      return true;
    } else {
      return false;
    }
  }

  getIsMyTask() {
    if (this.task && this.storageService.uniTaskrUser) {
      if (this.task.userId == this.storageService.uniTaskrUser.userId) {
        return true;
      }
    }
  }

  getCategory() {
    // if (this.filterSelected == 'tasks') {
    if (this.task.jobType == 4) {
      for (let category of this.categories.businessCategories) {
        if (category.categoryId == this.task.categoryId) {
          this.categoryName = category.name;
          this.categoryImage = category.src;
        }
      }
      for (let category of this.categories.homeCategories) {
        if (category.categoryId == this.task.categoryId) {
          this.categoryName = category.name;
          this.categoryImage = category.src;
        }
      }
    } else {
      if (this.jobTypes && this.jobTypes.length) {
        for (let job of this.jobTypes) {
          if (job.jobIndustriesId == this.task.categoryId) {
            this.categoryName = job.jobName;
            this.categoryImage = job.imageUrl;
          }
        }
      }
    }
  }

  getJobTypeName(typeId: number) {
    switch (typeId) {
      case (1): 
        return 'Full Time';
      case (2): 
        return 'Part Time';
      case (3): 
        return 'Internship';
      case (4): 
        return 'Task';
    }
  }

  filterSelectedClass(typeId: number): string {
    switch (typeId) {
      case (1): 
        return 'FullTime';
      case (2): 
        return 'PartTime';
      case (3): 
        return 'Internship';
      case (4): 
        return 'tasks';
    }
  }

  getPaymentFrequencyText() {
    switch (this.task.paymentFrequency) {
      case 1:
        return ' / year';
      case 2:
        return ' / day';
      case 3:
        return ' / month';
      case 4:
        return ' / hour';
      case 5:
        return null;
    }
  }
}

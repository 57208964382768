import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-countdown-clock',
  templateUrl: './countdown-clock.component.html',
  styleUrls: ['./countdown-clock.component.scss'],
})
export class CountdownClockComponent implements OnInit {

  /** Which kind of clock are we using? */
  @Input('clockType') clockType: "toStart" | "toFinish";

  /** Countdown expiry dateTime */
  @Input('expiryDate') expiryDate: Date;

  /** Message displayed on the count down clock */
  @Input('message') message: string;

  /** When should the count down end? */
  @Input('dueDate') dueDate;

  /** Time remaining on the clock */
  timeShown: string;

  /** Distance suggest above or below Zero */
  distance: number;
  
  constructor() { }

  ngOnInit() {
    this.startCountdown();
  }

  startCountdown() {
    let x = setInterval(() => {

      // Get today's date and time
      var now = new Date().getTime();
    
      // Find the distance between now and the count down date
      this.distance = new Date(this.expiryDate).getTime() - now;
    
      // Time calculations for days, hours, minutes and seconds
      var days = Math.floor(this.distance / (1000 * 60 * 60 * 24));
      var hours = Math.floor((this.distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      var minutes = Math.floor((this.distance % (1000 * 60 * 60)) / (1000 * 60));
      var seconds = Math.floor((this.distance % (1000 * 60)) / 1000);
    
      // Display the result in the element with id="countdownSlot"
      this.timeShown = days + "d " + hours + "h "
      + minutes + "m " + seconds + "s ";
    
      // If the count down is finished, write some text
      if (this.distance < 0) {
        clearInterval(x);
      }
    }, 1000);
  }

}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { forkJoin } from 'rxjs';
import { ShoutDetailsService } from 'src/app/shout-profile/service/shout-details.service';

@Component({
  selector: 'app-result-tiktok-engagement',
  templateUrl: './result-tiktok-engagement.component.html',
  styleUrls: ['./result-tiktok-engagement.component.scss'],
})
export class ResultTiktokEngagementComponent implements OnInit {


  /** Which form row are we dealing with? */
  @Input('formRow') formRow;

  /** Are there exisint engagement records? */
  @Input('existingEngagements') existingEngagements;

  /** Tell the container what the insight values are */
  @Output('insightValues') insightValues = new EventEmitter();

  /** List of insights values */
  updatedInsightsArray: any[] = [];

  constructor(
    private shoutDetailsService: ShoutDetailsService
  ) { }

  ngOnInit() { 
    console.log('insightUpdate()');
    console.log('formRow: ', this.formRow);
  }

  insightUpdate(field, ev) {
    console.log('insightUpdate()');
    console.log('field: ', field);
    console.log('ev: ', ev.detail.value);
    console.log('formRow: ', this.formRow);
    let updatedInsight = false;
    for (let insightSet of this.updatedInsightsArray) {
      if (insightSet.formRow.id == this.formRow.id && insightSet.formRow.order == this.formRow.order) {
        insightSet[field] = ev.detail.value;
        updatedInsight = true;
        break;
      }
    }

    if (!updatedInsight) {
      let newSet = {
        formRow: null,
        views: undefined,
        likes: undefined,
        shares: undefined,
        comments: undefined,
      };
      newSet[field] = ev.detail.value
      newSet.formRow = JSON.parse(JSON.stringify(this.formRow));
      this.updatedInsightsArray.push(JSON.parse(JSON.stringify(newSet)));
    }
    console.log('updatedInsightsArray: ', this.updatedInsightsArray);
    let fjRequests = [];

    for (let insightSet of this.updatedInsightsArray) {
      // If all the fields are valid then post if off...
      let tempSet = JSON.parse(JSON.stringify(insightSet));
      if (tempSet.views && tempSet.shares && tempSet.comments && tempSet.likes) {
        fjRequests.push(
          this.shoutDetailsService.resultTikTokEngagement(
            tempSet.formRow.taskId,
            tempSet.formRow.order,
            tempSet.formRow.id,
            tempSet.views,
            tempSet.shares,
            tempSet.comments,
            tempSet.likes
          ));
      }
    }

    forkJoin(fjRequests).subscribe(fjRes => {
      console.log('TikTok fjRes: ', fjRes);
    }, err => {
      console.log('TikTok fjRes error: ', err);
    })
  }

  getInsightValue(field) {

    if (this.existingEngagements) {
      for (let insightSet of this.existingEngagements) {
        if (insightSet.formRefId === this.formRow.formId && insightSet.displayOrder === this.formRow.order) {
          return insightSet[field];
        }
      }
    }

  }

}

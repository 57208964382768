import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';
import { Capacitor } from '@capacitor/core';
import { ActionSheetController, LoadingController, ModalController } from '@ionic/angular';
import { ImageCroppedEvent, ImageCropperComponent, ImageTransform } from 'ngx-image-cropper';

@Component({
  selector: 'app-image-cropper',
  templateUrl: './image-cropper.page.html',
  styleUrls: ['./image-cropper.page.scss'],
})
export class ImageCropperPage implements OnInit {

  @ViewChild('cropper') cropper: ImageCropperComponent;
  @Input('imageQuality') imageQuality: number;
  myImage: any = null;
  croppedImage: any = '';
  transform: ImageTransform = {};
  isMobile = Capacitor.getPlatform() !== 'web';

  constructor(
    private loadingCtrl: LoadingController,
    private actionSheetController: ActionSheetController,
    private modalController: ModalController
  ) { }

  ngOnInit() {
  }

  async selectImage(type: string) {
    const image = await Camera.getPhoto({
      source: type == 'camera' ? CameraSource.Camera : CameraSource.Photos,
      quality: this.imageQuality ? this.imageQuality : 40,
      allowEditing: false,
      resultType: CameraResultType.Base64,
    });
    const loading = await this.loadingCtrl.create();
    await loading.present();

    this.myImage = `data:image/jpeg;base64,${image.base64String}`;
    this.croppedImage = null;
  }

  async showAttachmentOptions() {
    const actionSheet = await this.actionSheetController.create({
      // header: 'Albums',
      cssClass: 'left-align-buttons',
      buttons: [
        {
          text: 'Camera',
          icon: 'camera-outline',
          handler: () => {
            this.selectImage('camera');
          }
        },
        {
          text: 'Photo & Video Library',
          icon: 'image-outline',
          handler: () => {
            this.selectImage('photos');
          }
        },
        {
          text: 'Cancel',
          role: 'cancel',
          handler: () => {

          }
        }]
    });
    await actionSheet.present();
  }

  // Called when cropper is ready
  imageLoaded() {
    this.loadingCtrl.dismiss();
  }

  // Called when we finished editing (because autoCrop is set to false)
  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;

    this.modalController.dismiss({ data: event });
  }

  // We encountered a problem while loading the image
  loadImageFailed() {
    console.log('Image load failed!');
  }

  // Manually trigger the crop
  cropImage() {
    this.cropper.crop();
    this.myImage = null;
  }

  // Discard all changes
  discardChanges() {
    this.myImage = null;
    this.croppedImage = null;
  }

  // Edit the image
  rotate() {
    const newValue = ((this.transform.rotate ?? 0) + 90) % 360;

    this.transform = {
      ...this.transform,
      rotate: newValue,
    };
  }

  flipHorizontal() {
    this.transform = {
      ...this.transform,
      flipH: !this.transform.flipH,
    };
  }

  flipVertical() {
    this.transform = {
      ...this.transform,
      flipV: !this.transform.flipV,
    };
  }

  closeModal() {
    this.modalController.dismiss();
  }

}

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Platform } from '@ionic/angular';
import { LocalUserService } from 'src/app/welcome/services/local-user/local-user.service';
import { environment } from 'src/environments/environment';
import { StorageService } from '../storage/storage.service';

@Injectable({
  providedIn: 'root',
})
export class AuthguardService {
  /** Which URL was I trying to access when I got bounced to login? */
  deniedURL: string;

  constructor(
    private router: Router,
    private platform: Platform,
    private localUserService: LocalUserService,
    private storageService: StorageService // public signInService: SigninService
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ) {
    // console.log('where am I !!!', window.location.href);
    if (window.location.href.indexOf('new-create-task/0') > -1 || window.location.href.indexOf('home-hire-tabs/tasks') > -1) {
      return true;
    // } else if (!this.storageService.firebaseUser && window.location.href.indexOf('landing-hire') == -1 && window.location.href.indexOf('task-profile') == -1) {
    } else if (!this.localUserService.uniTaskrProfile && window.location.href.indexOf('landing-hire') == -1 && window.location.href.indexOf('task-profile') == -1) {
      this.router.navigate(['/landing']);
    } else {
      // if (!this.storageService.firebaseUser && window.location.href.indexOf('landing-hire') == -1) {
      if (!this.localUserService.firebaseUserObject && window.location.href.indexOf('landing-hire') == -1) {
      } else {
        return true;
      }
    }
  }
  
}

import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MenuController } from '@ionic/angular';

@Component({
  selector: 'app-pro-student-button',
  templateUrl: './pro-student-button.component.html',
  styleUrls: ['./pro-student-button.component.scss'],
})
export class ProStudentButtonComponent implements OnInit {
  @Input() amIPro?: boolean;
  @Input() accountPage?: boolean;

  constructor(private router: Router, private menuCtrl: MenuController) {}

  ngOnInit() {}

  goToPro() {
    this.router.navigate(['/pro-student-upgrade']);
    this.menuCtrl.close();
  }
}

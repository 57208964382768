import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-gender-ethnicity',
  templateUrl: './gender-ethnicity.component.html',
  styleUrls: ['./gender-ethnicity.component.scss'],
})
export class GenderEthnicityComponent implements OnInit {

  @Input('genders') genders;
  @Input('ethnicGroups') ethnicGroups;
  @Input('interestsList') interestsList;

  /** Existing values */
  @Input('setGender') setGender;
  @Input('setEthnicGroup') setEthnicGroup;
  @Input('setInterests') setInterests;
  @Input('shoutAccounts') shoutAccounts;

  /* Gender Selected */
  @Output('genderSelected') genderSelected = new EventEmitter();
  /* Ethnicity Selected */
  @Output('enthnicitySelected') enthnicitySelected = new EventEmitter();
  /* Interests Selected */
  @Output('interestsSelected') interestsSelected = new EventEmitter();
  /* Save - yes or no? */
  @Output('dontSave') dontSave = new EventEmitter();

  /** Which gender has the user selected? */
  selectedGender: string;
  /** Which Ethnic Groupd has the user selected? */
  selectedEthnicGroup: string;

  /** Has the user chosen too many interests? */
  interestsError: string;

  /** Which interests has the user selected */
  selectedInterests: string[];

  /** Selected number of interests valid? */
  interestsValid: boolean = false; // Less than 4

  constructor() { }

  ngOnInit() {
    if (this.genderSelected) {
      this.selectedGender = this.setGender;
    }
  
    this.selectedEthnicGroup = this.setEthnicGroup;
    this.setEthnicGroup = String(this.setEthnicGroup);

    this.setInterests = this.setInterests.map(interest => interest.id);
  }

  getSelectedGender(g): boolean {
    if (this.selectedGender == g.id) {
      return true;
    } else {
      return false;
    }
  }

  selectGender(g) {
    this.selectedGender = g.id;
    this.genderSelected.emit(this.selectedGender)
  }

  updatedEthnicGroups(ev) {
    this.selectedEthnicGroup = ev.detail.value;
    this.enthnicitySelected.emit(this.selectedEthnicGroup)
  }

  updatedInterests(ev) {
    this.selectedInterests = ev.detail.value;
    // if (this.selectedInterests.length > 0 && this.selectedInterests.length < 4) {
      this.interestsValid = true;
      this.interestsSelected.emit(this.selectedInterests);
      this.interestsError = null;
      this.dontSave.emit(this.interestsValid);
    // } 
    // else {
    //   this.interestsError = "Please choose 3 interests";
    //   this.interestsValid = false;
    //   this.dontSave.emit(this.interestsValid);
    // }
  }

  getEthnicGroup() {
    if (this.setEthnicGroup) {
      return this.setEthnicGroup.toString();
    }
  }

}



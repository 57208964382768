import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { ErrorLogService } from 'src/app/error-logs/service/error-log.service';
import { environment } from 'src/environments/environment';
import { StorageService } from '../storage/storage.service';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { AlertController, ToastController } from '@ionic/angular';
import { App } from '@capacitor/app'
import { Router } from '@angular/router';
import { ConfigService } from '../config-service/config-service.service';
import { FirebaseAuthentication } from '@capacitor-firebase/authentication';

@Injectable({
  providedIn: 'root',
})
export class HttpService {

  isAuthenticated: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  currentAccessToken = null;


  constructor(
    private router: Router,
    private alertController: AlertController,
    private toastController: ToastController,
    private http: HttpClient,
    private storageService: StorageService,
    private logService: ErrorLogService,
    private configService: ConfigService
  ) { 
    this.loadToken();
  }

  async loadToken() {
    // const token = await Storage.get({ key: ACCESS_TOKEN_KEY });
    const token = await this.storageService.get('ACCESS_TOKEN_KEY');
    if (token && token.value) {
      this.currentAccessToken = token.value;
      this.isAuthenticated.next(true);
    } else {
      this.isAuthenticated.next(false);
    }
  }

  errorMessageContents = {
    title: undefined,
    message: undefined,
    button: undefined,
  };

  public runHttpCall(method, endpoint, contentType, body?, proUrl?): Observable<any> {
    const url = proUrl ? `${this.configService.environment.uniTaskrApi.proUrl}${endpoint}` : `${this.configService.environment.uniTaskrApi.url}${endpoint}`;
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.storageService.token}`,
    });
    switch (method) {
      case 'GET':
        return this.http.get<any>(url, { headers }).pipe(
          tap(() => { },
            (error) => {
              this.recordTapError(url, headers, error, body)
            }))

      case 'POST':
        return this.http.post<any>(url, body, { headers }).pipe(
          tap(() => { },
            (error) => {
              this.recordTapError(url, headers, error, body)
            }))

      case 'PATCH':
        return this.http.patch<any>(url, body, { headers }).pipe(
          tap(() => { },
            (error) => {
              this.recordTapError(url, headers, error, body)
            }))

      case 'PUT':
        return this.http.put<any>(url, body, { headers }).pipe(
          tap(() => { },
            (error) => {
              this.recordTapError(url, headers, error, body)
            }))
      case 'DELETE':
        return this.http.delete<any>(url, { headers }).pipe(
          tap(() => { },
            (error) => {
              this.recordTapError(url, headers, error, body)
            }))
    }
  }

  public runHttpCallUserModule(method, endpoint, contentType, body?, proUrl?): Observable<any> {
    const url = `${this.configService.environment.httpUrls.uniUserUrl}${endpoint}`;
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.storageService['uni-user-token'] ? this.storageService['uni-user-token'] : this.storageService.token }`,
    });
    switch (method) {
      case 'GET':
        return this.http.get<any>(url, { headers }).pipe(
          tap(() => { },
            (error) => {
              this.recordTapError(url, headers, error, body)
            }))

      case 'POST':
        return this.http.post<any>(url, body, { headers }).pipe(
          tap(() => { },
            (error) => {
              this.recordTapError(url, headers, error, body)
            }))

      case 'PATCH':
        return this.http.patch<any>(url, body, { headers }).pipe(
          tap(() => { },
            (error) => {
              this.recordTapError(url, headers, error, body)
            }))

      case 'PUT':
        return this.http.put<any>(url, body, { headers }).pipe(
          tap(() => { },
            (error) => {
              this.recordTapError(url, headers, error, body)
            }))
      case 'DELETE':
        return this.http.delete<any>(url, { headers }).pipe(
          tap(() => { },
            (error) => {
              this.recordTapError(url, headers, error, body)
            }))
    }
  }


  public runHttpCallUniCreateApi(method, endpoint, contentType, body?, proUrl?): Observable<any> {
    const url = `${environment.uniCreateApi.url}${endpoint}`;
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.storageService.token}`,
    });
    switch (method) {
      case 'GET':
        return this.http.get<any>(url, { headers }).pipe(
          tap(() => { },
            (error) => {
              this.recordTapError(url, headers, error, body)
            }))

      case 'POST':
        return this.http.post<any>(url, body, { headers }).pipe(
          tap(() => { },
            (error) => {
              this.recordTapError(url, headers, error, body)
            }))

      case 'PATCH':
        return this.http.patch<any>(url, body, { headers }).pipe(
          tap(() => { },
            (error) => {
              this.recordTapError(url, headers, error, body)
            }))

      case 'PUT':
        return this.http.put<any>(url, body, { headers }).pipe(
          tap(() => { },
            (error) => {
              this.recordTapError(url, headers, error, body)
            }))
      case 'DELETE':
        return this.http.delete<any>(url, { headers }).pipe(
          tap(() => { },
            (error) => {
              this.recordTapError(url, headers, error, body)
            }))
    }
  }

  async recordTapError(url, headers, error, body?) {
    console.log('recordTapError() url: ', url);
    console.log('recordTapError() error: ', error);
    if (error.status == 401 || error.status == 403) {
      console.log(`${error.status} Error ${error.status}!`);
      // const alert = await this.alertController.create({
      //   header: 'Hang on..',
      //   message: 'Your session has expired. Please login again to continue.',
      //   buttons: ['ok']
      // });
      // await alert.present();

      try {
        const token = await FirebaseAuthentication.getIdToken({
          forceRefresh: true,
        });
        console.log('>>>>>>>>>>>>')
        console.log('>>>>>>>>>>>>')
        console.log('New token response: ', token);
        this.storageService.token = token.token;
        console.log('<<<<<<<<<<<<')
        console.log('<<<<<<<<<<<<')

        const toast = await this.toastController.create({
          message: 'Session Refreshed!',
          duration: 1500,
          position: 'top',
          icon: "checkmark-circle"
        });
    
        await toast.present();
      } catch (refreshErr) {
        console.log('>>>>>>>>>>>>')
        console.log('>>>>>>>>>>>>')
        console.log('refreshErr: ', refreshErr);
        console.log('<<<<<<<<<<<<')
        console.log('<<<<<<<<<<<<')
      }
      
    }

    if (url.includes('api/profile')) {
      let errorObj = {
        url: url,
        headers: headers,
        error: error,
        body: body ? body : ''
      }
      this.logService.addLogFirebase(JSON.stringify(errorObj), 'HTTP Service: tapError', 2001);
      this.logService.addCodeLogFirebase(JSON.stringify(errorObj), 'HTTP Service: tapError', 2001);
    }
  }

  recordcatchError(url, headers, error, body?) {
    if (url.includes('api/profile')) {
      let errorObj = {
        url: url,
        headers: headers,
        error: error,
        body: body ? body : ''
      }
      this.logService.addLogFirebase(JSON.stringify(errorObj), 'HTTP Service: catchError', 2002);
      this.logService.addCodeLogFirebase(JSON.stringify(errorObj), 'HTTP Service: catchError', 2002);
    }
  }

}

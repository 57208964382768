import { Injectable } from '@angular/core';
import { Deploy } from 'cordova-plugin-ionic';

@Injectable({
  providedIn: 'root'
})
export class AppflowService {

  /** Download Progress */
  downloadProgress: number = 0;
  
  /** Extract Progress */
  extractProgress: number = 0;

  constructor() { 
    this.performManualUpdate();
  }

  async performManualUpdate() {
    console.log('performManualUpdate()')
      await Deploy.downloadUpdate((progress) => {
        this.downloadProgress = progress;
      })
      await Deploy.extractUpdate((progress) => {
        this.extractProgress = progress;
      })
      await Deploy.reloadApp();
  }
}

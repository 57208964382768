import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';
import { forkJoin } from 'rxjs';
import { MessagingService } from 'src/app/messaging/service/messaging.service';
import { ActivityService } from 'src/app/my-activity/service/activity.service';
import { HttpService } from 'src/app/services/http/http.service';
import { ApplicantsService, ApplicationStatusEnum } from 'src/app/task-manage/service/applicant.service';
import { Channel } from 'stream-chat';
import { ChannelService, DefaultStreamChatGenerics } from 'stream-chat-angular';

@Injectable({
  providedIn: 'root',
})
export class DesktopChatService {
  /** Is there a target task?? */
  incomingTaskId: string;

  /** Job that we have foccused now */
  focussedJob: any;

  /** List of all my hiring jobs */
  jobsCompleteList: any[] = [];
  /** List of all my tasks */
  tasksBuying: any[] = [];

  /** What are we filtering the items by? */
  selectedFilter: string = 'all';
  /** Which jobs are we actually showing on screen? */
  showJobsList: any[];

  /** What type of applicant are we showing? */
  selectedApplicantFilter: 'all' | 'applied' | 'shortlisted' | 'hired' | 'completed' = 'all';
  // selectedApplicantFilter: ApplicationStatusEnum = ApplicationStatusEnum.All;

  /** All Applicants on the job */
  taskApplicants: any[];
  /** filtered applicants to display */
  displayApplicants: any[] = [];

  /** Today's Date */
  today = new Date();

  /** Applicant Search Query */
  applicant_search_query: string;

  /** People who clicked on the apply on company site button */
  externalClickers: any[];

  page: number = 1;

  displayTimer: any

  constructor(
    private loadingController: LoadingController,
    private activityService: ActivityService,
    private applicantService: ApplicantsService,
    private messagingService: MessagingService,
    private channelService: ChannelService,
    private datePipe: DatePipe,
    private httpService: HttpService
  ) { }

  initDesktopChat() {
    console.log('initDesktopChat()');
    this.getAllActivity(true);
    // this.initialiseStream();
  }

  getAllActivity(setInitial: boolean, ev?) {
    forkJoin({
      getTasks: this.activityService.getHiringTasks(),
      getServices: this.activityService.getHiringServices(),
    }).subscribe((allActivity) => {
      console.log('allActivity: ', allActivity);
      if (ev) {
        ev.target.complete();
      }
      this.tasksBuying = allActivity.getTasks;
      this.jobsCompleteList = allActivity.getTasks
        // .concat(allActivity.getServices)
        .sort((a, b) => new Date(b.createdDate).getTime() - new Date(a.createdDate).getTime());

      let initialTask;
      if (this.incomingTaskId) {
        for (let task of this.tasksBuying) {
          if (task.taskId == this.incomingTaskId) {
            initialTask = task;
            break;
          }
        }
      }

      if (this.jobsCompleteList && this.jobsCompleteList.length > 0) {
        this.selectFilterBtn(setInitial, this.selectedFilter, initialTask);
      }
    });
  }

  selectFilterBtn(setInitial: boolean, filter: string, initialTask) {
    this.selectedFilter = filter;
    this.showJobsList = undefined;

    switch (filter) {
      case 'all':
        this.showJobsList = this.jobsCompleteList;
        console.log('showJobsList showJobsList showJobsList showJobsList: ', this.showJobsList);
        if (initialTask) {
          this.setFocussedTask(initialTask);
        } else if (setInitial) {
          this.setFocussedTask(this.showJobsList[0]);
        }
        break;
      case 'tasks':
        this.showJobsList = this.jobsCompleteList.filter((job) => !job.isService);
        if (initialTask) {
          this.setFocussedTask(initialTask);
        } else if (setInitial) {
          this.setFocussedTask(this.showJobsList[0]);
        }
        break;
      case 'completed':
        this.showJobsList = this.jobsCompleteList.filter((job) => job.paid && job.isCompleted);
        if (initialTask) {
          this.setFocussedTask(initialTask);
        } else if (setInitial) {
          this.setFocussedTask(this.showJobsList[0]);
        }
        break;
    }
  }

  async setFocussedTask(task: any) {
    console.log('2. setFocussedTask(): ', task);
    const loading = await this.loadingController.create({
      cssClass: 'my-custom-class',
      message: 'This could take up to a minute...',
      duration: 300000,
    });
    await loading.present();

    /** Deactivate active channel */
    this.channelService.deselectActiveChannel();

    this.focussedJob = task;
    if (task.externalApplyUrl) {
      // this.getClickers(loading)
    } else {
      this.getJobOffers(loading);
    }
  }

  getClickers(loading?) {
    this.applicantService.getDetailedExternalClickers(this.focussedJob.taskId).subscribe(clickersRes => {
      console.log('clickersRes: ', clickersRes);
      this.externalClickers = clickersRes;
      loading?.dismiss();
    }, err => {
      console.log('clickersRes ERROR: ', err);
    })
  }

  getJobOffers(loading?) {
    console.log('3. getJobOffers()');
    this.showTimeTaken(5);
    this.applicantService.getDetailedTaskOffersFilter(this.focussedJob.taskId, this.page, this.getStatusEnum(this.selectedApplicantFilter)).subscribe(
      (offerRes) => {
        console.log('getDetailedTaskOffersFilter', offerRes);
        this.taskApplicants = offerRes.data;
        this.displayApplicants = this.taskApplicants;
        loading?.dismiss();
        clearTimeout(this.displayTimer);
      },
      (err) => {
        console.log('offerRes error: ', err);
      }
    );
  }

  showTimeTaken(count: number) {
    this.displayTimer = setTimeout(() => {
      console.log(`${count} seconds...`)
      this.showTimeTaken(count + 5)
    }, 5000)
  }

  searchApplicants() {
    console.log('searchApplicants(): ', this.applicant_search_query);
    // let applicable = JSON.parse(JSON.stringify(this.displayApplicants));
    this.displayApplicants = this.taskApplicants.filter((applicant) => {
      if (this.selectedApplicantFilter == 'all') {
        this.displayApplicants.push(applicant);
      }
      if (!applicant.isAccepted && !applicant.isCompleted && this.selectedApplicantFilter == 'applied') {
        this.checkTextMatch(applicant);
      } else if (applicant.isShorted && this.selectedApplicantFilter == 'shortlisted') {
        this.displayApplicants.push(applicant);
      } else if (applicant.isAccepted && !applicant.isBuyerCompleted && this.selectedApplicantFilter == 'hired') {
        this.displayApplicants.push(applicant);
      } else if (applicant.isAccepted && applicant.isBuyerCompleted && this.selectedApplicantFilter == 'completed') {
        this.displayApplicants.push(applicant);
        console.log('array', this.taskApplicants);
      } else if (!applicant.isAccepted && !applicant.isCompleted && this.selectedApplicantFilter == 'applied') {
        this.displayApplicants.push(applicant);
      }

      if (
        this.applicant_search_query &&
        (applicant.profile.firstName.toLowerCase().includes(this.applicant_search_query) ||
          applicant.profile.lastName.toLowerCase().includes(this.applicant_search_query))
      ) {
        return applicant;
      }
    });
  }

  checkTextMatch(applicant) {
    // if
    if (
      applicant.profile.firstName.toLowerCase().includes(this.applicant_search_query) ||
      applicant.profile.lastName.toLowerCase().includes(this.applicant_search_query)
    ) {
      return applicant;
    } else {
      return;
    }
  }

  // selectChipFilter(filter: 'applied' | 'shortlisted' | 'hired' | 'completed') {
  selectChipFilter(filterEvent) {
    console.log('selectChipFilter(): ', filterEvent);
    if (filterEvent.detail && filterEvent.detail.value) {
      console.log('If 1...')
      this.selectedApplicantFilter = filterEvent.detail.value;
      this.displayApplicants = [];

      for (let applicant of this.taskApplicants) {
        if (this.selectedApplicantFilter == 'all') {
          this.displayApplicants.push(applicant);
        } else if (
          !applicant.isAccepted &&
          !applicant.isCompleted &&
          !applicant.isRejected &&
          this.selectedApplicantFilter == 'applied'
        ) {
          this.displayApplicants.push(applicant);
        } else if (applicant.isShorted && !applicant.isRejected && this.selectedApplicantFilter == 'shortlisted') {
          this.displayApplicants.push(applicant);
        } else if (
          applicant.isAccepted &&
          !applicant.isBuyerCompleted &&
          !applicant.isRejected &&
          this.selectedApplicantFilter == 'hired'
        ) {
          this.displayApplicants.push(applicant);
        } else if (
          applicant.isAccepted &&
          applicant.isBuyerCompleted &&
          !applicant.isRejected &&
          this.selectedApplicantFilter == 'completed'
        ) {
          this.displayApplicants.push(applicant);
          console.log('array', this.taskApplicants);
        } else if (
          !applicant.isAccepted &&
          !applicant.isCompleted &&
          !applicant.isRejected &&
          this.selectedApplicantFilter == 'applied'
        ) {
          this.displayApplicants.push(applicant);
        }
      }

      console.log('this.displayApplicants AFTER: ', this.displayApplicants);
      this.sortDisplayApplcants()
      return true;
    } else {
      console.log("If 1's else...")
      this.selectedApplicantFilter = filterEvent;
      this.displayApplicants = [];

      for (let applicant of this.taskApplicants) {
        if (this.selectedApplicantFilter == 'all') {
          this.displayApplicants.push(applicant);
        }
        if (
          !applicant.isAccepted &&
          !applicant.isCompleted &&
          !applicant.isRejected &&
          this.selectedApplicantFilter == 'applied'
        ) {
          this.displayApplicants.push(applicant);
        } else if (applicant.isShorted && !applicant.isRejected && this.selectedApplicantFilter == 'shortlisted') {
          this.displayApplicants.push(applicant);
        } else if (
          applicant.isAccepted &&
          !applicant.isBuyerCompleted &&
          !applicant.isRejected &&
          this.selectedApplicantFilter == 'hired'
        ) {
          this.displayApplicants.push(applicant);
        } else if (
          applicant.isAccepted &&
          applicant.isBuyerCompleted &&
          !applicant.isRejected &&
          this.selectedApplicantFilter == 'completed'
        ) {
          this.displayApplicants.push(applicant);
          console.log('array', this.taskApplicants);
        } else if (
          !applicant.isAccepted &&
          !applicant.isCompleted &&
          !applicant.isRejected &&
          this.selectedApplicantFilter == 'applied'
        ) {
          this.displayApplicants.push(applicant);
        }
      }
      console.log('ELSE this.displayApplicants AFTER: ', this.displayApplicants);
      this.sortDisplayApplcants()
      return true;
    }
  }

  sortDisplayApplcants() {
    console.log('sortDisplayApplcants()');

  }

  updateFilters(event) { }

  initialiseStream() {
    console.log('initialiseStream()');
    this.messagingService.initialiseChat(0);
  }

  getChannel(taskOfferId: number): Channel<DefaultStreamChatGenerics> | undefined {
    console.log('getChannel()');
    console.log('taskOfferId: ', taskOfferId);
    let connectedChats: any[] = this.messagingService.allChannels.filter((chan) => {
      console.log('Filter chan: ', chan);
      console.log('Filter chan.id: ', chan.id);
      console.log('Filter chan.cid: ', chan.cid);
      if (chan.cid.includes(taskOfferId.toString()) || chan.id.includes(taskOfferId.toString())) {
        return chan;
      }
      // if (chan.cid == `messaging:${taskOfferId}` || chan.cid == `${taskOfferId}` || chan.id == `${taskOfferId}` || chan.id == `messaging:${taskOfferId}`) {
      //   return chan;
      // }
    });
    if (connectedChats.length > 0 && connectedChats[0] && connectedChats[0].state) {
      return connectedChats[0];
    } else {
      return undefined;
    }
  }

  getChannelGuid(applicationId: string): Channel<DefaultStreamChatGenerics> | undefined {
    console.log('getChannel()');
    console.log('taskOfferId: ', applicationId);
    let connectedChats: any[] = this.messagingService.allChannels.filter((chan) => {
      // console.log('Filter chan: ', chan);
      if (chan.cid == `messaging:${applicationId}` || chan.id == `${applicationId}`) {
        return chan;
      }
    });
    if (connectedChats.length > 0 && connectedChats[0] && connectedChats[0].state) {
      return connectedChats[0];
    } else {
      return undefined;
    }
  }

  getUnreadCount(taskOfferId: number): number {
    let connectedChats: any[] = this.messagingService.allChannels.filter((chan) => {
      if (chan.cid == `messaging:${taskOfferId}`) {
        return chan;
      }
    });
    if (connectedChats.length > 0 && connectedChats[0] && connectedChats[0].state) {
      return connectedChats[0].state.unreadCount;
    }
  }

  getUnreadCountForTask(taskId: number): number {
    let chatsToCount: any[] = this.messagingService.allChannels.filter((chan) => {
      if (chan.data && chan.data.taskId && chan.data.taskId == `${taskId}`) {
        return chan;
      }
    });

    // console.log('chatsToCount: ', chatsToCount);
    let sum = 0;
    chatsToCount.forEach((chat) => {
      sum += this.getUnreadCount(Number(chat.data.id));
    });

    return sum;
  }

  getLatestChatMessage(taskOfferId: number): string {
    // return `${taskOfferId}`;
    let connectedChats: any[] = this.messagingService.allChannels.filter((chan) => {
      if (chan.cid == `messaging:${taskOfferId}`) {
        return chan;
      }
    });
    if (connectedChats.length > 0 && connectedChats[0] && connectedChats[0].state) {
      return connectedChats[0].state.messageSets[0].messages[connectedChats[0].state.messageSets[0].messages.length - 1]
        .text;
    } else {
      return 'No chat...';
    }
  }

  timeSinceLastMessage(taskOfferId: number) {
    let connectedChats: any[] = this.messagingService.allChannels.filter((chan) => {
      if (chan.cid == `messaging:${taskOfferId}`) {
        return chan;
      }
    });
    if (connectedChats.length > 0 && connectedChats[0] && connectedChats[0].state) {
      return this.getSentTime(connectedChats[0].state.last_message_at);
    } else {
      return 'No messages...';
    }
  }

  getSentTime(updated_at) {
    if (this.isToday(updated_at)) {
      return this.datePipe.transform(updated_at, 'HH:mm');
    } else {
      return this.datePipe.transform(updated_at, 'EE dd/MM HH:mm');
    }
  }

  isToday(dateValue: string): boolean {
    if (this.today.toISOString().split('T')[0] == new Date(dateValue).toISOString().split('T')[0]) {
      return true;
    } else {
      return false;
    }
  }

  addChannelToUgcBriefApplication(ugc_brief_application_id: string, channelCid: string) {
    return this.httpService.runHttpCallUniCreateApi(
      'POST',
      'ugc-brief-applications/add-chat',
      "application/json",
      {
        ugc_brief_application_id: ugc_brief_application_id,
        channelCid: channelCid
      }
    )
  }

  getStatusEnum(stringStatus: 'all' | 'applied' | 'shortlisted' | 'hired' | 'completed') {
    switch (stringStatus) {
      case 'all':
        return ApplicationStatusEnum.All;
      case 'applied':
        return ApplicationStatusEnum.Applied;
      case 'shortlisted':
        return ApplicationStatusEnum.ShortListed;
      case 'hired':
        return ApplicationStatusEnum.Hired;
      case 'completed':
        return ApplicationStatusEnum.Completed;
    }
  }
}

import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { HttpService } from '../http/http.service';
import { StorageService } from '../storage/storage.service';

@Injectable({
  providedIn: 'root',
})
export class SubscriptionService {
  currentProPlan: any;
  currentProPlanChanged = new Subject();

  count: any;
  countChanged = new Subject();
  private methodCalledSubject = new Subject<string>();

  methodCalled$ = this.methodCalledSubject.asObservable();

  // taskCount: any;
  // taskCountChanged = new Subject();
  // taskChatCount: any;
  // taskChatCountChanged = new Subject();
  // jobCount: any;
  // jobCountChanged = new Subject();
  // jobChatCount: any;
  // jobChatCountChanged = new Subject();

  /** Replace modal Inputs */
  selectedPlan: any;
  currentPlan: any;

  studentPlan$ = new BehaviorSubject({});

  constructor(private http: HttpService, private storageService: StorageService) {
    this.currentProPlanChanged.subscribe((value) => {
      this.currentProPlan = value;
    });
    this.countChanged.subscribe((value) => {
      this.count = value;
    });
  }

  getSubscriptions(currencyCode, paymentFrequency, subSection?, role?) {
    return this.http.runHttpCall(
      'GET',
      `api/subscription?currencyCode=${currencyCode}${
        paymentFrequency ? (paymentFrequency == 1 ? '&paymentFrequency=1' : '&paymentFrequency=2') : ''
      }${role ? '&role=' + role : ''}`,
      'application/json',
      {},
      true
    );
  }

  upgradeSubscription(subscriptionId, code?: string, currencyCode?: string) {
    console.log('upgradeSubscription()');
    console.log('subscriptionId: ', subscriptionId);
    console.log('code: ', code);
    console.log('currencyCode: ', currencyCode);
    var url = `api/subscription/attempt`;
    var prepend = '?';
    if (code) {
      url = `${url}${prepend}code=${code}`;
      prepend = '&';
    }
    if (currencyCode) {
      url = `${url}${prepend}currencyCode=${currencyCode}`;
      prepend = '&';
    }
    return this.http.runHttpCall(
      'POST',
      url,
      'application/json',
      {
        userId: this.storageService.uniTaskrUser.userId,
        subscriptionId,
      },
      true
    );
  }

  previewSubscription(subscriptionId, code?: string, currencyCode?: string) {
    var url = `api/subscription/attempt/preview`;
    var prepend = '?';
    if (code) {
      url = `${url}${prepend}code=${code}`;
      prepend = '&';
    }
    if (currencyCode) {
      url = `${url}${prepend}currencyCode=${currencyCode}`;
      prepend = '&';
    }
    return this.http.runHttpCall(
      'POST',
      url,
      'application/json',
      {
        userId: 0,
        subscriptionId,
      },
      true
    );
  }

  getCurrentSubscription(userId, role?) {
    return this.http.runHttpCall(
      'GET',
      // `api/subscription/user/${userId}${role ? `?role=` + role : ''}`,
      `api/subscription/user${role ? `?role=` + role : ''}`,
      'application/json',
      {},
      true
    );
  }

  initStudentSubscription(role) {
    this.http
      // .runHttpCall('GET', `api/subscription/user${role ? `?role=` + role : ''}`, 'application/json', {}, true)
      .runHttpCall('GET', `api/subscription/user${`?role=` + role}`, 'application/json', {}, true)
      .subscribe((res) => {
        console.log('👿👿👿👿👿 Subs init res 👿👿👿👿👿: ', res);
        this.studentPlan$.next(res);
      });
  }

  justGetCurrentStudentSub() {
    return this.http.runHttpCall('GET', `api/subscription/user?role=3`, 'application/json', {}, true)
  }

  getStudentSubscription() {
    return this.studentPlan$.asObservable();
  }

  getStudentSubscriptionValue() {
    return this.studentPlan$.getValue();
  }

  getCounts() {
    return this.http.runHttpCall('GET', `api/count`, 'application/json', {}, true);
  }

  getSingleCount(trackableType) {
    return this.http.runHttpCall('GET', `api/count/${trackableType}`, 'application/json', {}, true);
  }

  getEnableSubFreeCode() {
    return this.http.runHttpCall('GET', `api/appconfig/promo`, 'application/json');
  }

  getCustomPreview(jobCount: number, paymentFrequency: number) {
    return this.http.runHttpCall(
      'GET',
      `api/custom/recruit/preview${jobCount ? '?jobCount=' + jobCount : ''}${
        paymentFrequency ? '&paymentFrequency=' + paymentFrequency : ''
      }`,
      'application/json',
      {},
      true
    );
  }

  createCustomSubscription(jobCount: number, paymentFrequency, currencyCode?: string) {
    return this.http.runHttpCall(
      'GET',
      `api/custom/recruit/create?jobCount=${jobCount}&paymentFrequency=${paymentFrequency}${
        currencyCode ? `&currencyCode=${currencyCode}` : `&currencyCode=GBP`
      }`,
      'application/json',
      {},
      true
    );
  }

  getCoupon(code: string) {
    return this.http.runHttpCall('GET', `api/coupon?code=${code}`, 'application/json', {}, true);
  }

  callMethodOnPreviousPage() {
    this.methodCalledSubject.next(undefined);
  }

  checkSubscriptionProvider() {
    return this.http.runHttpCall('GET', `api/subscription/user/provider`, 'application/json', {}, true);
  }
}

import { Component, EventEmitter, Input, NgZone, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ActionSheetController, AlertController, IonDatetime, LoadingController, PickerController } from '@ionic/angular';
import { format, parseISO } from 'date-fns';
import { ProfileService } from 'src/app/services/profile/profile.service';
import { SchoolsearchService } from 'src/app/services/schoolsearch/schoolsearch.service';

declare var google: any;

@Component({
  selector: 'app-list-fields',
  templateUrl: './list-fields.component.html',
  styleUrls: ['./list-fields.component.scss'],
})
export class ListFieldsComponent implements OnInit {
  /** Input field object */
  @Input('field') field;

  /** Input of the field values object */
  @Input('profile') focussedProfile;

  /** A value has been updated, inform the consumer. */
  @Output('updated') update = new EventEmitter();

  /** The address search field has been updated, inform the consumer. */
  @Output('addressUpdated') addressUpdate = new EventEmitter();

  /** The phone number */
  @ViewChild('phoneNumber', { static: true }) phoneNumber;

  /** Input phone number */
  inputMobile: string;
  inputAreaCode: string;
  verifiedMobile: boolean = false;

  /** Area Code Options */
  areaCodeOptions: { name: string, code: string }[] = [
    { name: 'GB', code: '44' },
    { name: 'US', code: '1' },
    { name: 'CA', code: '1' },
    { name: 'DE', code: '49' },
    { name: 'MX', code: '52' },
    { name: 'ES', code: '34' }
  ]

  /** Set the birthday */
  // dateValue: string;
  defaultDateValue;

  /** Updated Value */
  updatedValue;

  @ViewChild(IonDatetime, { static: true }) datetime: IonDatetime;

  dateValue = '';
  dateValue2 = '';

  /** Google Places Search Vars */
  autocompleteItems = [];
  autocomplete;
  service = new google.maps.places.AutocompleteService();
  showResultsList: boolean = true;

  geo: any;
  latitude: number;
  longitude: number;
  chosenPlace: any;

  address: string;
  postcode: string;
  coordinates: { lat: number; lng: number };

  chosenAddress: string;

  /** Home town string */
  hometownString: string;

  /** School search results */
  schoolResultsList: any[];
  schoolSelected: boolean = false;

  ignoreFirstTime: boolean = true;

  @Output('numberValid') numberValid = new EventEmitter<boolean>();

  @ViewChild('phoneNumberControl') phoneNumberControl;

  constructor(
    private router: Router,
    private pickerController: PickerController,
    private actionSheetCtrl: ActionSheetController,
    private loadingCtrl: LoadingController,
    private zone: NgZone,
    private alertController: AlertController,
    private profileService: ProfileService,
    private schoolService: SchoolsearchService
  ) {}

  ngOnInit() {
    if (this.focussedProfile && this.field.type == 'date') {
      let dOb: Date;
      if (this.focussedProfile[this.field.identifier] == '') {
        // dOb = new Date();
      } else {
        dOb = new Date(this.focussedProfile[this.field.identifier]);
        this.dateValue = format(parseISO(dOb.toISOString()), 'MMM dd yyyy');
      }

      console.log('dateValue: ', this.dateValue);
    }
    if (this.focussedProfile && this.focussedProfile.hometown) {
      this.autocomplete = this.focussedProfile.hometown;
    }
    if (this.focussedProfile && this.focussedProfile.mobile) {
      console.log('🤙🤙🤙🤙 mobile: ', this.focussedProfile.mobile);
      this.verifiedMobile = true;
      
      let mobile = this.focussedProfile.mobile;

      if (!this.focussedProfile.mobile.startsWith('+44') && this.focussedProfile.currencyCode == 'GBP') {
        mobile = `+${this.focussedProfile.mobile.slice(0, 2)} ${this.focussedProfile.mobile.slice(
          2,
          6
        )} ${this.focussedProfile.mobile.slice(6)}`;
      }

      let natNumber: string;
      // if (mobile && mobile.split(" ").length > 2) {
      //   natNumber = `${mobile.split(' ')[]} ${mobile.split(' ')[3]}`
      // } else {
        natNumber = `${mobile.split(' ')[1]} ${mobile.split(' ')[2] ? mobile.split(' ')[2] : ''}`
      // }

      this.inputMobile = natNumber;
      this.inputAreaCode = mobile.split(' ')[0];

      this.phoneNumber = {
        internationalNumber: mobile,
        nationalNumber: mobile,
        isoCode: this.focussedProfile.countryCode ? this.focussedProfile.countryCode.toLowerCase() : 'gb',
        dialCode: `${mobile.split(' ')[0]}`,
      };
    }
  }

  segmentChanged(ev) {
    this.updatedValue = ev.detail.value;
    if (this.updatedValue) {
      this.field.value = this.updatedValue;
      this.update.emit(this.field);
    }
  }

  formatDate(value: string) {
    this.updatedValue = new Date(value);
    if (this.updatedValue) {
      this.field.value = this.updatedValue;
      this.update.emit(this.field);
    }
    return format(parseISO(value), 'MMM dd yyyy');
  }

  getValue() {
    if (this.field.type === 'date') {
      this.defaultDateValue = format(
        parseISO(new Date(this.focussedProfile[this.field.identifier]).toISOString()),
        'MMM dd yyyy'
      );
      return format(parseISO(this.focussedProfile[this.field.identifier]), 'MMM dd yyyy');
    } else if (this.field.type === 'mobile' && this.focussedProfile.mobile) {
      if (`${this.focussedProfile.mobile.charAt(0)}${this.focussedProfile.mobile.charAt(1)}` == '44') {
        return this.focussedProfile && this.focussedProfile.mobile ? this.focussedProfile.mobile.substring(2) : '';
      } else if (`${this.focussedProfile.mobile.charAt(0)}` == '1') {
        return this.focussedProfile && this.focussedProfile.mobile ? this.focussedProfile.mobile.substring(1) : '';
      } else if (`${this.focussedProfile.mobile.charAt(0)}${this.focussedProfile.mobile.charAt(1)}` == '01') {
        return this.focussedProfile && this.focussedProfile.mobile ? this.focussedProfile.mobile.substring(1) : '';
      } else {
        return this.focussedProfile && this.focussedProfile.mobile ? this.focussedProfile.mobile : '';
      }
    } else {
      return this.focussedProfile[this.field.identifier];
    }
  }

  getCountryCode() {
    return this.phoneNumber.dialCode;
  }

  updateSelect(ev) {
    this.update.emit({
      value: ev.detail.value,
      identifier: this.field.identifier,
    });
  }

  hideDependsOn() {
    if (this.field.dependsValue !== this.focussedProfile[this.field.dependsOn]) {
      return true;
    } else {
      return false;
    }
  }

  getSelectValue() {
    return this.focussedProfile.studyYear;
  }

  updatedInputField(ev?, type?, field?) {
    console.log('updatedInputField()')
    if (type && type == 'mobile') {
      this.verifiedMobile = false;
      console.log('Update Input Field: ', this.inputMobile);

      // this.updatedValue = this.phoneNumber.internationalNumber.replace(this.phoneNumber.dialCode, '');
      // this.numberValid.emit(this.phoneNumberControl.control.invalid);
      this.updatedValue = `${this.inputAreaCode} ${this.inputMobile}`;
      // this.numberValid.emit(this.phoneNumberControl.control.invalid);
    } else {
      this.updatedValue = ev.detail.value;
      this.field.value = this.updatedValue;
      this.update.emit(this.field);
    }
    // School search bits
    if (field && field.schoolSearch == true) {
      this.schoolResultsList = this.schoolService.schoolSearch(ev.detail.value);
      if (ev.detail.value !== this.focussedProfile[this.field.identifier]) {
        this.schoolSelected = false;
      }
    }
  }

  async verifyMobile() {

    const loading = await this.loadingCtrl.create({
      message: 'Loading...',
      duration: 3000,
    });

    loading.present();

    let number = `${this.inputAreaCode}${this.inputMobile}`
    console.log('verifyMobile: ', number);
    let sendTo;

    sendTo = `${number}`;

    this.profileService.sendSms(sendTo).subscribe(async (res) => {
      console.log(res);
      loading.dismiss();
      const alert = await this.alertController.create({
        cssClass: 'my-custom-class',
        header: "Please enter the verification code you've just been sent.",
        inputs: [
          {
            name: 'name1',
            type: 'tel',
            placeholder: '####',
          },
        ],
        buttons: [
          {
            text: 'Cancel',
            role: 'cancel',
            cssClass: 'secondary',
            handler: () => {
              console.log('Confirm Cancel');
            },
          },
          {
            text: 'Ok',
            handler: (data) => {
              console.log('Confirm Ok', data);
              this.profileService.verifySms(sendTo, data.name1).subscribe(async (res) => {
                this.verifiedMobile = true;
                console.log('resss', res);
                if (res) {
                  this.update.emit({
                    value: sendTo.replace(' ', ''),
                    identifier: 'mobile',
                  });
                } else {
                  this.update.emit({
                    value: '',
                    identifier: 'mobile',
                  });
                  const alert = await this.alertController.create({
                    cssClass: 'my-custom-class',
                    header: 'Wrong verification code, please try again.',
                    buttons: [
                      {
                        text: 'Okay',
                        role: 'cancel',
                      },
                    ],
                  });
  
                  await alert.present();
                }
              });
            },
          },
        ],
      });
  
      await alert.present();
    }, async err => {
      console.log('Mobile ERROR: ', err);
      loading.dismiss();
      const alert = await this.alertController.create({
        header: 'Whoops!',
        subHeader: 'Invalid Mobile Number',
        buttons: ['Update'],
      });
  
      await alert.present();
    });

  }

  saveUpdatedValue(ev, type?) {
    console.log('saveUpdatedValue(): ', ev);
    console.log('saveUpdatedValue(): ', type);
    if (type && type == 'mobile' && this.inputMobile && this.inputAreaCode) {
      // this.verifyMobile(`${this.inputAreaCode} ${this.inputMobile}`);
    } else if (this.updatedValue && type && type !== 'mobile') {
      // console.log('Should emit...');
      this.field.value = this.updatedValue;
      this.update.emit(this.field);
    }
  }

  updateSearch() {
    if (this.autocomplete) {
      if (this.autocomplete == '') {
        this.autocompleteItems = [];
        return;
      }
      let me = this;
      this.service.getPlacePredictions(
        {
          input: this.autocomplete,
          componentRestrictions: {
            country: 'uk',
          },
        },
        (predictions, status) => {
          me.autocompleteItems = [];

          me.zone.run(() => {
            if (predictions != null) {
              this.showResultsList = true;
              predictions.forEach((prediction) => {
                me.autocompleteItems.push(prediction.description);
              });
            }
          });
        }
      );
    }
  }

  chooseItem(item: any) {
    this.chosenAddress = item;
    this.geo = item;
    this.geoCode(this.geo); //convert Address to lat and long
  }

  geoCode(address: any) {
    let geocoder = new google.maps.Geocoder();
    geocoder.geocode({ address: address }, (results) => {
      this.latitude = results[0].geometry.location.lat();
      this.longitude = results[0].geometry.location.lng();
      this.chosenPlace = results[0];
      this.autocomplete = results[0].formatted_address;
      this.autocompleteItems = [];
      this.address = results[0].formatted_address;
      this.coordinates = { lat: this.latitude, lng: this.longitude };
      this.autocompleteItems = [];
      for (let t of results[0].address_components) {
        if (t.types[0] === 'postal_code') {
          this.postcode = t.short_name;
        }
      }
      this.autocomplete = this.address;
      this.field.value = this.address;
      // this.autocomplete = [];
      this.autocompleteItems = [];
      this.showResultsList = false;

      this.addressUpdate.emit({
        homeTownLat: this.latitude,
        homeTownLocation: this.address,
        homeTownlon: this.longitude,
        hometown: this.address,
      });
    });
  }

  async presentBirthdayPicker() {
    const picker = await this.pickerController.create({
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
        },
        {
          text: 'Confirm',
          handler: (selected) => {
            console.log('selected: ', selected);
            let dob = new Date();
            dob.setFullYear(selected.year.value);
            dob.setMonth(selected.month.value);
            dob.setDate(selected.day.value);

            console.log('dob: ', this.formatDate(dob.toISOString()));
            this.dateValue = this.formatDate(dob.toISOString());
          },
        },
      ],
      columns: [
        {
          name: 'year',
          options: [
            { text: '2006', value: '2006' },
            { text: '2005', value: '2005' },
            { text: '2004', value: '2004' },
            { text: '2003', value: '2003' },
            { text: '2002', value: '2002' },
            { text: '2001', value: '2001' },
            { text: '2000', value: '2000' },
            { text: '1999', value: '1999' },
            { text: '1998', value: '1998' },
            { text: '1997', value: '1997' },
            { text: '1996', value: '1996' },
            { text: '1995', value: '1995' },
            { text: '1994', value: '1994' },
            { text: '1993', value: '1993' },
            { text: '1992', value: '1992' },
            { text: '1991', value: '1991' },
            { text: '1990', value: '1990' },
            { text: '1989', value: '1989' },
            { text: '1988', value: '1988' },
            { text: '1987', value: '1987' },
            { text: '1986', value: '1986' },
            { text: '1985', value: '1985' },
            { text: '1984', value: '1984' },
            { text: '1983', value: '1983' },
            { text: '1982', value: '1982' },
            { text: '1981', value: '1981' },
            { text: '1980', value: '1980' },
            { text: '1979', value: '1979' },
            { text: '1978', value: '1978' },
            { text: '1977', value: '1977' },
            { text: '1976', value: '1976' },
            { text: '1975', value: '1975' },
            { text: '1974', value: '1974' },
            { text: '1973', value: '1973' },
            { text: '1972', value: '1972' },
            { text: '1971', value: '1971' },
            { text: '1970', value: '1970' },
            { text: '1969', value: '1969' },
            { text: '1968', value: '1968' },
            { text: '1967', value: '1967' },
            { text: '1966', value: '1966' },
            { text: '1965', value: '1965' },
            { text: '1964', value: '1964' },
            { text: '1963', value: '1963' },
            { text: '1962', value: '1962' },
            { text: '1961', value: '1961' },
            { text: '1960', value: '1960' },
            { text: '1959', value: '1959' },
            { text: '1958', value: '1958' },
            { text: '1957', value: '1957' },
            { text: '1956', value: '1956' },
            { text: '1955', value: '1955' },
            { text: '1954', value: '1954' },
            { text: '1953', value: '1953' },
            { text: '1952', value: '1952' },
            { text: '1951', value: '1951' },
            { text: '1950', value: '1950' },
            { text: '1949', value: '1949' },
            { text: '1948', value: '1948' },
            { text: '1947', value: '1947' },
            { text: '1946', value: '1946' },
            { text: '1945', value: '1945' },
            { text: '1944', value: '1944' },
            { text: '1943', value: '1943' },
            { text: '1942', value: '1942' },
            { text: '1941', value: '1941' },
            { text: '1940', value: '1940' },
            { text: '1939', value: '1939' },
            { text: '1938', value: '1938' },
            { text: '1937', value: '1937' },
            { text: '1936', value: '1936' },
            { text: '1935', value: '1935' },
            { text: '1934', value: '1934' },
            { text: '1933', value: '1933' },
            { text: '1932', value: '1932' },
            { text: '1931', value: '1931' },
            { text: '1930', value: '1930' },
          ],
        },
        {
          name: 'month',
          options: [
            { text: 'Jan', value: '0' },
            { text: 'Feb', value: '01' },
            { text: 'Mar', value: '02' },
            { text: 'Apr', value: '03' },
            { text: 'May', value: '04' },
            { text: 'Jun', value: '05' },
            { text: 'Jul', value: '06' },
            { text: 'Aug', value: '07' },
            { text: 'Sep', value: '08' },
            { text: 'Oct', value: '09' },
            { text: 'Nov', value: '10' },
            { text: 'Dec', value: '11' },
          ],
        },
        {
          name: 'day',
          options: [
            { text: '01', value: '01' },
            { text: '02', value: '02' },
            { text: '03', value: '03' },
            { text: '04', value: '04' },
            { text: '05', value: '05' },
            { text: '06', value: '06' },
            { text: '07', value: '07' },
            { text: '08', value: '08' },
            { text: '09', value: '09' },
            { text: '10', value: '10' },
            { text: '11', value: '11' },
            { text: '12', value: '12' },
            { text: '13', value: '13' },
            { text: '14', value: '14' },
            { text: '15', value: '15' },
            { text: '16', value: '16' },
            { text: '17', value: '17' },
            { text: '18', value: '18' },
            { text: '19', value: '19' },
            { text: '20', value: '20' },
            { text: '21', value: '21' },
            { text: '22', value: '22' },
            { text: '23', value: '23' },
            { text: '24', value: '24' },
            { text: '25', value: '25' },
            { text: '26', value: '26' },
            { text: '27', value: '27' },
            { text: '28', value: '28' },
            { text: '29', value: '29' },
            { text: '30', value: '30' },
            { text: '31', value: '31' },
          ],
        },
      ],
    });
    await picker.present();
  }

  async selectAreaCode() {
    console.log('selectAreaCode()')

    const actionSheet = await this.actionSheetCtrl.create({
      header: 'Dialling Code',
      buttons: [
        {
          text: 'GB (+44)',
          data: {
            action: '44',
          },
        },
        {
          text: 'US (+1)',
          data: {
            action: '1',
          },
        },
        {
          text: 'CA (+1)',
          data: {
            action: '1',
          },
        },
        {
          text: 'DE (+49)',
          data: {
            action: '49',
          },
        },
        {
          text: 'MX (+52)',
          data: {
            action: '52',
          },
        },
        {
          text: 'ES (+34)',
          data: {
            action: '34',
          },
        },
        {
          text: 'Cancel',
          role: 'cancel',
          data: {
            action: this.inputAreaCode,
          },
        },
      ],
    });

    await actionSheet.present();
    let data = await actionSheet.onDidDismiss()
    console.log('area code data: ', data);
    this.inputAreaCode = data.data.action;
  }

  selectSchool(school, field) {
    this.updatedValue = school.name;
    this.field.value = school.name;
    this.focussedProfile[this.field.identifier] = school.name;

    this.update.emit(this.field.value);
    this.schoolResultsList = undefined;
    this.schoolSelected = true;
  }

  editMobileNumber() {
    return true;
  }

  async editEmailAddress() {
    console.log('editEmailAddress()');
    this.router.navigate(['/account-update-email']);
  }
}

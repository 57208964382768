import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class DeeplinkService {

  linkDomain: string = "taskr.uk";

  constructor(private http: HttpClient) {}

  createLinkDemo() {
    const url = 'https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=AIzaSyCVjcDm-tqQ1nvVwJ0mASIOcFpFMOnPLmI';
    let request = {
      dynamicLinkInfo: {
        domainUriPrefix: 'https://unidosh.page.link',
        link: 'https://uniblaze.web.app/task-profile/19991',
        androidInfo: {
          androidPackageName: 'com.unidosh',
        },
        iosInfo: {
          iosBundleId: 'com.unidosh',
        },
      },
    };

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this.http.post<any>(url, request, { headers });
  }

  createShoutLink(briefId: string) {
    const url = `https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=${environment.firebaseConfig.apiKey}`;

    let deeplink: string = `https://uniinferno.page.link/?link=https://shout.unitaskr.com/choose-platform/${briefId}&isi=6466427295&ibi=com.unitaskr.unitaskrshout&efr=1`;
    let request = { 
      "longDynamicLink": deeplink
    }

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this.http.post<any>(url, request, { headers });
  }


  createLinkTask(pageStub: string) {
    const url = `https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=${environment.firebaseConfig.apiKey}`;
    let request = {
      dynamicLinkInfo: {
        domainUriPrefix: "https://uniblaze.page.link",
        // domainUriPrefix: 'https://unidoshprod.page.link',
        // link: `https://web.unitaskr.com/${pageStub}`,
        link: `https://uniblaze.web.app/${pageStub}`,
        androidInfo: {
          androidPackageName: 'com.unidosh',
        },
        iosInfo: {
          iosBundleId: 'com.unidosh',
        },
      },
    };

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this.http.post<any>(url, request, { headers });
  }

  createUniTrackLink(pageStub: string) {

  }

  createShortLink(longUrl: string) {

    // const url: string = "https://api-ssl.bitly.com/v4/shorten";
    const url: string = "https://api-ssl.bitly.com/v4/bitlinks";

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer f11521e7117fabb8f79b5c340a8065aa22861621'
    });

    let payload = {
      "domain": this.linkDomain,
      "long_url": longUrl,
      custom_bitlinks: ["unitaskrappshare"],
    }

    return this.http.post<any>(url, payload, { headers: headers })
  }

  customiseBackHalf(shortLink: string, suffix: string) {
    const url: string = "https://api-ssl.bitly.com/v4/custom_bitlinks";

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer f11521e7117fabb8f79b5c340a8065aa22861621'
    });

    shortLink.replace('https://', '')
    shortLink.replace('http://', '')

    console.log('shortLink: ', shortLink);

    let payload = {
      "custom_bitlink": `${this.linkDomain}/${suffix}`,
      "bitlink_id": shortLink
    }
    console.log('payload: ', payload);
    return this.http.post<any>(`${url}`, payload, { headers: headers })
  }
}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MenuController, ModalController } from '@ionic/angular';
import { ProfileReponse, ProfileService } from '../services/profile/profile.service';
import { StorageService } from '../services/storage/storage.service';


@Component({
  selector: 'app-student-id-card',
  templateUrl: './student-id-card.page.html',
  styleUrls: ['./student-id-card.page.scss'],
})
export class StudentIdCardPage implements OnInit {

  /** My focussed profile object */
  myProfile: ProfileReponse;

  constructor(
    private router: Router,
    private modalController: ModalController,
    private menuController: MenuController,
    private profileService: ProfileService,
    public storageService: StorageService
  ) {

  }

  ngOnInit() {
    this.menuController.close();
    this.getMyProfile();
  }

  getMyProfile() {
    this.profileService.getProfile().subscribe(profileRes => {
      console.log('profileRes: ', profileRes);
      this.myProfile = profileRes;
    }, err => {
      console.log('profileRes err: ', err);
    })
  }

  verifyMe() {
    this.router.navigate(['/account'], { queryParams: { openSetting: "verifyMe" } });
    this.close();
  }

  close() {
    this.modalController.dismiss();
  }
}

import { Injectable } from '@angular/core';
import { fakeAsync } from '@angular/core/testing';
import { ArgumentOutOfRangeError, BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { CountryService } from 'src/app/services/country/country.service';
import { FirebaseService } from 'src/app/services/firebase/firebase.service';
import { HttpService } from 'src/app/services/http/http.service';
import { StorageService } from 'src/app/services/storage/storage.service';

@Injectable({
  providedIn: 'root',
})
export class TaskService {
  storedTaskApplication;

  /** What is the status of the shout that i am looking at? */
  selectedShoutStatus: number = 0;

  /** Subscription to trigger my tasks refresh. */
  shouldRefresh = new BehaviorSubject({
    shouldRefresh: false,
    spinner: undefined,
  });

  clear = new Subject();

  constructor(private http: HttpService, private countryService: CountryService) { }

  clearAll(): Observable<any> {
    return this.clear.asObservable();
  }

  getFilterTasks(
    skip: number,
    keyword?: string,
    lat?: number,
    long?: number,
    distance?: number,
    categoryIds?: any
  ): Observable<TaskResponse[]> {
    return this.http.runHttpCall('POST', 'api/tasks/filter', 'application/json', {
      skip: skip,
      take: 8,
      listFromDT: '2022-01-10T00:00:00.000Z',
      categoryIds: categoryIds ? categoryIds : null,
      distance: distance ? distance : 0,
      lat: lat,
      lon: long,
      locationType: 0,
      userId: 0,
      search: keyword && keyword !== '' ? keyword : null,
    });
  }

  // getFilterTasksv2(isShout: boolean, skip: number, keyword?: string, distance?: number, categoryIds?: number[], subCategoryIds?: number[]): Observable<TaskResponse[]> {
  getFilterTasksv2(filterRequest): Observable<TaskResponse[]> {
    return this.http.runHttpCall('POST', 'api/tasks/filter/v2', 'application/json', filterRequest);
  }

  getShoutFilter(skip: number) {
    return this.http.runHttpCall('POST', `api/shout/filter`, 'application/json', {
      take: 10,
      skip: skip,
    });
  }

  getFeaturedShouts(skip: number) {
    return this.http.runHttpCall('POST', `api/shout/featured`, 'application/json', {
      location: this.countryService.userCountry.country == 'GB' ? 1 : 2, // Uk - 1 , US - 2
      // location: this.countryService.userCountry.country == "GB" ?  1 : 2, // Uk - 1 , US - 2
      // location: this.countryService.userCountry.country == 2,
      page: {
        take: 10,
        skip: skip,
      },
    });
  }

  getFeaturedShoutsv2(filterRequest) {
    return this.http.runHttpCall('POST', `api/shout/featured`, 'application/json', filterRequest);
  }

  getShoutUserFilter(skip: number, status: number) {
    return this.http.runHttpCall('POST', `api/shout/user/filter`, 'application/json', {
      status: status,
      page: {
        take: 10,
        skip: skip,
      },
    });
  }

  getTaskByIdPublic(id: string) {
    return this.http.runHttpCall('GET', `api/tasks/${id}`, 'application/json');
  }

  getTaskById(id: string) {
    return this.http.runHttpCall('GET', `api/tasks/user/${id}`, 'application/json');
  }

  getShoutById(id: string) {
    console.log('getShoutById(): ', id);
    return this.http.runHttpCall('GET', `api/shout/${id}`, 'application/json');
  }

  getShoutDashboard() {
    return this.http.runHttpCall('GET', `api/shout/dashboard`, 'application/json');
  }

  getTaskOffers(taskId: number) {
    return this.http.runHttpCall('POST', `api/taskoffers/task`, 'application/json', {
      taskId: taskId,
      page: {
        skip: 0,
        take: 3,
      },
    });
  }

  getDetailedOffer(offerId: number) {
    return this.http.runHttpCall('GET', `api/taskoffers/detailed/${offerId}`, 'application/json');
  }

  getTasksIPosted() {
    return this.http.runHttpCall('POST', `api/tasks/user/my-tasks`, 'application/json', {
      skip: 0,
      take: 8,
    });
  }

  deleteTask(taskId) {
    return this.http.runHttpCall('DELETE', `api/tasks/user/${taskId}`, 'application/json');
  }

  makeOfferOnTask(offerRequest) {
    return this.http.runHttpCall('POST', `api/taskoffers`, 'application/json', offerRequest);
  }

  declineOfferOnTask(offerId) {
    return this.http.runHttpCall('POST', `api/taskoffers/${offerId}/decline`, 'application/json');
  }

  updateOfferOnTask(offerId, amount, message, links) {
    return this.http.runHttpCall('PUT', `api/taskoffers/${offerId}`, 'application/json', {
      amount: amount,
      message: message,
      attachLinks: links,
    });
  }

  markComplete(taskOfferId) {
    return this.http.runHttpCall('PUT', `api/taskoffers/${taskOfferId}/seller-complete`, 'application/json');
  }

  updatePause(value: boolean, taskId) {
    return this.http.runHttpCall('POST', `api/tasks/user/${taskId}/update/is-paused`, 'application/json', {
      isPaused: value,
    });
  }

  getTaskQuestions(taskId: string): Observable<any[]> {
    return this.http.runHttpCall('POST', `api/tasks/${taskId}/questions`, 'application/json', {
      skip: 0,
      take: 10,
      listFromDT: new Date().toISOString(),
    });
  }
  getTaskOfferMessages() {
    return this.http.runHttpCall('GET', `api/taskoffers/user/task-offers`, 'application/json');
  }

  addClick(taskId: number) {
    return this.http.runHttpCall('POST', `api/tasks/user/external/click/${taskId}`, 'application/json');
  }

  /**
   * SERVICEZ
   */
  markCompleteService(serviceOfferId) {
    return this.http.runHttpCall('PUT', `api/serviceoffers/${serviceOfferId}/seller-complete`, 'application/json');
  }

  getLiveTicker() {
    return this.http.runHttpCall('GET', `api/liveticker`, 'application/json');
  }
}

export interface TaskResponse {
  budget: number;
  budgetType: number;
  dueDate: string;
  duration: number;
  hiredCount: number;
  isCompleted: false;
  isHired: false;
  isPinTask: true;
  isSurvey: false;
  location: string;
  shortAddress: string;
  locationType: number;
  myOffersCount: number;
  offersCount: number;
  profilePictureUrl: string;
  questionsCount: number;
  shoutStatus: number;
  startedCount: number;
  taskId: number;
  taskerCount: number;
  title: string;
  userId: string;
  lat: number;
  lon: number;
  isPaused: boolean;
  notificationReadCount: number;
  jobType: number;
}

export interface LinkedInResponse {
  city: string;
  company: string;
  country: string;
  cpc: string[];
  currency: string[];
  description: string;
  employmentStatus: string;
  experienceLevel: string[];
  expirationDate: number;
  id :number 
  industries: string[]
  jobFunction: string[];
  linkedinCompanyId: string;
  linkedinCompanyUrl: string;
  listDate: number;
  location: string;
  objectID: string;
  postalCode: string;
  state: string;
  title: string;
  url: string;
  _highlightResult: any;
}

export interface ShoutResponse {
  budget: number;
  budgetType: number;
  createdDate: Date;
  description: string;
  isPinTask: boolean;
  profilePictureUrl: string;
  shoutCampaignStats: any;
  status: number;
  taskId: number;
  taskOffer: number;
  title: string;
  userId: number;
}

import { Component, Input, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';

@Component({
  selector: 'app-manage-task-more-options',
  templateUrl: './manage-task-more-options.component.html',
  styleUrls: ['./manage-task-more-options.component.scss'],
})
export class ManageTaskMoreOptionsComponent implements OnInit {
  @Input('questionCount') questionCount: number;
  @Input('isPaused') isPaused: boolean;
  @Input('taskType') taskType: any;

  constructor(private popoverController: PopoverController) {}

  ngOnInit() {
    console.log('questionCount: ', this.questionCount);
    console.log('taskType: ', this.taskType);
  }

  triggerEvent(type: string) {
    console.log('triggerEvent(): ', type);
    this.popoverController.dismiss({ type: type });
  }
}

// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.x-small-size {
  width: 24px;
  height: 24px;
}

.small-size {
  width: 36px;
  height: 36px;
}

.list-size {
  width: 52px;
  height: 52px;
}

.med-size {
  width: 72px;
  height: 72px;
}

.large-size {
  width: 90px;
  height: 90px;
}

.x-large-size {
  width: 104px;
  height: 104px;
}

.xx-large-size {
  width: 134px;
  height: 134px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/avatar/avatar.component.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,YAAA;AACF;;AAEA;EACE,WAAA;EACA,YAAA;AACF;;AAEA;EACE,WAAA;EACA,YAAA;AACF;;AAEA;EACE,WAAA;EACA,YAAA;AACF;;AAEA;EACE,WAAA;EACA,YAAA;AACF;;AAEA;EACE,YAAA;EACA,aAAA;AACF;;AAEA;EACE,YAAA;EACA,aAAA;AACF","sourcesContent":[".x-small-size {\n  width: 24px;\n  height: 24px;\n}\n\n.small-size {\n  width: 36px;\n  height: 36px;\n}\n\n.list-size {\n  width: 52px;\n  height: 52px;\n}\n\n.med-size {\n  width: 72px;\n  height: 72px;\n}\n\n.large-size {\n  width: 90px;\n  height: 90px;\n}\n\n.x-large-size {\n  width: 104px;\n  height: 104px;\n}\n\n.xx-large-size {\n  width: 134px;\n  height: 134px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { format, parseISO } from 'date-fns';

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
})
export class DatePickerComponent implements OnInit {

  @Input('defaultDateValue') defaultDateValue;

  @Output('formattedDate') formattedDate = new EventEmitter();

  /** What day is it today? */
  today: string = new Date().toISOString();

  dateValue2;
  dateValue;

  constructor(
    private modalCtrl: ModalController
  ) { }

  ngOnInit() {
    console.log('modal defaultDateValue: ', this.defaultDateValue);
  }

  formatDate(value: string) {
    let formatted = format(parseISO(value), 'MMM dd yyyy');
    this.modalCtrl.dismiss(formatted)
    return format(parseISO(value), 'MMM dd yyyy');
  }
}

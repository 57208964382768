import { Injectable } from '@angular/core';
import { HttpService } from 'src/app/services/http/http.service';
import { doc, getFirestore, setDoc, onSnapshot, arrayUnion } from '@firebase/firestore';
import { StorageService } from 'src/app/services/storage/storage.service';

@Injectable({
  providedIn: 'root'
})
export class ChatChannelService {

  focussedTask;
  focussedApplicant;

  focussedConversation;

  presence = {
    id: 0
  };

  constructor(
    private http: HttpService,
    private storageService: StorageService
  ) { }

  getMyInbox(archived) {
    return this.http.runHttpCall(
      "GET",
      `api/channels/user?isArchived=${archived}`,
      "application/json")
  }

  createChannel(chatName: string, userId: number, taskerOfferId, entityType, adminUsers?) {
    return this.http.runHttpCall(
      "POST",
      "api/channels",
      "application/json",
      {
        name: chatName,
        users: userId > 0 ? [userId] : [],
        adminUsers: adminUsers ? adminUsers : [],
        linkedEntity: {
          linkedEntityId: taskerOfferId,
          linkedEntityType: entityType // TaskOffer = 1, Service = 2,
        }
      }
    )
  }

  createStreamChannel(channelType: string, channelId: string, createdBy: string, members: string[], channelData: any) {
    return this.http.runHttpCall(
      "POST",
      "api/streamio/channel",
      "application/json",
      {
        channelType,
        channelId,
        createdBy,
        members,
        data: JSON.stringify(channelData)
      }
    )
  }

  createStreamRecruitChannel(channelType: string, channelId: string, createdBy: string, members: string[], channelData: any) {
    return this.http.runHttpCall(
      "POST",
      "api/streamio/channel/recruit",
      "application/json",
      {
        channelType,
        channelId,
        createdBy,
        members,
        data: JSON.stringify(channelData)
      }
    )
  }

  // trackChat(channelId: string) {
  //   return this.http.runHttpCall(
  //     "POST",
  //     `api/streamio/channel/${channelId}/track`,
  //     "application/json"
  //   )
  // }

  redeemChat(channelId: number, isRecruit: boolean) {
    return this.http.runHttpCall(
      "POST",
      `api/streamio/channel/${channelId}/redeem${isRecruit?"?recruit=true":""}`,
      "application/json"
    )
  }

  createFireChannel(chatId: string, lastMessage: string) {
    const db = getFirestore();
    return setDoc(doc(db, "blaze-channels", `${chatId}`), {
      lastMessage: lastMessage,
      sender: this.storageService.uniTaskrUser.userId,
      readBy: [this.storageService.uniTaskrUser.userId],
      messages: [],
      timestamp: new Date()
    }, { merge: true })
  }

  getFireChannel(chatId: string) {
    const db = getFirestore();
    let docRef = doc(db, "blaze-channels", `${chatId}`);
    const unsub = onSnapshot(docRef, (doc) => {
      this.presence[chatId] = doc.data();
    });
  }

  readFireMessage(channelId: string, readBy: boolean) {
    return this.http.runHttpCall(
      "PUT",
      `api/channels/${channelId}/read-by?hasReadBy=${readBy}`,
      "application/json"
    )
  }

  newFireMessage(chatId: string, message: string) {
    const db = getFirestore();
    return setDoc(doc(db, "channels", `${chatId}`), {
      lastMessage: message,
      sender: this.storageService.uniTaskrUser.userId,
      readBy: [this.storageService.uniTaskrUser.userId],
      timestamp: new Date()
    }, { merge: false })
  }

  getLinkedConversation(linkedEntityId: number, linkedEntityType: number) {
    return this.http.runHttpCall(
      "POST",
      "api/channels/linked-entity",
      "application/json",
      {
        linkedEntityId: linkedEntityId,
        linkedEntityType: linkedEntityType // TaskOffer = 1, Service = 2,
      }
    )
  }

  canMigrate() {
    return this.http.runHttpCall(
      "POST",
      `api/chatmigration/can-migrate`,
      "application/json"
    )
  }

  chatMigrate() {
    return this.http.runHttpCall(
      "POST",
      `api/chatmigration/migrate`,
      "application/json"
    )
  }

  hasMigrated() {
    return this.storageService.get('has_migrated_chat');
  }

  archiveChat(channelId, shouldArchive) {
    return this.http.runHttpCall(
      "POST",
      `api/channels/${channelId}/user/set-is-archived`,
      "application/json",
      {
        isArchived: shouldArchive
      }
    )
  }
}

import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ProfileService } from 'src/app/services/profile/profile.service';

@Component({
  selector: 'app-company-reviews',
  templateUrl: './company-reviews.component.html',
  styleUrls: ['./company-reviews.component.scss'],
})
export class CompanyReviewsComponent implements OnInit {

  /** Which layout would you like to see? */
  @Input('type') viewType: 'blue-box' | 'white-box' | undefined;

  /** What is the name of the company? */
  @Input('companyName') companyName;

  /** What is the overall rating of this comapny? */
  @Input('overallRating') overallRating;

  /** How many reviews has this company had? */
  @Input('reviewCount') reviewCount = 0;

  /** The list of all exisint reviews */
  @Input('allReviews') allReviews = [];

  /* Get more reviews */
  @Output('getMoreReviews') getMoreReviews = new EventEmitter<any>();

  /** Which reviews are we actually going to render? */
  showReviews = [];
  noMoreReviews: boolean = false;

  constructor(private profileService: ProfileService) { }

  ngOnInit() {
    console.log('allReviews: ', this.allReviews);
    if (this.allReviews && this.allReviews.length > 20) {
      this.showReviews = this.allReviews.splice(20);
    } else {
      this.showReviews = this.allReviews //.slice(0, this.allReviews.length - 1);
    }

    // this.profileService.getValue().subscribe((val) => {
    //   this.showReviews = val;
    // })

    // console.log('showReviews: ', this.showReviews);
  }

  getFilledStars(rating) {
    if (rating) {
      let filled = Math.floor(rating / 1);
      return filled;
    } else {
      return 0;
    }
  }

  getPartialStar(rating) {
    if (rating - (Math.floor(rating / 1)) > 0) {
      return true;
    } else {
      return false;
    }
  }

  getEmptyStars(rating) {
    let emptyStars = 5;
    if (this.getPartialStar(rating)) {
      emptyStars--;
    }
    emptyStars -= this.getFilledStars(rating);

    return emptyStars;
  }
}

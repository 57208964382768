// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chevron {
  display: inline-block;
  min-width: 110px;
  text-align: center;
  padding: 3px 0;
  margin-right: -8px;
  background: #abb8ff;
  clip-path: polygon(0 0, 90% 0, 100% 50%, 90% 100%, 0 100%, 10% 50%);
  color: #fff;
}

.selected_chevron {
  background: #3e5cff;
}

.sticky {
  position: fixed;
  top: 0;
}

.web-width {
  width: calc(72vw - 276px);
}`, "",{"version":3,"sources":["webpack://./src/app/messaging/channels/custom-header/custom-header.component.scss"],"names":[],"mappings":"AAAA;EACI,qBAAA;EACA,gBAAA;EACA,kBAAA;EACA,cAAA;EACA,kBAAA;EACA,mBAAA;EAEA,mEAAA;EACA,WAAA;AACJ;;AAEA;EACI,mBAAA;AACJ;;AAEA;EACI,eAAA;EACA,MAAA;AACJ;;AAEA;EACI,yBAAA;AACJ","sourcesContent":[".chevron {\n    display: inline-block;\n    min-width: 110px;\n    text-align: center;\n    padding: 3px 0;\n    margin-right: -8px;\n    background: #abb8ff;\n    -webkit-clip-path: polygon(0 0, 90% 0, 100% 50%, 90% 100%, 0 100%, 10% 50%);\n    clip-path: polygon(0 0, 90% 0, 100% 50%, 90% 100%, 0 100%, 10% 50%);\n    color: #fff;\n}\n\n.selected_chevron {\n    background: #3e5cff;\n}\n\n.sticky {\n    position: fixed;\n    top: 0;\n}\n\n.web-width {\n    width: calc(72vw - 276px);\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

import { Component, OnInit } from '@angular/core';
import { ModalController, PickerController } from '@ionic/angular';
import { format, parseISO } from 'date-fns';
import { LocalUserService } from 'src/app/welcome/services/local-user/local-user.service';

@Component({
  selector: 'app-adhoc-dateofbirth',
  templateUrl: './adhoc-dateofbirth.component.html',
  styleUrls: ['./adhoc-dateofbirth.component.scss'],
})
export class AdhocDateofbirthComponent implements OnInit {

  /** User's set date of birth */
  dateOfBirth;
  dateOfBirthForRegistration;

  constructor(
    private pickerController: PickerController,
    private modalController: ModalController,
    private localUserService: LocalUserService
  ) { }

  ngOnInit() {}

  formatDate(value: string) {
    return format(parseISO(value), 'MMM dd yyyy');
  }

  saveDOB() {
    console.log('saveDOB(): ', this.dateOfBirthForRegistration);
    this.localUserService.myProfile.dateOfBirth = this.dateOfBirthForRegistration;
    this.modalController.dismiss();
  }

  async presentBirthdayPicker() {
    const picker = await this.pickerController.create({
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
        },
        {
          text: 'Confirm',
          handler: (selected) => {
            console.log('selected: ', selected);
            let dob = new Date();
            dob.setFullYear(selected.year.value);
            dob.setMonth(selected.month.value);
            dob.setDate(selected.day.value);

            console.log('dob: ', dob.toISOString());
            console.log('formatDate dob: ', this.formatDate(dob.toISOString()));
            this.dateOfBirth = this.formatDate(dob.toISOString());
            this.dateOfBirthForRegistration = dob.toISOString();
          },
        },
      ],
      columns: [
        {
          name: 'year',
          options: [
            { text: '2006', value: '2006' },
            { text: '2005', value: '2005' },
            { text: '2004', value: '2004' },
            { text: '2003', value: '2003' },
            { text: '2002', value: '2002' },
            { text: '2001', value: '2001' },
            { text: '2000', value: '2000' },
            { text: '1999', value: '1999' },
            { text: '1998', value: '1998' },
            { text: '1997', value: '1997' },
            { text: '1996', value: '1996' },
            { text: '1995', value: '1995' },
            { text: '1994', value: '1994' },
            { text: '1993', value: '1993' },
            { text: '1992', value: '1992' },
            { text: '1991', value: '1991' },
            { text: '1990', value: '1990' },
            { text: '1989', value: '1989' },
            { text: '1988', value: '1988' },
            { text: '1987', value: '1987' },
            { text: '1986', value: '1986' },
            { text: '1985', value: '1985' },
            { text: '1984', value: '1984' },
            { text: '1983', value: '1983' },
            { text: '1982', value: '1982' },
            { text: '1981', value: '1981' },
            { text: '1980', value: '1980' },
            { text: '1979', value: '1979' },
            { text: '1978', value: '1978' },
            { text: '1977', value: '1977' },
            { text: '1976', value: '1976' },
            { text: '1975', value: '1975' },
            { text: '1974', value: '1974' },
            { text: '1973', value: '1973' },
            { text: '1972', value: '1972' },
            { text: '1971', value: '1971' },
            { text: '1970', value: '1970' },
            { text: '1969', value: '1969' },
            { text: '1968', value: '1968' },
            { text: '1967', value: '1967' },
            { text: '1966', value: '1966' },
            { text: '1965', value: '1965' },
            { text: '1964', value: '1964' },
            { text: '1963', value: '1963' },
            { text: '1962', value: '1962' },
            { text: '1961', value: '1961' },
            { text: '1960', value: '1960' },
            { text: '1959', value: '1959' },
            { text: '1958', value: '1958' },
            { text: '1957', value: '1957' },
            { text: '1956', value: '1956' },
            { text: '1955', value: '1955' },
            { text: '1954', value: '1954' },
            { text: '1953', value: '1953' },
            { text: '1952', value: '1952' },
            { text: '1951', value: '1951' },
            { text: '1950', value: '1950' },
            { text: '1949', value: '1949' },
            { text: '1948', value: '1948' },
            { text: '1947', value: '1947' },
            { text: '1946', value: '1946' },
            { text: '1945', value: '1945' },
            { text: '1944', value: '1944' },
            { text: '1943', value: '1943' },
            { text: '1942', value: '1942' },
            { text: '1941', value: '1941' },
            { text: '1940', value: '1940' },
            { text: '1939', value: '1939' },
            { text: '1938', value: '1938' },
            { text: '1937', value: '1937' },
            { text: '1936', value: '1936' },
            { text: '1935', value: '1935' },
            { text: '1934', value: '1934' },
            { text: '1933', value: '1933' },
            { text: '1932', value: '1932' },
            { text: '1931', value: '1931' },
            { text: '1930', value: '1930' },
          ],
        },
        {
          name: 'month',
          options: [
            { text: 'Jan', value: '0' },
            { text: 'Feb', value: '01' },
            { text: 'Mar', value: '02' },
            { text: 'Apr', value: '03' },
            { text: 'May', value: '04' },
            { text: 'Jun', value: '05' },
            { text: 'Jul', value: '06' },
            { text: 'Aug', value: '07' },
            { text: 'Sep', value: '08' },
            { text: 'Oct', value: '09' },
            { text: 'Nov', value: '10' },
            { text: 'Dec', value: '11' },
          ],
        },
        {
          name: 'day',
          options: [
            { text: '01', value: '01' },
            { text: '02', value: '02' },
            { text: '03', value: '03' },
            { text: '04', value: '04' },
            { text: '05', value: '05' },
            { text: '06', value: '06' },
            { text: '07', value: '07' },
            { text: '08', value: '08' },
            { text: '09', value: '09' },
            { text: '10', value: '10' },
            { text: '11', value: '11' },
            { text: '12', value: '12' },
            { text: '13', value: '13' },
            { text: '14', value: '14' },
            { text: '15', value: '15' },
            { text: '16', value: '16' },
            { text: '17', value: '17' },
            { text: '18', value: '18' },
            { text: '19', value: '19' },
            { text: '20', value: '20' },
            { text: '21', value: '21' },
            { text: '22', value: '22' },
            { text: '23', value: '23' },
            { text: '24', value: '24' },
            { text: '25', value: '25' },
            { text: '26', value: '26' },
            { text: '27', value: '27' },
            { text: '28', value: '28' },
            { text: '29', value: '29' },
            { text: '30', value: '30' },
            { text: '31', value: '31' },
          ],
        },
      ],
    });
    await picker.present();
  }

}

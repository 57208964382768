import { Component, OnInit } from '@angular/core';
import { Channel } from 'stream-chat';
import { ChannelService, DefaultStreamChatGenerics } from 'stream-chat-angular';
import { MessagingService } from '../../service/messaging.service';

@Component({
  selector: 'app-major-messaging',
  templateUrl: './major-messaging.component.html',
  styleUrls: ['./major-messaging.component.scss'],
})
export class MajorMessagingComponent implements OnInit {

  constructor(
    public messagingService: MessagingService,
    private channelService: ChannelService,
  ) { }

  ngOnInit() {
    this.messagingService.initialiseChat(0);
  }

  activateChannel(channel: Channel<DefaultStreamChatGenerics>) {
    this.channelService.setAsActiveChannel(channel);
  }

}

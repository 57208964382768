import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-basic-information',
  templateUrl: './basic-information.component.html',
  styleUrls: ['./basic-information.component.scss'],
})
export class BasicInformationComponent implements OnInit {

  isClicked = false;

  constructor() { }

  ngOnInit() { }

  toggleIntroMessage() {
    this.isClicked = !this.isClicked
  }

}

import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpService } from '../http/http.service';
import { FirebaseService } from 'src/app/welcome/services/firebase/firebase.service';
import { StorageService } from '../storage/storage.service';

@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  private reviews: BehaviorSubject<any>;

  constructor(
    private http: HttpService,
    private storageService: StorageService
  ) {
    this.reviews = new BehaviorSubject<any>([]);
  }

  setValue(newValue): void {
    this.reviews.next(newValue);
  }

  getValue(): Observable<any> {
    return this.reviews.asObservable();
  }

  getProfile() {
    return this.http.runHttpCall('GET', 'api/profile', 'application/json');
  }

  getAccountInfo() {
    return this.http.runHttpCall('GET', 'api/account', 'application/json');
  }

  putProfile(putRequest) {
    return this.http.runHttpCall('PUT', 'api/profile', 'application/json', putRequest);
  }

  getShoutSocial() {
    return this.http.runHttpCall('GET', 'api/shout/social', 'application/json');
  }

  getCreatorProfile() {
    console.log('>>>> getCreatorProfile(): ', this.storageService.firebaseUser);
    return this.http.runHttpCallUniCreateApi('GET', `creators/${this.storageService.firebaseUser.uid}`, 'application/json');
  }

  getShoutInterests() {
    return this.http.runHttpCall('GET', 'api/shoutinterests', 'application/json');
  }

  putShoutSocial(putRequest) {
    Object.keys(putRequest).forEach((key) => {
      if (putRequest[key] == null) {
        putRequest[key] = '';
      }
    });

    if (putRequest.tikTokFollowers == '' || putRequest.tikTokFollowers == null) {
      putRequest.tikTokFollowers = 0;
    }

    return this.http.runHttpCall('PUT', 'api/shout/social', 'application/json', putRequest);
  }

  postCreateCreator(postRequest) {
    return this.http.runHttpCallUniCreateApi('POST', 'creators', 'application/json', postRequest);
  }

  putCreateCreator(id: string, putRequest) {
    return this.http.runHttpCallUniCreateApi('PUT', `creators/${id}`, 'application/json', putRequest);
  }

  getUgcCreatorProfile() {
    return this.http.runHttpCallUniCreateApi('GET', `creators/${this.storageService.firebaseUser.uid}`, 'application/json');
  }

  getInviteAlerts() {
    return this.http.runHttpCall('GET', 'api/shout/invite-alerts', 'application/json');
  }

  putInviteAlerts(request) {
    return this.http.runHttpCall('PUT', 'api/shout/invite-alerts', 'application/json', request);
  }

  submitReferralCode(code: string) {
    return this.http.runHttpCall('POST', 'api/usercredit/referral', 'application/json', { code: code });
  }

  verifyStudenEmailSend(email: string) {
    return this.http.runHttpCall('POST', 'api/verification/email/send', 'application/json', {
      email: email,
    });
  }

  verifyStudenEmailCheck(email: string, code: string) {
    return this.http.runHttpCall('POST', 'api/verification/email/check', 'application/json', {
      email: email,
      code: code,
    });
  }

  sendSms(number: string) {
    return this.http.runHttpCall('POST', 'api/verification/sms/send', 'application/json', {
      number: number,
    });
  }

  verifySms(number: string, code: string) {
    return this.http.runHttpCall('POST', 'api/verification/sms/check', 'application/json', {
      number: number,
      code: code,
    });
  }

  getStudentProfie(offerId, offerType) {
    return this.http.runHttpCall(
      'GET',
      `api/profile/${offerType == 1 ? 'task' : 'service'}-offer/${offerId}`,
      'application/json'
    );
  }

  getServiceSeller(offerId) {
    return this.http.runHttpCall('GET', `api/profile/service/${offerId}`, 'application/json');
  }

  getUserbyId(userdId: number | string) {
    return this.http.runHttpCall('GET', `api/profile/user/${userdId}`, 'application/json');
  }

  getProfileByChannel(channelId: string) {
    return this.http.runHttpCall('GET', `api/profile/channel/${channelId}`, 'application/json');
  }

  deactivateAccount() {
    return this.http.runHttpCall('POST', `api/account/deactivate`, 'application/json', {});
  }

  getReviews(userId, skips, take?: number) {
    return this.http.runHttpCall('POST', `api/reviews/filter/user/${userId}`, 'application/json', {
      page: {
        take: take ? take : 3,
        skip: skips,
      },
      ratingGt: 0,
      ignoreServiceId: 0,
    });
  }
}

export interface ProfileReponse {
  bio: string;
  businessName: string;
  categoryRating: string[];
  cvTitle: string;
  cvUrl: string;
  dateOfBirth: string;
  email: string;
  firstName: string;
  graduationYear: number;
  hasFacebookVerification: boolean;
  hasGoogleVerification: boolean;
  homeTownLat: number;
  homeTownLocation: string;
  homeTownlon: number;
  hometown: string;
  instagramName: string;
  isBankInforVerified: boolean;
  isBusiness: boolean;
  isPaymentMethod: boolean;
  isStudent: boolean;
  isVerified: boolean;
  jobSuccessRate: number;
  languages: string;
  lastName: string;
  mobile: string;
  numberOfReview: number;
  profilePictureUrl: string;
  qualification: string;
  rating: number;
  recentTaskCompletedList: any[];
  review: any[];
  reviews: any[];
  servicesModel: any[];
  skills: string;
  study: string;
  studyType: string;
  studyYear: string;
  transportation: string;
  university: string;
  website: string;
  whoAreYou: string;
  hasLinkedInVerification: boolean;
  proBadge: boolean;
}


export interface CreateCreatorDto {
  blazeFirebaseUid: string;
  email: string;
  firstName: string;
  lastName: string;
  bio: string;
  photoURL: string;
  currencyCode: string;
  countryCode: string;
  homeTownLat: number;
  homeTownLng: number;
  homeTownName: string;
  uniTaskrRating: number;
  instaHandle: string;
  instaFollowers: number;
  tikTokHandle: string;
  tikTokFollowers: number;
  interests: string[];
  dateOfBirth: Date;
  ethnicGroup: number;
  gender: number;
  createdDate: Date;
  isActive: boolean;
  mobile: string;
}
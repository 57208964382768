import { Component, OnInit } from '@angular/core';
import { RegisterService } from '../service/register.service';
import { AlertController, LoadingController, ModalController } from '@ionic/angular';
import { awsImageUploadResponse, S3UploadService } from 'src/app/services/s3-upload/s3-upload.service';
import { ImageCropperPage } from 'src/app/image-cropper/image-cropper.page';

@Component({
  selector: 'app-manual-verification',
  templateUrl: './manual-verification.page.html',
  styleUrls: ['./manual-verification.page.scss'],
})
export class ManualVerificationPage implements OnInit {

  url: any = [];
  currentInput: any = '';
  defaultUserProfileUrl: string = '../../../assets/images/landing/photographer.png';
  manualPicture: string;
  activeImageUrl: any = null;

  constructor(private registerService: RegisterService, private alertController: AlertController, private modalCtrl: ModalController, private loadingController: LoadingController, private uploadService: S3UploadService) { }

  ngOnInit() {
  }

  async onFileSelected(event) {
    const loading = await this.loadingController.create({
      cssClass: 'my-custom-class',
      message: 'Uploading image...',
      duration: 10000
    });
    await loading.present();
    console.log(event.target.files[0]);
    this.currentInput = event.target.files[0];

    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();

      reader.readAsDataURL(event.target.files[0]); // read file as data url

      reader.onload = (event) => {
        this.activeImageUrl = event.target.result;
        this.uploadService.browserCompressUpload(this.activeImageUrl, 'manual-verification-documents').then((res: awsImageUploadResponse) => {
          console.log('the res: ', res);
          const result = this.registerService.verifyManually(res.key);
          result.subscribe(async (result) => {
            console.log('posted up correctly: ', result);
            const alert = await this.alertController.create({
              header: 'All Good',
              message: "You have successfully uploaded your image.",
              buttons: [
                {
                  text: "OK",
                  handler: () => {
                    this.goBack();
                  }
                }
              ]
            });
            await loading.dismiss();
            alert.present();
          }, async err => {
            console.log('Error posting up the picture: ', err);
            const alert = await this.alertController.create({
              header: 'Whoops!',
              message: "There was a problem uploading your image. Please try again.",
              buttons: [
                {
                  text: "OK",
                  handler: () => {
                    // this.goBack();
                  }
                }
              ]
            });
            await loading.dismiss();
            alert.present();
          });


        }).catch(err => {
          console.log('Catch: ', err);
          loading.dismiss();
        })

      };
    }
  }

  async goBack() {
    console.log('first')
    this.modalCtrl.dismiss({
      'dismissed': true
    });
  }

  async showCropOptions() {
    const modal = await this.modalCtrl.create({
      component: ImageCropperPage,
      componentProps: {
        imageQuality: 80
      }
    });
    modal.present();

    const { data, role } = await modal.onWillDismiss();

    if (data) {
      const loading = await this.loadingController.create({
        cssClass: 'my-custom-class',
        message: 'Uploading image...',
        duration: 10000
      });
      await loading.present();
      console.log('Dismiss data: ', data);
      console.log('Dismiss role: ', role);

      const base64Data = data.data.base64;
      const base64Response = await fetch(base64Data);
      const blob = await base64Response.blob();
      const newFile = new File([blob], new Date().getTime().toString(), { type: `image/${data.data.format}` })
      this.uploadService.browserCompressUpload(newFile, 'manual-verification').then((res: awsImageUploadResponse) => {
        console.log('nice console log: ', res);
        this.activeImageUrl = res.Location;
        const result = this.registerService.verifyManually(res.key);
        result.subscribe(async (result) => {
          console.log('posted up correctly: ', result);
          const alert = await this.alertController.create({
            header: 'All Good',
            message: "You have successfully uploaded your image.",
            buttons: [
              {
                text: "OK",
                handler: () => {
                  this.goBack();
                }
              }
            ]
          });
          await loading.dismiss();
          alert.present();
        }, async err => {
          console.log('Error posting up the picture: ', err);
          const alert = await this.alertController.create({
            header: 'Whoops!',
            message: "There was a problem uploading your image. Please try again.",
            buttons: [
              {
                text: "OK",
                handler: () => {
                  // this.goBack();
                }
              }
            ]
          });
          await loading.dismiss();
          alert.present();
        });
      }).catch(err => {
        console.log('Catch: ', err);
      })
    }

  }

}

import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { HttpService } from 'src/app/services/http/http.service';
import { StorageService } from 'src/app/services/storage/storage.service';

@Injectable({
  providedIn: 'root',
})
export class CvService {
  jobTypes: any;
  jobTypesUpdated = new Subject();

  constructor(private http: HttpService, private storageService: StorageService) {
    this.jobTypesUpdated.subscribe((value) => {
      this.jobTypes = value;
    });
  }

  getCvBuilder() {
    return this.http.runHttpCall('GET', 'api/cvbuilder', 'application/json');
  }

  getJobTypes() {
    return this.http.runHttpCall('GET', 'api/cvbuilder/user/job-type', 'application/json');
  }

  getBasicJobTypes() {
    return this.http.runHttpCall('GET', 'api/cvbuilder/user/job-type/0', 'application/json');
  }
  
  getUserJobTypes(userId) {
    return this.http.runHttpCall('GET', `api/cvbuilder/user/job-type/${userId}`, 'application/json');
  }

  getJobSkills(jobTypeId: number) {
    return this.http.runHttpCall('GET', `api/cvbuilder/skills/user/${jobTypeId}`, 'application/json');
  }

  getSkillsForJob(jobTypeId: number) {
    return this.http.runHttpCall('GET', `api/cvbuilder/skills/user/${jobTypeId}`, 'application/json');
  }

  viewCv(userId) {
    return this.http.runHttpCall('GET', `api/cvbuilder/view/${userId}`, 'application/json');
  }

  postSkills(skillsArray) {
    let newSkills = [];
    for (let s of skillsArray) {
      newSkills.push(`${s}`);
    }

    return this.http.runHttpCall('POST', 'api/cvbuilder/skills/user/job-type', 'application/json', {
      userId: `${this.storageService.uniTaskrUser.userId}`,
      userChkJobType: newSkills,
    });
  }

  addCustomSkill(jobTypeId: number, skill: string) {
    return this.http.runHttpCall('POST', `api/cvbuilder/skills/user/${jobTypeId}`, 'application/json', {
      jobSkillName: skill,
    });
  }

  getMyEducation() {
    return this.http.runHttpCall('GET', `api/cvbuilder/education`, 'application/json');
  }

  postEducation(eduReq) {
    return this.http.runHttpCall('POST', 'api/cvbuilder/education', 'application/json', eduReq);
  }

  deleteEducation(educationId: number) {
    return this.http.runHttpCall('DELETE', `api/cvbuilder/education/${educationId}`, 'application/json');
  }

  getMyExperience() {
    return this.http.runHttpCall('GET', `api/cvbuilder/experience`, 'application/json');
  }

  postExperience(expReq) {
    return this.http.runHttpCall('POST', 'api/cvbuilder/experience/user', 'application/json', expReq);
  }

  deleteExperience(workExperId: number) {
    return this.http.runHttpCall('DELETE', `api/cvbuilder/experience/user/${workExperId}`, 'application/json');
  }

  updateTaskReviewVisible(reviewId, isAdd) {
    return this.http.runHttpCall('POST', `api/cvbuilder/experience/review/task/${reviewId}`, 'application/json', {
      isAddToCv: isAdd,
    });
  }

  updateServiceReviewVisible(reviewId, isAdd) {
    return this.http.runHttpCall('POST', `api/cvbuilder/experience/review/service/${reviewId}`, 'application/json', {
      isAddToCv: isAdd,
    });
  }

  updateLookingFor(lookingForReq: { fulltime: boolean; partTime: boolean; temp: boolean }) {
    return this.http.runHttpCall('POST', 'api/cvbuilder/looking-for', 'application/json', lookingForReq);
  }

  updateCoverMessage(message: string) {
    return this.http.runHttpCall('POST', 'api/cvbuilder/cover', 'application/json', {
      coverMessage: message,
    });
  }

  updateBasicInfo(basicRequest) {
    return this.http.runHttpCall('POST', 'api/cvbuilder/basic', 'application/json', basicRequest);
  }

  updateQualifications(qualificationList) {
    return this.http.runHttpCall('POST', 'api/cvbuilder/qualifications', 'application/json', qualificationList);
  }

  updateLanguages(languageList) {
    return this.http.runHttpCall('POST', 'api/cvbuilder/languages', 'application/json', languageList);
  }

  addAttachment(attachmentRequest) {
    return this.http.runHttpCall('POST', 'api/cvbuilder/attachments/add', 'application/json', attachmentRequest);
  }

  removeAttachment(attachmentRequest) {
    return this.http.runHttpCall('POST', 'api/cvbuilder/attachments/remove', 'application/json', attachmentRequest);
  }

  getCvComplete() {
    return this.http.runHttpCall('GET', 'api/cvbuilder/completeness', 'application/json');
  }
}
export interface JobTypeResponse {
  createdDT: string;
  imageUrl: string;
  isInterested: boolean;
  jobIndustriesId: number;
  jobName: String;
  hasSkills: boolean;
}

import { Injectable, NgZone } from '@angular/core';
import { map, tap } from 'rxjs/operators';
import { HttpService } from 'src/app/services/http/http.service';
// import { ChatChannelService } from 'src/app/inbox/service/channel.service';
import { ApplicantsService } from 'src/app/task-manage/service/applicant.service';
import { Router } from '@angular/router';
import { AlertController, LoadingController, ModalController } from '@ionic/angular';
import { PriceChangeService } from 'src/app/chat/services/price-change/price-change.service';
import { StorageService } from 'src/app/services/storage/storage.service';
import { forkJoin } from 'rxjs';
import { TaskService } from 'src/app/home-earn/service/task.service';
import { ChatBlazeStore } from '../state/chat-blaze.store';

import {
  ChannelService
} from 'stream-chat-angular';
import { HireBlazePage } from 'src/app/hire-blaze/hire-blaze.page';

@Injectable({
  providedIn: 'root'
})
export class ChatBlazeService {

  /** Put the channel ID here for navigation */
  nav_channel_ID: string;

  constructor(
    private router: Router,
    private loadingController: LoadingController,
    private alertController: AlertController,
    private modalCtrl: ModalController,
    private http: HttpService,
    // private chatChannelService: ChatChannelService,
    private channelService: ChannelService,
    private applicantService: ApplicantsService,
    private priceChangeService: PriceChangeService,
    private storageService: StorageService,
    private taskService: TaskService,
  ) { }

  getChatHistory(channelId: string) {
    return this.http.runHttpCall(
      "POST",
      `api/channels/${channelId}/messages`,
      "application/json",
      {
        "take": 32767,
        "skip": 0
      }
    )
  }

  getConversationById(channelId: string) {
    return this.http.runHttpCall(
      "GET",
      `api/channels/${channelId}`,
      "application/json",
    )
  }

  addMessageToChannel(channelId: string, messageRequest: MessageRequest, sendNotification?: boolean) {
    return this.http.runHttpCall(
      "POST",
      `api/channels/${channelId}/messages/add${sendNotification ? '' : '?sendNotification=true'}`,
      "application/json",
      messageRequest
    );
  }

  getMessageById(channelId: string, messageId: string) {
    return this.http.runHttpCall(
      "GET",
      `api/channels/${channelId}/messages/${messageId}`,
      "application/json").pipe(tap(async msgRes => {
        let stored = await this.storageService.setAndString(`api/channels/${channelId}/messages/${messageId}`, msgRes);
        console.log('stored: ', stored);
      }))
  }


  getMessagesObs(channelId: string, messageId: string) {
    let msg = this.checkStorage(channelId, messageId)
    return msg;
  }

  checkStorage(channelId: string, messageId: string) {
    return this.storageService.getAndParseObservable(`api/channels/${channelId}/messages/${messageId}`).pipe(map(msgRes => {
      if (msgRes) {
        return msgRes
      } else {
        return false;
      }
    }))

  }

  async hireStudent(taskId, taskOfferId, modalMode?: boolean) {
    console.log('hireStudent()');
    console.log('hireStudent() taskId: ', taskId);
    console.log('hireStudent() taskOfferId: ', taskOfferId);
    this.router.navigate([`/hire-blaze/${taskId}/${taskOfferId}`]);
  }

  payStudent(taskId, channelId: string) {
    console.log('payStudent()');
    console.log('payStudent() taskId: ', taskId);
    console.log('payStudent() taskOfferId: ', channelId);
    // this.applicantService.focussedApplicant = this.chatChannelService.focussedApplicant;
    // this.applicantService.focussedTask = this.chatChannelService.focussedTask;

    this.router.navigate([`/pay-blaze/${taskId}/${channelId}`]);
  }

  async markComplete(channel_id) {
    const loading = await this.loadingController.create({
      cssClass: 'my-custom-class',
      message: 'Marking complete...',
      duration: 7000
    });
    await loading.present();
    console.log('markComplete() SERVICE');

    return this.taskService.markComplete(channel_id).subscribe(async markCompleteRes => {
      console.log('markCompleteRes: ', markCompleteRes);
      // this.chatChannelService.focussedApplicant = markCompleteRes;

      const message = await this.channelService.sendMessage(
        `<b>@${this.storageService.uniTaskrUser.firstName}</b> has marked the task <b>complete</b>.`,
      );
      loading.dismiss();

      return { complete: true, error: undefined };


    }, async err => {
      console.log('markCompleteRes error: ', err);
      loading.dismiss();

      if (err.error.includes('Outstanding shout form')) {
        const alert = await this.alertController.create({
          header: 'Whoops!',
          message: 'Please upload all of your results before marking the SHOUT',
          buttons: ['OK'],
        });

        await alert.present();
        return { complete: false, error: 'incomplete shout results' };
      }
    })
  }

  async rebookRequest(channel_id) {
    console.log('rebookRequest()');

    const loading = await this.loadingController.create({
      cssClass: 'my-custom-class',
      message: 'Requesting...',
      duration: 7000
    });
    await loading.present();

    // if (this.chatChannelService.focussedConversation.linkedEntity.linkedEntityType === 1) {

    //   this.applicantService.rebook(this.chatChannelService.focussedApplicant.taskOfferId).subscribe(rebookRes => {
    //     console.log('rebookRes: ', rebookRes);

    //     let messageRequest = {
    //       id: null,
    //       isDeleted: false,
    //       createdDate: (new Date()).toISOString(),
    //       channelId: channel_id,
    //       message: "Re-Booking Request",
    //       data: JSON.stringify({
    //         type: "rebook_request",
    //         newOffer: rebookRes
    //       }),
    //       userId: this.storageService.uniTaskrUser.userId
    //     }

    //     this.addMessageToChannel(channel_id, messageRequest, false).subscribe(() => {
    //       console.log('sent message');
    //       loading.dismiss();
    //     })
    //   }, err => {
    //     console.log('rebookRes error: ', err);
    //   });

    // } 
    // else if (this.chatChannelService.focussedConversation.linkedEntity.linkedEntityType === 2) {
    //   this.applicantService.rebookService(this.chatChannelService.focussedApplicant.serviceOfferId).subscribe(rebookRes => {
    //     console.log('rebookRes Service: ', rebookRes);

    //     let messageRequest = {
    //       id: null,
    //       isDeleted: false,
    //       createdDate: (new Date()).toISOString(),
    //       channelId: channel_id,
    //       message: "Re-Booking Request",
    //       data: JSON.stringify({
    //         type: "rebook_request",
    //         newOffer: rebookRes
    //       }),
    //       userId: this.storageService.uniTaskrUser.userId
    //     }

    //     this.addMessageToChannel(channel_id, messageRequest, false).subscribe(() => {
    //       console.log('sent message');
    //       loading.dismiss();
    //     })
    //   }, err => {
    //     console.log('rebookRes error: ', err);
    //   });
    // }
  }

  async requestPriceChange(price, channel_id) {
    const loading = await this.loadingController.create({
      cssClass: 'my-custom-class',
      message: 'Requesting...',
      duration: 7000
    });
    await loading.present();

    // if (this.chatChannelService.focussedConversation.linkedEntity.linkedEntityType === 1) {
    //   console.log('Price req: ', this.chatChannelService.focussedApplicant.taskOfferId);
    //   console.log('Price proposal: ', price);

    //   this.priceChangeService.requestPriceChange(this.chatChannelService.focussedApplicant.taskOfferId, price).subscribe(priceRes => {
    //     console.log('priceRes: ', priceRes);

    //     let messageRequest = {
    //       id: null,
    //       isDeleted: false,
    //       createdDate: (new Date()).toISOString(),
    //       channelId: channel_id,
    //       message: "Price Change Request",
    //       data: JSON.stringify({
    //         type: "price_change_request",
    //         newPrice: price
    //       }),
    //       userId: this.storageService.uniTaskrUser.userId
    //     }

    //     this.addMessageToChannel(channel_id, messageRequest, false);

    //   });
    // }
    // else if (this.chatChannelService.focussedConversation.linkedEntity.linkedEntityType === 2) {
    //   this.priceChangeService.requestPriceChangeService(this.chatChannelService.focussedApplicant.serviceOfferId, price).subscribe(priceRes => {
    //     console.log('priceRes: ', priceRes);
    //   });
    // }
  }

  async sendStudentHiredMessage(channel_id) {
    let messageRequest = {
      id: null,
      isDeleted: false,
      createdDate: (new Date()).toISOString(),
      channelId: channel_id,
      message: "Hired",
      data: JSON.stringify({
        type: "hire_student",
      }),
      userId: this.storageService.uniTaskrUser.userId
    }

    this.addMessageToChannel(channel_id, messageRequest, false).subscribe(messageSent => {
      console.log('messageSent: ', messageSent);
    })
  }

  sendStudentPaidMessage(channel_id) {
    let messageRequest = {
      id: null,
      isDeleted: false,
      createdDate: (new Date()).toISOString(),
      channelId: channel_id,
      message: "Paid",
      data: JSON.stringify({
        type: "pay_student",
      }),
      userId: this.storageService.uniTaskrUser.userId
    }

    this.addMessageToChannel(channel_id, messageRequest, false).subscribe(messageSent => {
      console.log('messageSent: ', messageSent);
    })
  }


  getTaskOrServiceById(itemId: string) {
    return forkJoin({
      taskRequest: this.http.runHttpCall(
        "GET",
        `api/tasks/user/${itemId}`,
        "application/json"),
      serviceRequest: this.http.runHttpCall(
        "GET",
        `api/services/user/${itemId}`,
        "application/json")
    })
  }

  getChattingWithID() {
    let foundOtherUser: boolean = false;
    // if (this.chatChannelService.focussedConversation && this.chatChannelService.focussedConversation.users.length) {
    //   for (let user of this.chatChannelService.focussedConversation.users) {
    //     if (user.userId !== this.storageService.uniTaskrUser.userId) {
    //       return user.userId
    //     }
    //   }

    //   if (!foundOtherUser) {
    //     return this.chatChannelService.focussedConversation.adminUsers[0].userId
    //   }
    // }
  }

  getTaskReviewStatus(taskOfferId: string) {
    return this.http.runHttpCall(
      "GET",
      `api/reviews/task-offer/${taskOfferId}/has-reviewed`,
      "application/json")
  }

  getServiceReviewStatus(serviceOfferId: string) {
    return this.http.runHttpCall(
      "GET",
      `api/reviews/service-offer/${serviceOfferId}/has-reviewed`,
      "application/json")
  }
}

export interface MessageRequest {
  id: string,
  isDeleted: boolean,
  createdDate: string,
  channelId: string,
  message: string,
  data: string,
  userId: number
}

export interface MessageResponse {
  channelId: string,
  createdDate: string,
  data: string,
  id: string,
  isDeleted: boolean,
  message: string,
  userId: number
  adminUserId: number,
  firstName: string,
}



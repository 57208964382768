import { Component, Input, OnInit } from '@angular/core';
import { CurrencyService } from 'src/app/services/currency/currency.service';
import { RecruitService } from '../../services/recruit/recruit.service';
import DOMPurify from "dompurify";
import { DomSanitizer } from '@angular/platform-browser';
import { CountryService } from 'src/app/services/country/country.service';
import { FirebaseService } from 'src/app/services/firebase/firebase.service';
import { ErrorLogService } from '../../../error-logs/service/error-log.service';
import { Router } from '@angular/router';


@Component({
  selector: 'app-horiz-recent-roles',
  templateUrl: './horiz-recent-roles.component.html',
  styleUrls: ['./horiz-recent-roles.component.scss'],
})
export class HorizRecentRolesComponent implements OnInit {

  /** Are we showing recent jobs or my jobs? */
  @Input('isMyRoles') isMyRoles: boolean | string = true;

  /** Label for which roles we're showing */
  @Input('headingText') headingText: string = "My Jobs"

  /** List of recent roles */
  recentRoles;

  /** My Currency Code */
  @Input('myCurrencyCode') myCurrencyCode: string;

  /** My Conversion Rate if I am from USA */
  myConversionRateUsd: Object;

  /** My Conversion Rate if I am from UK */
  myConversionRateUk: Object;

  constructor(
    private router: Router,
    private recruitService: RecruitService,
    private currencyService: CurrencyService,
    private sanitizer: DomSanitizer,
    private countryService: CountryService,
    private firebaseService: FirebaseService,
    private logService: ErrorLogService
  ) { }

  ngOnInit() {
    this.getRecentRoles();
    this.getMyRates();

    this.recruitService.shouldRefresh.subscribe(refresh => {
      if (refresh.shouldRefresh) {
        this.getRecentRoles(refresh.spinner);
      }
    })
  }

  getRecentRoles(ev?) {
    if (this.isMyRoles == true && this.firebaseService.myProfile) {
      this.recruitService.getMyRoles().subscribe(myRecruitRes => {
        console.log('myRecruitRes: ', myRecruitRes);
        this.recentRoles = myRecruitRes.data;
        for (let roles of this.recentRoles) {
          if (roles.locationType == 1 && roles.location !== '') {
            this.countryService.getShortAddress(roles.lat, roles.lon).subscribe((res: any) => {
              roles.location = res.results[0].address_components[2].short_name;
            })
          }
        }
        if (ev) ev.target.complete();
        this.recruitService.shouldRefresh.next({
          shouldRefresh: false,
          spinner: undefined
        });
        console.log('done 1');
      }), err => {
        if (ev) ev.target.complete();
        console.log('myRecruitRes error: ', err);
        this.logService.addLog(`myRecruitRes error ${JSON.stringify(err)}`, this.router.url);
        this.logService.addLogFirebase(JSON.stringify(err), this.router.url, 4);
        this.logService.addCodeLogFirebase(JSON.stringify(err), this.router.url, 4);
      };
    } else if (this.isMyRoles == true && !this.firebaseService.myProfile) {
      this.recentRoles = [];
    } else {
      this.recruitService.getRecentRoles().subscribe(recruitRes => {
        this.recentRoles = recruitRes.data;
        for (let roles of this.recentRoles) {
          if (roles.locationType == 1 && roles.location !== '') {
            this.countryService.getShortAddress(roles.lat, roles.lon).subscribe((res: any) => {
              roles.location = res.results[0].address_components[2].short_name;
            })
          }
        }
        if (ev) ev.target.complete();
        this.recruitService.shouldRefresh.next({
          shouldRefresh: false,
          spinner: undefined
        });
      }), err => {
        if (ev) ev.target.complete();
        console.log('recruitRes error: ', err);
        this.logService.addLog(`recruitRes error ${JSON.stringify(err)}`, this.router.url);
        this.logService.addLogFirebase(JSON.stringify(err), this.router.url, 5);
        this.logService.addCodeLogFirebase(JSON.stringify(err), this.router.url, 5);
      };
    }
  }

  getMyRates() {
    this.currencyService.getConversionRate('USD', 'GBP').subscribe(rateRes => {
      // console.log('$ > £ rate res: ', rateRes);
      this.myConversionRateUk = rateRes.rate;
    });
    this.currencyService.getConversionRate('GBP', 'USD').subscribe(rateRes => {
      // console.log('£ > $ rate res: ', rateRes);
      this.myConversionRateUsd = rateRes.rate;
    })
  }

  getSanitized(htmlString: string) {
    let output = htmlString.replace("\n", "<br> <br>");
    const sanitizedContent = DOMPurify.sanitize(output);
    return this.sanitizer.bypassSecurityTrustHtml(sanitizedContent);
  }

  clickWholeCardAction(role) {

  }

  whichRate(taskCode) {
    if (taskCode = '') {
      taskCode = 'GBP';
    }
    if (this.myCurrencyCode == taskCode) {
      return 1;
    } else if (this.myCurrencyCode !== taskCode && this.myCurrencyCode == 'GBP') {
      return this.myConversionRateUk;
    } else if (this.myCurrencyCode !== taskCode && this.myCurrencyCode == 'USD') {
      return this.myConversionRateUsd;
    }
  }
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActionSheetController, LoadingController, ModalController } from '@ionic/angular';
import { awsImageUploadResponse, S3UploadService } from 'src/app/services/s3-upload/s3-upload.service';

import { environment } from 'src/environments/environment';
import { CameraService } from 'src/app/services/camera/camera.service';
import { TaskService } from 'src/app/home-earn/service/task.service';
import { Subscription } from 'rxjs';
import { FullWidthViewerComponent } from '../full-width-viewer/full-width-viewer.component';
import { GallerySwiperComponent } from '../gallery-swiper/gallery-swiper.component';

@Component({
  selector: 'app-gallery-view',
  templateUrl: './gallery-view.component.html',
  styleUrls: ['./gallery-view.component.scss'],
})
export class GalleryViewComponent implements OnInit {
  /** Should send in a list of image URLs */
  @Input('imageList') existingImageList: { name: string; key?: string; url?: string; isSelected?: boolean }[] = [];

  /** Are we in view only mode? */
  @Input('viewOnly') viewOnly: boolean | string = false;

  /** Tell the consumer the list of images.. */
  @Output('listOfUploads') listOfUploads = new EventEmitter();

  /** Which image should be highlighted? */
  focussedImage: { name: string; key?: string; url?: string };

  selectedFiles: FileList;

  /** URL of the uploadedImage */
  imageUrl: string;

  /** How many images have we updloaded? */
  uploadCount: number = 0;

  subscription: Subscription;

  constructor(
    private loadingController: LoadingController,
    private actionSheetController: ActionSheetController,
    private uploadService: S3UploadService,
    private cameraService: CameraService,
    private taskService: TaskService,
    private modalCtrl: ModalController
  ) {}

  ngOnInit() {
    console.log('ngOnInit(): ', this.existingImageList);
    if (this.existingImageList && this.existingImageList.length) {
      this.focussedImage = this.existingImageList[0];
      this.existingImageList.forEach((img) => (img.isSelected = img === this.focussedImage));
    } else {
      // this.focussedImage = "https://upload.wikimedia.org/wikipedia/commons/thumb/4/44/Jelly_cc11.jpg/440px-Jelly_cc11.jpg"
    }
    this.subscription = this.taskService.clearAll().subscribe((message) => {
      this.clearImages();
    });
  }

  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    this.subscription.unsubscribe();
  }

  focusImage(image) {
    console.log('focusImage(): ', image);
    this.focussedImage = image;
    this.setSelected();
  }

  setSelected() {
    this.existingImageList.forEach((img) => (img.isSelected = img === this.focussedImage));
  }

  getImageSource(image, location: string) {
    // console.log('getImageSource(): ', image);
    if (
      (image && typeof image == 'string' && image.includes('http')) ||
      (image && image.key && typeof image.key == 'string' && image.key.includes('http'))
    ) {
      if (image.key) {
        return image.key;
      } else {
        return image;
      }
    } else if (image == 'string' || image == '') {
      return '../../../assets/images/placeholder.jpeg';
    } else {
      let availWidth = screen.availWidth;

      if (availWidth > 250) {
        availWidth = 240;
      }

      let imageWidth: number;
      let imageHeight: number;
      if (location === 'focussed') {
        imageWidth = 2 * 0.95 * availWidth;
        imageHeight = 2 * 240;
      } else if (location === 'gallery') {
        imageWidth = 106;
        imageHeight = 74;
      }

      let cdnRequestObject = {
        bucket: 'unitaskr-web-cdn',
        key: image && image.key ? image.key : image && image.url ? image.url : image, // "Screenshot 2021-11-17 at 11.10.24.png",
        edits: {
          resize: {
            // width: imageWidth,
            // height: imageHeight,
            fit: 'cover',
          },
        },
      };

      let base64 = btoa(JSON.stringify(cdnRequestObject));
      return `${environment.uniTaskrApi.cdnBaseUrl}${base64}`;
    }
  }

  async takePicture(type: string) {
    this.cameraService.takePicture(type).then(async (image) => {
      const base64Data = image.base64String;
      const base64Response = await fetch(`data:image/${image.format};base64,${base64Data}`);
      const blob = await base64Response.blob();
      const newFile = new File([blob], new Date().getTime().toString(), { type: `image/${image.format}` });
      this.uploadService
        .browserCompressUpload(newFile, 'cover-images')
        .then((res: awsImageUploadResponse) => {
          console.log('Res: ', res);
          this.existingImageList.push({ name: res.key, key: res.key });
          this.focussedImage = { name: res.key, key: res.key };
          this.focusImage({ name: res.key, key: res.key });
          this.selectedFiles = undefined;
          this.listOfUploads.emit(this.existingImageList);
        })
        .catch((err) => {
          console.log('Catch: ', err);
        });
    });
  }

  async upload() {
    const loading = await this.loadingController.create({
      cssClass: 'my-custom-class',
      message: 'Uploading image...',
      duration: 10000,
    });
    await loading.present();

    const file = this.selectedFiles.item(0);
    console.log('This is the file >>>>>>>>>>>>>>>>>>>', file);

    // this.uploadService.zipAndUpload(file, 'zip-files').then((res: awsImageUploadResponse) => {
    this.uploadService
      .browserCompressUpload(file, 'cover-images')
      .then((res: awsImageUploadResponse) => {
        console.log('Res: ', res);

        this.existingImageList.push({ name: res.key, key: res.key });
        this.focussedImage = { name: res.key, key: res.key };
        this.setSelected();
        loading.dismiss();
        this.selectedFiles = undefined;
        this.listOfUploads.emit(this.existingImageList);
      })
      .catch((err) => {
        console.log('Catch: ', err);
        loading.dismiss();
      });
  }

  selectFile(event) {
    this.selectedFiles = event.target.files;
    this.upload();
  }

  async showAttachmentOptions() {
    // this.hideFooter = true;
    const actionSheet = await this.actionSheetController.create({
      // header: 'Albums',
      cssClass: 'left-align-buttons',
      buttons: [
        {
          text: 'Camera',
          icon: 'camera-outline',
          handler: () => {
            this.takePicture('camera');
          },
        },
        {
          text: 'Photo & Video Library',
          icon: 'image-outline',
          handler: () => {
            this.takePicture('photos');
          },
        },
        {
          text: 'Cancel',
          role: 'cancel',
          handler: () => {
            // this.hideFooter = false;
          },
        },
      ],
    });
    await actionSheet.present();
  }

  async showFullScreen() {
    const modal = await this.modalCtrl.create({
      component: GallerySwiperComponent,
      componentProps: {
        images: this.existingImageList,
      },
    });
    await modal.present();
  }

  deleteImage(focussedImage) {
    let index = this.existingImageList.indexOf(focussedImage);
    this.existingImageList.splice(index, 1);

    this.focussedImage = this.existingImageList[0] ? this.existingImageList[0] : undefined;
  }

  deleteImageFromList(index) {
    this.existingImageList.splice(index, 1);
    this.focussedImage = this.existingImageList[0] ? this.existingImageList[0] : undefined;
  }

  clearImages() {
    this.existingImageList = [];
    this.focussedImage = undefined;
  }
}

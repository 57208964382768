import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-experience-card',
  templateUrl: './experience-card.component.html',
  styleUrls: ['./experience-card.component.scss'],
})
export class ExperienceCardComponent implements OnInit {

  @Input('record') record;

  expanded: boolean = false;

  constructor() { }

  ngOnInit() {}

  getEmploymentType(employmentType) {
    switch (employmentType) {
      case 1:
        return 'Freelancer';
      case 2:
        return 'Full Time';
      case 3:
        return 'Part Time';
      case 4:
        return 'Trainee';
      case 5:
        return 'Internship';
      case 6:
        return 'Temp';
    }
  }

}

import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { AlertController, LoadingController, PopoverController } from '@ionic/angular';
import { ChatBlazeService } from 'src/app/chat-blaze/service/chat-blaze.service';
import { TaskService } from 'src/app/home-earn/service/task.service';
// import { ChatChannelService } from 'src/app/inbox/service/channel.service';
import { ActivityResponse } from 'src/app/my-activity/service/activity.service';
import { ManageTaskMoreOptionsComponent } from 'src/app/popovers/manage-task-more-options/manage-task-more-options.component';
import { StorageService } from 'src/app/services/storage/storage.service';
import { ApplicantsService } from 'src/app/task-manage/service/applicant.service';
import DOMPurify from 'dompurify';
import { decode } from 'html-entities';

@Component({
  selector: 'app-activity-item',
  templateUrl: './activity-item.component.html',
  styleUrls: ['./activity-item.component.scss'],
})
export class ActivityItemComponent implements OnInit {
  /** Which item are we displaying? */
  @Input('activityItem') focussedItem: ActivityResponse | any;

  /** Is this in the 'bought' section? */
  @Input('type') type: string;

  /** Which filter is selected? */
  // @Input('whichFilter') whichFilter: string;

  /** Have we got a focussed student? */
  focussedStudent;

  jobType = ['FULL TIME', 'PART TIME', 'INTERNSHIP', 'TASK'];

  constructor(
    private router: Router,
    private popoverController: PopoverController,
    private loadingController: LoadingController,
    private alertController: AlertController,
    private taskService: TaskService,
    // private channelService: ChatChannelService,
    private chatService: ChatBlazeService,
    private applicantService: ApplicantsService,
    private storageService: StorageService,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit() {
    // console.log('888888888 focussedItem: ', this.focussedItem);
  }

  getItemType(): string {
    if (this.focussedItem.isService) {
      return 'service';
    } else {
      return 'task';
    }
  }

  openQuestions() {
    console.log('openQuestions()');
    this.router.navigate([`task-questions/${this.focussedItem.taskId}`]);
  }

  openApplicants() {
    console.log('openApplicants(): ', this.focussedItem);

    if (this.focussedItem.isDraft) {
      this.router.navigate([`/new-create-task/${this.focussedItem.taskId}_e`]);
    } else {
      this.router.navigate([`task-manage/${this.focussedItem.taskId}/applied`]);
    }
  }
  openHired() {
    console.log('openHired(): ', this.focussedItem);
    this.router.navigate([`task-manage/${this.focussedItem.taskId}/hired`]);
  }
  openPaid() {
    console.log('openPaid(): ', this.focussedItem);
    this.router.navigate([`task-manage/${this.focussedItem.taskId}/completed`]);
  }

  getMoreContinue() {
    if (this.focussedItem.isDraft) {
      return 'Continue';
    } else {
      return 'More';
    }
  }

  async pause() {
    const loading = await this.loadingController.create({
      cssClass: 'my-custom-class',
      message: 'Please wait...',
      duration: 2000,
    });
    await loading.present();
    let value;
    if (this.focussedItem.isPaused) {
      value = false;
    } else {
      value = true;
    }

    this.taskService.updatePause(value, this.focussedItem.taskId).subscribe(
      (updateRes) => {
        console.log('updateRes: ', updateRes);
        this.focussedItem = updateRes;
        loading.dismiss();
      },
      (err) => {
        console.log('updateRes error: ', err);
        loading.dismiss();
      }
    );
  }

  goToJobOrTask(type: string) {
    if (this.focussedItem.jobType == 4) {
      this.router.navigate([`/new-create-task/${this.focussedItem.taskId + type}`]);
    } else {
      this.router.navigate([`/create-job-form/${this.focussedItem.taskId + type}`]);
    }
  }

  async openOptionsPopover(ev) {
    if (this.focussedItem.isDraft) {
      if (this.focussedItem.jobType == 4) this.router.navigate([`/new-create-task/${this.focussedItem.taskId}_e`]);
      else this.router.navigate([`/create-job-form/${this.focussedItem.taskId}_e`]);
    } else {
      if (this.getItemType() == 'service') {
        this.router.navigate([`view-service/${this.focussedItem.serviceId}`]);
      } else {
        const popover = await this.popoverController.create({
          component: ManageTaskMoreOptionsComponent,
          cssClass: 'task_more_options_popover',
          event: ev,
          mode: 'ios',
          translucent: false,
          componentProps: {
            questionCount: this.focussedItem.questions,
            isPaused: this.focussedItem.isPaused,
            taskType: this.focussedItem.jobType,
          },
          size: 'auto',
          dismissOnSelect: true,
          // align: "center",
          reference: 'event',
        });
        await popover.present();

        popover.onDidDismiss().then((result) => {
          console.log('onDidDismiss resolved with role', result);

          switch (result.data.type) {
            case 'questions':
              this.router.navigate([`/task-questions/${this.focussedItem.taskId}`]);
              break;
            case 'edit':
              this.goToJobOrTask('_e');
              break;
            case 'postSimilar':
              this.goToJobOrTask('_s');
              break;
            case 'view':
              this.router.navigate([`/task-profile/${this.focussedItem.taskId}`]);
              break;
            case 'pause':
              this.pause();
              break;
            case 'cancel':
              if (this.focussedItem.hired == 0 && this.focussedItem.paid == 0) {
                this.cancelTask();
              } else {
                this.cannotCancelTask();
              }
              break;
          }
        });
      }
    }
  }

  getTaskColor() {
    switch (this.focussedItem.jobType) {
      case 1:
        return 'tertiary';
      case 2:
        return 'success';
      case 3:
        return 'warning';
      case 4:
        return 'primary';
    }
  }

  getJobType() {
    switch (this.focussedItem.jobType) {
      case 1:
        return 'Full Time';
      case 2:
        return 'Part Time';
      case 3:
        return 'Internship';
      case 4:
        return 'Task';
    }
  }

  async cancelTask() {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: `Cancel ${this.focussedItem.jobType == 4 ? 'Task' : 'Job'}?`,
      message: `Are you sure that you want to cancel this ${this.focussedItem.jobType == 4 ? 'task' : 'job'}? Your ${
        this.focussedItem.jobType == 4 ? 'task' : 'job'
      } will be unavailable on the ${this.focussedItem.jobType == 4 ? 'task' : 'job'} board.`,
      buttons: [
        {
          text: 'No',
          role: 'cancel',
          cssClass: 'secondary',
          id: 'cancel-button',
          handler: (blah) => {
            console.log('Confirm Cancel: blah');
          },
        },
        {
          text: `Yes, cancel ${this.focussedItem.jobType == 4 ? 'task' : 'job'}`,
          id: 'confirm-button',
          handler: () => {
            console.log('Confirm Okay');
            this.taskService.deleteTask(this.focussedItem.taskId).subscribe(
              (delRes) => {
                console.log('delRes: ', delRes);
                this.focussedItem = delRes;
              },
              (err) => {
                console.log('delRes error: ', err);
              }
            );
          },
        },
      ],
    });

    await alert.present();
  }

  async cannotCancelTask() {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'Whoops!',
      message: 'You are not permitted to cancel a task when someone is already hired. Please contact support.',
      buttons: ['OK'],
    });

    await alert.present();
  }

  rebook(ev) {
    console.log('rebook(): ', ev);
    // get the channel ID;
    // this.channelService.getLinkedConversation(ev.serviceOfferId, 2).subscribe(
    //   (linkedConvoRes) => {
    //     console.log('linkedConvoRes: ', linkedConvoRes);

    //     this.applicantService.rebookService(ev.serviceOfferId).subscribe(
    //       (rebookRes) => {
    //         console.log('rebookRes Service: ', rebookRes);

    //         let messageRequest = {
    //           id: null,
    //           isDeleted: false,
    //           createdDate: new Date().toISOString(),
    //           channelId: linkedConvoRes.id,
    //           message: 'Re-Booking Request',
    //           data: JSON.stringify({
    //             type: 'rebook_request',
    //             newOffer: rebookRes,
    //           }),
    //           userId: this.storageService.uniTaskrUser.userId,
    //         };

    //         this.chatService.addMessageToChannel(linkedConvoRes.id, messageRequest, false).subscribe(async () => {
    //           console.log('sent message');

    //           const alert = await this.alertController.create({
    //             cssClass: 'my-custom-class',
    //             header: 'Done!',
    //             message: 'You have requested a re-booking!',
    //             buttons: [
    //               {
    //                 text: 'Okay',
    //                 id: 'confirm-button',
    //                 handler: () => {
    //                   console.log('Confirm Okay');
    //                   this.storageService.set('nav_to_chat_from', 'my-activity-buying').then((set) => {
    //                     // this.router.navigate([`/chat-blaze/${linkedConvoRes.id}`]);
    //                     this.router.navigate([`/messaging/${this.channelService.focussedApplicant.taskOfferId}`]);
    //                   });
    //                 },
    //               },
    //             ],
    //           });

    //           await alert.present();
    //         });
    //       },
    //       async (err) => {
    //         console.log('rebookRes error: ', err);
    //         const alert = await this.alertController.create({
    //           cssClass: 'my-custom-class',
    //           header: 'Whoops!',
    //           message: 'You have already requested a re-booking. Please check the chat.',
    //           buttons: ['OK'],
    //         });

    //         await alert.present();
    //       }
    //     );
    //   },
    //   (err) => {
    //     console.log('getLinkedConversation: ', err);
    //   }
    // );
    // then rebook: chatService.rebookRequest(channel_id)
  }

  getSanitized(htmlString: string) {
    let output = htmlString.replace('\n', '<br /> <br>');
    const sanitizedContent = DOMPurify.sanitize(output);
    return this.sanitizer.bypassSecurityTrustHtml(sanitizedContent);
  }
}

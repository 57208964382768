import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController } from '@ionic/angular';
import { initializeApp } from 'firebase/app';
import { arrayUnion, collection, doc, getDoc, getFirestore, onSnapshot, query, updateDoc } from 'firebase/firestore';
import { TaskService } from 'src/app/home-earn/service/task.service';
import { ShoutBriefService } from 'src/app/services/create-api/shout-brief-service/shout-brief.service';
import { ImageService } from 'src/app/services/image/image.service';
import { StorageService } from 'src/app/services/storage/storage.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-student-inbox-item',
  templateUrl: './student-inbox-item.component.html',
  styleUrls: ['./student-inbox-item.component.scss'],
})
export class StudentInboxItemComponent implements OnInit {

  @Input('task') task: any;

  amCreator: boolean | undefined;
  amHirer: boolean | undefined = true;

  /** Who am I chatting to? */
  chattingToId: string | undefined;
  chattingToUser: any;

  app = initializeApp(environment.firebaseConfig);
  db = getFirestore(this.app);

  detailedTaskOffer: any;
  focussedTask: any;

  channelReference: any;

  latestMessage: string = 'No chat...'

  chat: any;

  constructor(
    private router: Router,
    private alertController: AlertController,
    private storageService: StorageService,
    public imageService: ImageService,
    private taskService: TaskService,
    private shoutBriefService: ShoutBriefService
  ) { }

  ngOnInit() {
    // this.getChattingTo();
    console.log('<app-student-inbox-item></app-student-inbox-item>');
    console.log('Who am i? ', this.storageService.uniTaskrUser.userId);

    this.initChannelWatch()
  }

  async openChat() {
    console.log('openChat()');
    console.log('chat: ', this.chat);
    console.log('task: ', this.task);
    if (this.storageService.firebaseUser.uid && this.chat !== undefined) {
      if (this.chat.readBy && (this.chat.readBy.indexOf(this.storageService.firebaseUser.uid) == -1)) {
        const chatRef = doc(this.db, environment.firebase_chat.channels, `${this.task.offerId}`);
        await updateDoc(chatRef, {
          readBy: arrayUnion(this.storageService.firebaseUser.uid)
        })
      }

      console.log('nav: ', `/chat/${this.task.offerId}`);
      this.router.navigate([`/chat/${this.task.offerId}`]);
    } else {
      const alert = await this.alertController.create({
        header: 'One second..',
        message: `Please wait for ${this.task.firstName} to start the chat.`,
        buttons: ['Okay'],
      });

      await alert.present();
    }
  }

  async initChannelWatch() {
    console.log('initChannelWatch(): ', this.task.offerId);
    this.channelReference = collection(this.db, environment.firebase_chat.channels);

    this.channelReference = doc(this.db, environment.firebase_chat.channels, `${this.task.offerId}`);
    const docSnap = await getDoc(this.channelReference);

    if (docSnap.exists()) {
      console.log("Chat Channel data:", docSnap.data());
      this.chat = docSnap.data();
      this.latestMessage = docSnap.data()['last_message'];
      this.listenToChatChanges(this.channelReference);
    }
    else {
      console.log("No such document!");
    }
  }

  async listenToChatChanges(chatChannelReference: any) {
    console.log('>>> listenToChatChanges() <<<: ', chatChannelReference);
    const unsub = onSnapshot(doc(this.db, environment.firebase_chat.channels, `${this.task.offerId}`), (doc) => {
      console.log("Current data: ", doc.data());
      this.chat = doc.data()
    });
  }

  getTaskApplication() {
    let chatId = this.task.offerId;

  }

  getShoutApplication(chatId: string) {
    this.shoutBriefService.getShoutBriefApplication(chatId).subscribe(shoutAppRes => {
      console.log('shoutAppRes: ', shoutAppRes);
      this.detailedTaskOffer = shoutAppRes;
      this.getShout();
    })
  }

  getTask() {
    this.taskService.getTaskById(this.detailedTaskOffer.taskId).subscribe(completeTaskRes => {
      console.log('completeTaskRes: ', completeTaskRes);
      this.focussedTask = completeTaskRes;
      this.amHirer = completeTaskRes.userId == this.storageService.uniTaskrUser.userId ? true : false;
    }, err => {
      console.log('completeTaskRes error: ', err);
    });
  }

  getShout() {

  }

  convertTimestamp(fbTime: any) {
    try {
      return fbTime.toDate();
    } catch (e) {
      return new Date(fbTime);
    }
  }

  isUnseen() {
    if (this.chat && this.chat.readBy && this.chat.readBy.indexOf(this.storageService.firebaseUser.uid) == -1) {
      return true;
    }
    return false;
  }
}

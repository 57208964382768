import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { ErrorLogService } from '../error-logs/service/error-log.service';
import { StorageService } from '../services/storage/storage.service';


@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  tokenString: string;
  constructor(
    private storageService: StorageService,
    private logService: ErrorLogService,
    private router: Router
    ) { }

  // Intercepts all HTTP requests
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    request = request.clone({
      headers: request.headers.set('Accept', 'application/json')
    });

    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          // console.log('event--->>>', event);
        }
        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          let errorMessage = {
            where: "interceptor 401",
            errorBody: error
          }
          this.logService.addLogFirebase(JSON.stringify(errorMessage), 'interceptor', 4001)
          this.logService.addCodeLogFirebase(JSON.stringify(errorMessage), 'interceptor', 4001)
          if (error.error && error.error.success === false) {
            console.log('Login Failed');
          } else {
            // Route to login page
            this.router.navigate(['/']);
          }
        }
        if (error.status === 403 && error.url.includes(environment.uniTaskrApi.proUrl)) {
          let errorMessage = {
            where: "interceptor 403",
            errorBody: error
          }
          this.logService.addLogFirebase(JSON.stringify(errorMessage), 'interceptor', 4003)
          this.logService.addCodeLogFirebase(JSON.stringify(errorMessage), 'interceptor', 4003)
          if (error.error && error.error.success === false) {
            console.log('Forbidden');
            this.router.navigate(['/forbidden']);
          } else {
            // Route to login page
            this.router.navigate(['/']);
          }
        }
        return throwError(error);
      }));

  }

  token() {
    let value: String;
    this.storageService.get('accessToken').then((token) => {
      console.log('Storage token: ', token);
      value = token;
      return value;
    }).catch((err) => {
      console.log('Error getting token: ', err);
    });

  }
}

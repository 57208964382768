// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.title_row {
  width: 100%;
  justify-content: center;
  align-content: center;
}
.title_row h2 {
  font-size: 17px;
  font-weight: bold;
}

.lottie_row {
  width: 100%;
  justify-content: center;
  align-content: center;
}

.progess_row {
  width: 100%;
  justify-content: center;
  align-content: center;
  margin-bottom: 36px;
}
.progess_row ion-progress-bar {
  width: 80%;
  height: 8px;
  border-radius: 4px;
  --background: #B2C9ED;
  --progress-background: #3079E9;
}`, "",{"version":3,"sources":["webpack://./src/app/services/appflow/popover/popover.component.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,uBAAA;EACA,qBAAA;AACJ;AAAI;EACI,eAAA;EACA,iBAAA;AAER;;AAEA;EACI,WAAA;EACA,uBAAA;EACA,qBAAA;AACJ;;AAEA;EACI,WAAA;EACA,uBAAA;EACA,qBAAA;EACA,mBAAA;AACJ;AAAI;EACI,UAAA;EACA,WAAA;EACA,kBAAA;EACA,qBAAA;EACA,8BAAA;AAER","sourcesContent":[".title_row {\n    width: 100%;\n    justify-content: center;\n    align-content: center;\n    h2 {\n        font-size: 17px;\n        font-weight: bold;\n    }\n}\n\n.lottie_row {\n    width: 100%;\n    justify-content: center;\n    align-content: center;\n}\n\n.progess_row {\n    width: 100%;\n    justify-content: center;\n    align-content: center;\n    margin-bottom: 36px;\n    ion-progress-bar {\n        width: 80%;\n        height: 8px;\n        border-radius: 4px;\n        --background: #B2C9ED;\n        --progress-background: #3079E9;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

import { Injectable } from '@angular/core';
import { Preferences } from '@capacitor/preferences';
import { BehaviorSubject, from, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  public token: string = '';
  public FirebaseToken: string = '';

  ACCESS_TOKEN_KEY = 'my-access-token';
  REFRESH_TOKEN_KEY = 'my-refresh-token';

  public firebaseUser;
  public uniTaskrUser;
  public $uniTaskrUserSubject: BehaviorSubject<any> = new BehaviorSubject(false);
  public userId: string = '';

  public imageUrl: string = '';
  public signUpEmail: string = '';


  uniUser;


  constructor() { }

  async migrateOldStorage() {
    console.log('migrateOldStorage()')
    try {
      let migrated = await Preferences.migrate();
      // alert('Migrated')
    } catch (e) {
      // alert('Migration Failed.');
    }
  }

  set(key: string, value): Promise<any> {
    try {
      if (key == 'accessToken') {
        this.token = value;
        return;
      } else {
        return Preferences.set({ key: key, value: value });
      }
    } catch (e) {
      alert(`Prefence Set Error ${key}`)
      return;
    }
  }

  get(key): Promise<any> {
    return Preferences.get({ key: key });
  }

  getToken(): any {
    return Preferences.get({ key: 'accessToken' });
  }

  setAndString(key: string, value: any): Promise<any> {
    try {
      return this.set(key, JSON.stringify(value));
    } catch (e) {
      alert(`Prefence setAndString Error ${key}`)
    }

  }

  async getAndParse(key): Promise<any> {
    let res = await Preferences.get({ key: key });
    try {
      return JSON.parse(res.value)
    } catch {
      return res.value
    }
  }

  getAndParseObservable(key): Observable<any> {
    return from(Preferences.get({ key: key }).then(res => {
      try {
        return JSON.parse(res.value)
      } catch {
        return res.value
      }
    }));
  }

  Remove(key): Promise<any> {
    return Preferences.remove({ key: key });
  }

  empty(): Promise<any> {

    this.token = '';
    this.FirebaseToken = '';
    this.firebaseUser = undefined;
    this.uniTaskrUser = undefined;
    this.userId = '';

    this.imageUrl = '';
    this.signUpEmail = '';

    this.Remove('target-task');

    return Preferences.clear();
  }
}

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Capacitor } from '@capacitor/core';
import { AlertController, MenuController, Platform } from '@ionic/angular';
import { FirebaseAuthentication } from '@capacitor-firebase/authentication';

import { initializeApp } from 'firebase/app';
import {
  Auth,
  createUserWithEmailAndPassword,
  getAuth,
  indexedDBLocalPersistence,
  initializeAuth,
  sendEmailVerification,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signOut,
} from 'firebase/auth';
import { Database, getDatabase } from 'firebase/database';
import { RegisterService } from 'src/app/registration-group/service/register.service';
import { environment } from 'src/environments/environment';
import { ProfileService } from '../profile/profile.service';
import { StorageService } from '../storage/storage.service';
import { PresenceService } from '../presence/presence.service';
import { NotificationsService } from '../notifications/notifications.service';
import { Subject } from 'rxjs';
import { ErrorLogService } from 'src/app/error-logs/service/error-log.service';
import { HttpClient } from '@angular/common/http';
import { SubscriptionService } from '../subscription/subscription.service';
import { CvService } from 'src/app/cv-builder-new/service/cv.service';
import { ChatClientService, ChannelService } from 'stream-chat-angular';
import { SignInWithAppleResponse } from '@capacitor-community/apple-sign-in';
import { AppUpdateService } from 'src/app/app-update/service/app-update.service';
import { MessagingService } from 'src/app/messaging/service/messaging.service';

@Injectable({
  providedIn: 'root',
})
export class FirebaseService {
  private readonly database: Database;
  private readonly auth: Auth;

  /** My global profile */
  public myProfile;
  public profileUpdated = new Subject<any>();
  /** My global Shout profile */
  public myShoutProfile;

  /** Where should we navigate after login? */
  targetPath: string;

  /** Are we going to need to link the logins? */
  shouldLinkLogins: boolean = false;
  /** User to link logins */
  registerUserRes: any;
  /** Usre login details form */
  detailsForm;
  /** Currency Code */
  myCurrencyCode: string;
  /** Did the currency code change? */
  currencyCodeChange: Subject<string> = new Subject<string>();

  /** Is the newly logged in user unverified?? */
  notVerified: boolean = false;

  /** Logged in firebase user object */
  firebaseUserObject: any;

  /** When I user apple login I get the user bits here */
  appleLoginUserReult: SignInWithAppleResponse;

  constructor(
    private router: Router,
    private platform: Platform,
    private alertController: AlertController,
    private menuController: MenuController,
    private storageService: StorageService,
    private profileService: ProfileService,
    private registerService: RegisterService,
    private presenceService: PresenceService,
    private notificationsService: NotificationsService,
    private logService: ErrorLogService,
    private http: HttpClient,
    private subscription: SubscriptionService,
    private cvService: CvService,
    private chatClientService: ChatClientService,
    public channelService: ChannelService,
    private appUpdateService: AppUpdateService,
    private messagingService: MessagingService
  ) {
    this.targetPath = JSON.parse(JSON.stringify(window.location.pathname));
    const firebaseApp = initializeApp(environment.firebaseConfig);

    if (Capacitor.isNativePlatform()) {
      try {
        console.log('Try set persistence.');
        const persistentAuth = initializeAuth(firebaseApp, {
          persistence: indexedDBLocalPersistence,
        });
        console.log('persistentAuth: ', persistentAuth);
      } catch (e) {
        console.log('Caught set persistence: ', e);
      }
    }

    this.database = getDatabase(firebaseApp);
    this.auth = getAuth(firebaseApp);
  }

  startListeningToAuthStateChange() {
    console.log('startListeningToAuthStateChange()');

    const auth = getAuth();
    auth.onAuthStateChanged(async (user) => {
      console.log('^^^^^^');
      console.log('Auth State Changed: ', user);
      console.log('^^^^^^');

      // Check if the phone number login triggered this:
      if (user && user.providerData && user.providerData.length == 1 && user.providerData[0].providerId == 'phone') {
        console.log('IN this werid one..');
      } else if (user && !user.emailVerified) {
        console.log('&&&& Not Verified &&&&');
        this.notVerified = true;
        this.storageService.firebaseUser = user;
        this.firebaseUserObject = user;
        this.storageService.token = await user.getIdToken();

        setTimeout(() => {
          this.checkBusyRegistering();
        }, 2500);
      } else if (user && user.emailVerified) {
        this.storageService.firebaseUser = user;
        this.storageService.token = await user.getIdToken();

        this.notificationsService.checkAndSubscribe();

        // Check if the Firebase user is valid
        this.registerService.loginUser(user.email).subscribe(
          (loginUserRes) => {
            console.log('loginUserRes: ', loginUserRes);
            this.storageService.uniTaskrUser = loginUserRes;

            console.log('ONE ONE ONE ONE ONE');

            this.profileService.getProfile().subscribe(
              async (myProfileRes) => {
                this.subscription.getCounts().subscribe((countRes) => {
                  console.log('the countRes: ', countRes);
                  this.subscription.countChanged.next(countRes);
                });

                this.subscription.getSingleCount(5).subscribe((singleCountRes) => {
                  console.log('single count: ', singleCountRes);
                });

                let role: number;
                  if (myProfileRes.isStudent == true) {
                    role = 3;
                  } else if (myProfileRes.isStudent == false && myProfileRes.isBusiness == true) {
                    role = 1;
                  } else if (myProfileRes.isStudent == false && myProfileRes.isBusiness == false) {
                    role = 2;
                  }

                this.subscription.getCurrentSubscription(loginUserRes.userId, role).subscribe(
                  (subRes) => {
                    console.log('sub: ', subRes);
                    this.subscription.currentProPlanChanged.next(subRes);
                  },
                  (err) => {
                    console.log(err);
                  }
                );

                this.cvService.getJobTypes().subscribe((jobTypesRes) => {
                  console.log('jobTypesRes: ', jobTypesRes);
                  this.cvService.jobTypesUpdated.next(jobTypesRes);
                });

                this.myCurrencyCode = myProfileRes.currencyCode ? myProfileRes.currencyCode : this.myCurrencyCode;
                this.currencyCodeChange.next(this.myCurrencyCode);
                this.myProfile = myProfileRes;

                this.notificationsService.checkForNewNotifications();
                this.firebaseUserObject = user;

                this.subscription.initStudentSubscription(3);

                if (
                  this.targetPath == '/thinking' ||
                  this.targetPath == '/landing' ||
                  this.targetPath == '/' ||
                  this.targetPath.includes('forgot-password')
                ) {
                  this.targetPath = undefined;
                }

                if (this.targetPath == '/logout') {
                  signOut(auth)
                    .then(() => {
                      this.router.navigate(['/landing']);
                    })
                    .catch((error) => {
                      console.log('Did not sign out');
                    });
                }

                this.presenceService.logLoginTransaction(this.firebaseUserObject);

                let mustUpdate = await this.appUpdateService.getLatestAppVersionDetails();
                console.log('******* mustUpdate *******');
                console.log('******* mustUpdate *******');
                console.log(mustUpdate);
                console.log('******* mustUpdate *******');
                console.log('******* mustUpdate *******');
                if (mustUpdate) {
                  console.log('Firebase must update..');
                  this.router.navigate(['/app-update']);
                } else {
                  console.log('+++ Initialise Stream Bits +++');
                  this.messagingService.initialiseChat(0);

                  this.presenceService.setPresence('online');
                  this.menuController.enable(true);

                  if (location.href.indexOf('deeplink') > -1) {
                    console.log('Deep Link Page!');
                  } else {
                    if (this.myProfile.isStudent) {
                      if (this.targetPath) {
                        this.router.navigate([this.targetPath]);
                      } else {
                        // this.router.navigate(['/home-earn']);
                        this.router.navigate(['/student-earn-btns']);
                      }
                    } else {
                      if (this.targetPath) {
                        this.router.navigate([this.targetPath]);
                      } else {
                        this.router.navigate(['/home-hire-tabs/tasks']);
                      }
                    }
                  }
                }
              },
              (err) => {
                console.log('myProfileRes ERROR: ', err);
                this.logService.addLog(`myProfileRes ERROR ${JSON.stringify(err)}`, this.router.url);
                this.logService.addLogFirebase(JSON.stringify(err), this.router.url, 1001);
                this.logService.addCodeLogFirebase(JSON.stringify(err), this.router.url, 1001);
                this.loginError();
              }
            );
          },
          (err) => {
            console.log('Error logging in: ', err);
            this.logService.addLog(`Error logging in 141 ${JSON.stringify(err)}`, this.router.url);
            this.logService.addLogFirebase(JSON.stringify(err), this.router.url, 1002);
            this.logService.addCodeLogFirebase(JSON.stringify(err), this.router.url, 1002);
            // this.router.navigate(['/landing']);
          }
        );
      } else {
        console.log('Super firebase else.....');

        if (location.href.indexOf('deeplink') > -1) {
          console.log('Deep Link Page!');
        } else {
          if (this.targetPath && this.targetPath.split('/')[1] == 'reset-password-legacy') {
            console.log('Super firebase else..... ONE: ', this.targetPath);
            this.router.navigate([this.targetPath]);
          } else {
            console.log('Super firebase else..... TWO');
            if (
              window.location.href.indexOf('new-create-task/0') > -1 ||
              window.location.href.indexOf('task-profile') > -1 ||
              window.location.href.indexOf('reg-with-email') > -1 ||
              window.location.href.indexOf('linkedin-success') > -1
            ) {
            } else {
              this.router.navigate(['/landing']);
            }
          }
        }
      }
    });
  }

  initialiseStreamBits(streamToken: string) {
    console.log('initialiseStreamBits().....');
    void this.chatClientService.init(environment.stream_io.apiKey, this.firebaseUserObject.uid, streamToken).then((chatClientRes) => {
      console.log('44444 chatClientRes: ', chatClientRes);
    });

    // this.channelService.init({
    //   type: 'messaging',
    //   members: { $in: [this.firebaseUserObject.uid] },
    // }).then(chatClient => {

    //   console.log('44444 chatClient: ', chatClient);
    // })
  }

  // initialiseStreamBits(streamToken: string) {
  //   console.log('initialiseStreamBits().....')
  //   void this.chatClientService.init(
  //     environment.stream_io.apiKey,
  //     this.firebaseUserObject.uid,
  //     streamToken
  //   ).then(chatClientRes => {
  //     console.log('44444 chatClientRes: ', chatClientRes);
  //   })
  // }

  async checkBusyRegistering() {
    console.log('checkBusyRegistering()');
    let currentPage = window.location.pathname;
    console.log('currentPage: ', currentPage);
    let regStuff = await this.storageService.getAndParse('registration-details');
    console.log('regStuff: ', regStuff);
    let regType = await this.storageService.get('signUpType');
    console.log('regType: ', regType);

    if ((currentPage == '/thinking' || currentPage.includes('/reg-with-email')) && regStuff && regType) {
      this.resendVerificationEmail(
        this.storageService.firebaseUser.email,
        this.storageService.firebaseUser.displayName
      ).subscribe(
        (resendRes) => {
          console.log('resendRes: ', resendRes);
          this.router.navigate(['/verify-email']);
        },
        (err) => {
          console.log('Unable to resendRes: ', err);
        }
      );
    }
  }

  async getCurrentUser() {
    const result = await FirebaseAuthentication.getCurrentUser();
    return result;
  }

  async getIdToken() {
    const { user } = await FirebaseAuthentication.getCurrentUser();
    if (!user) {
      return null;
    }
    const { token } = await FirebaseAuthentication.getIdToken({
      forceRefresh: true,
    });
    return token;
  }

  createUserWithEmail(email: string, password: string) {
    return createUserWithEmailAndPassword(this.auth, email, password);
  }

  resetPassword(email: string) {
    return sendPasswordResetEmail(this.auth, email);
  }

  sendVerificationEmail() {
    // let user = this.auth.currentUser;
    return FirebaseAuthentication.sendEmailVerification();
  }

  async sendSpecificVerificationEmail() {
    console.log('sendSpecificVerificationEmail() ................');
    const result: any = await FirebaseAuthentication.getCurrentUser();
    console.log('current user: ', result);
    // const user = await this.getCurrentUser();
    return sendEmailVerification(result.user);
  }

  resendVerificationEmail(email, displayName) {
    let url = environment.uniTaskrApi.resendVerifyUrl;

    return this.http.post<any>(url, {
      uid: this.firebaseUserObject.uid,
      email: email,
      name: displayName,
    });
  }

  async checkUserExists(email: string, password?: string) {
    // const auth = getAuth();
    return signInWithEmailAndPassword(this.auth, email, password ? password : 'password');
  }

  loginUserWithEmail(email: string, password: string) {
    return signInWithEmailAndPassword(this.auth, email, password);
  }

  async loginError() {
    const alert = await this.alertController.create({
      header: 'Whoops!',
      message: 'Unable to login, please try again.',
      buttons: ['OK'],
    });
    await alert.present();
    this.router.navigate(['/logout']);
  }

  async updateEmail(newEmail: string) {
    const currentUser = await this.getCurrentUser();
    console.log('Current user: ', currentUser);
    if (!currentUser) {
      return;
    }
    const updatedEmail = await FirebaseAuthentication.updateEmail({
      newEmail: newEmail,
    });
    return updatedEmail;
  }

  getProfileUpdateListener() {
    return this.profileUpdated.asObservable();
  }

  /**
   *
   * @returns The logged in user as a promise
   * Log user in using the Google Login Popover
   */
  async signInWithFacebook() {
    const result = await FirebaseAuthentication.signInWithFacebook();
    return result;
  }

  /**
   *
   * @returns The logged in user as a promise
   * Log user in using the Google Login Popover
   */
  async signInWithGoogle() {
    const result = await FirebaseAuthentication.signInWithGoogle();
    return result;
  }

  /**
   *
   * @returns The logged in user as a promise
   * Log user in using the Google Login Popover
   */
  async signInWithApple() {
    const result = await FirebaseAuthentication.signInWithApple({
      customParameters: [{ key: 'fullName', value: 'true' }],
      scopes: ['name'],
    });
    return result;
  }
}

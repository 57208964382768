import { Component, Input, OnInit } from '@angular/core';
import { Browser } from '@capacitor/browser';

@Component({
  selector: 'app-offer-attachments',
  templateUrl: './offer-attachments.component.html',
  styleUrls: ['./offer-attachments.component.scss'],
})
export class OfferAttachmentsComponent implements OnInit {

  @Input('focussedApplicant') focussedApplicant;

  newAttachments: [];

  constructor() { }

  ngOnInit() {
    // this.newAttachments = this.focussedApplicant.attachments.map(attachment => {
    //   attachment.type = 'image';
    //   return attachment;
    // })
    console.log('the attachments: ', this.focussedApplicant);
  }

  ionViewDidEnter() {
    console.log('the attachments: ', this.focussedApplicant);
  }

  async goToLink(link): Promise<void> {
    if (link.startsWith('https://')) {
      await Browser.open({ url: link })
    } else {
      await Browser.open({ url: `https://${link}/` });
    }
  }

}

import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { TheCurrencyPipe } from './the-currency.pipe'

@NgModule({
    declarations: [TheCurrencyPipe],
    imports: [
        CommonModule
    ],
    exports: [TheCurrencyPipe],
    providers: [
        CurrencyPipe
    ]
})
export class PipesModule { }
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Photo } from '@capacitor/camera';
import { Filesystem, FilesystemDirectory } from '@capacitor/filesystem';
import { Share } from '@capacitor/share';
import { ModalController, Platform } from '@ionic/angular';
import { AnalyticsService } from 'src/app/services/analytics/analytics.service';
import { S3UploadService } from 'src/app/services/s3-upload/s3-upload.service';
import { environment } from 'src/environments/environment';
import { SwiperComponent } from 'swiper/angular';
import { Media } from '@capacitor-community/media';
import { Capacitor, Plugins } from '@capacitor/core';

@Component({
  selector: 'app-full-size-image-viewer',
  templateUrl: './full-size-image-viewer.page.html',
  styleUrls: ['./full-size-image-viewer.page.scss'],
})
export class FullSizeImageViewerPage implements OnInit {

  /* Handle on the slider element */
  @ViewChild('slideShow', { static: true }) slideShow: SwiperComponent;

  /* Default initial slide */
  public currentSlide: number = 0;

  /** The list of images that we'll be displaying */
  @Input('images') images;

  /** Which image is focussed? */
  @Input('focussed') focussed;

  constructor(
    private platform: Platform,
    private modalController: ModalController,
    private analyticsService: AnalyticsService,
    private uploadService: S3UploadService
  ) { }

  ngOnInit() {
  }

  ionViewDidEnter() {
    this.analyticsService.recordPageEntry('full-size-image-viewer', 'viewed as modal');
    let focussedIndex = this.images.indexOf(this.focussed);
    this.slideShow.swiperRef.slideTo(focussedIndex, 100);
  }

  updateCurrentSlide() {
    this.currentSlide = this.slideShow.swiperRef.activeIndex.valueOf();
  }

  getImageSource(image, location: string) {
    if (image && typeof (image) == "string" && image.includes('http')) {
      return image;
    } else if (image == "string" || image == "") {
      return "../../../assets/images/placeholder.jpeg";
    } else {
      let availWidth = screen.availWidth;
      let availHeight = screen.availHeight;

      if (availWidth > 250) {
        availWidth = 240;
      }

      let imageWidth: number;
      let imageHeight: number;
      if (location === "focussed") {
        imageWidth = 2 * 0.95 * availWidth;
        // imageHeight = 2 * 240;
        imageHeight = 2 * 0.95 * availHeight;
      } else if (location === "gallery") {
        imageWidth = 106;
        imageHeight = 74;
      }

      let cdnRequestObject = {
        bucket: "unitaskr-web-cdn",
        key: image.key ? image.key : image.url ? image.url : image, // "Screenshot 2021-11-17 at 11.10.24.png",
        edits: {
          resize: {
            fit: "contain"
          }
        }
      }

      let base64 = btoa(JSON.stringify(cdnRequestObject))
      return `${environment.uniTaskrApi.cdnBaseUrl}${base64}`;
    }
  }


  async save() {
    try {
      Media.savePhoto({
        path: this.focussed.file
      })
        .then(() => console.log('Image has been saved'))
        .catch(e => console.error(e));
    } catch (e) { 
      console.error(e); 
      if (e == 'Error: Not implemented on web.') {
        alert('Please download on a mobile device using the UniTaskr app.')
      }
    }
  }


  async downloadImage() {
    console.log('downloadImage(): ', this.focussed);
    const search = ' ';
    const replaceWith = '-';
    const videoUrl = this.focussed.file;
    const fileName = `UniTaskr_image`
    this.uploadService.downloadVideo(videoUrl, fileName);
  }

  private async _readAsBase64(url: string): Promise<string> {
    const response = await fetch(url);
    const blob = await response.blob();
    return await this._convertBlobToBase64(blob);
  }
  private async _convertBlobToBase64(blob: Blob): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onerror = () => reject();
      reader.onload = () => resolve(reader.result as string);
      reader.readAsDataURL(blob);
    });
  }


  getSlideId(slide) {
    return slide.url;
  }

  getSlideStyle(slide) {
    let imageUrl = this.getImageSource(slide.url, 'focussed');
    return `background: url(${imageUrl}) center center no-repeat; background-size: contain;`
  }

  dismiss() {
    this.modalController.dismiss();
  }

}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-recruit-task-filter',
  templateUrl: './recruit-task-filter.component.html',
  styleUrls: ['./recruit-task-filter.component.scss'],
})
export class RecruitTaskFilterComponent implements OnInit {
  constructor() {}

  ngOnInit() {}

  @Input('filterSelected') filterSelected: string;

  @Output('updateFilterSelected') updatedFilterSelected = new EventEmitter<string>();

  selectFilter(filter) {
    this.updatedFilterSelected.emit(filter);
  }
}

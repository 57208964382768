// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.screenshot_card {
  margin-top: 4px;
  margin-bottom: 4px;
}
.screenshot_card .screenshot_item {
  --background: transparent;
}
.screenshot_card .screenshot_item .display_text_label {
  overflow: unset;
  font-size: 1rem;
}
.screenshot_card .display_text_label {
  font-size: 1rem;
}
.screenshot_card ion-icon {
  font-size: large;
  color: var(custom-file-upload);
}
.screenshot_card .link_input {
  background: var(--bg-light-blue);
  color: var(--primary-ut-text);
  border: solid 1px var(--ion-color-light-shade);
  border-radius: 6px;
  --padding-start: 10px;
  margin-bottom: 9px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/result-link/result-link.component.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;EACA,kBAAA;AACJ;AACI;EACI,yBAAA;AACR;AAAQ;EACI,eAAA;EACA,eAAA;AAEZ;AAEI;EACI,eAAA;AAAR;AAGI;EACI,gBAAA;EACA,8BAAA;AADR;AAII;EACI,gCAAA;EACA,6BAAA;EACA,8CAAA;EACA,kBAAA;EACA,qBAAA;EACA,kBAAA;AAFR","sourcesContent":[".screenshot_card {\n    margin-top: 4px;\n    margin-bottom: 4px;\n\n    .screenshot_item {\n        --background: transparent;\n        .display_text_label {\n            overflow: unset;\n            font-size: 1rem;\n        }\n    }\n\n    .display_text_label {\n        font-size: 1rem;\n    }\n\n    ion-icon {\n        font-size: large;\n        color: var(custom-file-upload);\n    }\n\n    .link_input {\n        background: var(--bg-light-blue);\n        color: var(--primary-ut-text);\n        border: solid 1px var(--ion-color-light-shade);\n        border-radius: 6px;\n        --padding-start: 10px;\n        margin-bottom: 9px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

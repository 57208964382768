import { Component, OnInit } from '@angular/core';
import { AppflowService } from '../appflow.service';
import { AnimationItem } from 'lottie-web';
import { AnimationOptions } from 'ngx-lottie';

@Component({
  selector: 'app-popover',
  templateUrl: './popover.component.html',
  styleUrls: ['./popover.component.scss'],
})
export class PopoverComponent implements OnInit {

  options: AnimationOptions = {
    path: 'https://assets4.lottiefiles.com/packages/lf20_szdrhwiq.json',
  };

  constructor(
    public appFlowService: AppflowService
  ) { }

  ngOnInit() {
    console.log('Just opened Update progress popover!!');
    this.appFlowService.performManualUpdate();
  }

  animationCreated(animationItem: AnimationItem): void {
    console.log(animationItem);
  }

  updateAnimation(): void {
    
    // ✔️✔️ Update `options` in this way
    this.options = {
      ...this.options, // In case you have other properties that you want to copy
      path: 'https://assets9.lottiefiles.com/packages/lf20_PBgNop.json',
    };
  }

}

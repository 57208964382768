import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AlertController, LoadingController, ModalController } from '@ionic/angular';
import { awsImageUploadResponse, S3UploadService } from 'src/app/services/s3-upload/s3-upload.service';
import { environment } from 'src/environments/environment';

import { Browser } from '@capacitor/browser';
import { FullSizeImageViewerPage } from 'src/app/modals/full-size-image-viewer/full-size-image-viewer.page';

@Component({
  selector: 'app-attach-file-array',
  templateUrl: './attach-file-array.component.html',
  styleUrls: ['./attach-file-array.component.scss'],
})
export class AttachFileArrayComponent implements OnInit {

  @Input('label') label: string;

  /** Should send in a list of file URLs */
  // @Input('fileList') existingFileList: { name: string, key: string, type: string }[] = [];
  @Input('fileList') existingFileList: any[] = [];

  /** Are we in view only mode? */
  @Input('viewOnly') viewOnly: boolean | string = false;

  /** Send Attachment list updates to the consumer */
  @Output('listUpdates') listUpdates = new EventEmitter;

  /** Which file should be highlighted? */
  focussedFile: { name: string, key: string, type: string };

  selectedFiles: FileList;

  /** URL of the uploadedFile */
  fileUrl: string;

  /** List of actual images to show */
  finalImageList: string[] = [];


  constructor(
    private loadingController: LoadingController,
    private alertController: AlertController,
    private modalController: ModalController,
    private uploadService: S3UploadService
  ) { }

  ngOnInit() {
    // console.log('existing file list: ', this.existingFileList);
    if (this.existingFileList && this.existingFileList.length) {
      this.focussedFile = this.existingFileList[0];

      this.sortImages();
    }
  }

  getIsFocussed(image) {
    if (image && this.focussedFile && image.key == this.focussedFile.key) {
      return true;
    }
  }

  removeDuplicates(a) {
    let existingUrls = [];
    let newArray = [];
    for (let object of a) {
      for (let key in object) {
        if ((key == 'url' || key == 'key') && !existingUrls.includes(object[key])) {
          existingUrls.push(object[key]);
          newArray.push(object);
        }
      }
    }
    // console.log('newArray: ', newArray);
    return newArray;
  }

  sortImages() {
    this.finalImageList = [];
    this.existingFileList = this.removeDuplicates(this.existingFileList);
    for (let image of this.existingFileList) {
      if (typeof (image) !== "string") {
        this.finalImageList.push(this.getImageSource(image.url ? image.url : image.key, 'gallery'));
        image.finalUrl = this.getImageSource(image.url ? image.url : image.key, 'gallery');
      } else {
        this.finalImageList.push(this.getImageSource(image, 'gallery'));
        image = this.getImageSource(image, 'gallery');
      }
    }

    // console.log('finalImageList: ', this.finalImageList);
  }

  getImageSource(image, location: string) {
    if (image && typeof (image) == "string" && image.includes('http')) {

      let split = image.split("/");
      return `https://${split[3]}.s3.eu-west-1.amazonaws.com/${split[4]}`
    }
    else if (image == "string" || image == "") {
      return "../../../assets/images/placeholder.jpeg";
    } else {
      let availWidth = screen.availWidth;
      if (availWidth > 250) {
        availWidth = 240;
      }
      
      let imageWidth: number;
      let imageHeight: number;
      if (location === "focussed") {
        imageWidth = 2 * 0.95 * availWidth;
        imageHeight = 2 * 240;
      } else if (location === "gallery") {
        imageWidth = 106;
        imageHeight = 74;
      }

      let cdnRequestObject = {
        bucket: "unitaskr-web-cdn",
        key: image.key ? image.key : image.url ? image.url : image, // "Screenshot 2021-11-17 at 11.10.24.png",
        edits: {
          resize: {
            width: imageWidth,
            height: imageHeight,
            fit: "cover"
          }
        }
      }

      let base64 = btoa(JSON.stringify(cdnRequestObject))
      return `${environment.uniTaskrApi.cdnBaseUrl}${base64}`;
    }
  }

  getAltPhoto(image) {

    let picture = image.url ? image.url : image;

    // https://s3-eu-west-1amazonaws.com/unidosh-services-attachments/2504
    // https://unidosh-services-attachments.s3.eu-west-1.amazonaws.com/2498

    let noProtocol = picture.replace('https://', '');
    noProtocol = picture.replace('http://', '');
    let split = noProtocol.split("/");
    return `https://${split[1]}.s3.eu-west-1.amazonaws.com/${split[2]}`

  }

  getFileType(file) {
    // console.log('getFileType(): ', file);
    if (file.type && file.type.indexOf('image') > -1) {
      return 'image';
    } else if (file.type && file.type == "application/pdf") {
      return 'pdf';
    } else if (file.type && file.type == "application/vnd.openxmlformats-officedocument.wordprocessingml.document" || file.url.indexOf('supporting-files')) {
      return 'doc';
    } else if (file.type && file.type == "application/vnd.openxmlformats-officedocument.presentationml.presentation") {
      return 'ppt';
    } else {
      return 'image';
    }
  }

  getDisplayName(imageName: string) {
    try {
      let name = imageName.replace('supporting-files/', '');
      return name;
    } catch {
      return "Doc"
    }

  }

  async fileAction(file) {

    if (this.viewOnly) {
      this.openFile(file);
    } else {
      let viewOnlyButtons = [
        {
          text: 'Open file',
          handler: () => {
            console.log('Confirm open');
            this.openFile(file);
          }
        }
      ]

      let notViewButtons = [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            console.log('Confirm Cancel: blah');
          }
        }, {
          text: 'Delete',
          handler: () => {
            console.log('Confirm Delete');
            this.confirmAndDelete(file);
          }
        }, {
          text: 'Open file',
          handler: () => {
            console.log('Confirm open');
            this.openFile(file);
          }
        }
      ]

      const alert = await this.alertController.create({
        cssClass: 'my-custom-class',
        header: 'What would you like to do?',
        message: "You'll be asked to confirm any delete actions.",
        buttons: this.viewOnly ? viewOnlyButtons : notViewButtons
      });

      await alert.present();
    }
  }

  async openFile(file) {
    if (!file.type) {
      file.type = 'image';
    }
    switch (file.type) {
      case "application/pdf":
        console.log('Browser URL: ', `${environment.uniTaskrApi.s3FileBaseUrl}${file.key}`);
        await Browser.open({ url: `${environment.uniTaskrApi.s3FileBaseUrl}${file.key}` });
        break;
      case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        await Browser.open({ url: `${environment.uniTaskrApi.s3FileBaseUrl}${file.key}` });
        break;
      case "image":
        if (file.url.startsWith('supporting-files') || file.url.startsWith('cover-images')) {
          const modal = await this.modalController.create({
            component: FullSizeImageViewerPage,
            // swipeToClose: true,
            componentProps: {
              images: [{ url: file.url }],
              focussed: file
            }
          });
          await modal.present();
        } else {
          await Browser.open({ url: file.finalUrl });
        }
        break;
    }
  }

  async confirmAndDelete(file) {
    const alert = await this.alertController.create({
      header: 'Are you sure you want to remove this file?',
      message: "It'll be permanently deleted.",
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            console.log('Confirm Cancel: blah');
          }
        }, {
          text: 'Delete',
          handler: () => {
            console.log('Confirm Delete');
            let index = this.existingFileList.indexOf(file);
            this.existingFileList.splice(index, 1);
            this.listUpdates.emit(this.existingFileList);
          }
        }
      ]
    });

    await alert.present();
  }

  async upload() {
    const loading = await this.loadingController.create({
      cssClass: 'my-custom-class',
      message: 'Uploading file...',
      duration: 10000
    });
    await loading.present();

    const file = this.selectedFiles.item(0);
    console.log('upload() file: ', file);
    this.uploadService.browserCompressUpload(file, 'supporting-files').then((res: awsImageUploadResponse) => {
      console.log('Res: ', res);

      this.existingFileList.push({ name: res.key, key: res.key, type: file.type });
      this.focussedFile = { name: res.key, key: res.key, type: file.type };
      loading.dismiss();
      this.selectedFiles = undefined;

      console.log('existingFileList: ', this.existingFileList);

      this.listUpdates.emit(this.existingFileList);

    }).catch(err => {
      console.log('Catch: ', err);
      loading.dismiss();
    })
  }

  selectFile(event) {
    this.selectedFiles = event.target.files;
    this.upload();
  }

}

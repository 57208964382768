// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.task_row {
  width: 94%;
  margin-left: 2.5%;
  margin-right: 3.5%;
  margin-bottom: 2.5%;
  border-radius: 6px;
  border: solid 1px var(--bg-very-light-grey);
}
.task_row .task_item {
  --background: transparent;
  width: 100%;
}
.task_row .less_indentation {
  --padding-start: 12px;
  margin-inline: 0px 6px !important;
}
.task_row .top_border {
  border-top: solid 1px var(--bg-very-light-grey);
}
.task_row .item_title_label {
  font-size: 0.875rem;
  font-weight: bold;
  margin-left: 2px;
  font-family: Poppins-Regular;
  color: var(--ion-color-dark-tint);
}

.type_btn {
  font-family: Poppins-Regular;
  font-weight: bold;
  font-size: 0.875rem;
  --color: var(--bg-lavendar);
  --padding-start: 0;
  --padding-end: 0;
}`, "",{"version":3,"sources":["webpack://./src/app/components/activity-item-selling/activity-item-selling.component.scss"],"names":[],"mappings":"AAAA;EACI,UAAA;EACA,iBAAA;EACA,kBAAA;EACA,mBAAA;EACA,kBAAA;EACA,2CAAA;AACJ;AACI;EACI,yBAAA;EACA,WAAA;AACR;AAEI;EACI,qBAAA;EACA,iCAAA;AAAR;AAGI;EACI,+CAAA;AADR;AAII;EACI,mBAAA;EACA,iBAAA;EACA,gBAAA;EACA,4BAAA;EACA,iCAAA;AAFR;;AAMA;EACI,4BAAA;EACA,iBAAA;EACA,mBAAA;EACA,2BAAA;EACA,kBAAA;EACA,gBAAA;AAHJ","sourcesContent":[".task_row {\n    width: 94%;\n    margin-left: 2.5%;\n    margin-right: 3.5%;\n    margin-bottom: 2.5%;\n    border-radius: 6px;\n    border: solid 1px var(--bg-very-light-grey);\n\n    .task_item {\n        --background: transparent;\n        width: 100%;\n    }\n\n    .less_indentation {\n        --padding-start: 12px;\n        margin-inline: 0px 6px !important;\n    }\n\n    .top_border {\n        border-top: solid 1px var(--bg-very-light-grey);\n    }\n\n    .item_title_label {\n        font-size: 0.875rem;\n        font-weight: bold;\n        margin-left: 2px;\n        font-family: Poppins-Regular;\n        color: var(--ion-color-dark-tint);\n    }\n}\n\n.type_btn {\n    font-family: Poppins-Regular;\n    font-weight: bold;\n    font-size: 0.875rem;\n    --color: var(--bg-lavendar);\n    --padding-start: 0;\n    --padding-end: 0;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

import { Component, EventEmitter, Input, NgZone, OnInit, Output } from '@angular/core';
import { LoadingController } from '@ionic/angular';
import { awsImageUploadResponse, S3UploadService } from 'src/app/services/s3-upload/s3-upload.service';
import { ShoutDetailsService } from 'src/app/shout-profile/service/shout-details.service';

@Component({
  selector: 'app-result-video',
  templateUrl: './result-video.component.html',
  styleUrls: ['./result-video.component.scss'],
})
export class ResultVideoComponent implements OnInit {

  /** Unique identifier for this screenshot. */
  @Input('vidId') videoId: number;

  /** List of uploaded screenshots for display and delete */
  @Input('uploadedVideos') uploadedVideos;

  /** The formRow we're trying to fill or get filled */
  @Input('formRow') formRow;

  /** What text would like you to be displayed above the screenshot? */
  @Input('headingText') headingText: string = "Video";

  /** Return the video location */
  @Output('videoLocation') videoLocation = new EventEmitter();

  /** Updated profile picture files */
  selectedFiles: FileList;
  /** URL of the uploadedVideos */
  videoUrl: string;

  videoObject: any;

  constructor(
    private loadingController: LoadingController,
    private uploadService: S3UploadService,
    private shoutDetailsService: ShoutDetailsService,
    private zone: NgZone
  ) { }

  ngOnInit() {}

  selectFile(event) {
    this.selectedFiles = event.target.files;
    this.upload();
  }

  getId() {
    return `shout-result-video-${this.videoId}`
  }

  deleteVideo() {
    console.log('deleteVideo(): ', this.uploadedVideos);

    let focussedScreen = this.getVideo();

    console.log('focussedScreen: ', focussedScreen);

    for (let screen of this.uploadedVideos) {
      if (screen.url === focussedScreen) {
        // Need to delete this guy!

        this.shoutDetailsService.removeResultScreenshot(screen.id, this.formRow.taskId).subscribe(deleteRes => {
          console.log('delete screen deleteRes: ', deleteRes);
          
          for (let screen of this.uploadedVideos) {
            if (screen.id === deleteRes.id) {
              this.uploadedVideos.splice(this.uploadedVideos.indexOf(screen), 1);
              this.videoObject = undefined;
              break;
            }
          }
          
        }, err => {
          console.log('deleteRes error: ', err);
        });
      }
    }
  }


  async upload() {
    this.zone.run(async () => {
      const loading = await this.loadingController.create({
        cssClass: 'my-custom-class',
        message: 'Uploading video...',
        duration: 100000
      });
      await loading.present();

      const file = this.selectedFiles.item(0);
      console.log('upload() file: ', file);

      this.uploadService.browserCompressUpload(file, 'shout-result-videos').then((res: awsImageUploadResponse) => {
        console.log('Res: ', res);
        this.videoUrl = res.Location;

        // this.videoObject[this.getId()] = res.Location;

        this.videoObject = {
          [this.getId()]: res.Location
        }


        console.log('Set video URL: ', this.videoUrl);
        this.videoLocation.emit(res.Location);
        this.selectedFiles = undefined;
        loading.dismiss();

      }).catch(err => {
        console.log('Catch: ', err);
        loading.dismiss();
      })
    })
  }

  getVideo() {
    if (this.videoObject) {
      return this.videoObject[this.getId()];
    }

    if (this.uploadedVideos) {
      for (let screen of this.uploadedVideos) {
        if (screen.formRefId === this.formRow.formId && screen.displayOrder === this.formRow.order) {
          return screen.url;
        }
      }
    }
  }

}

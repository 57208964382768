import { Injectable, NgZone, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, from, Observable, of, Subscription } from 'rxjs';
import { StorageService } from 'src/app/services/storage/storage.service';
import { environment } from 'src/environments/environment';
import { Channel, StreamChat } from 'stream-chat';
import { FirebaseService } from '../../welcome/services/firebase/firebase.service';
import { ChannelActionsContext, ChannelListToggleService, ChannelPreviewContext, ChannelService, ChatClientService, CustomTemplatesService, DefaultStreamChatGenerics, StreamI18nService } from 'stream-chat-angular';
import { LocalUserService } from 'src/app/welcome/services/local-user/local-user.service';

@Injectable({
  providedIn: 'root'
})
export class MessagingService {

  /** has the chat been initialised?? */
  isInit: boolean = false;

  /** have the channels been initialised? */
  isChannelInit: boolean = false;

  /** Should this be visible?? */
  showMessaging: boolean = false;
  chatIsReady$!: Observable<boolean>;

  @ViewChild('channelActionsTemplate')
  private channelActionsTemplate!: TemplateRef<ChannelActionsContext>;
  @ViewChild('channelPreview')
  private channelPreview!: TemplateRef<ChannelPreviewContext>;


  /** Is the side menu (list of channels) visible? */
  menuIsOpen: boolean = true;

  /** Stream Subscriptions */
  channelSubscription$: Subscription;

  /** All Channels */
  allChannels: Channel<DefaultStreamChatGenerics>[] = [];

  /** Active Channel */
  activeChannel: Channel | null;

  /** Behaviour subject so focussed chat knows to refresh the task offer */
  shouldGetTaskAgain: BehaviorSubject<boolean> = new BehaviorSubject(false);

  /** All Unread Messages */
  unreadMessageCount: number;

  /** Student Inbox update list order */
  $shouldUpdateList = new BehaviorSubject({});


  /** Stream Channel Page size */
  channelPageSize: number = 10;
  /** Stream Channel Page number */
  channelPageNumber: number = 1;

  constructor(
    private router: Router,
    private chatService: ChatClientService,
    private channelService: ChannelService,
    private streamI18nService: StreamI18nService,
    private storageService: StorageService,
    private channelListToggleService: ChannelListToggleService,
    private zone: NgZone
  ) { }

  async initialiseChat(initChat?) {
    // console.log('initialiseChat: ', initChat)
    // console.log('this.storageService.uniTaskrUser: ', this.storageService.uniTaskrUser)

    if (this.storageService.uniTaskrUser && this.storageService.uniTaskrUser.streamIoToken) {
      if (!this.isInit) {
        this.isInit = true;
        this.streamI18nService.setTranslation();

        // console.log("&&&&&&&&&&&")
        // console.log('Enter Messaging Page: ', this.chatIsReady$);
        // console.log('storageService.firebaseUser: ', this.storageService.firebaseUser);
        // console.log('welcomeFirebase.firebaseUser: ', this.welcomeFirebase.firebaseUser);
        // console.log('storageService.uniTaskrUser.streamIoToken: ', this.storageService.uniTaskrUser.streamIoToken);
        // console.log("&&&&&&&&&&&")
        this.chatService.init(
          environment.stream_io.apiKey,
          // `${this.welcomeFirebase.firebaseUser.uid}`,
          `${this.storageService.firebaseUser.uid}`,
          this.storageService.uniTaskrUser.streamIoToken).then((initChatRes: any) => {
            console.log('initChatRes: ', initChatRes);

            this.unreadMessageCount = initChatRes?.me.total_unread_count

            this.zone.run(() => {
              console.log('+++ unreadMessageCount +++ : ', this.unreadMessageCount);
            })

            this.chatIsReady$ = of(true);
            // if (initChat) {
            this.intialiseChannels(initChat)
            // }
          });

        if (!this.activeChannel) {
          this.menuIsOpen = true;
        } else {
          this.menuIsOpen = false;
        }


      } else {
        if (initChat !== 0) {
          const chatClient = StreamChat.getInstance<any>(environment.stream_io.apiKey);
          const filter = { type: 'messaging', id: initChat };
          const channels = await chatClient.queryChannels(filter);

          // console.log('Set Param Chat!!: ', channels);
          // console.log('Set Param Chat!!')
          // console.log('Set Param Chat!!')

          this.channelService.setAsActiveChannel(channels[0]);
          this.menuIsOpen = false;
        }
      }
    } else {
      this.router.navigate(['/thinking'])
    }


  }

  getStreamToken() {
    console.log('getStreamToken() : ', this.storageService.uniTaskrUser.streamIoToken);
    return of(this.storageService.uniTaskrUser.streamIoToken)
  }

  intialiseChannels(initChat) {
    // console.log('intialiseChannels()');
    // console.log('intialiseChannels()');
    // console.log('intialiseChannels()');
    // console.log('intialiseChannels()');
    // console.log('intialiseChannels()');

    if (!this.isChannelInit) {
      this.isChannelInit = true;
      // const sort = [{ last_message_at: -1 }];
      const sort = { last_message_at: -1 };

      this.channelService.init({
        type: 'messaging',
        members: { $in: [`${this.storageService.firebaseUser.uid}`] },
        // members: { $in: [`RO2ala9L6SQRRTo7rNN8I5ty0br1`] },
      }, // filters
        { last_message_at: -1 }, // Sort
        {
          limit: this.channelPageSize,
          offset: 0
          // limit: 150
        }, // options
        false).then(initRes => {
          console.log('initRes: ', initRes);

          this.channelSubscription$ = this.channelService.channels$.subscribe(
            async (c) => {
              console.log('Returned Channels: ', c);

              this.$shouldUpdateList.next(c)

              const unique = (value, index, self) => {
                return self.indexOf(value) === index
              }

              this.allChannels = c.filter(unique)

              if (initRes.length > this.channelPageSize - 1) {
                this.getPageTwoOfChannels(initRes.length);
              }

              if (this.activeChannel) {
                for (let chan of this.allChannels) {
                  if (chan.cid == this.activeChannel.cid) {
                    this.shouldGetTaskAgain.next(true);
                  }
                }
              }

              if (initChat !== 0) {
                const chatClient = StreamChat.getInstance<any>(environment.stream_io.apiKey);
                const filter = { type: 'messaging', id: initChat };
                const channels = await chatClient.queryChannels(filter);
                console.log('Done the param channel query - going to focus the orginal chat...');
                // this.channelService.setAsActiveChannel(channels[0]);

              } else {
                this.zone.run(() => {
                  this.channelListToggleService.open();
                })
              }


              const chatClient = StreamChat.getInstance<any>(environment.stream_io.apiKey);
              chatClient.on((event) => {
                // console.log('££££££££££££££');
                // console.log('New Client Event: ', event);
                // console.log('££££££££££££££')
                if (event.total_unread_count !== undefined) {
                  console.log('total_unread_count', event.total_unread_count);
                  this.unreadMessageCount = event.total_unread_count;
                }

                if (event.unread_channels !== undefined) {
                  console.log('unread_channels', event.unread_channels);
                }
              })


            }
          );

          this.channelService.activeChannel$.subscribe(
            (c) => {
              console.log('Active Channel Updated!!: ', c);
              this.zone.run(() => {
                this.activeChannel = c;
                console.log('New Active Channel!!: ', this.activeChannel);
              })
            }
          );
        })
    }
  }

  async getPageTwoOfChannels(offset: number) {
    console.warn('>>>: getPageTwoOfChannels()');
    const chatClient = StreamChat.getInstance<any>(environment.stream_io.apiKey);
    const filter = { 
      type: 'messaging',
      members: { $in: [`${this.storageService.firebaseUser.uid}`] },
    };
    const sort = {};
    const options = {
      limit: this.channelPageSize,
      offset: offset * this.channelPageNumber
    };
    const channels = await chatClient.queryChannels(filter, sort, options);
    // console.warn('>>>: Next page chats: ', channels);

    let mushedChannels = this.allChannels.concat(channels);

    const unique = (value, index, self) => {
      return self.indexOf(value) === index
    }

    this.allChannels = mushedChannels.filter(unique);
    // console.warn('>>>: concat allChannels: ', this.allChannels);

    if (channels.length > this.channelPageSize - 1) {
      this.channelPageNumber += 1;
      this.getPageTwoOfChannels(channels.length);
    } else {
      console.warn('>>>: Final All Channels: ', this.allChannels);

      let unreadChans = [];

      for (let chan of this.allChannels) {
        if (chan.state.unreadCount > 0) {
          unreadChans.push(chan);
        }
      }

      console.log('**************************')
      console.log('**************************')
      console.log('**************************')
      console.log('unreadChans: ', unreadChans);
      console.log('**************************')
      console.log('**************************')
      console.log('**************************')

    }
  }



  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }


  /**
   * 
   * Create a new channel with me in it.
   * UniBot will be in the channel too.
   * 
   * @param name - name of the channel
   */
  createChannelForMe(name) {
    console.log('createChannelForMe(): ', name);
    const dasherizedName = name.replace(/\s+/g, '-').toLowerCase();
    const channel = this.chatService.chatClient.channel(
      'messaging',
      dasherizedName,
      {
        name,
        members: [this.storageService.firebaseUser.uid, 'uniBot']
      });
    from(channel.create());
  }

  /**
   * 
   * Create a new channel for a specific task.
   * UniBot will be in the channel.
   * I will be inthe channel.
   * 
   * I am the hirer, and am starting a chat with a student.
   * 
   * @param name - name of the channel
   * @param offerId - task offer ID that we're talking about.
   */
  createChannelForOfferId(name: string, offerId: string, studentUid: string) {
    console.log('createChannelForMe(): ', name);
    const dasherizedName = name.replace(/\s+/g, '-').toLowerCase();
    const channel = this.chatService.chatClient.channel(
      'messaging',
      offerId,
      {
        name,
        members: [this.storageService.firebaseUser.uid, studentUid, 'uniBot']
      });
    from(channel.create());
  }
}

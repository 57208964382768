import { Injectable } from '@angular/core';

import { getDocs, query, orderBy, getFirestore, limit, collection } from '@firebase/firestore';
import { ErrorLogService } from 'src/app/error-logs/service/error-log.service';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class AppUpdateService {

  updateVersionDetails;

  constructor(
    private errorLogService: ErrorLogService
  ) { }

  async getLatestAppVersionDetails(): Promise<boolean> {
    const db = getFirestore();
    const appVersionsRef = collection(db, "appVersion");
    const q = query(appVersionsRef, orderBy('timestamp'), limit(1))

    const querySnapshot = await getDocs(q);

    let versions = [];
    querySnapshot.forEach((doc) => {
      versions.push(doc.data());
    });

    if (Number(environment.applicationVersion.trim().replace('.', '')) < Number(versions[0].minSupportedVersion.trim().replace('.', ''))) {
      this.updateVersionDetails = versions[0];
      return true;
    } else {
      return false;
    }
  }
}

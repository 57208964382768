import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Browser } from '@capacitor/browser';

@Component({
  selector: 'app-add-link-array',
  templateUrl: './add-link-array.component.html',
  styleUrls: ['./add-link-array.component.scss'],
})
export class AddLinkArrayComponent implements OnInit {

  /** Should send in a list of links */
  @Input('linkList') existingLinkList: string[] = [];

  /** Are we in view only mode? */
  @Input('viewOnly') viewOnly: boolean = false;

  /** Send list updates to the consumer */
  @Output('listUpdates') listUpdates = new EventEmitter;

  /** Send event to the consumer when component expands*/
  @Output('componentExanded') componentExanded = new EventEmitter;

  /** Should the input row be visible? */
  showInput: boolean = false;

  /** Input link form */
  newLinkForm: UntypedFormGroup;

  constructor(
    private formBuilder: UntypedFormBuilder
  ) {
    this.newLinkForm = this.formBuilder.group({
      link: ["", Validators.required]
    })
  }

  ngOnInit() {
    console.log("existingLinkList",this.existingLinkList);

    if (this.existingLinkList && this.existingLinkList.length) {
    } else {
      this.existingLinkList = [];
    }
  }

  addLink() {
    console.log("showInput");
    this.showInput = true;
    this.componentExanded.emit();
  }

  pushLinkToList() {
    console.log('pushLinkToList(): ', this.newLinkForm.value.link);
    this.existingLinkList.push(this.newLinkForm.value.link);
    this.newLinkForm.reset();

    this.listUpdates.emit(this.existingLinkList);
    this.componentExanded.emit();
  }

  removeLink(link) {
    if (this.existingLinkList.indexOf(link) > -1) {
      this.existingLinkList.splice(this.existingLinkList.indexOf(link), 1);
      if(this.existingLinkList.length == 0){
        this.showInput = false;
      }
      this.listUpdates.emit(this.existingLinkList);
    }
  }

  openLink(link) {
    Browser.open({ url: link });
  }

}

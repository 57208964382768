// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pro_student_row ion-card {
  width: 100%;
  background: var(--bg-lavendar);
  cursor: pointer;
  margin-top: 6px;
  margin-bottom: 6px;
}
.pro_student_row ion-card ion-card-header {
  padding-top: 14px;
  padding-bottom: 5px;
}
.pro_student_row ion-card ion-card-header.header_padding {
  padding-top: 8px;
  padding-bottom: 0;
}
.pro_student_row ion-card ion-card-header ion-card-subtitle {
  color: var(--bg-serious-purple-accent);
  font-weight: 500;
  font-size: 0.7rem;
}
.pro_student_row ion-card ion-card-header ion-card-subtitle span {
  font-size: 1.2rem;
}
.pro_student_row ion-card ion-card-header .top-right-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px; /* Adjust this value as needed */
}
.pro_student_row ion-card ion-card-content.content_padding {
  padding-bottom: 10px;
  padding-left: 16px;
}
.pro_student_row ion-card ion-card-content h3 {
  font-size: 1rem;
  font-family: Poppins-semibold;
  color: var(--bg-serious-purple-accent);
}
.pro_student_row ion-card ion-card-content ion-button {
  --background: var(--bg-purple-shout);
  --color: var(--ion-color-light);
  --background-activated: var(--shout-steps-purple-dark);
}`, "",{"version":3,"sources":["webpack://./src/app/pro-student/components/pro-student-button/pro-student-button.component.scss"],"names":[],"mappings":"AACE;EACE,WAAA;EACA,8BAAA;EACA,eAAA;EACA,eAAA;EACA,kBAAA;AAAJ;AACI;EACE,iBAAA;EACA,mBAAA;AACN;AAAM;EACE,gBAAA;EACA,iBAAA;AAER;AAAM;EACE,sCAAA;EACA,gBAAA;EACA,iBAAA;AAER;AADQ;EACE,iBAAA;AAGV;AACM;EACE,kBAAA;EACA,SAAA;EACA,WAAA;EACA,eAAA,EAAA,gCAAA;AACR;AAKM;EACE,oBAAA;EACA,kBAAA;AAHR;AAKM;EACE,eAAA;EACA,6BAAA;EACA,sCAAA;AAHR;AAKM;EACE,oCAAA;EACA,+BAAA;EACA,sDAAA;AAHR","sourcesContent":[".pro_student_row {\n  ion-card {\n    width: 100%;\n    background: var(--bg-lavendar);\n    cursor: pointer;\n    margin-top: 6px;\n    margin-bottom: 6px;\n    ion-card-header {\n      padding-top: 14px;\n      padding-bottom: 5px;\n      &.header_padding {\n        padding-top: 8px;\n        padding-bottom: 0;\n      }\n      ion-card-subtitle {\n        color: var(--bg-serious-purple-accent);\n        font-weight: 500;\n        font-size: 0.7rem;\n        span {\n          font-size: 1.2rem;\n        }\n      }\n\n      .top-right-icon {\n        position: absolute;\n        top: 10px;\n        right: 10px;\n        font-size: 24px; /* Adjust this value as needed */\n      }\n\n      \n    }\n    ion-card-content {\n      &.content_padding {\n        padding-bottom: 10px;\n        padding-left: 16px;\n      }\n      h3 {\n        font-size: 1rem;\n        font-family: Poppins-semibold;\n        color: var(--bg-serious-purple-accent);\n      }\n      ion-button {\n        --background: var(--bg-purple-shout);\n        --color: var(--ion-color-light);\n        --background-activated: var(--shout-steps-purple-dark);\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-result-link',
  templateUrl: './result-link.component.html',
  styleUrls: ['./result-link.component.scss'],
})
export class ResultLinkComponent implements OnInit {

  /** List of attached links for display and delete */
  @Input('attachedLinks') attachedLinks;

  /** The formRow we're trying to fill or get filled */
  @Input('formRow') formRow;

  /** What text would like you to be displayed above the link? */
  @Input('headingText') headingText: string = "Screenshot";

  /** Tell the container what the new value of the link is */
  @Output('linkValue') linkValue = new EventEmitter();

  constructor() { }

  ngOnInit() { }

  updateLinkValue(ev) {
    console.log('updateLinkValue(): ', ev.target.value);
    this.linkValue.emit(ev.target.value)
  }

  getLinkValue() {
    if (this.attachedLinks) {
      for (let result of this.attachedLinks) {
        if (result.formRefId === this.formRow.formId && result.displayOrder === this.formRow.order) {
          return result.text;
        }
      }
    }
  }

}

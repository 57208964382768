import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { SignInWithAppleResponse } from '@capacitor-community/apple-sign-in';
import { MenuController, Platform } from '@ionic/angular';
import { getAuth, signOut } from 'firebase/auth';
import { Subject } from 'rxjs';
import { AppUpdateService } from 'src/app/app-update/service/app-update.service';
import { CvService } from 'src/app/cv-builder-new/service/cv.service';
import { MessagingService } from 'src/app/messaging/service/messaging.service';
import { FirebaseService } from 'src/app/services/firebase/firebase.service';
import { HttpService } from 'src/app/services/http/http.service';
import { NotificationsService } from 'src/app/services/notifications/notifications.service';
import { PresenceService } from 'src/app/services/presence/presence.service';
import { ProfileService } from 'src/app/services/profile/profile.service';
import { SubscriptionService } from 'src/app/services/subscription/subscription.service';
import { StorageService } from 'src/app/services/storage/storage.service';
import { WalletService } from 'src/app/services/wallet/wallet.service';
import { AppUnavailableService } from 'src/app/app-unavailable/service/app-unavailable.service';
import { ErrorLogService } from 'src/app/error-logs/service/error-log.service';
import { FirebaseChatService } from 'src/app/services/firebase-chat/firebase-chat-service.service';

@Injectable({
  providedIn: 'root',
})
export class LocalUserService {
  /** My global profile */
  public myProfile;
  public profileUpdated = new Subject<any>();
  /** My global Shout profile */
  public myShoutProfile;
  /** My global UniTaskr profile */
  public uniTaskrProfile;

  /** Are we going to need to link the logins? */
  shouldLinkLogins: boolean = false;
  /** User to link logins */
  registerUserRes: any;
  /** Usre login details form */
  detailsForm;
  /** Currency Code */
  myCurrencyCode: string;
  /** Did the currency code change? */
  currencyCodeChange: Subject<string> = new Subject<string>();

  /** Is the newly logged in user unverified?? */
  notVerified: boolean = false;

  /** Logged in firebase user object */
  firebaseUserObject: any;

  /** When I user apple login I get the user bits here */
  appleLoginUserReult: SignInWithAppleResponse;

  /** Are we in demo mode?? */
  isDemoMode: boolean = false;

  /** Where are we navigating to once we're logged in? */
  targetPath: any;

  constructor(
    private router: Router,
    private platform: Platform,
    private menuController: MenuController,
    private readonly http: HttpService,
    private profileService: ProfileService,
    private subscription: SubscriptionService,
    private cvService: CvService,
    private notificationsService: NotificationsService,
    private presenceService: PresenceService,
    private appUpdateService: AppUpdateService,
    private messagingService: MessagingService,
    private oldFirebaseService: FirebaseService,
    private storageService: StorageService,
    private wallet: WalletService,
    private appUnavailableService: AppUnavailableService,
    private logService: ErrorLogService,
    private chatService: FirebaseChatService
  ) { }

  /**
   * Kick off app once login is successful
   */

  loadUpApp(firebaseUser, targetPath, loading?) {
    if (!this.targetPath) {
      this.targetPath = targetPath;
    }

    // console.log('---------------------------')
    // console.log('Load Up App targetPath: ', this.targetPath)
    // console.log('---------------------------')

    this.loginv3(firebaseUser.email).subscribe(v3Res => {
      // console.log('v3Res: ', v3Res);
      this.storageService.uniTaskrUser = v3Res;
      this.storageService.$uniTaskrUserSubject.next(v3Res);
      this.storageService.firebaseUser = firebaseUser;

      /**
       * Record Logins for UniStatz
       */

      this.appUnavailableService.getDemoModeDetails().then(isDemoMode => {
        // console.log('---------------------------')
        // console.log('Demo Mode: ', isDemoMode)
        // console.log('---------------------------')
        this.isDemoMode = isDemoMode;
      });

      this.profileService.getProfile().subscribe(
        async (myProfileRes) => {
          // console.log('myProfileRes: ', myProfileRes);
          console.log('👠👠👠👠👠 myProfileRes: ', myProfileRes);
          this.uniTaskrProfile = myProfileRes;
          this.oldFirebaseService.myProfile = myProfileRes;
          this.oldFirebaseService.currencyCodeChange.next(myProfileRes.currencyCode);

          /** Get Subscription info */
          this.subscription.getCounts().subscribe((countRes) => {
            // console.log('the countRes: ', countRes);
            this.subscription.countChanged.next(countRes);
          });

          this.subscription.getSingleCount(5).subscribe((singleCountRes) => {
            // console.log('single count: ', singleCountRes);
          });

          console.log('👠👠👠👠👠')
          this.chatService.getMyChats();

          let role: number;
          if (myProfileRes.isStudent == true) {
            role = 3;
          } else if (myProfileRes.isStudent == false && myProfileRes.isBusiness == true) {
            role = 1;
            console.log('👠👠👠👠👠 Role ', role);
          } else if (myProfileRes.isStudent == false && myProfileRes.isBusiness == false) {
            role = 2;
          }

          console.log('👠👠👠👠👠 Role ', role);
          this.subscription.getCurrentSubscription(v3Res.userId).subscribe(
            (subRes) => {
              // console.log('sub: ', subRes);
              this.subscription.currentProPlanChanged.next(subRes);
            },
            (err) => {
              console.log(err);
            }
          );

          /** Load wallet */
          this.wallet.initWallet();

          /** Get Job Types */
          this.cvService.getJobTypes().subscribe((jobTypesRes) => {
            // console.log('jobTypesRes: ', jobTypesRes);
            this.cvService.jobTypesUpdated.next(jobTypesRes);
          });

          /** Currency Code Info */
          this.myCurrencyCode = myProfileRes.currencyCode ? myProfileRes.currencyCode : this.myCurrencyCode;
          this.currencyCodeChange.next(this.myCurrencyCode);
          this.myProfile = myProfileRes;

          this.notificationsService.checkForNewNotifications();
          this.firebaseUserObject = firebaseUser;

          // console.log('******')
          // console.log('My Profile: ', this.uniTaskrProfile);
          // console.log('******')

          this.subscription.initStudentSubscription(role);

          /** Navigate to the correct app page */
          console.log('>>>>>>>>>>>>>>')
          console.log('Before this.targetPath: ', this.targetPath);
          console.log('<<<<<<<<<<<<<<')

          let targetPathVar;
          if (this.targetPath && this.targetPath.url) {
            targetPathVar = this.targetPath.url;
          }
          if (this.targetPath && !this.targetPath.url) {
            targetPathVar = this.targetPath;
          }

          // console.log('>>>>>>>>>>>>>>')
          // console.log('After targetPathVar: ', targetPathVar);
          // console.log('<<<<<<<<<<<<<<')

          if (
            // (targetPathVar &&
            //   (targetPathVar == '/thinking' ||
            //     targetPathVar == '/landing' ||
            //     targetPathVar == '' ||
            //     targetPathVar == '/' ||
            //     targetPathVar.includes('getstarted') ||
            //     targetPathVar.includes('forgot-password')
            //   )
            // )
            (this.targetPath && this.targetPath.url &&
              (this.targetPath.url == '/thinking' ||
                this.targetPath.url == 'thinking' ||
                this.targetPath.url == '/landing' ||
                this.targetPath.url == '' ||
                this.targetPath.url == '/' ||
                this.targetPath.url.includes('getstarted') ||
                this.targetPath.url.includes('forgot-password') ||
                this.targetPath.url == 'getstarted-email'
              )
            )
          ) {
            this.targetPath = '';
          }

          console.log('👠👠👠👠👠 Above logout... ');

          if (this.targetPath == '/logout') {
            const auth = getAuth();
            signOut(auth)
              .then(() => {
                loading?.dismiss();
                this.router.navigate(['/landing']);
              })
              .catch((error) => {
                console.log('Did not sign out');
              });
          }

          this.presenceService.logLoginTransaction(this.firebaseUserObject);

          let mustUpdate = await this.appUpdateService.getLatestAppVersionDetails();
          console.log('👠👠👠👠👠 mustUpdate: ', mustUpdate);
          if (mustUpdate) {
            // console.log('Firebase must update..');
            loading?.dismiss();
            this.router.navigate(['/app-update']);
          } else {

            this.presenceService.setPresence('online');
            this.menuController.enable(true);

            if (location.href.indexOf('deeplink') > -1) {
              console.log('Deep Link Page!');
            } else {
              loading?.dismiss();
              console.log('👠👠👠👠👠 this.myProfile.isStudent: ', this.myProfile.isStudent);
              if (this.myProfile.isStudent) {
                if (this.targetPath) {
                  console.log('Yaaayyyy!!')
                  try {
                    this.targetPath = this.targetPath && this.targetPath.url ? this.targetPath.url.replace('//', '/') : this.targetPath.replace('//', '/');
                  } catch (e) {
                    console.log('cannot replace: ', e);
                  }


                  // console.log('---------------------------')
                  // console.log('TWO Load Up App targetPath: ', this.targetPath.url)
                  // console.log('---------------------------')

                  this.logService.addLog(`TWO Load Up App targetPath: ${this.targetPath.url}`, 'LoadUp 1')

                  try {
                    // this.router.navigate([this.targetPath]);
                    if (myProfileRes.isStudent == true) {
                      if (this.targetPath.url) {
                        this.router.navigateByUrl(this.targetPath.url)
                      } else {
                        // console.log('Would nav - now stop...')
                        // if (this.platform.is('capacitor') && this.platform.is('android')) {
                        this.router.navigate(['/student-earn-btns']);
                        // }
                      }
                    } else {
                      if (this.targetPath.url) {
                        this.router.navigateByUrl(this.targetPath.url)
                      } else {
                        // console.log('STOP! on upgrade page...')
                        // if (this.platform.is('capacitor') && this.platform.is('android')) {
                        this.router.navigate(['/home-hire-tabs/tasks']);
                        // }
                      }
                    }
                  } catch (e) {
                    console.log('One Fail route: ', e)
                  }

                } else {
                  // console.log('Boooooo!!')
                  // console.log('Boooooo!!')
                  // console.log('Boooooo!!')
                  // this.router.navigate(['/home-earn']);
                  if (window.location.href.includes('pro-student-upgrade')) {
                  } else {
                    // this.router.navigate(['/student-earn-tabs']);
                    this.router.navigate(['/student-earn-btns']);
                  }
                }
              } else {
                console.log('👠👠👠👠👠 targetPath: ', this.targetPath)
                // if (this.targetPath) {
                //   console.log('---------------------------')
                //   console.log('👠👠👠👠👠 THREE Load Up App targetPath: ', this.targetPath)
                //   console.log('---------------------------')
                //   try {
                //     // this.router.navigate([this.targetPath]);
                //     if (this.ta)
                //     this.router.navigateByUrl(this.targetPath)
                //   } catch (e) {
                //     console.log('Two Fail route: ', e)
                //   }
                // } else {
                  this.router.navigate(['/home-hire-tabs/tasks']);
                // }
              }
            }
          }
        });
    },
      (err) => {
        console.log('#### Login V3 Error ####');
        console.log('Login v3 error: ', err);
        this.router.navigate(['/landing'])
      }
    );
  }

  loginv3(email: string) {
    return this.http.runHttpCall('POST', 'api/auth/v3/login', 'application/json', {
      email: email,
    });
  }
}

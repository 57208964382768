// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.total_service_card {
  margin: 0;
  height: 150px;
  border-radius: 5px;
  box-shadow: unset !important;
  border: solid 1px var(--bg-very-light-grey);
}
.total_service_card .status_badge {
  position: absolute;
  top: 8px;
  left: 10px;
  font-size: 13px;
  font-weight: lighter;
  --background: #fff;
  --color: var(--bg-serious-purple-accent);
  --padding-top: 4px;
  --padding-end: 8px;
  --padding-bottom: 4px;
  --padding-start: 8px;
}
.total_service_card .status_badge_pending {
  --background: var(--ion-color-warning);
  --color: #fff;
}
.total_service_card .status_badge_approved {
  --background: var(--ion-color-success);
  --color: #fff;
}

.image_row {
  height: 95px;
  width: 100%;
  justify-content: center;
  align-content: center;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.image_row .fill_image {
  object-fit: cover;
}

.title_p {
  font-size: 13px;
  margin-left: 8px;
}`, "",{"version":3,"sources":["webpack://./src/app/my-activity-blaze/components/service-card/service-card.component.scss"],"names":[],"mappings":"AAAA;EACI,SAAA;EACA,aAAA;EACA,kBAAA;EACA,4BAAA;EACA,2CAAA;AACJ;AACI;EACI,kBAAA;EACA,QAAA;EACA,UAAA;EACA,eAAA;EACA,oBAAA;EACA,kBAAA;EACA,wCAAA;EACA,kBAAA;EACA,kBAAA;EACA,qBAAA;EACA,oBAAA;AACR;AACI;EACI,sCAAA;EACA,aAAA;AACR;AACI;EACI,sCAAA;EACA,aAAA;AACR;;AAGA;EACI,YAAA;EACA,WAAA;EACA,uBAAA;EACA,qBAAA;EACA,2BAAA;EACA,sBAAA;EACA,4BAAA;AAAJ;AACI;EACI,iBAAA;AACR;;AAGA;EACI,eAAA;EACA,gBAAA;AAAJ","sourcesContent":[".total_service_card {\n    margin: 0;\n    height: 150px;\n    border-radius: 5px;\n    box-shadow: unset !important;\n    border: solid 1px var(--bg-very-light-grey);\n\n    .status_badge {\n        position: absolute;\n        top: 8px;\n        left: 10px;\n        font-size: 13px;\n        font-weight: lighter;\n        --background: #fff;\n        --color: var(--bg-serious-purple-accent);\n        --padding-top: 4px;\n        --padding-end: 8px;\n        --padding-bottom: 4px;\n        --padding-start: 8px;\n    }\n    .status_badge_pending {\n        --background: var(--ion-color-warning);\n        --color: #fff;\n    }\n    .status_badge_approved {\n        --background: var(--ion-color-success);\n        --color: #fff;\n    }\n}\n\n.image_row {\n    height: 95px;\n    width: 100%;\n    justify-content: center;\n    align-content: center;\n    background-position: center;\n    background-size: cover;\n    background-repeat: no-repeat;\n    .fill_image {\n        object-fit: cover;\n    }\n}\n\n.title_p {\n    font-size: 13px;\n    margin-left: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.green_card {
  background: var(--accent-green-text);
}
.green_card .whole_row {
  background: var(--accent-green-text);
}
.green_card ion-item {
  --background: transparent;
}

.black_card {
  background: #000;
}
.black_card .whole_row {
  background: #000;
}
.black_card ion-item {
  --background: transparent;
}

.whole_row {
  height: 86px;
  width: 100%;
  align-content: center;
  justify-content: center;
}

.countdown_item {
  width: 100%;
}
.countdown_item ion-icon {
  font-size: 32px;
  color: #fff;
}
.countdown_item ion-label h2 {
  font-family: Poppins-Regular !important;
  font-size: 1.125rem;
  color: #fff;
}
.countdown_item ion-label p {
  font-family: Poppins-Regular !important;
  font-size: 1rem;
  color: #fff;
}`, "",{"version":3,"sources":["webpack://./src/app/components/countdown-clock/countdown-clock.component.scss"],"names":[],"mappings":"AAAA;EACI,oCAAA;AACJ;AAAI;EACI,oCAAA;AAER;AAAI;EACI,yBAAA;AAER;;AAEA;EACI,gBAAA;AACJ;AAAI;EACI,gBAAA;AAER;AAAI;EACI,yBAAA;AAER;;AAEA;EACI,YAAA;EACA,WAAA;EACA,qBAAA;EACA,uBAAA;AACJ;;AAEA;EACI,WAAA;AACJ;AACI;EACI,eAAA;EACA,WAAA;AACR;AAEQ;EACI,uCAAA;EACA,mBAAA;EACA,WAAA;AAAZ;AAEQ;EACI,uCAAA;EACA,eAAA;EACA,WAAA;AAAZ","sourcesContent":[".green_card {\n    background: var(--accent-green-text);\n    .whole_row {\n        background: var(--accent-green-text);\n    }\n    ion-item {\n        --background: transparent;   \n    }\n}\n\n.black_card {\n    background: #000;\n    .whole_row {\n        background: #000;\n    }\n    ion-item {\n        --background: transparent;   \n    }\n}\n\n.whole_row {\n    height: 86px;\n    width: 100%;\n    align-content: center;\n    justify-content: center;\n}\n\n.countdown_item {\n    width: 100%;\n\n    ion-icon {\n        font-size: 32px;\n        color: #fff;\n    }\n    ion-label {\n        h2 {\n            font-family: Poppins-Regular !important;\n            font-size: 1.125rem;\n            color: #fff;\n        }\n        p {\n            font-family: Poppins-Regular !important;\n            font-size: 1rem;\n            color: #fff;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

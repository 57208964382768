import { Injectable } from '@angular/core';
import { HttpService } from '../../http/http.service';
import { StorageService } from '../../storage/storage.service';

@Injectable({
  providedIn: 'root'
})
export class ShoutBriefService {

  constructor(
    private http: HttpService,
    private storageService: StorageService
  ) { }

  getShoutBrief(shoutBriefId: string) {
    return this.http.runHttpCallUniCreateApi('GET', `shout-briefs/${shoutBriefId}`, 'application/json');
  }

  getShoutBriefApplication(shoutBriefId: string) {
    let creatorId = this.storageService.firebaseUser.uid;
    return this.http.runHttpCallUniCreateApi(
      "GET",
      `shout-brief-applications/${shoutBriefId}/${creatorId}`,
      "application/json")
  }
}

export class CreateShoutBriefDto {
  ugcBriefId: string;
  title: string;
  briefBudget: number;
  commissionFactor: number;
  businessAccount: number;
  engagementLink: string;
  suggestedCaption: string;
  suggestedHastags: string;
  socialPlatform: number;
  isPublic: boolean;
}

export class ShoutBriefResponseDto extends CreateShoutBriefDto {
  id: string;
}

export interface ShoutBriefApplicationResponse {
  id: string;
  applicationStatus: number;
  createdAt: Date;
  creatorBlazeUid: string;
  shoutBriefId: string;
  submittedPostId: string;
}
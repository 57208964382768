import { Injectable } from '@angular/core';
import AWS from 'aws-sdk/global';
import S3 from 'aws-sdk/clients/s3';
import JSZip from 'jszip';
import Compress from 'compress.js'

import imageCompression from 'browser-image-compression';
import { PopoverController } from '@ionic/angular';
import { ImageLoadingComponent } from 'src/app/popovers/image-loading/image-loading/image-loading.component';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class S3UploadService {


  /** List of progress bars */
  progressBars = [];

  /** Uploaded so far */
  dataUploaded = 10;
  dataTotal = 100;

  constructor(
    private httpClient: HttpClient,
    private popoverController: PopoverController,
  ) { }

  // async uploadFile(file, bucketName: string, baseBucketName?: string) {
  async uploadFile(file, bucketName: string, fileFormat?: string) {

    const popover = await this.popoverController.create({
      component: ImageLoadingComponent,
      cssClass: 'image-loading-popover',
      // event: ev,
      size: 'cover',
      alignment: 'center',
      translucent: true,
      backdropDismiss: false
    });
    await popover.present();

    return new Promise((resolve, reject) => {

      const contentType = file.type;
      console.log('contentType>>>>>>>>>>>', contentType);
      const bucket = new S3(
        {
          accessKeyId: environment.s3_upload.accessKeyId,
          secretAccessKey: environment.s3_upload.secretAccessKey
        }
      );
      let time = new Date().getTime();
      // let filename = `${time}`;
      let filename = `${time}${fileFormat ? `_${fileFormat}` : ''}`;
      const params = {
        // Bucket: baseBucketName ? baseBucketName : 'unitaskr-web-cdn',
        Bucket: 'unitaskr-web-cdn',
        Key: `${bucketName}/${filename}`,
        Body: file,
        ACL: 'public-read',
        ContentType: contentType
      };

      bucket.upload(params, (err, data) => {
        if (err) {
          console.log('There was an error uploading your file: ', err);
          this.popoverController.dismiss();
          reject(err);
          // return false;
        } else {
          console.log('Upload completed successfully.', data);
          this.popoverController.dismiss();
          resolve(data);
          // return data;
        }
      });
      // for upload progress
      bucket.upload(params).on('httpUploadProgress', (evt) => {
        // console.log(evt.loaded + ' of ' + evt.total + ' Bytes');
        this.dataUploaded = evt.loaded;
        this.dataTotal = evt.total;
      }).send(function (err, data) {
        if (err) {
          console.log('There was an error uploading your file: ', err);
          return false;
        }
        console.log('Successfully uploaded file.', data);
        return true;
      });


    });
  }

  async browserCompressUpload(file, bucketName: string, baseBucketName?: string) {

    const popover = await this.popoverController.create({
      component: ImageLoadingComponent,
      cssClass: 'image-loading-popover',
      // event: ev,
      size: 'cover',
      alignment: 'center',
      translucent: true,
      backdropDismiss: false
    });
    await popover.present();

    return new Promise(async (resolve, reject) => {
      const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 1920,
        useWebWorker: true
      }
      try {
        const compressedFile = await imageCompression(file, options);

        const contentType = file.type;
        const bucket = new S3(
          {
            accessKeyId: environment.s3_upload.accessKeyId,
            secretAccessKey: environment.s3_upload.secretAccessKey
          }
        );
        let time = new Date().getTime();
        let filename = `${time}`;
        const params = {
          Bucket: baseBucketName ? baseBucketName : 'unitaskr-web-cdn',
          Key: `${bucketName}/${filename}`,
          Body: compressedFile,
          ACL: 'public-read',
          ContentType: contentType
        };

        bucket.upload(params, (err, data) => {
          if (err) {
            console.log('There was an error uploading your file: ', err);
            this.popoverController.dismiss();
            reject(err);
            // return false;
          } else {
            // console.log('Upload completed successfully.', data);
            this.popoverController.dismiss();
            resolve(data);
            // return data;
          }
        });
        // for upload progress
        bucket.upload(params).on('httpUploadProgress', (evt) => {
          // console.log(evt.loaded + ' of ' + evt.total + ' Bytes');
          this.dataUploaded = evt.loaded;
          this.dataTotal = evt.total;
        }).send(function (err, data) {
          if (err) {
            console.log('There was an error uploading your file: ', err);
            return false;
          }
          console.log('Successfully uploaded file.', data);
          return true;
        });

      } catch (error) {
        console.log("Failed to compress: ", error);
      }
    });
  }


  async zipAndUpload(file, bucketName: string) {

    const popover = await this.popoverController.create({
      component: ImageLoadingComponent,
      cssClass: 'image-loading-popover',
      // event: ev,
      size: 'cover',
      alignment: 'center',
      translucent: true,
      backdropDismiss: false
    });
    await popover.present();

    return new Promise((resolve, reject) => {
      const zip = new JSZip();

      // var img = zip.folder("images");
      var img = zip.file("New_zip", file, { base64: true });

      zip.generateAsync({ type: "blob" }).then((content) => {
        console.log(content)
        // see FileSaver.js
        const contentType = file.type;
        console.log('contentType>>>>>>>>>>>', contentType);
        const bucket = new S3(
          {
            accessKeyId: environment.s3_upload.accessKeyId,
            secretAccessKey: environment.s3_upload.secretAccessKey
          }
        );
        let time = new Date().getTime();
        let filename = `${time}`;
        const params = {
          Bucket: 'unitaskr-web-cdn',
          // Key: `${bucketName}/${filename}`,
          Key: `${filename}.zip`,
          Body: content,
          ACL: 'public-read',
          ContentType: contentType
        };

        bucket.upload(params, function (err, data) {
          if (err) {
            console.log('There was an error uploading your file: ', err);
            this.popoverController.dismiss();
            reject(err);
            // return false;
          } else {
            console.log('Upload completed successfully.', data);
            this.popoverController.dismiss();
            resolve(data);
            // return data;
          }
        });
        // for upload progress
        bucket.upload(params).on('httpUploadProgress', function (evt) {
          // console.log(evt.loaded + ' of ' + evt.total + ' Bytes');
          this.dataUploaded = evt.loaded;
          this.dataTotal = evt.total;
        }).send(function (err, data) {
          if (err) {
            console.log('There was an error uploading your file: ', err);
            return false;
          }
          console.log('Successfully uploaded file.', data);
          return true;
        });
      });
    });
  }


  downloadVideo(url: string, fileName: string): void {
    this.httpClient.get(url)
      .subscribe((response: any) => {
        console.log('Response blob!');
        this.saveVideo(response, fileName);
      }, error => {
        console.error('Blob Error downloading video:', error);
      });
  }

  private saveVideo(data: Blob, fileName: string): void {
    const downloadLink = document.createElement('a');
    downloadLink.href = URL.createObjectURL(data);
    downloadLink.download = fileName;
    downloadLink.click();
    URL.revokeObjectURL(downloadLink.href);

    console.log('File downloaded!');
  }
}

export interface awsImageUploadResponse {
  Bucket: string,
  ETag: string,
  Key: string,
  Location: string,
  key: string;
}

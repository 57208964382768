import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Share } from '@capacitor/share';
import { Platform, ToastController } from '@ionic/angular';
import { forkJoin } from 'rxjs';
import { ErrorLogService } from 'src/app/error-logs/service/error-log.service';
import { DeeplinkService } from 'src/app/services/deeplink.service';
import { ShoutDetailsService } from 'src/app/shout-profile/service/shout-details.service';

@Component({
  selector: 'app-shout-results',
  templateUrl: './shout-results.component.html',
  styleUrls: ['./shout-results.component.scss'],
})
export class ShoutResultsComponent implements OnInit {

  /** Input form ref details */
  @Input('shoutFormRef') shoutFormRef;

  /** Input form display */
  @Input('formDisplay') formDisplay;

  /** Which category of form fields are we displaying? */
  @Input('category') category;

  /** Results that have already been posted by the user */
  @Input('existingResults') existingResults;

  /** Which shout are we referring to? */
  @Input('focussedShout') focussedShout;

  /** Which focussedPrizeDraw are we referring to? */
  @Input('focussedPrizeDraw') focussedPrizeDraw;

  /** Results updated */
  @Output('resultUpdated') resultUpdated = new EventEmitter();

  /** List of insights values */
  updatedInsightsArray: any[] = [];

  /** List of poll response values */
  updatedPollsArray: any[] = [];

  /** List of uploaded screenshots for display and delete */
  uploadedScreenshots: any[] = [];

  /** List of existing Spotify Insights data */
  existingSpotifyInsights: any[] = [];

  /** List of existing TikTok engagement data */
  existingTikTokEngagements: any[] = [];

  /** List of existing Poll results data */
  existingPollResults: any[] = [];

  /** List of other results */
  otherResults: any[] = [];

  constructor(
    private router: Router,
    private platform: Platform,
    private toastController: ToastController,
    private shoutDetailsService: ShoutDetailsService,
    private deeplinkService: DeeplinkService,
    private logService: ErrorLogService
  ) { }

  ngOnInit() {

    console.log('shoutFormRef: ', this.shoutFormRef);
    console.log('formDisplay: ', this.formDisplay);

    // Existing Screenshots
    if (this.existingResults && this.existingResults.formScreens) {
      this.uploadedScreenshots = this.existingResults.formScreens;
    }

    // Existing Spotify Insights
    if (this.existingResults && this.existingResults.spotify_Insights) {
      this.existingSpotifyInsights = this.existingResults.spotify_Insights;
    }

    // Existing TikTok Insights
    if (this.existingResults && this.existingResults.tikTokEngagements) {
      this.existingTikTokEngagements = this.existingResults.tikTokEngagements;
    }

    // Existing Poll Results
    if (this.existingResults && this.existingResults.polls) {
      this.existingPollResults = this.existingResults.polls;
    }

    // Existing Other Results
    if (this.existingResults && this.existingResults.otherResults) {
      this.otherResults = this.existingResults.otherResults;
    }
  }

  isRowHidden(formRow) {
    if (formRow.category !== this.category) {
      return true; // Row will be hidden
    }
  }

  getType(formRow) {
    switch (formRow.formCode) {
      // Screenshot cases
      // Pre
      case "Pre_Screenshot":
        return "screenshot";
      case "Pre_Screenshot_Story":
        return "screenshot";
      case "Pre_Screenshot_of_your_TikTok_Profile":
        return "screenshot"
      case "Pre_Screenshot of Your TikTok":
        return "screenshot"
      case "Pre_Screenshot_of_your_Instagram_Profile":
        return "screenshot"
      case "Pre_Screenshot of Your Insta":
        return "screenshot"
      case "Pre_Screenshot of IG Story":
        return "screenshot"
      case "Pre_Screenshot_Of_Results":
        return "screenshot"
      case "Photo Of Receipt":
        return "screenshot"
      // Post
      case "Post_Screenshot":
        return "screenshot";
      case "Post_Screenshot_Of_Results":
        return "screenshot";
      case "Post_Screenshot of Your Insta":
        return "screenshot"

      // IG Insights cases
      case "Post_Spotify_Insights":
        return "ig_insights";
      case "Pre_Insight":
        return "ig_insights";

      // TikTok Insights & Engagement cases
      case "Post_TikTok_Engagement":
        return "tiktok_insights"
      case "Post_Insight":
        return "tiktok_insights"
      case "Pre_TikTok_Engagement":
        return "tiktok_insights"

      // Poll 2 inputs
      case "Post_Poll_With_A_and_B":
        return "poll_a_b";
      case "Pre_Poll_With_A_and_B":
        return "poll_a_b";

      // Poll 3 inputs
      case "Post_Poll_With_A_B_and_C":
        return "poll_a_b_c";

      // Poll 4 inputs
      case "Pre_Poll":
        return "poll_4_inputs"
      case "Post_Poll":
        return "poll_4_inputs"

      // Link Cases
      case "Pre_Link":
        return "link";

      // Referral Cases
      case "Pre_Referral":
        return "referral"
      case "Post_Referral":
        return "referral"

      // Video Cases
      case "Pre_Video":
        return "video"

      // Other Results
      case "Post_Other_Results":
        return "other_results"
    }
  }

  otherUpdate(ev, formRow) {
    console.log('otherUpdate()');
    console.log('ev: ', ev);
    console.log('formRow: ', formRow);

    this.shoutDetailsService.resultOther(
      formRow.taskId,
      formRow.order,
      formRow.id,
      ev.detail.value
    ).subscribe(otherRes => {
      console.log('otherRes: ', otherRes);
      this.resultUpdated.emit(true);
    }, err => {
      console.log('otherRes error: ', err);
    });
  }

  pollUpdate(field, ev, formRow) {
    let updatedPoll = false;
    for (let pollSet of this.updatedPollsArray) {
      if (pollSet.formRow.id == formRow.id && pollSet.formRow.order == formRow.order) {
        pollSet[field] = ev.detail.value;
        updatedPoll = true;
        break;
      }
    }
    if (!updatedPoll) {
      let newSet = {
        formRow: null,
        A: undefined,
        B: undefined,
        C: undefined,
        D: undefined,
      };
      newSet[field] = ev.detail.value
      newSet.formRow = JSON.parse(JSON.stringify(formRow));
      this.updatedPollsArray.push(JSON.parse(JSON.stringify(newSet)));
    }

    // console.log('updatedPollsArray: ', this.updatedPollsArray);
    let fjRequests = [];

    for (let insightSet of this.updatedPollsArray) {
      // If all the fields are valid then post if off...
      let tempSet = JSON.parse(JSON.stringify(insightSet));
      if (tempSet.A && tempSet.B) {
        fjRequests.push(
          this.shoutDetailsService.resultPoll(
            tempSet.formRow.taskId,
            tempSet.formRow.order,
            tempSet.formRow.id,
            tempSet.A,
            tempSet.B,
            tempSet.C,
            tempSet.D
          ));
      }
    }

    forkJoin(fjRequests).subscribe(fjRes => {
      console.log('POLL fjRes: ', fjRes);
      this.resultUpdated.emit(true);
    }, err => {
      console.log('POLL fjRes error: ', err);
    })
  }

  insightUpdate(field, ev, formRow) {
    // console.log('insightUpdate()');
    // console.log('field: ', field);
    // console.log('ev: ', ev.detail.value);
    // console.log('formRow: ', formRow);
    let updatedInsight = false;
    for (let insightSet of this.updatedInsightsArray) {
      if (insightSet.formRow.id == formRow.id && insightSet.formRow.order == formRow.order) {
        insightSet[field] = ev.detail.value;
        updatedInsight = true;
        break;
      }
    }
    if (!updatedInsight) {
      let newSet = {
        formRow: null,
        views: undefined,
        impressions: undefined,
        stickerTaps: undefined,
        websiteClicks: undefined,
        profileVisits: undefined
      };
      newSet[field] = ev.detail.value
      newSet.formRow = JSON.parse(JSON.stringify(formRow));
      this.updatedInsightsArray.push(JSON.parse(JSON.stringify(newSet)));
    }
    // console.log('updatedInsightsArray: ', this.updatedInsightsArray);
    let fjRequests = [];

    for (let insightSet of this.updatedInsightsArray) {
      // If all the fields are valid then post if off...
      let tempSet = JSON.parse(JSON.stringify(insightSet));
      if (tempSet.views && tempSet.impressions && tempSet.stickerTaps && tempSet.websiteClicks && tempSet.profileVisits) {
        fjRequests.push(
          this.shoutDetailsService.resultSpotifyInsight(
            tempSet.formRow.taskId,
            tempSet.formRow.order,
            tempSet.formRow.id,
            tempSet.views,
            tempSet.impressions,
            tempSet.stickerTaps,
            tempSet.websiteClicks,
            tempSet.profileVisits
          ));
      }
    }

    forkJoin(fjRequests).subscribe(fjRes => {
      console.log('fjRes: ', fjRes);
      this.resultUpdated.emit(true);
    }, err => {
      console.log('fjRes error: ', err);
    })
  }

  getOtherResultValue(formRow) {
    // console.log('getOtherResultValue(): ', formRow);
    if (this.otherResults) {
      for (let insightSet of this.otherResults) {
        if (insightSet.formRefId === formRow.formId && insightSet.displayOrder === formRow.order) {
          return insightSet.text;
        }
      }
    }
  }

  getInsightValue(field, formRow) {
    if (this.existingSpotifyInsights) {
      for (let insightSet of this.existingSpotifyInsights) {
        if (insightSet.formRefId === formRow.formId && insightSet.displayOrder === formRow.order) {
          return insightSet[field];
        }
      }
    }
  }

  getPollValue(field, formRow) {
    if (this.existingPollResults) {
      for (let insightSet of this.existingPollResults) {
        if (insightSet.formRefId === formRow.formId && insightSet.displayOrder === formRow.order) {
          return insightSet[field];
        }
      }
    }
  }

  sceenshotLocation(ev, formRow) {
    // Submit this screenshot as a result.
    this.shoutDetailsService.resultScreenshot(
      formRow.taskId,
      formRow.order,
      formRow.id,
      ev
    ).subscribe(screenshotRes => {
      console.log('screenshotRes: ', screenshotRes);
      this.uploadedScreenshots.push(screenshotRes);
      this.resultUpdated.emit(true);
    }, err => {
      console.log('screenshotRes: ', err);
    })
  }

  videoLocation(ev, formRow) {
    console.log('videoLocation(): ', ev);
    console.log('videoLocation(): ', formRow);
    // Submit this video as a result.
    this.shoutDetailsService.resultScreenshot(
      formRow.taskId,
      formRow.order,
      formRow.id,
      ev
    ).subscribe(screenshotRes => {
      console.log('screenshotRes: ', screenshotRes);
      this.uploadedScreenshots.push(screenshotRes);
      this.resultUpdated.emit(true);
    }, err => {
      console.log('screenshotRes: ', err);
    })
  }

  linkValue(ev, formRow) {
    console.log('linkValue()');
    console.log('ev: ', ev);
    console.log('formRow: ', formRow);

    this.shoutDetailsService.resultOther(
      formRow.taskId,
      formRow.order,
      formRow.id,
      ev
    ).subscribe(otherRes => {
      console.log('otherRes: ', otherRes);
      this.resultUpdated.emit(true);
    }, err => {
      console.log('otherRes error: ', err);
    });
  }

  updateReferral(ev, formRow) {
    console.log('updateReferral()');
    console.log('ev: ', ev);
    console.log('formRow: ', formRow);

    // this.shoutDetailsService.resultOther(
    //   formRow.taskId,
    //   formRow.order,
    //   formRow.id,
    //   ev.detail.value
    // ).subscribe(otherRes => {
    //   console.log('otherRes: ', otherRes);
    // }, err => {
    //   console.log('otherRes error: ', err);
    // });
  }

  getResults() {
    if (this.existingResults && this.existingResults.otherResults) {
      this.otherResults = this.existingResults.otherResults;
      return this.otherResults;
    }
  }

  // async shareTask() {
  //   this.deeplinkService.createLinkTask(`shout-profile/${this.focussedShout.taskId}`).subscribe(async linkRes => {
  //     console.log('linkRes: ', linkRes);

  //     try {
  //       await Share.share({
  //         title: 'UniTaskr',
  //         text: `Join the SHOUT: ${this.focussedShout.title}! Use my referral code for an extra £5! ${this.focussedPrizeDraw.user.referralCode}`,
  //         url: linkRes.shortLink,
  //         dialogTitle: 'Share SHOUT'
  //       });
  //     } catch (e) {
  //       console.log('Caught error: ', e);
  //     }
  //   }, err => {
  //     console.log('linkRes error: ', err);
  //     this.logService.addLog(`linkRes error ${JSON.stringify(err)}`, this.router.url);
  //     this.logService.addLogFirebase(JSON.stringify(err), this.router.url, 2);
  //     this.logService.addCodeLogFirebase(JSON.stringify(err), this.router.url, 2);
  //   });

  // }

  async shareTask() {
    this.deeplinkService.createLinkTask(`shout-profile/${this.focussedShout.taskId}`)
    .subscribe(
      async (linkRes) => {

        this.deeplinkService.createShortLink(linkRes.shortLink).subscribe(shortLinkRes => {
          console.log('shortLinkRes: ', shortLinkRes);

          this.deeplinkService.customiseBackHalf(shortLinkRes.id, `${(new Date().getTime())}`).subscribe(async customRes => {
            console.log('customRes: ', customRes);

            if (this.platform.is('capacitor')) {
              try {
                await Share.share({
                  title: 'UniTaskr',
                  text: `Join the SHOUT: ${this.focussedShout.title}! Use my referral code for an extra £5! ${this.focussedPrizeDraw.user.referralCode}`,
                  url: customRes.custom_bitlink,
                  dialogTitle: 'Share SHOUT'
                });
              } catch (e) {
                console.log('Caught error: ', e);
              }
            } else {
              let copiedText = await navigator.clipboard.writeText(customRes.custom_bitlink,);
              console.log('copiedText: ', copiedText);
              const toast = await this.toastController.create({
                message: 'Sharing link copied to clipboard!',
                duration: 3500,
                position: 'middle',
              });
  
              await toast.present();
            }

          })
        })
    }, err => {
      console.log('linkRes error: ', err);
      this.logService.addLog(`linkRes error ${JSON.stringify(err)}`, this.router.url);
      this.logService.addLogFirebase(JSON.stringify(err), this.router.url, 22);
      this.logService.addCodeLogFirebase(JSON.stringify(err), this.router.url, 22);
    });

  }
}

// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.attachment_btn_row {
  width: 100%;
  margin-left: 20px;
}

.attachment_btn {
  --background: var(--bg-super-light-grey);
  --color: #000;
  font-size: 0.875rem;
}
.attachment_btn label {
  padding: 0;
  font-size: 0.875rem;
}`, "",{"version":3,"sources":["webpack://./src/app/components/add-link-array/add-link-array.component.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,iBAAA;AACJ;;AAEA;EACI,wCAAA;EACA,aAAA;EACA,mBAAA;AACJ;AAAI;EACI,UAAA;EACA,mBAAA;AAER","sourcesContent":[".attachment_btn_row {\n    width: 100%;\n    margin-left: 20px;\n}\n\n.attachment_btn {\n    --background: var(--bg-super-light-grey);\n    --color: #000;\n    font-size: 0.875rem;\n    label {\n        padding: 0;\n        font-size: 0.875rem;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

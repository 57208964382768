// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chip-container-row {
  width: 100%;
  align-content: center;
  background: var(--bg-light-blue);
  padding-top: 3%;
  padding-bottom: 2%;
}
.chip-container-row .chip-chip {
  --background: #fff;
  color: #000;
  font-family: Poppins-Regular !important;
  font-weight: lighter;
}`, "",{"version":3,"sources":["webpack://./src/app/components/title-and-chips/title-and-chips.component.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,qBAAA;EACA,gCAAA;EACA,eAAA;EACA,kBAAA;AACJ;AACI;EACI,kBAAA;EACA,WAAA;EACA,uCAAA;EACA,oBAAA;AACR","sourcesContent":[".chip-container-row {\n    width: 100%;\n    align-content: center;\n    background: var(--bg-light-blue);\n    padding-top: 3%;\n    padding-bottom: 2%;\n\n    .chip-chip {\n        --background: #fff;\n        color: #000;\n        font-family: Poppins-Regular !important;\n        font-weight: lighter;\n    }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

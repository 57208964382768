import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import schoolJson from '../../../assets/sundry_data/schools.json';

@Injectable({
  providedIn: 'root'
})
export class SchoolsearchService {

  constructor(
    private http: HttpClient
  ) { }

  schoolSearch(queryText: string) {  
    let returnResults = schoolJson.schools.filter(school => {
      if (school.name.toLowerCase().includes(queryText.toLowerCase())) {
        return school;
      }
    });
    if (returnResults.length > 5) {
      returnResults = returnResults.slice(0, 5);
    }
    return returnResults;
  }
}

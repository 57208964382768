import { Injectable } from '@angular/core';
import { Network } from '@capacitor/network';

@Injectable({
  providedIn: 'root',
})
export class NetworkService {
  public internetConnection = true;
  public backOnline = false;

  previousStatus: boolean;

  constructor() {}

  async checkNetwork() {
    // console.log('************');
    // console.log('checkNetwork()');
    // console.log('************');
    const networkStatus = await Network.getStatus();
    // console.log('networkStatus: ', networkStatus);
    if (networkStatus.connected) {
      this.internetConnection = true;
      this.previousStatus = true;
    } else {
      this.internetConnection = true;
      this.previousStatus = true;
    }
  }

  listenToNetwork() {
    Network.addListener('networkStatusChange', async (status) => {
      // console.log('Network Status: ', status);
      this.toasting(status);
    });
  }

  async toasting(status) {
    console.log('toasting()');
    if (status && status.connected) {
      this.internetConnection = true;
      this.backOnline = true;

      setTimeout(() => {
        this.backOnline = false;
      }, 1000);
    } else {
      // console.log('Show the toast!!');
      this.internetConnection = false;
    }
  }
}

import { CurrencyPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyService } from '../services/currency/currency.service';

@Pipe({
  name: 'theCurrency',
})
export class TheCurrencyPipe implements PipeTransform {
  constructor(private currencyPipe: CurrencyPipe) {}

  transform(value: number, from: string, to: string, conversionRate: any) {
    // console.log('from: ', from);
    // console.log('to: ', to);
    // console.log('value: ', value);
    // console.log('conversionRate: ', conversionRate);
    if (conversionRate == undefined) {
      return 'No Rate';
    }
    if (to == '') {
      to = 'GBP';
    }

    let newValue = value * conversionRate;
    let returnValue = this.currencyPipe.transform(newValue, from, 'symbol', '1.2-2');
    return returnValue;
  }
}

// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.attachment_row .attachment_button {
  --background: #fff;
  --color: var(--bg-light-grey);
}

.insight_card {
  margin-top: 2%;
  margin-bottom: 2%;
}
.insight_card .insight_card_title {
  font-size: 1.25rem;
  margin-left: 3.5%;
  margin-top: 4%;
}
.insight_card .insight_card_content {
  padding-inline-start: 12px;
  padding-inline-end: 12px;
}
.insight_card .insight_card_content .insight_card_content_row {
  width: 100%;
}
.insight_card .insight_card_content .insight_card_content_row .ingisht_card_item {
  width: 100%;
  border-radius: 6px;
  --background: var(--bg-light-blue);
}

.poll_card {
  margin-top: 2%;
  margin-bottom: 2%;
}
.poll_card .poll_card_title {
  font-size: 1.25rem;
  margin-left: 3.5%;
  margin-top: 4%;
}
.poll_card .poll_card_content {
  padding-inline-start: 12px;
  padding-inline-end: 12px;
}
.poll_card .poll_card_content .poll_card_content_row {
  width: 100%;
}
.poll_card .poll_card_content .poll_card_content_row .poll_card_item {
  width: 100%;
  border-radius: 6px;
  --background: var(--bg-light-blue);
}`, "",{"version":3,"sources":["webpack://./src/app/components/shout-results-after/shout-results-after.component.scss"],"names":[],"mappings":"AACI;EACI,kBAAA;EACA,6BAAA;AAAR;;AAIA;EACI,cAAA;EACA,iBAAA;AADJ;AAGI;EACI,kBAAA;EACA,iBAAA;EACA,cAAA;AADR;AAII;EACI,0BAAA;EACA,wBAAA;AAFR;AAGQ;EACI,WAAA;AADZ;AAGY;EACI,WAAA;EACA,kBAAA;EACA,kCAAA;AADhB;;AAOA;EACI,cAAA;EACA,iBAAA;AAJJ;AAMI;EACI,kBAAA;EACA,iBAAA;EACA,cAAA;AAJR;AAOI;EACI,0BAAA;EACA,wBAAA;AALR;AAMQ;EACI,WAAA;AAJZ;AAMY;EACI,WAAA;EACA,kBAAA;EACA,kCAAA;AAJhB","sourcesContent":[".attachment_row {\n    .attachment_button {\n        --background: #fff;\n        --color: var(--bg-light-grey);\n    }\n}\n\n.insight_card {\n    margin-top: 2%;\n    margin-bottom: 2%;\n\n    .insight_card_title {\n        font-size: 1.25rem;\n        margin-left: 3.5%;\n        margin-top: 4%;\n    }\n\n    .insight_card_content {\n        padding-inline-start: 12px;\n        padding-inline-end: 12px;\n        .insight_card_content_row {\n            width: 100%;\n            // justify-content: center;\n            .ingisht_card_item {\n                width: 100%;\n                border-radius: 6px;\n                --background: var(--bg-light-blue);\n            }\n        }\n    }\n}\n\n.poll_card {\n    margin-top: 2%;\n    margin-bottom: 2%;\n\n    .poll_card_title {\n        font-size: 1.25rem;\n        margin-left: 3.5%;\n        margin-top: 4%;\n    }\n\n    .poll_card_content {\n        padding-inline-start: 12px;\n        padding-inline-end: 12px;\n        .poll_card_content_row {\n            width: 100%;\n            // justify-content: center;\n            .poll_card_item {\n                width: 100%;\n                border-radius: 6px;\n                --background: var(--bg-light-blue);\n            }\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

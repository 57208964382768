import { Injectable } from '@angular/core';
import { HttpService } from 'src/app/services/http/http.service';
import { map, tap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { ServicesStore } from '../state/services.store';

@Injectable({
  providedIn: 'root',
})
export class ServicesService {
  taskCreated: boolean = false;

  jobIndustries;

  constructor(private http: HttpService, private _servicesStore: ServicesStore) {}

  getAllCategories() {
    const servicesStore = this._servicesStore.getValue();
    if (servicesStore.services) {
      console.log('Returning stored cats...');
      return of(servicesStore.services);
    } else {
      console.log('Updating stored cats...');
      return this.updateServicesStore();
    }
  }

  getAllJobIndustries() {
    if (this.jobIndustries) {
      return of(this.jobIndustries);
    }
    return this.http.runHttpCall('GET', 'api/categories/job-industires', 'application/json').pipe(tap(jobTypeRes => {
      this.jobIndustries = jobTypeRes;
    }))
  }

  updateServicesStore() {
    return this.http.runHttpCall('GET', 'api/categories', 'application/json').pipe(
      map((catRes) => {
        let splitRes = {
          businessCategories: [],
          homeCategories: [],
        };
        catRes.forEach((cat) => {
          if (cat.isBusiness) {
            splitRes.businessCategories.push(cat);
          } else {
            splitRes.homeCategories.push(cat);
          }
        });
        return splitRes;
      }),
      tap((res) => {
        const timeNow = new Date();
        this._servicesStore.update({
          services: res,
          servicesLastUpdated: timeNow.toString(),
        });
      })
    );
  }

  getSubCategories(categoryId: number): Observable<SubCategoryListResponse[]> {
    return this.http.runHttpCall('POST', 'api/categories/sub', 'application/json', {
      categoryId,
    });
  }

  searchBySubCategory(subCatId: number): Observable<any[]> {
    console.log('searchBySubCategory(): ', subCatId);
    return this.http.runHttpCall('POST', 'api/services/search/subcategory', 'application/json', {
      subCategoryId: subCatId,
      take: 10,
      skip: 0,
    });
  }

  searchByKeyword(keyword: string): Observable<any[]> {
    return this.http.runHttpCall('POST', 'api/services/search/keyword', 'application/json', {
      keyword: keyword,
      page: {
        take: 10,
        skip: 0,
      },
    });
  }

  searchByKeywordV2(
    keyword: string,
    categoryIds: number[],
    subCategoryIds: number[],
    skips?: number
  ): Observable<any[]> {
    return this.http.runHttpCall('POST', 'api/services/search/v2', 'application/json', {
      searchQuery: keyword,
      page: {
        take: 10,
        skip: skips,
      },
      // location: {
      //   distance: 0,
      //   lat: 0,
      //   lon: 0,
      //   locationType: 0
      // },
      categoryIds: categoryIds,
      subCategoryIds: subCategoryIds,
    });
  }

  getServiceById(id: number): Observable<ServiceDetailsResponse> {
    return this.http.runHttpCall('GET', `api/services/user/${id}`, 'application/json');
  }

  createTask(taskRequest) {
    return this.http.runHttpCall('POST', `api/tasks/user`, 'application/json', taskRequest);
  }

  createDraftTask(taskRequest) {
    return this.http.runHttpCall('POST', `api/tasks/user/draft`, 'application/json', taskRequest);
  }

  updateTask(taskRequest, taskId) {
    return this.http.runHttpCall('PUT', `api/tasks/user/${taskId}`, 'application/json', taskRequest);
  }

  createService(postServiceRequest) {
    return this.http.runHttpCall('POST', `api/services`, 'application/json', postServiceRequest);
  }

  updateService(serviceId, postServiceRequest) {
    return this.http.runHttpCall('PUT', `api/services/${serviceId}`, 'application/json', postServiceRequest);
  }

  deleteService(serviceId) {
    return this.http.runHttpCall('DELETE', `api/services/${serviceId}`, 'application/json');
  }
}

export interface SubCategoryListResponse {
  popularCarouselUrl: string;
  servicesCategoryId: number;
  servicesSubcategoryId: number;
  subcategoryName: string;
}

export interface ServiceDetailsResponse {
  attachments: any[];
  availability: string;
  background: string;
  categories: any;
  favUser: number;
  googleLocation: string;
  isDelete: boolean;
  lat: number;
  links: any[];
  location: string;
  locationType: number;
  lon: number;
  offer: any;
  otherReviews: any[];
  peopleAlsoViewed: any[];
  pictureUrl: string;
  price: number;
  priceincluded: string;
  profilePictureUrl: string;
  reviews: any[];
  serviceUserId: number;
  servicesCategoryId: number;
  servicesSubcategoryId: number;
  studentServiceId: number;
  subCategories: any;
  title: string;
  userId: number;
  userName: string;
}

export interface CategoryInterface {
  categoryId: number;
  servicesSubcategoryId: number;
  name: string;
  image: string;
  open: boolean;
  subCategories: {
    name: string;
    image: string;
  }[];
}

import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { HttpService } from 'src/app/services/http/http.service';

@Injectable({
  providedIn: 'root'
})
export class PriceChangeService {

  /** Observable to watch price changes */
  $watchPriceChange = new BehaviorSubject({});

  constructor(
    private http: HttpService
  ) { }

  /**
   * TASKS
   */
  requestPriceChange(taskOfferId: number, price: number) {
    return this.http.runHttpCall(
      "POST",
      `api/pricechange/task-offer/${taskOfferId}`,
      "application/json",
      { proposedPrice: price }
    )
  }

  getPriceChangeById(changeId: string) {
    return this.http.runHttpCall(
      "GET",
      `api/pricechange/${changeId}`,
      "application/json"
    )
  }

  declinePriceChange(changeId: string) {
    return this.http.runHttpCall(
      "POST",
      `api/pricechange/${changeId}/decline`,
      "application/json"
    )
  } 
  
  counterPriceChange(changeId: string, price: number) {
    return this.http.runHttpCall(
      "POST",
      `api/pricechange/${changeId}/reject`,
      "application/json", {
        propsoedPrice: price
      }
    )
  } 
  
  acceptPriceChange(changeId: string) {
    return this.http.runHttpCall(
      "POST",
      `api/pricechange/${changeId}/accept`,
      "application/json"
    )
  } 


  /**
   * SERVICES
   */
   requestPriceChangeService(serviceOfferId: number, price: number) {
    return this.http.runHttpCall(
      "POST",
      `api/pricechange/service-offer/${serviceOfferId}`,
      "application/json",
      { proposedPrice: price }
    )
  }

}
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController, LoadingController, ModalController } from '@ionic/angular';
import { ServicesService } from 'src/app/hiring-wizard/service/services.service';
import { FirebaseService } from 'src/app/services/firebase/firebase.service';
import { ProfileService } from 'src/app/services/profile/profile.service';
import { StorageService } from 'src/app/services/storage/storage.service';
import { SubscriptionSection } from '../../plan/plan-subscriptionSection.enum';
import { TrackableType } from '../../plan/plan-trackable-type.enum';
import { SubscriptionService } from '../../services/subscription/subscription.service';

@Component({
  selector: 'app-pro-modal',
  templateUrl: './pro-modal.page.html',
  styleUrls: ['./pro-modal.page.scss'],
})
export class ProModalPage implements OnInit {
  proLimit: number;
  proPlan: string;
  typeName: string;
  sectionName: string = 'Tasking';
  trackingCount: any;
  @Input('trackableItemType') trackableItemType = TrackableType.TaskPosted;
  @Input('category') category;
  @Input('notRegistered') notRegistered;
  @Input('upgradingToPost') upgradingToPost?;

  taskAlertFigures = [
    {
      categoryId: 20,
      name: 'Tutoring',
      alerts: 419,
    },
    {
      categoryId: 3,
      name: 'Marketing',
      alerts: 77220,
    },
    {
      categoryId: 5,
      name: 'Tech',
      alerts: 23832,
    },
    {
      categoryId: 1,
      name: 'Design',
      alerts: 55042,
    },
    {
      categoryId: 6,
      name: 'Photography',
      alerts: 39786,
    },
    {
      categoryId: 4,
      name: 'Writing',
      alerts: 43482,
    },
    {
      categoryId: 2,
      name: 'Business',
      alerts: 37149,
    },
    {
      categoryId: 17,
      name: 'DJ',
      alerts: 86,
    },
    {
      categoryId: 19,
      name: 'Gardening',
      alerts: 168,
    },
    {
      categoryId: 15,
      name: 'Delivery',
      alerts: 97357,
    },
    {
      categoryId: 7,
      name: 'Cleaning',
      alerts: 31933,
    },
    {
      categoryId: 14,
      name: 'Extra Hands',
      alerts: 38974,
    },
    {
      categoryId: 9,
      name: 'Flat Pack',
      alerts: 19301,
    },
    {
      categoryId: 10,
      name: 'Tech Help',
      alerts: 25416,
    },
    {
      categoryId: 11,
      name: 'Organising',
      alerts: 41525,
    },
    {
      categoryId: 12,
      name: 'Pets',
      alerts: 43390,
    },
    {
      categoryId: 13,
      name: 'Child Care',
      alerts: 37207,
    },
    {
      categoryId: 16,
      name: 'Something else',
      alerts: 35801,
    },
  ];

  taskAlerts;

  constructor(
    private router: Router,
    private modalController: ModalController,
    private alertController: AlertController,
    private subscription: SubscriptionService,
    private servicesService: ServicesService,
    private storageService: StorageService,
    private profileService: ProfileService,
    private loadingCtrl: LoadingController
  ) {}

  ngOnInit() {
    this.proPlan = this.subscription.currentProPlan.name;
    const section: SubscriptionSection = this.subscription.currentProPlan.section;
    this.sectionName = SubscriptionSection[section];

    console.log(this.category);

    this.taskAlerts = this.taskAlertFigures.filter((categoryObj) => categoryObj.categoryId == this.category)[0];

    console.log(this.taskAlerts);

    this.servicesService.getAllCategories().subscribe((catRes) => {
      console.log('catRes: ', catRes);
    });

    this.trackingCount = this.subscription.count.find((count) => count.trackableType == this.trackableItemType);
    const trackableType: TrackableType = this.trackableItemType;

    if (this.trackingCount) {
      this.proLimit = this.trackingCount.totalAvailable;
      this.typeName = 'tasks';
      if (this.trackableItemType == TrackableType.RecruitPosted) {
        this.typeName = 'jobs';
      } else if (TrackableType[trackableType].includes('Chat')) {
        this.typeName = 'conversations';
      }
    }
  }

  upgradePlan() {
    this.modalController.dismiss();
    this.router.navigate([`/plan/${this.sectionName.toLowerCase()}`], {
      queryParams: {
        upgradingToPost: this.upgradingToPost ? this.upgradingToPost : false,
      },
    });
  }

  async saveToDrafts() {
    const loading = await this.loadingCtrl.create();
    await loading.present();
    this.storageService.getAndParse('createTaskReq').then((taskReq) => {
      console.log(taskReq);
      this.servicesService.createDraftTask(taskReq).subscribe(
        (savedDraft) => {
          console.log('Draft saved!');
          this.storageService.Remove('createTaskReq');
          this.profileService.getProfile().subscribe(async (profileRes) => {
            await loading.dismiss();

            const alert = await this.alertController.create({
              header: 'Task Saved',
              message: "Job has been saved to drafts, go to 'My Activity' to find all your posts.",
              buttons: ['OK'],
            });

            await alert.present();

            this.modalController.dismiss();
            if (!profileRes.isStudent && !this.notRegistered) {
              this.router.navigate(['/home-hire-tabs/tasks']);
            } else if (profileRes.isStudent && !this.notRegistered) {
              // this.router.navigate(['/home-earn']);
              this.router.navigate(['/student-earn-btns']);
            } else {
            }
          });
        },
        async (err) => {
          await loading.dismiss();
          this.modalController.dismiss();
          console.log(err);
        }
      );
    });
  }

  closeModal() {
    this.modalController.dismiss();
  }
}

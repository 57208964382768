import { Injectable } from '@angular/core';
// import { Intercom } from '@capacitor-community/intercom';
import { Platform } from '@ionic/angular';
import { StorageService } from '../storage/storage.service';

@Injectable({
  providedIn: 'root'
})
export class IntercomService {

  constructor(
    private platform: Platform,
    private storageService: StorageService
  ) { }

  initIntercom() {

    console.log('initIntercom()')
    console.log('email: ', this.storageService.uniTaskrUser.email)
    console.log('userId: ', this.storageService.uniTaskrUser.userId)

    let options = {
      userId: this.storageService.uniTaskrUser.userId.toString(),
      email: this.storageService.uniTaskrUser.email,
    }


    // Intercom.registerIdentifiedUser(options); // or email or both
    // Intercom.updateUser({
    //   userId: this.storageService.uniTaskrUser.userId.toString(),
    //   email: this.storageService.uniTaskrUser.email,
    //   name: `${this.storageService.uniTaskrUser.firstName} ${this.storageService.uniTaskrUser.lastName}`,
    //   customAttributes: {}
    // });

    // Intercom.displayMessageComposer({ message: 'Hello there!' });
  }


  displayLauncher() {
    if (this.platform.is('capacitor')) {
      // Intercom.displayLauncher();
    }
  }
  hideLauncher() {
    if (this.platform.is('capacitor')) {
      // Intercom.hideLauncher();
    }
  }

  displayMessenger() {
    if (this.platform.is('capacitor')) {
      // Intercom.displayMessageComposer({ message: "Hello! Please could you help me with ..." });
    }
  }
}

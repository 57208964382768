import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { FirebaseAuthentication } from '@capacitor-firebase/authentication';
import { initializeApp } from 'firebase/app';
import {
  Auth,
  applyActionCode,
  confirmPasswordReset,
  createUserWithEmailAndPassword,
  fetchSignInMethodsForEmail,
  getAuth,
  signInWithEmailAndPassword,
  signOut,
  verifyPasswordResetCode,
} from 'firebase/auth';
import { StorageService } from 'src/app/services/storage/storage.service';
import { environment } from '../../../../environments/environment';
import { DeviceService } from '../device/device.service';
import { LocalUserService } from '../local-user/local-user.service';
import { UniUserService } from '../uni-user/uni-user.service';
import { AuthService } from '../auth/auth.service';
import { SignInWithAppleResponse } from '@capacitor-community/apple-sign-in';
import { ErrorLogService } from 'src/app/error-logs/service/error-log.service';
import { ConfigService } from '../../../services/config-service/config-service.service';
// import { deepCopy } from '@angular-devkit/core';

@Injectable({
  providedIn: 'root'
})
export class FirebaseService {
  private auth: Auth;

  /** Global Firebase Initialised App */
  firebaseApp;

  /** Global firebase user */
  firebaseUser;
  /** Global User Module User */
  userModuleUser;
  /** Global My Currency Code */
  myCurrencyCode;

  /** Should we pester the user about verifying their email? */
  showNotVerifiedBanner = false;

  /** Should the auth state change act on it's ears? */
  firstTimeEntry = true;

  /** Where did the user want to go when they launched the app? */
  targetPath: { url: string, location: string };
  // targetPath: string;

  /** They've logged in with Apple, user */
  appleLoginUserReult: SignInWithAppleResponse;

  /** Paths to ignore on auth change and not route */
  ignorePaths = ['auth-action', 'connect-tiktok'];
  // ignorePaths = ['auth-action'];

  constructor(
    private router: Router,
    private localUserService: LocalUserService,
    private uniUserService: UniUserService,
    private deviceService: DeviceService,
    private storageService: StorageService,
    private authService: AuthService,
    private logService: ErrorLogService,
    private configService: ConfigService,
  ) {
    
    this.targetPath = Object.assign({ url: window.location.href.replace('https://web.unitaskr.com/', '').replace('https://uniblaze.web.app/', '').replace('http://localhost:8100/', '').replace('capacitor://localhost', '').replace('/?', '?') });
    // this.targetPath = window.location.href.replace('https://web.unitaskr.com/', '').replace('https://uniblaze.web.app/', '').replace('http://localhost:8100/', '').replace('capacitor://localhost', '').replace('/?', '?');
    

    // console.log('******************');
    // console.log('000 Taarget Path: ', this.targetPath);
    // console.log('******************');
    // this.logService.addLog(`000 Taarget Path: ${JSON.stringify(this.targetPath)}`, 'FB Service 0')

    // this.targetPath = deepCopy(window.location.href);
    // this.firebaseApp = initializeApp(environment.firebaseConfig);
    // this.configService.loadEnvConfig();
    this.auth = getAuth(this.firebaseApp);
  }

  startListeningToAuthStateChange() {
    const auth = getAuth();
    auth.onAuthStateChanged(async (user: any) => {

      // console.log('******************');
      // console.log('111 Taarget Path: ', this.targetPath);
      // console.log('******************');
      // this.logService.addLog(`111 Taarget Path: ${this.targetPath}`, 'FB Service 1')


      // auth ignore paths
      console.log("Try ignore path.... ", this.targetPath.url)
      if (this.ignorePaths.some(path => this.targetPath.url.includes(path))) {
        console.log('ignore path', this.targetPath.url);
        return; // stay on ignored page
      }

      // console.log('******************');
      // console.log('NEW Auth State Changed: ', user);
      // console.log('NEW Auth State Changed');
      // console.log('******************');

      // this.logService.addLog(`NEW Auth State Changed`, 'FB Service NEW Auth')

      if (user == null) {
        this.firstTimeEntry = true;
        // console.log('User is null -> go to landing...');
        if (this.targetPath.url.includes('connect-tiktok')) {
          this.router.navigate(['/connect-tiktok']);
        } else {
          this.router.navigate(['/landing']);
        }
      } else if (user) {
        this.storageService.token = user.accessToken;

        this.storageService.firebaseUser = user;
        this.firebaseUser = user;
        this.firstTimeEntry = false;

        // console.log('******************');
        // console.log('NEW Stored FB User: ', this.firebaseUser);
        // console.log('******************');
        /** Check if user email is verified */
        if (!user.emailVerified) {
          this.showNotVerifiedBanner = true;
        }

        this.uniUserService.checkEmailAppId(user.email).subscribe(async checkUserRes => {
          /**
           * After checking the email:
           * 1. If all steps are complete
           * 1. 1 Login with User Module
           * 1. 2 Login with Local App
           */

          if (checkUserRes.appCompleted) {
            /** Happiest case -> Log user in */
            this.localUserService.loadUpApp(user, this.targetPath.url);
          } else if (checkUserRes.email && !checkUserRes.appCompleted && checkUserRes.appStarted) {
            /**
             * User is registered with user module, but not with this app
             * 1. Login to the User Module
             * 2. Navigate to complete onboarding
             */

            // Login to the User Module
            this.uniUserService.login(await this.deviceService.getDevice()).subscribe(userModLoginRes => {
              this.userModuleUser = userModLoginRes.user;
              // Navigate to complete onboarding
              this.router.navigate(['/getstarted-type']);
            }, err => {
              console.log('userModLogin ERROR: ', err);
              this.logService.addLog(`userModLogin ERROR ${JSON.stringify(err)}`, 'welcome/services/firebase/firebase.service.ts');
            });
          } else if (checkUserRes.email && checkUserRes.appCompleted && checkUserRes.appStarted) {
            /**
             * User is registered with user module, but not with this app
             * 1. Login to the User Module
             * 2. Navigate to complete onboarding
             */
          }
          else if (!checkUserRes.email && !checkUserRes.appCompleted && !checkUserRes.appStarted) {

            /**
             * User is registered with Firebase, but not registered with anything else.
             * 1. Register with User Module
             * 2. Register with Local App API
             * 3. Proceed to registration type
             */

            /** Set First and Last name */
            let firstName: string = "first"
            let lastName: string = "last"
            if (this.firebaseUser.displayName) {
              firstName = this.firebaseUser.displayName.split(' ')[0];
              lastName = this.firebaseUser.displayName.split(' ')[1];
            } else if (this.appleLoginUserReult) {
              firstName = this.appleLoginUserReult.response.givenName ? this.appleLoginUserReult.response.givenName : `Guest ${this.firebaseUser.uid.substring(this.firebaseUser.uid.length - 6)}`;
              lastName = this.appleLoginUserReult.response.familyName ? this.appleLoginUserReult.response.familyName : `${this.firebaseUser.uid.substring(this.firebaseUser.uid.length - 6)}`;
            }

            // 1. Reg With User Module
            const userModReg = await this.authService.completeUserRegister(this.firebaseUser, this.firebaseUser.accessToken, { firstName: firstName, lastName: lastName });

            userModReg.subscribe(async userModRegRes => {
              this.userModuleUser = {};
              this.userModuleUser.email = this.firebaseUser.email ? this.firebaseUser.email : this.firebaseUser.user.email;
              this.userModuleUser.firstName = firstName;
              this.userModuleUser.lastName = lastName;
              await this.storageService.set('user_password', 'social_login');

              this.router.navigate(['/getstarted-type']);
            }, err => {
              console.log('there has been an error: ', err);
              this.logService.addLog(`there has been an error ${JSON.stringify(err)}`, 'welcome/services/firebase/firebase.service.ts');
            });

          }

        }, err => {
          console.log('FB State Listener checkUserRes error: ', err);
          this.logService.addLog(`FB State Listener checkUserRes error ${JSON.stringify(err)}`, 'welcome/services/firebase/firebase.service.ts');
        });
      }
    });
  }


  signInWithEmailAndPassword(email: string, password: string) {
    return signInWithEmailAndPassword(this.auth, email, password);
    // .finally((firebaseUserRes) => {
    //   this.firebaseUser =  firebaseUserRes
    //   // maybe set token here ?
    // });
  }

  async createUserWithEmailAndPassword(email: string, password: string) {
    // console.log('*** It is a function ***')
    return createUserWithEmailAndPassword(this.auth, email, password);
    // .then((firebaseUserRes) => {
    //   this.firebaseUser =  firebaseUserRes
    //   // maybe set token here ?
    // });

  }

  async checkEmailExists(email: string) {
    const signInMethods = await fetchSignInMethodsForEmail(this.auth, email);
    if (signInMethods.length > 0) {
      return true;
    }
    return false;
  }

  /**
   *
   * @returns The logged in user as a promise
   * Log user in using the Google Login Popover
   */
  async signInWithGoogle() {
    const result = await FirebaseAuthentication.signInWithGoogle();
    return result;
  }

  /**
   *
   * @returns The logged in user as a promise
   * Log user in using the Google Login Popover
   */
  async signInWithApple() {
    // console.log('Welcome FB signInWithApple()');
    const result = await FirebaseAuthentication.signInWithApple(
      { scopes: ["name", "email"] });
    return result;
  }

  async getIdToken() {
    // console.log('_____ getIdToken() _____')
    // const user = await FirebaseAuthentication.getCurrentUser();

    // console.log('_____ getIdToken() User Result _____');
    // console.log('User: ', user);
    // this.firebaseUser = user;
    // this.localUserService.firebaseUserObject = user;
    // console.log('_____ getIdToken() User Result _____');

    // if (!user) {
    //   return null;
    // }

    // this.firebaseUser = user;
    // this.storageService.firebaseUser = user;

    try {
      const token = await FirebaseAuthentication.getIdToken({
        forceRefresh: true,
      });
      // console.log('New token response: ', token);
      this.storageService.token = token.token;
      return token;
    } catch (e) {
      console.log('Error getting new token: ', e);
      return e;
    }


  }

  async checkUserExists(email: string, password?: string) {
    // const auth = getAuth();
    return signInWithEmailAndPassword(this.auth, email, password ? password : 'password');
  }

  applyActionCode(actionCode: string) {
    return applyActionCode(this.auth, actionCode);
  }

  verifyPasswordResetCode(actionCode) {
    return verifyPasswordResetCode(this.auth, actionCode);
  }

  confirmPasswordReset(actionCode, newPassword) {
    return confirmPasswordReset(this.auth, actionCode, newPassword)
  }
}


import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { FirebaseService } from 'src/app/services/firebase/firebase.service';

@Component({
  selector: 'app-upgrade-plan',
  templateUrl: './upgrade-plan.page.html',
  styleUrls: ['./upgrade-plan.page.scss'],
})
export class UpgradePlanPage implements OnInit {
  @Input('upgradeType') upgradeType: 'monthly' | 'annual';

  constructor(
    private modalController: ModalController,
    private router: Router,
    public firebaseService: FirebaseService
  ) {}

  ngOnInit() {
    console.log('&&&&&& Upgrade plan upgradeType: ', this.upgradeType);
  }

  upgrade() {
    this.modalController.dismiss();
    this.router.navigate([`/plan/${this.upgradeType == 'monthly' ? 'monthly' : 'annual'}`]);
  }

  register() {
    this.modalController.dismiss();
    // this.router.navigate([`/reg-with-email/business`]);
    this.router.navigate([`/getstarted-email`]);
  }

  closeModal() {
    this.modalController.dismiss();
  }
}

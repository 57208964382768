// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.title_filter_row ion-item {
  width: 100%;
}
.title_filter_row .heading-2 {
  font-weight: bold;
  font-size: 16px;
  height: 100%;
  font-family: Poppins-bold;
}
.title_filter_row .filter_dropdown {
  border: solid 1px grey;
  border-radius: 24px;
  font-size: 12px;
  --padding-top: 3px;
  --padding-end: 10px;
  --padding-bottom: 3px;
  --padding-start: 10px;
}
.title_filter_row .manage-btn {
  font-size: 16px;
  font-weight: 600;
}

.empty_state_card {
  width: 100%;
  height: 150px;
  background: unset;
  cursor: pointer;
  border-radius: 5px;
}
.empty_state_card .deets-stat--heading {
  color: var(--bg-purple-accent);
  font-size: 14px;
}`, "",{"version":3,"sources":["webpack://./src/app/my-activity-blaze/components/my-jobs/my-jobs.component.scss"],"names":[],"mappings":"AACI;EACI,WAAA;AAAR;AAEI;EACI,iBAAA;EACA,eAAA;EACA,YAAA;EACA,yBAAA;AAAR;AAGI;EACI,sBAAA;EACA,mBAAA;EACA,eAAA;EACA,kBAAA;EACA,mBAAA;EACA,qBAAA;EACA,qBAAA;AADR;AAII;EACI,eAAA;EACA,gBAAA;AAFR;;AAOA;EAEI,WAAA;EACA,aAAA;EACA,iBAAA;EACA,eAAA;EACA,kBAAA;AAJJ;AAMI;EACI,8BAAA;EACA,eAAA;AAJR","sourcesContent":[".title_filter_row {\n    ion-item {\n        width: 100%;\n    }\n    .heading-2 {\n        font-weight: bold;\n        font-size: 16px;\n        height: 100%;\n        font-family: Poppins-bold;\n    }\n\n    .filter_dropdown {\n        border: solid 1px grey;\n        border-radius: 24px;\n        font-size: 12px;\n        --padding-top: 3px;\n        --padding-end: 10px;\n        --padding-bottom: 3px;\n        --padding-start: 10px;\n    }\n\n    .manage-btn {\n        font-size: 16px;\n        font-weight: 600;\n    }\n}\n\n\n.empty_state_card {\n    background: unset;\n    width: 100%;\n    height: 150px;\n    background: unset;\n    cursor: pointer;\n    border-radius: 5px;\n\n    .deets-stat--heading {\n        color: var(--bg-purple-accent); \n        font-size: 14px;\n        // font-weight: bold;\n    }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

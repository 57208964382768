import { Injectable } from '@angular/core';
import { getDocs, doc, getDoc, query, orderBy, getFirestore, limit, collection } from '@firebase/firestore';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AppUnavailableService {

  appStatusDetails;

  currentSessionDemoMode: boolean | undefined = undefined;

  constructor() { }

  async getLatestAppVersionDetails(): Promise<{ status: boolean, version: string}> {
    const db = getFirestore();
    const appStatusRef = collection(db, "appAvailableStatus");
    const q = query(appStatusRef, orderBy('timestamp'), limit(1))

    const querySnapshot = await getDocs(q);

    let versions = [];
    querySnapshot.forEach((doc) => {
      versions.push(doc.data());
    });

    if (versions[0] && versions[0].isAvailable == false) {
      console.log('There is a problem with the app!');
      this.appStatusDetails = versions[0];
      return {
        status: false,
        version: versions[0].reviewVersionNumber
      };
    } else {
      return {
        status: true,
        version: versions[0].reviewVersionNumber
      };
    }
  }

  async getDemoModeDetails(): Promise<boolean> {

    console.log('getDemoModeDetails(): ', environment.applicationVersion);

    if (this.currentSessionDemoMode == undefined) {
      const db = getFirestore();
      /**
       * Get demo mode by this App's Version
       * Demo mode record ID == App Version as set in error-log.service.ts
       */
      const docRef = doc(db, "addDemoMode", environment.applicationVersion);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        console.log("Document data:", docSnap.data());
        let demoModeRecord = docSnap.data(); 
        if (demoModeRecord && demoModeRecord.enabled) {
          this.currentSessionDemoMode = true;
          return true;
        } else {
          this.currentSessionDemoMode = false;
          return false;
        }
      } else {
        // docSnap.data() will be undefined in this case
        console.log("No such document!");
      }
    } else {
      return this.currentSessionDemoMode;
    }

  }
}

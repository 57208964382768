// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.active_channel {
  --background: lightblue;
}
.active_channel h2 {
  color: #000;
}
.active_channel p {
  color: #000;
}

.channel_message_list {
  position: absolute;
  width: -webkit-fill-available;
  top: calc(var(--ion-safe-area-top) + 60px + 38px);
  height: calc(100vh - var(--ion-safe-area-top) - 110px - var(--ion-safe-area-bottom));
  overflow-y: scroll;
}

.load_more_row {
  width: -webkit-fill-available;
  justify-content: center;
  margin-top: 1.5vh;
}`, "",{"version":3,"sources":["webpack://./src/app/messaging/channels/custom-channel-list/custom-channel-list.component.scss"],"names":[],"mappings":"AAAA;EACI,uBAAA;AACJ;AAAI;EACI,WAAA;AAER;AAAI;EACI,WAAA;AAER;;AAEA;EACI,kBAAA;EACA,6BAAA;EACA,iDAAA;EAEA,oFAAA;EACA,kBAAA;AAAJ;;AAGA;EACI,6BAAA;EACA,uBAAA;EACA,iBAAA;AAAJ","sourcesContent":[".active_channel {\n    --background: lightblue;\n    h2 {\n        color: #000;\n    }\n    p {\n        color: #000;\n    }\n}\n\n.channel_message_list {\n    position: absolute;\n    width: -webkit-fill-available;\n    top: calc(var(--ion-safe-area-top) + 60px + 38px);\n    // height: calc(100vh - var(--ion-safe-area-top) - 142px - var(--ion-safe-area-bottom) - 110px)\n    height: calc(100vh - var(--ion-safe-area-top) - 110px - var(--ion-safe-area-bottom));\n    overflow-y: scroll;\n}\n\n.load_more_row {\n    width: -webkit-fill-available;\n    justify-content: center;\n    margin-top: 1.5vh;   \n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

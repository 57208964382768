import {
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
  ViewChildren,
  QueryList,
  AfterViewInit,
  Output,
  EventEmitter,
} from '@angular/core';
import { Router } from '@angular/router';
import { Keyboard } from '@capacitor/keyboard';
import { AlertController, ModalController, Platform } from '@ionic/angular';
import { VerifyStudentCompletePage } from 'src/app/registration-group/verify-student-complete/verify-student-complete.page';
import { FirebaseService } from 'src/app/services/firebase/firebase.service';
import { ModeratorService } from 'src/app/services/moderator/moderator.service';
import { StorageService } from 'src/app/services/storage/storage.service';
import { QuestionsService } from 'src/app/task-questions/service/question.service';
import { LocalUserService } from '../../welcome/services/local-user/local-user.service';
import { VerifyEmailModalPage } from '../../modals/verify-email-modal/verify-email-modal.page';

@Component({
  selector: 'app-task-comment',
  templateUrl: './task-comment.component.html',
  styleUrls: ['./task-comment.component.scss'],
})
export class TaskCommentComponent implements OnInit, AfterViewInit {
  @ViewChildren('messages') messages: QueryList<any>;
  @ViewChild('content') content: ElementRef;

  /** The Task ID */
  @Input('taskId') taskId;

  /** The whole Taks */
  focussedTask;

  /** Who posted the task? */
  @Input('userId') posterId;

  /** The comments we're going to be displaying */
  @Input('focussedComments') focussedComments;

  /** How many comments are there? */
  @Input('focussedCommentsCount') focussedCommentsCount;

  /** Job or a task? */
  @Input('jobType') jobType;

  /** Does the consumer want the input field in the footer? */
  @Input('inputFooter') inputFooter: boolean = true;

  /** Should we cancel the scroll to bottom? */
  @Input('cancelScrollToBottom') cancelScrollToBottom: boolean = false;

  /** Tell the consumer which message we're replying to */
  @Output('replyFocus') replyFocusEvent = new EventEmitter();

  /** Tell the consumer a new message has been sent */
  @Output('sentMessage') sentMessage = new EventEmitter();

  /** If a user types a new message, it's stored here:  */
  newCommentText: string;
  foccusedMessageForReply: any;

  /** time right now */
  now = new Date().getMilliseconds;

  /** Is a message currently 'in the chamber'? */
  sendingInProgress: boolean = false;

  constructor(
    private router: Router,
    private modalController: ModalController,
    private alertController: AlertController,
    private questionService: QuestionsService,
    private firebaseService: FirebaseService,
    public storageService: StorageService,
    private moderatorService: ModeratorService,
    private platform: Platform,
    private localUserService: LocalUserService
  ) {}

  ngOnInit() {
    console.log('Comments: ', this.focussedComments);
    console.log('focussedTask: ', this.focussedTask);
    if (!this.cancelScrollToBottom) {
      setTimeout(() => {
        this.scrollToBottom();
      }, 120);
    }
  }

  messagesChanged() {
    console.log('Messaged changed: ', this.focussedComments);
  }

  ngAfterViewInit() {
    // this.scrollToBottom();
    this.messages.changes.subscribe(this.scrollToBottom);
  }

  getProfilePicture(profilePicUrl): string {
    if (profilePicUrl.length > 0) {
      return profilePicUrl;
    } else {
      return '../../../assets/images/placeholder.jpeg';
    }
  }

  async sendNewComment() {
    if (this.localUserService.myProfile.isVerified || this.jobType !== 4) {
      let containsBadWords = this.moderatorService.containsBannedWords(this.newCommentText);

      if (containsBadWords.containsBadWords) {
        this.badWordAlert(containsBadWords.type);
      } else {
        this.sendingInProgress = true;
        if (this.foccusedMessageForReply) {
          this.questionService
            .replyToQuestion(this.foccusedMessageForReply.taskQuestionId, this.newCommentText)
            .subscribe(
              (replyRes) => {
                console.log('replyRes: ', replyRes);
                this.newCommentText = undefined;
                this.foccusedMessageForReply = undefined;
                // this.getTaskQuestions(this.taskId);
                this.sentMessage.emit(replyRes);
                // this.focussedQuestions.push(sendRes);
                if (this.platform.is('capacitor')) {
                  Keyboard.hide();
                }
              },
              (err) => {
                console.log('replyRes error: ', err);
              }
            );
        } else {
          console.log('sendNewComment(): ', this.newCommentText);
          this.questionService.postnewQuestion(this.taskId, this.newCommentText).subscribe(
            (sendRes) => {
              console.log('sendRes: ', sendRes);
              this.newCommentText = undefined;
              // this.focussedQuestions.push(sendRes);
              this.sentMessage.emit(sendRes);
              this.sendingInProgress = false;
              if (this.platform.is('capacitor')) {
                Keyboard.hide();
              }
            },
            async (err) => {
              console.log('sendRes error: ', err);
              this.sendingInProgress = false;
              if(err.status == 403){
                const modal = await this.modalController.create({
                  component: VerifyEmailModalPage,
                  initialBreakpoint:  1,
                  breakpoints: [0, 1],
                  // swipeToClose: true,
                  componentProps: {
                    sectionName: 'job-question'
                  },
                });
                modal.present();
              }
            }
          );
        }
      }
    } else {
      const modal = await this.modalController.create({
        component: VerifyStudentCompletePage,
        initialBreakpoint: 1,
        breakpoints: [0, 1, 1],
      });
      await modal.present();
      modal.onDidDismiss().then((data) => {
        console.log('Verify student dismissed ', data);
        if (this.localUserService.myProfile.isVerified) {
          this.sendNewComment();
        }
      });
    }
  }

  async badWordAlert(wordType: string) {
    const alert = await this.alertController.create({
      header: 'Warning',
      subHeader: 'Important message',
      message:
        wordType == 'inappropriate'
          ? "If you've not got something nice to say, don't say it at all."
          : "We don't allow tasks like this.",
      buttons: ['OK'],
    });

    await alert.present();
  }

  replyToQuestion(q) {
    console.log('replyToQuestion(): ', q);
    this.foccusedMessageForReply = q;
    let element = document.getElementById('scrollToBottom');
    element.scrollIntoView({ behavior: 'smooth', block: 'end' });
    this.replyFocusEvent.emit(q);
  }

  removeReply() {}

  scrollToBottom(): void {
    console.log('scrollToBottom(): ', this.focussedComments);
    console.log('scrollToBottom() messages: ', this.messages);
    if (this.focussedComments && this.messages) {
      try {
        setTimeout(() => {
          if (
            this.focussedComments[this.focussedComments.length - 1] &&
            this.focussedComments[this.focussedComments.length - 1].taskQuestionId
          ) {
            let element = document.getElementById(
              this.focussedComments[this.focussedComments.length - 1].taskQuestionId
            );
            console.log(this.focussedComments[this.focussedComments.length - 1].taskQuestionId, 'the element');
            element.scrollIntoView({ behavior: 'smooth', block: 'end' });
          }
        }, 120);
      } catch (err) {
        console.log('scroll err: ', err);
      }
    }
  }

  showReplyButton(): boolean {
    if (this.posterId == this.firebaseService.myProfile.userId) {
      return true;
    } else {
      return false;
    }
  }

  questionIsValid() {
    if (this.newCommentText && this.newCommentText.trim().length > 1) {
      return true;
    } else {
      return false;
    }
  }

  openStudentProfile(question) {
    this.router.navigate([`/student-profile/${question.questionUserId}`]);
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { DomSanitizer } from '@angular/platform-browser';
import DOMPurify from "dompurify";
import { Router } from '@angular/router';

@Component({
  selector: 'app-services-card-row',
  templateUrl: './services-card-row.component.html',
  styleUrls: ['./services-card-row.component.scss'],
})
export class ServicesCardRowComponent implements OnInit {

  /** Services that I offer */
  @Input('services') services: any[];

  constructor(
    private router: Router,
    private sanitizer: DomSanitizer,
  ) { }

  ngOnInit() { }

  getImageSource(image, location: string) {
    // console.log('getImageSource(): ', image);
    // console.log('image: ', image);

    if (image.split(',')) {
      image = image.split(',')[0];
    }

    if (image && typeof (image) == "string" && image.includes('http')) {
      return image;
    } else if (image == "string" || image == "") {
      return "../../../assets/images/placeholder.jpeg";
    } else {
      let availWidth = screen.availWidth;
      if (availWidth > 250) {
        availWidth = 240;
      }

      let imageWidth: number;
      let imageHeight: number;
      if (location === "focussed") {
        imageWidth = 2 * 0.95 * availWidth;
        imageHeight = 2 * 240;
      } else if (location === "gallery") {
        imageWidth = 106;
        imageHeight = 74;
      }

      let cdnRequestObject = {
        bucket: "unitaskr-web-cdn",
        key: image.key ? image.key : image, // "Screenshot 2021-11-17 at 11.10.24.png",
        edits: {
          resize: {
            width: imageWidth,
            height: imageHeight,
            fit: "cover"
          }
        }
      }

      let base64 = btoa(JSON.stringify(cdnRequestObject))
      return `${environment.uniTaskrApi.cdnBaseUrl}${base64}`;
    }
  }

  getSanitized(htmlString: string) {
    let output = htmlString.replace("\n", "<br> <br>");
    const sanitizedContent = DOMPurify.sanitize(output);
    return this.sanitizer.bypassSecurityTrustHtml(sanitizedContent);
  }

  viewService(service) {
    this.router.navigate([`/view-service/${service.studentServiceId}`]);
  }
}

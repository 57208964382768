// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  // envStore: 'dev1',
  envStore: 'prd3',
  shoutEnabled: 'xwOpDNoF5SVecU8MKy5U', // prod
  applicationVersion: '6.5.0',
  firebaseConfig: {
    // apiKey: 'AIzaSyCVjcDm-tqQ1nvVwJ0mASIOcFpFMOnPLmI',
    // authDomain: 'uniblaze.firebaseapp.com',
    // projectId: 'uniblaze',
    // storageBucket: 'uniblaze.appspot.com',
    // messagingSenderId: '580103506279',
    // appId: '1:580103506279:web:1dfc0f0342b555159987c2',
    // measurementId: 'G-P69310WTX8',

    // apiKey: "AIzaSyB5ef9gKmpFgEHxae2Uolr3fW0ECVUXTfk",
    // authDomain: "uniinferno.firebaseapp.com",
    // projectId: "uniinferno",
    // storageBucket: "uniinferno.appspot.com",
    // messagingSenderId: "405175596256",
    // appId: "1:405175596256:web:5c7505804d682607434c85",
    // measurementId: "G-S4FHWBG07D"

    /** ANALYTICS PROD */
    apiKey: 'AIzaSyB5ef9gKmpFgEHxae2Uolr3fW0ECVUXTfk',
    authDomain: 'uniinferno.firebaseapp.com',
    projectId: 'uniinferno',
    storageBucket: 'uniinferno.appspot.com',
    messagingSenderId: '405175596256',
    appId: '1:405175596256:web:5c7505804d682607434c85',
    measurementId: 'G-DS0RMP6G7C',

    /** USER MODULE DEV 0 */
    // apiKey: "AIzaSyD3KdBa431dIcHhH46WsWKn8TX17Gjl9gc",
    // authDomain: "uniuser-f1d81.firebaseapp.com",
    // projectId: "uniuser-f1d81",
    // storageBucket: "uniuser-f1d81.appspot.com",
    // messagingSenderId: "509581421845",
    // appId: "1:509581421845:web:9ddb0985e8a61e37ac8d1c",
    // measurementId: "G-8CCZSJ6S0E"
  },
  uniTaskrUtilities: {
    webBaseUrl: 'https://web.unitaskr.com',
    iosBaseUrl: 'capacitor://localhost  ',
  },
  uniTaskrApi: {
    // url: "https://app.unitaskr.com/"
    // url: "https://appfinal.unidoshdev.com/"
    // url: "http://localhost:3000/json_placeholder/",
    // url: "https://api-dev.unitaskr.com/",
    // url: 'https://api-dev1.unitaskr.com/',
    // url: 'https://api-stg.unitaskr.com/',
    url: 'https://api-prd.unitaskr.com/',
    // proUrl: 'https://pro-api-dev.unitaskr.com/',
    proUrl: 'https://pro-api-prd.unitaskr.com/',

    cdnBaseUrl: 'https://d3v5ng09h7jl33.cloudfront.net/',
    s3FileBaseUrl: 'https://unitaskr-web-cdn.s3.eu-west-1.amazonaws.com/',

    // resendVerifyUrl: 'https://us-central1-uniblaze.cloudfunctions.net/sendVerifyEmail',
    // sendVerifyEmail: 'https://us-central1-uniblaze.cloudfunctions.net/verify',
    resendVerifyUrl: 'https://us-central1-uniinferno.cloudfunctions.net/sendVerifyEmail',
    sendVerifyEmail: 'https://us-central1-uniinferno.cloudfunctions.net/verify',
  },
  stripe: {
    public_key: 'pk_live_Xva1hpiqXddirAnw44XNYCKR', // Live
    sub_public_key: 'pk_live_51Kyzn5ClIuI0bhXflyycvd8lFjSSYWyL2v2kZYPmK8h2WyGdlQ8rJ3771cR49EdltX7GjNzJ7bEiT8QkLzyQpnFV00XGJSAm8A', // Live
    // sub_public_key:
    //   'pk_test_51Kyzn5ClIuI0bhXfh4QYdYgmDH8baeGe9HnsO01flwSg150DDc6C9fZNQd6fPvGo6lvZxPVUmW5Usf3RmynMzKGs00X0EbtT1e', // Test
    // public_key: 'pk_test_YxG5LDDFuu2QQhLAZubXCIp1', // Test
  },
  api: 'https://j3x0ln9gj7.execute-api.ap-northeast-1.amazonaws.com/dev/',
  stream_io: {
    // apiKey: 'k9yeue4c2yx3', // development app (UniTaskr App)
    // apiKey: '5exsjkkaw9kz' // production app (UniTaskr App Production)
    apiKey: 'jwusmudkgxxx', // production app (UniTaskr App Prd)
  },
  algolia: {
    application_id: 'VY2SM5XNEA',
    api_key: '5ecf651f646fc71cd5aaffd356544a50',
    admin_api_key: '746536276c2a7bde559e5167b2a16c80',
  },
  stripeStudent: {
    // url: 'http://billing.stripe.com/p/login/test_8wM01V2TEa1g9hKdQQ', // test
    url: 'http://billing.stripe.com/p/login/dR68An2rbbWGfD2dQQ' // production
    /**
     * User Module bits
     */
  },
  glassfy: {
    glassfyKey: '9bd565e8a46c4d04b9d359796d51e4c9'
  },
  httpUrls: {
    // uniUserUrl: "http://localhost:8080/",
    // uniUserUrl: 'https://uni-user-dev.unitaskr.com/',
    uniUserUrl: 'https://uni-user-prd.unitaskr.com/',
  },
  uniUser: {
    appId: '07d18b61-0a69-4553-b4ce-ac87e10384c8',
  },
  uniCreateApi: {
    // url: 'http://localhost:8080/'
    // url: 'https://unicreate-api-dev.unitaskr.com/'
    url: 'https://unicreate-api-prd.unitaskr.com/'
    
  },
  linkedIn: {
    client_id: '78buw9dtozgrmw', // live
    client_secret: '0Q2kXtXbJ7Vn0tZd', // live
    redirect_uri: 'https://web.unitaskr.com/linkedin-success', // live
    // client_id: '86vsy4xahux7eo', // test
    // client_secret: 'hcsoOmbYxkqte3md', // test
    // redirect_uri: 'https://uniblaze.web.app/linkedin-success', // test
  },
  s3_upload: {
    accessKeyId: 'AKIARCJU3XSIEVJBLHMI',
    secretAccessKey: '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'
  },
  firebase_chat: {
    chat_messages: 'chat_messages',
    channels: 'chats',
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */


import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { StorageService } from 'src/app/services/storage/storage.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-activity-item-bought',
  templateUrl: './activity-item-bought.component.html',
  styleUrls: ['./activity-item-bought.component.scss'],
})
export class ActivityItemBoughtComponent implements OnInit {
  /** Which item are we displaying? */
  @Input('activityItem') focussedItem;

  jobType = ['FULL TIME', 'PART TIME', 'INTERNSHIP', 'TASK'];

  constructor(private router: Router, private storageService: StorageService) {}

  ngOnInit() {}

  getItemType(): string {
    if (this.focussedItem.isService) {
      return 'service';
    } else if (this.focussedItem.isShout) {
      return 'shout';
    } else {
      return 'task';
    }
  }

  getImageSource(image, location: string) {
    if (image && image.includes('http')) {
      return image;
    } else if (image == 'string') {
      return '../../../assets/images/placeholder.jpeg';
    } else {
      let availWidth = screen.availWidth;

      if (availWidth > 250) {
        availWidth = 240;
      }

      let imageWidth: number;
      let imageHeight: number;
      if (location === 'focussed') {
        imageWidth = 2 * 0.95 * availWidth;
        imageHeight = 2 * 240;
      } else if (location === 'gallery') {
        imageWidth = 106;
        imageHeight = 74;
      }

      let cdnRequestObject = {
        bucket: 'unitaskr-web-cdn',
        key: image.key, // "Screenshot 2021-11-17 at 11.10.24.png",
        edits: {
          resize: {
            width: imageWidth,
            height: imageHeight,
            fit: 'cover',
          },
        },
      };

      let base64 = btoa(JSON.stringify(cdnRequestObject));
      return `${environment.uniTaskrApi.cdnBaseUrl}${base64}`;
    }
  }

  openItem() {
    console.log('openItem() my ID: ', this.storageService.uniTaskrUser.userId);
    console.log('openItem() task ID: ', this.focussedItem);
    if (this.getItemType() === 'task') {
      if (this.focussedItem.userID === this.storageService.uniTaskrUser.userId) {
        this.router.navigate([`/new-create-task/${this.focussedItem.taskId}_e`]);
      } else {
        this.router.navigate([`/task-profile/${this.focussedItem.taskId}`]);
      }
    } else if (this.getItemType() === 'service') {
      this.router.navigate([`/view-service/${this.focussedItem.taskId}`]);
    }
  }
}

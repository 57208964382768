import { Component, OnInit, Input } from '@angular/core';
import { SkillsService } from './service/skills.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-job-type',
  templateUrl: './job-type.component.html',
  styleUrls: ['./job-type.component.scss'],
})
export class JobTypeComponent implements OnInit {

  @Input('job') job: any;

  constructor(private skillsService: SkillsService, private route: ActivatedRoute,
    private router: Router,) { }

  ngOnInit() { }

  formatSrc(name) {
    return name.replaceAll(/,?\s+/g, '-');
  }

  toggleSelected(e) {
    console.log(e.detail);
    this.job.selected = e.detail.checked;
  }

  goToSkillsPage() {
    console.log(this.job)
    this.skillsService.focussedJobType = this.job;
    this.router.navigate([`skills/${this.formatSrc(this.job.name)}`])
  }

}
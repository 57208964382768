import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { Browser } from '@capacitor/browser';
import { environment } from 'src/environments/environment';
import SwiperCore, { Autoplay, Pagination, Navigation } from "swiper";
import { LocalUserService } from '../../welcome/services/local-user/local-user.service';

// install Swiper modules
SwiperCore.use([Pagination]);

@Component({
  selector: 'app-image-carousel',
  templateUrl: './image-carousel.component.html',
  styleUrls: ['./image-carousel.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ImageCarouselComponent implements OnInit {

  /** Image list that's been sent it */
  @Input('imageList') imageList: {
    autoId: number,
    imageUrl: string,
    isLive: boolean,
    shoutTask: number,
    url: string
  }[];

  /** Dummy carousel data */
  images = ["https://secure.img1-fg.wfcdn.com/im/03630951/resize-h445%5Ecompr-r85/1680/168018124/Large+Street+HipHop+Graffiti+3m+x+280cm+1+Piece+Wall+Mural.jpg", "https://images.photowall.com/products/60496/graffiti-wall.jpg?h=699&q=85", "https://as2.ftcdn.net/v2/jpg/00/77/95/21/1000_F_77952115_GDVLrhIi0Br5EgQ8U5G32cf5pg5ZEhNL.jpg"]

  constructor(
    private router: Router,
    private readonly localUserService: LocalUserService
  ) { }

  ngOnInit() { }

  getImageSource(image, location: string) {
    if (image && image.includes('http')) {
      return image;
    } else if (image == "string") {
      return "../../../assets/images/placeholder.jpeg";
    } else {
      let availWidth = screen.availWidth;
      if (availWidth > 250) {
        availWidth = 240;
      }

      let imageWidth: number;
      let imageHeight: number;
      if (location === "focussed") {
        imageWidth = 2 * 0.95 * availWidth;
        imageHeight = 2 * 240;
      } else if (location === "gallery") {
        imageWidth = 106;
        imageHeight = 74;
      }

      let cdnRequestObject = {
        bucket: "unitaskr-web-cdn",
        key: image.key, // "Screenshot 2021-11-17 at 11.10.24.png",
        edits: {
          resize: {
            width: imageWidth,
            height: imageHeight,
            fit: "cover"
          }
        }
      }

      let base64 = btoa(JSON.stringify(cdnRequestObject))
      return `${environment.uniTaskrApi.cdnBaseUrl}${base64}`;
    }
  }

  async actionThisItem(item) {
    console.log('actionThisItem(): ', item);

    if (item.url == "Shout page" && item.shoutTask) {
      this.router.navigate([`/shout-profile/${item.shoutTask}`]);
    }
    else if (item.url == 'Prize Drow' || item.url == 'Prize Draw') {
      this.router.navigate(['/prize-draw']);
    }
    else if (item.url == 'CV upload') {
      this.router.navigate(['/cv-builder-new']);
    }
    else if (item.url == 'UniTaskrPRO') {
      this.router.navigate(['/pro-landing']);
    }
    else if (item.url == 'Task page' && item.shoutTask) {
      this.router.navigate([`/task-profile/${item.shoutTask}`]);
    }
    else if (item.url == 'Services page') {
      this.router.navigate([`/hiring-wizard`]);
    }
    else if (item.url == 'Task Alert Settings') {
      this.router.navigate([`/task-alerts`]);
    }
    else if (item.url.includes('localhost')) {
      item.url = item.url.replace('http://', '')
        .replace('https://', '')
        .replace('localhost', '')
        .replace('.com', '')
      if (item.url == '/pro-student-upgrade' && !this.localUserService.uniTaskrProfile)
        this.router.navigate(['/welcome']);
      else
        this.router.navigate([item.url]);
    }
    else {
      await Browser.open({ url: `${item.url}` });
    }
  }

  getSlidesPerPage() {
    let deviceWidth = window.screen.width;
    switch (true) {
      case (deviceWidth <= 425):
        return 1;
      case (deviceWidth > 425 && deviceWidth <= 460):
        return 1.25;
      case (deviceWidth > 460 && deviceWidth <= 550):
        return 1.5;
      case (deviceWidth > 550 && deviceWidth <= 650):
        return 1.75;
      case (deviceWidth > 650 && deviceWidth <= 768):
        return 2;
      case (deviceWidth > 768 && deviceWidth <= 850):
        return 2.25;
      case (deviceWidth > 850 && deviceWidth <= 950):
        return 2.5;
      case (deviceWidth > 950 && deviceWidth <= 991):
        return 2.75;
      case (deviceWidth > 991 && deviceWidth <= 1100):
        return 1.5;
      case (deviceWidth > 1100 && deviceWidth <= 1250):
        return 1.75;
      case (deviceWidth > 1250 && deviceWidth <= 1350):
        return 2;
      case (deviceWidth > 1350 && deviceWidth <= 1500):
        return 2.25;
      case (deviceWidth > 1500 && deviceWidth <= 1600):
        return 2.5;
      case (deviceWidth > 1600 && deviceWidth <= 1700):
        return 2.75;
      case (deviceWidth > 1700):
        return 3;
    }
  }

  centeredOrNot() {
    let deviceWidth = window.screen.width;
    switch (true) {
      case (deviceWidth <= 370):
        return true;
      case (deviceWidth > 370):
        return false;
    }
  }
}

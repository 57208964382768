import { CurrencyPipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ActionSheetController, AlertController, LoadingController, Platform } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { ChatBlazeService } from 'src/app/chat-blaze/service/chat-blaze.service';
import { PriceChangeService } from 'src/app/chat/services/price-change/price-change.service';
import { TaskService } from 'src/app/home-earn/service/task.service';
import { TheCurrencyPipe } from 'src/app/pipes/the-currency.pipe';
import { FirebaseService } from 'src/app/services/firebase/firebase.service';
import { ImageService } from 'src/app/services/image/image.service';
import { PaymentService } from 'src/app/services/payments/payment.service';
import { ProfileService } from 'src/app/services/profile/profile.service';
import { StorageService } from 'src/app/services/storage/storage.service';
import { ApplicantsService } from 'src/app/task-manage/service/applicant.service';
import { Channel } from 'stream-chat';
import { ChannelService } from 'stream-chat-angular';
import { ChatChannelService } from '../../../inbox(del)/service/channel.service';
import { IntercomService } from '../../../services/intercom/intercom.service';
import { SubscriptionService } from '../../../services/subscription/subscription.service';
import { MessagingService } from '../../service/messaging.service';
import { LocalUserService } from 'src/app/welcome/services/local-user/local-user.service';
import { CurrencyService } from 'src/app/services/currency/currency.service';

@Component({
  selector: 'app-custom-header',
  templateUrl: './custom-header.component.html',
  styleUrls: ['./custom-header.component.scss'],
})
export class CustomHeaderComponent implements OnInit {
  @Input() channel!: Channel;
  activeChannel: Channel | undefined;

  activeChannelSub: Subscription;

  /** Focussed Task offer for channel */
  focussedTaskOffer;
  /** Focussed Student for channel */
  focussedStudent;
  /** Focussed Hirer for channel */
  focussedHirer;
  /** Focussed Task for channel */
  focussedTask;

  amHirer: boolean = false;

  paymentSummary;

  myCurrencyCode;
  myConversionRate;

  /** What device are we on? */
  isMobile: boolean = true;

  constructor(
    private router: Router,
    private platform: Platform,
    private actionSheetController: ActionSheetController,
    private alertController: AlertController,
    private loadingController: LoadingController,
    private channelService: ChannelService,
    private messagingService: MessagingService,
    private applicantService: ApplicantsService,
    private taskService: TaskService,
    private profileService: ProfileService,
    public imageService: ImageService,
    private storageService: StorageService,
    private paymentService: PaymentService,
    public chatService: ChatBlazeService,
    private theCurrencyPipe: TheCurrencyPipe,
    private currencyPipe: CurrencyPipe,
    private firebaseService: FirebaseService,
    private priceChangeService: PriceChangeService,
    private chatChannelService: ChatChannelService, // Access redeem endpoint
    private subscription: SubscriptionService,
    private intercomService: IntercomService,
    public localUserService: LocalUserService,
    private currencyService: CurrencyService
  ) {

    this.activeChannelSub = this.channelService.activeChannel$.subscribe(chanRes => {
      console.log('----------- Header -----------');
      this.messagingService.shouldGetTaskAgain.subscribe(shouldRes => {
        console.log('Should Res: ', shouldRes);
        if (shouldRes === true && this.messagingService.activeChannel) {
          this.getHeaderUniTaskrFacts(this.activeChannel && this.activeChannel.id ? this.activeChannel.id : this.messagingService.activeChannel.id);
          this.messagingService.shouldGetTaskAgain.next(false);
        }
      })

      console.log('----------- Header -----------');
    });

    if (this.messagingService.activeChannel) {
      this.getHeaderUniTaskrFacts(this.messagingService.activeChannel.id);
    }
    this.messagingService.shouldGetTaskAgain.subscribe(shouldRes => {
      console.log('Should Res: ', shouldRes);
      if (shouldRes === true && this.messagingService.activeChannel) {
        this.getHeaderUniTaskrFacts(this.activeChannel && this.activeChannel.id ? this.activeChannel.id : this.messagingService.activeChannel.id);
        this.messagingService.shouldGetTaskAgain.next(false);
      }
    })
  }

  ngOnInit() {
    if (this.platform.is('mobile')) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }

  }

  getMyRates() {
    console.log('getMyRates()')
    // console.log('getMyRates()')
    // console.log('this.focussedTaskOffer: ', this.focussedTaskOffer);
    // console.log('this.focussedTask: ', this.focussedTask);
    // console.log('getMyRates()')
    // console.log('getMyRates()')
    if (this.focussedTask && this.focussedTask.currencyCode == '') {
      this.focussedTask.currencyCode = 'GBP';
    }
    this.currencyService.getConversionRate(this.focussedTask.currencyCode, this.myCurrencyCode ? this.myCurrencyCode : this.localUserService.myCurrencyCode).subscribe(rateRes => {
      this.myConversionRate = rateRes.rate ? rateRes.rate : 1;

      console.log('myConversionRate 999999: ', this.myConversionRate)
    });
  }

  toggleMenu() {
    this.messagingService.menuIsOpen = true;
    this.channelService.deselectActiveChannel();
  }

  getHeaderUniTaskrFacts(taskOfferId) {
    console.log('getHeaderUniTaskrFacts: ', taskOfferId);
    this.applicantService.getTaskOfferById(taskOfferId).subscribe((taskOfferRes) => {
      console.log('Task offer res: ', taskOfferRes);

      this.focussedTaskOffer = taskOfferRes;

      this.taskService.getTaskById(taskOfferRes.taskId).subscribe((taskRes) => {
        console.log('taskRes: ', taskRes);
        this.focussedTask = taskRes;

        this.getMyRates();

        if (this.focussedTask.userId == this.storageService.uniTaskrUser.userId) {
          this.amHirer = true;
          this.profileService.getUserbyId(taskOfferRes.userId).subscribe(
            (profileRes) => {
              console.log('Student Profile res: ', profileRes);
              this.focussedStudent = profileRes;
            },
            (err) => {
              console.log('profileRes error: ', err);
            }
          );
        } else {
          this.profileService.getUserbyId(this.focussedTask.userId).subscribe(
            (profileRes) => {
              console.log('Hirer Profile res: ', profileRes);
              this.focussedHirer = profileRes;
            },
            (err) => {
              console.log('profileRes error: ', err);
            }
          );
        }
      });

      this.paymentService.getFinalSummary(this.focussedTaskOffer.taskOfferId).subscribe((summaryRes) => {
        this.paymentSummary = summaryRes;
      });
    });

  }

  viewStudentProfile() {
    console.log('viewStudentProfile()');
    this.router.navigate([`/student-profile/${this.focussedTaskOffer.userId}`]);
  }

  openOptions() {
    if (this.amHirer) {
      this.openBusinessOptions();
    } else {
      this.openTaskrOptions();
    }
  }

  async openBusinessOptions() {
    let businessOptions = [

      ...(this.focussedTaskOffer && !this.focussedTaskOffer.isAccepted
        ? [
          {
            text: `Hire ${this.focussedStudent.firstName}`,
            handler: () => {
              this.hireStudent();
            },
          },
        ]
        : []),
      ...(!this.focussedTaskOffer?.isCompleted && !this.focussedTaskOffer?.hasPaid && this.focussedTask?.jobType == 4
        ? [
          {
            text: `Change Price`,
            handler: () => {
              this.changePricePlease();
            },
          },
        ]
        : []),
      ...(this.focussedTaskOffer &&
        !this.focussedTaskOffer.isCompleted &&
        this.focussedTaskOffer &&
        this.paymentSummary.alreadyPaid > 0
        ? [
          {
            text: `Request Refund`,
            handler: () => { },
          },
        ]
        : []),
      ...[
        {
          text: `Manage Task`,
          handler: () => {
            this.router.navigate([`/new-create-task/${this.focussedTask.taskId}_e`]);
          },
        },
      ],
      {
        text: `View ${this.focussedStudent.firstName}'s Profile`,
        handler: () => {
          this.viewStudentProfile();
        },
      },
      ...(this.focussedTaskOffer &&
        !this.focussedTaskOffer.isAccepted
        ? [
          {
            text: 'Delete Chat (Get one chat token back)',
            handler: () => { this.redeemChatPlease(); },
          },
        ]
        : []),
      {
        text: 'Cancel',
        role: 'cancel',
        handler: () => { },
      }
    ];

    const actionSheet = await this.actionSheetController.create({
      header: 'Options',
      cssClass: 'left-align-buttons',
      buttons: businessOptions,
    });
    await actionSheet.present();

    actionSheet.onDidDismiss().then(() => { });
  }

  async openTaskrOptions() {
    console.log('openTaskrOptions()');
    let studentOptions = [
      ...(!this.focussedTask.isShout &&
        this.focussedTaskOffer &&
        this.focussedTaskOffer.isAccepted &&
        !this.focussedTaskOffer.isCompleted
        ? [
          {
            text: `Change Price`,
            handler: () => {
              this.changePricePlease();
            },
          },
        ]
        : []),
      ...(this.focussedTaskOffer &&
        this.focussedTaskOffer.isAccepted &&
        !this.focussedTaskOffer.isCompleted &&
        !this.focussedTask.isShout
        ? [
          {
            text: `Mark Complete`,
            handler: () => {
              this.chatService.markComplete(this.focussedTaskOffer.taskOfferId);
            },
          },
        ]
        : []),
      ...(this.focussedTask.isShout
        ? [
          {
            text: `Go to Steps`,
            handler: () => {
              this.router.navigate([`/shout-profile/${this.focussedTask.taskId}`], {
                queryParams: { selectedNavButton: 'steps' },
              });
            },
          },
        ]
        : []),
      ...(!this.focussedTask.isShout
        ? [
          {
            text: `View task`,
            handler: () => {
              this.router.navigate([`/task-profile/${this.focussedTask.taskId}`]);
            },
          },
        ]
        : []),
      {
        text: 'Cancel',
        role: 'cancel',

        handler: () => { },
      },
    ];

    const actionSheet = await this.actionSheetController.create({
      header: 'Options',
      cssClass: 'left-align-buttons',
      buttons: studentOptions,
    });
    await actionSheet.present();

    actionSheet.onDidDismiss().then(() => { });

  }

  hireStudent() {
    console.log('hireStudent()');
    this.channelService.deselectActiveChannel();
    this.chatService.hireStudent(this.focussedTask.taskId, this.focussedTaskOffer.taskOfferId.toString());
  }

  payStudent() {
    console.log('payStudent()');
    this.channelService.deselectActiveChannel();
    this.chatService.payStudent(this.focussedTask.taskId, this.focussedTaskOffer.taskOfferId.toString());
  }

  markComplete() {
    console.log('markComplete()');
    this.chatService.markComplete(this.focussedTaskOffer.taskOfferId).then((markCompleteRes) => {
      console.log('markCompleteRes: ', markCompleteRes);
    });
  }

  async changePricePlease() {

    console.log('changePricePlease()')

    console.log('this.focussedTaskOffer.amount: ', this.focussedTaskOffer.amount)
    console.log('this.focussedTask.currencyCode: ', this.focussedTask.currencyCode)
    console.log('this.localUserService.myCurrencyCode: ', this.localUserService.myCurrencyCode)
    console.log('this.myConversionRate: ', this.myConversionRate)
    console.log('changePricePlease()')

    this.currencyService.getConversionRate(this.focussedTask.currencyCode, this.myCurrencyCode ? this.myCurrencyCode : this.localUserService.myCurrencyCode).subscribe(async rateRes => {
      this.myConversionRate = rateRes.rate ? rateRes.rate : 1;
      

      console.log('myConversionRate 999999: ', this.myConversionRate)

      const alert = await this.alertController.create({
        cssClass: 'my-custom-class',
        header: 'Request Price Change',
  
        message: `The current price is ${this.theCurrencyPipe.transform(
          this.focussedTaskOffer.amount,
          this.focussedTask.currencyCode,
          this.localUserService.myCurrencyCode,
          this.myConversionRate
        )}. We will ask ${this.focussedStudent ? this.focussedStudent.firstName : this.focussedHirer.firstName
          } to confirm the price change request.`,
  
        inputs: [
          {
            name: 'price',
            type: 'number',
            placeholder: 'New price',
            attributes: {
              inputmode: 'decimal',
            },
          },
        ],
        buttons: [
          {
            text: 'Cancel',
            role: 'cancel',
            cssClass: 'secondary',
  
            handler: () => { },
          },
          {
  
            text: 'Request',
            handler: (data) => {
              this.requestPriceChangePlease(data.price);
            },
          },
        ],
      });
  
      await alert.present();
    });

  }

  async requestPriceChangePlease(price) {
    const loading = await this.loadingController.create({
      cssClass: 'my-custom-class',
      message: 'Requesting...',
      duration: 7000,
    });
    await loading.present();

    this.priceChangeService.requestPriceChange(this.focussedTaskOffer.taskOfferId, price).subscribe(
      async (priceRes) => {
        const message = await this.channelService.sendMessage(
          // `<b>@${this.focussedStudent ? this.focussedStudent.firstName : this.focussedHirer.firstName}
          `</b>Please accept or decline my Price Change Request. I've updated the price from: <br /> <b>${this.currencyPipe.transform(
            priceRes.currentPrice,
            this.focussedTask.currencyCode
          )}</b> to <b>${this.currencyPipe.transform(
            priceRes.proposedPrice,
            this.focussedTask.currencyCode
          )}</b>. <br /> <a href="price-change-respond/${priceRes.id}/${this.focussedTaskOffer.taskOfferId
          }">Click here to respond</a>.`
        );
        loading.dismiss();
      },
      (err) => {
        console.log('Entity 1 price error: ', err);
      }
    );
  }

  ngOnDestroy() {
    this.activeChannelSub.unsubscribe();
  }

  async redeemChatPlease() {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'Are you sure?',
      message: 'You will get 1 chat token back, and this chat will be deleted',
      buttons: [
        {
          text: 'No',
          role: 'cancel',
          cssClass: 'secondary'
        }, {
          text: 'Yes',
          handler: (data) => {
            this.redeemChat();
          }
        }
      ]
    });

    await alert.present();
  }

  async redeemChat() {
    const loading = await this.loadingController.create({
      cssClass: 'my-custom-class',
      message: 'Requesting...',
      duration: 7000
    });
    await loading.present();

    this.chatChannelService.redeemChat(this.focussedTaskOffer.taskOfferId, this.focussedTask.jobType != 4).subscribe(async redeemRes => {
      console.log('redeemRes :', redeemRes);
      this.subscription
        .getCounts()
        .pipe(take(1))
        .subscribe((countRes) => {
          console.log('countRes: ', countRes);
          this.subscription.countChanged.next(countRes);
          loading.dismiss();
          // deselect channel ???

          this.router.navigate([`task-manage/${this.focussedTask.taskId}/applied`]);
        });

    }, async err => {
      console.log('Redeem err: ', err);
      if (err.status == 403) {
        loading.dismiss();
        const alert = await this.alertController.create({
          cssClass: 'my-custom-class',
          header: 'Please contact us',
          buttons: [
            {
              text: 'Cancel',
              role: 'cancel',
              cssClass: 'secondary',
            }, {
              text: 'Contact Support',
              handler: (data) => {
                // Intercom

              }
            }
          ]
        });

        await alert.present();
      }
    });
  }

  openIntercomLauncher() {
    console.log('openIntercomLauncher()');
    if (this.platform.is('capacitor')) {
      this.intercomService.displayMessenger();
    } else {
      window.open("https://unitaskr.com/contact-us/");
    }
  }

}

import { Component, Input, OnInit } from '@angular/core';
import moment from 'moment';

@Component({
  selector: 'app-linkedin-task-profile-header',
  templateUrl: './linkedin-task-profile-header.component.html',
  styleUrls: ['./linkedin-task-profile-header.component.scss'],
})
export class LinkedinTaskProfileHeaderComponent implements OnInit {
  @Input('focussedTask') focussedTask;
  @Input('categories') categories: any;
  @Input('jobCategories') jobCategories: any;
  @Input('jobTypes') jobTypes: any;

  categoryName: string;
  categoryImage: string;

  isTodaysDate: boolean = false;

  timeSince: string;

  constructor() {}

  ngOnInit() {
    this.getCategory();
    this.timeSince = moment(this.focussedTask.createdDate).fromNow();
  }

  getCategory() {
    this.isTodaysDate = this.datesAreOnSameDay(new Date(this.focussedTask.dueDate), new Date()); //true
    if (this.focussedTask.jobType == 4) {
      for (let category of this.categories.businessCategories) {
        if (category.categoryId == this.focussedTask.categoryId) {
          this.categoryName = category.name;
          this.categoryImage = category.src;
        }
      }
      for (let category of this.categories.homeCategories) {
        if (category.categoryId == this.focussedTask.categoryId) {
          this.categoryName = category.name;
          this.categoryImage = category.src;
        }
      }
    } else {
      if (this.jobTypes?.length) {
        for (let job of this.jobTypes) {
          if (job.jobIndustriesId == this.focussedTask.categoryId) {
            this.categoryName = job.jobName;
            this.categoryImage = job.imageUrl;
          }
        }
      }
    }
  }

  datesAreOnSameDay(first, second) {
    return (
      first.getFullYear() === second.getFullYear() &&
      first.getMonth() === second.getMonth() &&
      first.getDate() === second.getDate()
    );
  }
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { environment } from 'src/environments/environment';
import { DomSanitizer } from '@angular/platform-browser';
import DOMPurify from "dompurify";
import { Router } from '@angular/router';

@Component({
  selector: 'app-service-card',
  templateUrl: './service-card.component.html',
  styleUrls: ['./service-card.component.scss'],
})
export class ServiceCardComponent implements OnInit {

  /** Input search result object */
  @Input('searchResult') searchResult;

  /** Tell the consumer that this search result has been selected */
  @Output('resultSelected') resultSelected = new EventEmitter;

  /** Dynamically generate image id */
  imageIdbase: string = "imageBase";
  today: Date = new Date();

  /** Show image loading spinner */
  showSpinner: boolean = true;

  constructor(
    private router: Router,
    private sanitizer: DomSanitizer,
  ) { }

  ngOnInit() {
    console.log('service-card: ', this.searchResult);
    let imageUrl = this.searchResult.pictureUrl !== "" && this.searchResult.pictureUrl ? this.searchResult.pictureUrl : "../../../assets/images/placeholder.jpeg";
    setTimeout(() => {
      if (document.getElementById(this.getImageId())) {
        this.showSpinner = false;
      }
    }, 200)
  }

  getImageId() {
    return `${this.imageIdbase}_${this.today.getMilliseconds()}`
  }

  getImageSource(image) {
    if (image && image.includes('http')) {
      return image;
    } else if (image == "string" || image == "") {
      return "../../../assets/images/splash.png";
    } else {
      if (image.split(",")) {
        image = image.split(",")[0];
      }

      let availWidth = screen.availWidth;
      if (availWidth > 250) {
        availWidth = 240;
      }

      let imageWidth: number;
      let imageHeight: number;
      imageWidth = 2 * 0.95 * availWidth;
      imageHeight = 2 * 240;


      let cdnRequestObject = {
        bucket: "unitaskr-web-cdn",
        key: image, // "Screenshot 2021-11-17 at 11.10.24.png",
        edits: {
          resize: {
            width: imageWidth,
            height: imageHeight,
            fit: "cover"
          }
        }
      }

      let base64 = btoa(JSON.stringify(cdnRequestObject))
      return `${environment.uniTaskrApi.cdnBaseUrl}${base64}`;
    }
  }

  getSanitized(htmlString: string) {
    let output = htmlString.replace("\n", "<br> <br>");
    const sanitizedContent = DOMPurify.sanitize(output);
    return this.sanitizer.bypassSecurityTrustHtml(sanitizedContent);
  }

  clickWholeCard() {
    this.resultSelected.emit(this.searchResult.serviceId);
  }

  getUrl() {
    return this.getImageSource(this.searchResult.pictureUrl);
  }

  openService() {
    this.router.navigate([`/sell-service/${this.searchResult.serviceId}`]);
  }

}

import { Injectable } from '@angular/core';
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';
import { ModalController } from '@ionic/angular';
import { RequestCameraPage } from 'src/app/modals/request-camera/request-camera.page';

@Injectable({
  providedIn: 'root'
})
export class CameraService {

  constructor(
    private modalController: ModalController
  ) { }

  async takePicture(type: string, nextMove?: string) {

    if (nextMove && nextMove == 'continue') {
      return Camera.getPhoto({
        source: type == 'camera' ? CameraSource.Camera : CameraSource.Photos,
        quality: 40,
        allowEditing: false,
        resultType: CameraResultType.Base64,
        saveToGallery: true
      })
    }
    else {
      return Camera.checkPermissions().then(async cameraPermsRes => {
        console.log('cameraPermsRes: ', cameraPermsRes);

        if ((type == 'camera' && cameraPermsRes.camera == 'granted') ||
          (type !== 'camera' && cameraPermsRes.photos == 'granted')) {
          return Camera.getPhoto({
            source: type == 'camera' ? CameraSource.Camera : CameraSource.Photos,
            quality: 40,
            allowEditing: false,
            resultType: CameraResultType.Base64,
            saveToGallery: true
          })
        } else {
          const modal = await this.modalController.create({
            component: RequestCameraPage,
          });
          modal.present();

          return modal.onDidDismiss().then((data) => {
            console.log('data for next move: ', data);

            if (data.data.next == 'stop') {
              console.log('Stop asking me please...')
            } else {
              return this.takePicture(type, data.data.next);
            }
          })
        }
      });
    }
  }
}

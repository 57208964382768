// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.item_housing_row {
  width: 100%;
  justify-content: center;
  padding-left: 5px;
  padding-right: 5px;
}

.form_item {
  --background: transparent;
  border-radius: 6px;
  width: 100%;
  border: solid 1px var(--bg-light-grey);
}
.form_item .standard_input {
  border: none;
}
.form_item ion-input {
  background: transparent !important;
}
.form_item ion-datetine {
  background: transparent;
}
.form_item .line_in_the_middle {
  width: 100%;
  justify-content: center;
}
.form_item .line_in_the_middle div {
  height: 16px;
  border-right: solid 1px var(--bg-light-grey);
}
.form_item ion-icon {
  color: var(--ion-color-dark);
}`, "",{"version":3,"sources":["webpack://./src/app/components/plus-minus-input/plus-minus-input.component.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,uBAAA;EACA,iBAAA;EACA,kBAAA;AACJ;;AAGA;EACI,yBAAA;EACA,kBAAA;EACA,WAAA;EACA,sCAAA;AAAJ;AAEI;EACI,YAAA;AAAR;AAGI;EACI,kCAAA;AADR;AAII;EACI,uBAAA;AAFR;AAKI;EACI,WAAA;EACA,uBAAA;AAHR;AAIQ;EACI,YAAA;EACA,4CAAA;AAFZ;AAMI;EAEI,4BAAA;AALR","sourcesContent":[".item_housing_row {\n    width: 100%;\n    justify-content: center;\n    padding-left: 5px;\n    padding-right: 5px;\n    // background: var(--bg-light-blue);\n}\n\n.form_item {\n    --background: transparent;\n    border-radius: 6px;\n    width: 100%;\n    border: solid 1px var(--bg-light-grey);\n\n    .standard_input {\n        border: none;\n    }\n\n    ion-input {\n        background: transparent !important;\n    }\n\n    ion-datetine {\n        background: transparent;\n    }\n\n    .line_in_the_middle {\n        width: 100%;\n        justify-content: center;\n        div {\n            height: 16px;\n            border-right: solid 1px var(--bg-light-grey);\n        }\n    }\n\n    ion-icon {\n        // background: #fff;\n        color: var(--ion-color-dark);\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

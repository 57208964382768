import { Injectable } from '@angular/core';
import { StorageService } from 'src/app/services/storage/storage.service';

import { doc, getFirestore, updateDoc, setDoc, arrayUnion } from '@firebase/firestore';
import { environment } from 'src/environments/environment';
import { ConfigService } from '../../services/config-service/config-service.service';

@Injectable({
  providedIn: 'root',
})
export class ErrorLogService {
  /** Global app version */
  // appVersion: string = ' 5.0.1';
  /** List of all local logs */
  allLogs: any[];

  constructor(private storageService: StorageService,
    private configService: ConfigService) {}

  addLog(message: string, location: string) {
    this.storageService.getAndParse('error_logs').then((logRes) => {
      // console.log('logRes: ', logRes);
      if (logRes && logRes) {
        logRes.push({
          user: this.storageService.uniTaskrUser ? `${this.storageService.uniTaskrUser.userId}` : 'unknown',
          when: new Date().toISOString(),
          // appVersion: this.appVersion,
          appVersion: environment.applicationVersion,
          email: this.storageService.uniTaskrUser ? this.storageService.uniTaskrUser.email : 'unregistered',
          where: location,
          message: message,
        });
        this.storageService.setAndString('error_logs', logRes);
      } else {
        this.storageService.setAndString('error_logs', [
          {
            user: this.storageService.uniTaskrUser ? `${this.storageService.uniTaskrUser.userId}` : 'unknown',
            when: new Date().toISOString(),
            // appVersion: this.appVersion,
            appVersion: environment.applicationVersion,
            email: this.storageService.uniTaskrUser ? this.storageService.uniTaskrUser.email : 'unregistered',
            where: location,
            message: message,
          },
        ]);
      }
    });
  }

  async addLogFirebase(message: string, location: string, errorCode: number) {
    // // console.log('addLogFirebase()');
    // /** Which environment are we in? */
    // let currentEnv = environment.uniTaskrApi.url.replace('https://', '').replace('.unitaskr.com/', '');
    // // console.log('Current Env: ', currentEnv);
    // const db = getFirestore();
    // const userLogRef = doc(
    //   db,
    //   currentEnv !== 'api-stg' ? 'userErrorLogs' : 'userErrorLogs-dev',
    //   `${this.storageService.uniTaskrUser ? this.storageService.uniTaskrUser.userId : 'unregistered'}`
    // );
    // try {
    //   updateDoc(userLogRef, {
    //     logs: arrayUnion({
    //       when: new Date().toISOString(),
    //       where: location,
    //       environment: currentEnv,
    //       appVersion: this.appVersion,
    //       email: this.storageService.uniTaskrUser ? this.storageService.uniTaskrUser.email : 'unregistered',
    //       message: message,
    //       errorCode: errorCode,
    //     }),
    //   })
    //     .then(() => {
    //       // console.log('Added log!');
    //     })
    //     .catch((err) => {
    //       console.log('Add log error: ', err);
    //       setDoc(
    //         doc(
    //           db,
    //           currentEnv !== 'api-stg' ? 'userErrorLogs' : 'userErrorLogs-dev',
    //           `${this.storageService.uniTaskrUser ? this.storageService.uniTaskrUser.userId : 'unregistered'}`
    //         ),
    //         {
    //           logs: [
    //             {
    //               when: new Date().toISOString(),
    //               where: location,
    //               environment: currentEnv,
    //               appVersion: this.appVersion,
    //               email: this.storageService.uniTaskrUser ? this.storageService.uniTaskrUser.email : 'unregistered',
    //               message: message,
    //               errorCode: errorCode,
    //             },
    //           ],
    //         },
    //         { merge: true }
    //       )
    //         .then(() => {
    //           // console.log('Doc Set! ');
    //         })
    //         .catch((err) => {
    //           console.log('Error setting doc: ', err);
    //         });
    //     });
    // } catch (e) {
    //   console.error('Error adding document: ', e);
    // }
  }

  async addCodeLogFirebase(message: string, location: string, errorCode: number) {
    // // console.log('addCodeLogFirebase()');
    // /** Which environment are we in? */
    // let currentEnv = environment.uniTaskrApi.url.replace('https://', '').replace('.unitaskr.com/', '');
    // // console.log('Current Env: ', currentEnv);
    // const db = getFirestore();
    // const userLogRef = doc(db, currentEnv !== 'api-stg' ? 'codeErrorLogs' : 'codeErrorLogs-dev', `${errorCode}`);
    // try {
    //   updateDoc(userLogRef, {
    //     logs: arrayUnion({
    //       when: new Date().toISOString(),
    //       where: location,
    //       environment: currentEnv,
    //       appVersion: this.appVersion,
    //       email: this.storageService.uniTaskrUser ? this.storageService.uniTaskrUser.email : 'unregistered',
    //       message: message,
    //       errorCode: errorCode,
    //     }),
    //   })
    //     .then(() => {
    //       // console.log('Added log!');
    //     })
    //     .catch((err) => {
    //       console.log('Add log error: ', err);
    //       setDoc(
    //         doc(db, currentEnv !== 'api-stg' ? 'codeErrorLogs' : 'codeErrorLogs-dev', `${errorCode}`),
    //         {
    //           logs: [
    //             {
    //               when: new Date().toISOString(),
    //               where: location,
    //               environment: currentEnv,
    //               appVersion: this.appVersion,
    //               email: this.storageService.uniTaskrUser ? this.storageService.uniTaskrUser.email : 'unregistered',
    //               message: message,
    //               errorCode: errorCode,
    //             },
    //           ],
    //         },
    //         { merge: true }
    //       )
    //         .then(() => {
    //           // console.log('Doc Set! ');
    //         })
    //         .catch((err) => {
    //           console.log('Error setting doc: ', err);
    //         });
    //     });
    // } catch (e) {
    //   console.error('Error adding document: ', e);
    // }
  }

  async addShoutLogFirebase(message: string, location: string, shoutId: number, shoutName: string) {
    // console.log('addShoutLogFirebase()');

    /** Which environment are we in? */
    let currentEnv = this.configService.environment.uniTaskrApi.url.replace('https://', '').replace('.unitaskr.com/', '');
    // console.log('Current Env: ', currentEnv);

    const db = getFirestore();
    const userShoutLogRef = doc(
      db,
      currentEnv !== 'api-stg' ? 'shoutIncomplete' : 'shoutIncomplete-dev',
      `${location}`
    );

    try {
      updateDoc(userShoutLogRef, {
        logs: arrayUnion({
          when: new Date().toISOString(),
          where: location,
          environment: currentEnv,
          // appVersion: this.appVersion,
          appVersion: environment.applicationVersion,
          email: this.storageService.uniTaskrUser ? this.storageService.uniTaskrUser.email : 'unregistered',
          message: message,
          shoutId: shoutId,
          shoutTitle: shoutName,
        }),
      })
        .then(() => {
          // console.log('Added log!');
        })
        .catch((err) => {
          console.log('Add log error: ', err);

          setDoc(
            doc(db, currentEnv !== 'api-stg' ? 'shoutIncomplete' : 'shoutIncomplete-dev', `${location}`),
            {
              logs: [
                {
                  when: new Date().toISOString(),
                  where: location,
                  environment: currentEnv,
                  // appVersion: this.appVersion,
                  appVersion: environment.applicationVersion,
                  email: this.storageService.uniTaskrUser ? this.storageService.uniTaskrUser.email : 'unregistered',
                  message: message,
                  shoutId: shoutId,
                  shoutTitle: shoutName,
                },
              ],
            },
            { merge: true }
          )
            .then(() => {
              // console.log('Doc Set! ');
            })
            .catch((err) => {
              console.log('Error setting doc: ', err);
            });
        });
    } catch (e) {
      console.error('Error adding document: ', e);
    }
  }

  getLogs() {
    return this.storageService.getAndParse('error_logs');
  }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, from, Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { tap } from 'rxjs/operators';
import { Storage } from '@ionic/storage-angular';
import { environment } from 'src/environments/environment';
import { ConfigService } from '../config-service/config-service.service';
import { LocalUserService } from 'src/app/welcome/services/local-user/local-user.service';

// Expire time in seconds
const TTL = 60 * 60;

// Key to identify only cached API data
const CACHE_KEY = '_mycached_';

const CURRENCY_KEY = 'selected-currency';

@Injectable({
  providedIn: 'root',
})
export class CurrencyService {
  myCurrencyCode = new BehaviorSubject('');
  usdToGbp = new BehaviorSubject('');
  gbpToUsd = new BehaviorSubject('');

  constructor(
    private http: HttpClient,
    private storage: Storage,
    private configService: ConfigService,
    private localUserService: LocalUserService
  ) {}

  getCurrencyCode() {
    if (this.localUserService.uniTaskrProfile) {
      if (
        this.localUserService.uniTaskrProfile.currencyCode === 'USD' ||
        this.localUserService.uniTaskrProfile.currencyCode === 'MXN' ||
        this.localUserService.uniTaskrProfile.currencyCode === 'CAD'
      ) {
        return 'USD';
      } else {
        return 'GBP';
      }
    } else {
      console.log('>> Option 1...');
      return 'GBP';
    }
    
  }

  getConversionRate(from: string, to: string): any {
    // console.log('a message', from, to);
    try {
      return this.getData(`${this.configService.environment.uniTaskrApi.url}api/currencyrate?from=${from}&to=${to}`);
      // return this.http.get(`https://api.currencyapi.com/v3/latest?apikey=7S17cLVZ1OCvUFpBMgkpPAXF2CVt7TjweuIbHkFQ&currencies=${to}&base_currency=${from}`);
    } catch (e) {
      console.warn('Unable to get currency stuff: ', e);
    }
  }

  private getData(url, forceRefresh = false) {
    if (forceRefresh) {
      this.callAndCache(url);
    }

    return from(this.getCachedRequest(url)).pipe(
      switchMap((result) => {
        if (!result) {
          return this.callAndCache(url);
        } else {
          return of(result);
        }
      })
    );
  }

  private callAndCache(url): Observable<any> {
    return this.http.get(url).pipe(
      tap((res) => {
        console.log('CACHE THE REQUEST');
        this.cacheRequest(url, res);
      })
    );
  }

  // Store request data
  cacheRequest(url, data): Promise<any> {
    const validUntil = new Date().getTime() + TTL * 1000;
    url = `${CACHE_KEY}${url}`;
    return this.storage.set(url, { validUntil, data });
  }

  // Try to load cached data
  async getCachedRequest(url): Promise<any> {
    const currentTime = new Date().getTime();
    url = `${CACHE_KEY}${url}`;

    const storedValue = await this.storage.get(url);

    if (!storedValue) {
      return null;
    } else if (storedValue.validUntil < currentTime) {
      await this.storage.remove(url);
      return null;
    } else {
      return storedValue.data;
    }
  }

  // Example to remove one cached URL
  async invalidateCacheEntry(url) {
    url = `${CACHE_KEY}${url}`;
    await this.storage.remove(url);
  }
}

// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loading-tasks-row {
  width: 100%;
  height: 25%;
  justify-content: center;
  align-content: center;
}
.loading-tasks-row .input-set {
  width: 30%;
  justify-content: center;
}
.loading-tasks-row .input-set .avatar-row {
  width: 100%;
  justify-content: center;
}
.loading-tasks-row .input-set .avatar-row .is_white {
  --color: #fff;
}
.loading-tasks-row .input-set .hint-text {
  text-align: center;
}
.loading-tasks-row .input-set .is_white {
  color: #fff;
}`, "",{"version":3,"sources":["webpack://./src/app/components/loading-spinner/loading-spinner.component.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,WAAA;EACA,uBAAA;EACA,qBAAA;AACJ;AACI;EACI,UAAA;EACA,uBAAA;AACR;AAAQ;EACI,WAAA;EACA,uBAAA;AAEZ;AADY;EACI,aAAA;AAGhB;AAAQ;EACI,kBAAA;AAEZ;AAAQ;EACI,WAAA;AAEZ","sourcesContent":[".loading-tasks-row {\n    width: 100%;\n    height: 25%;\n    justify-content: center;\n    align-content: center;\n\n    .input-set {\n        width: 30%;\n        justify-content: center;\n        .avatar-row {\n            width: 100%;\n            justify-content: center;\n            .is_white {\n                --color: #fff;\n            }\n        }\n        .hint-text {\n            text-align: center;   \n        }\n        .is_white {\n            color: #fff;\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

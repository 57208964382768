// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.address_search_row {
  width: 100%;
  margin-top: 8px;
}
.address_search_row .where_item {
  width: 95%;
  border: solid 1px var(--bg-very-light-grey);
  border-radius: 5px;
  margin-left: 10px;
  --padding-start: 10px;
  --border-radius: 5px;
}
.address_search_row .where_item ion-input {
  border: none;
}
.address_search_row .where_item ion-label {
  line-height: normal;
}`, "",{"version":3,"sources":["webpack://./src/app/components/address-search/address-search.component.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,eAAA;AACF;AACE;EACE,UAAA;EACA,2CAAA;EACA,kBAAA;EACA,iBAAA;EACA,qBAAA;EACA,oBAAA;AACJ;AAAI;EACE,YAAA;AAEN;AAAI;EACE,mBAAA;AAEN","sourcesContent":[".address_search_row {\n  width: 100%;\n  margin-top: 8px;\n\n  .where_item {\n    width: 95%;\n    border: solid 1px var(--bg-very-light-grey);\n    border-radius: 5px;\n    margin-left: 10px;\n    --padding-start: 10px;\n    --border-radius: 5px;\n    ion-input {\n      border: none;\n    }\n    ion-label {\n      line-height: normal;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

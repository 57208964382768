import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HttpService } from '../http/http.service';

@Injectable({
  providedIn: 'root',
})
export class WalletService {
  walletBalance$ = new BehaviorSubject(0);
  // walletTransactions$ = new BehaviorSubject([]);

  constructor(private http: HttpService) {}

  initWallet() {
    this.http.runHttpCall('GET', `api/wallet/balance`, 'application/json').subscribe((res) => {
      // console.log('init res balance: ', res);
      this.walletBalance$.next(res / 100);
    });
    // this.http.runHttpCall('GET', `api/wallet/transactions`, 'application/json').subscribe((res) => {
    //   console.log('init res transactions: ', res);
    //   this.walletTransactions$.next(res);
    // });
  }

  getWalletBalance() {
    return this.walletBalance$.asObservable();
  }

  // getWalletTransactions() {
  //   return this.walletTransactions$.asObservable();
  // }

  topUpWallet(amount) {
    return this.http.runHttpCall('POST', `api/wallet/top-up?amount=${amount}`, 'application/json', {});
  }

  // getWalletTransactionsLength() {
  //   return this.walletTransactions$.getValue().length;
  // }

  getWalletBalanceNumber() {
    return this.walletBalance$.getValue();
  }

  payWithWallet(taskOfferId) {
    return this.http.runHttpCall('POST', `api/wallet/use/task-offer/${taskOfferId}`, 'application/json', {});
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import * as md5 from 'md5';

@Component({
  selector: 'app-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss'],
})
export class AvatarComponent implements OnInit {

  /** Image source input */
  @Input('src') src: string;

  /** Which size would you like the avatar */
  @Input('size') size: "x-small" | "small" | "list" | "medium" | "large" | "x-large" | "xx-large";

  @Input('image') image;

  constructor() { }

  ngOnInit() { }


  getImageSource(image) {
    // console.log('getImageSource(): ', image);
    if (image == 'linkedIn') {
      return 'src/assets/icon/LinkedIn-profile-pic.svg';
    } else {
      if (image && image.includes('http') || image && image.includes('job-icons')) {
        let trimVersion = image.split('?v=')[0];
        trimVersion = trimVersion.replace('?v=', '');
        return trimVersion;
      } else if (image == "string" || image == "" || image == null) {
        // return "../../../assets/images/placeholder.jpeg";
        // return "../../../assets/images/defaultprofilepicnew.svg";
        if (this.image) {
          return this.getGravatarURL(`${this.image}@unitaskr.com`);
          // return `https://joeschmoe.io/api/v1/${this.image}`;
        } else {
          return this.getGravatarURL(`${this.image}@unitaskr.com`);
          // return "../../../assets/images/defaultprofilepicnew.svg";
        }
      } else {
        let availWidth = screen.availWidth;
        if (availWidth > 250) {
          availWidth = 240;
        }
        let imageWidth: number;
        let imageHeight: number;
        // if (location === "focussed") {
        imageWidth = 2 * 0.95 * availWidth;
        imageHeight = 2 * 240;
        // } else if (location === "gallery") {
        //   imageWidth = 106;
        //   imageHeight = 74;
        // }
  
        let cdnRequestObject = {
          bucket: "unitaskr-web-cdn",
          key: image, // "Screenshot 2021-11-17 at 11.10.24.png",
          edits: {
            resize: {
              width: imageWidth,
              height: imageHeight,
              fit: "cover"
            }
          }
        }
  
        let base64 = btoa(JSON.stringify(cdnRequestObject))
        return `${environment.uniTaskrApi.cdnBaseUrl}${base64}`;
      }
    }
  }

  getGravatarURL(hashString: string) {
    const address = String(hashString).trim().toLowerCase();

    // Create an MD5 hash of the final string
    const hash = md5(address);

    // Grab the actual image URL
    return `https://www.gravatar.com/avatar/${hash}?d=identicon&f=y`;
  }

}

import { Component, OnInit } from '@angular/core';
import { ActivityService } from 'src/app/my-activity/service/activity.service';

@Component({
  selector: 'app-my-jobs',
  templateUrl: './my-jobs.component.html',
  styleUrls: ['./my-jobs.component.scss'],
})
export class MyJobsComponent implements OnInit {

  /** Which jobs are we filtering? */
  jobStatusFilter: string = "all";

  /** List of all my earning jobs */
  jobsCompleteList: any[] = [];

  constructor(
    private activityService: ActivityService
  ) { }

  ngOnInit() {
    this.getSellingActivity();
    this.getSoldActivity();

    this.activityService.shouldRefresh.subscribe(refresh => {
      if (refresh.shouldRefresh) {
        this.getSellingActivity(refresh.spinner);
        this.getSoldActivity();
      }
    })
  }

  updateJobFilter(ev) {
    this.jobStatusFilter = ev.detail.value
  }

  getSellingActivity(ev?) {
    this.jobsCompleteList = [];
    this.activityService.getAllActivity().subscribe(allActivityRes => {
      console.log('allActivityRes: ', allActivityRes);
      // this.jobsCompleteList = allActivityRes;
      for (let a of allActivityRes) {
        let alreadyIncluded = false;
        for (const job of this.jobsCompleteList) {
          if (job.taskId == a.taskId) alreadyIncluded = true;
        }
        if (!alreadyIncluded) this.jobsCompleteList.push(a);
      }
      if (ev) ev.target.complete();
      this.activityService.shouldRefresh.next({
        shouldRefresh: false,
        spinner: undefined
      });

      console.log('jobsCompleteList jobsCompleteList jobsCompleteList jobsCompleteList jobsCompleteList: ', this.jobsCompleteList);
    }, err => {
      console.log('allActivityRes: ', err);
    })
  }

  getSoldActivity() {
    this.activityService.getCompletedActivity().subscribe(soldActivityRes => {
      console.log('soldActivityRes: ', soldActivityRes);
      for (let a of soldActivityRes) {
        a.isPaid = true;
        this.jobsCompleteList.push(a);
      }

      console.log('this.jobsCompleteList: ', this.jobsCompleteList);
    })
  }

}

import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HttpService } from 'src/app/services/http/http.service';

@Injectable({
  providedIn: 'root',
})
export class RecruitService {
  /** Subscription to trigger my roles refresh. */
  shouldRefresh = new BehaviorSubject({
    shouldRefresh: false,
    spinner: undefined,
  });

  constructor(private http: HttpService) {}

  getRecentRoles() {
    return this.http.runHttpCall('POST', 'api/recruitrole/filter', 'application/json', {
      take: 8,
      skip: 0,
    });
  }

  getMyRoles() {
    return this.http.runHttpCall('POST', 'api/recruitrole/user/my-roles', 'application/json', {
      take: 8,
      skip: 0,
    });
  }

  postNewRole(roleRequest) {
    return this.http.runHttpCall('POST', 'api/recruitrole', 'application/json', roleRequest);
  }

  postRecruitTask(recruitTask) {
    return this.http.runHttpCall('POST', 'api/tasks/user/recruit', 'application/json', recruitTask);
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController, AlertController, LoadingController } from '@ionic/angular';
import { ServicesService } from '../../hiring-wizard/service/services.service';
import { ProfileService } from '../../services/profile/profile.service';
import { StorageService } from '../../services/storage/storage.service';
import { SubscriptionService } from '../../services/subscription/subscription.service';

@Component({
  selector: 'app-verify-email-modal',
  templateUrl: './verify-email-modal.page.html',
  styleUrls: ['./verify-email-modal.page.scss'],
})
export class VerifyEmailModalPage implements OnInit {
  @Input('sectionName') sectionName;

  constructor(private router: Router,
    private modalController: ModalController) { }

  ngOnInit() {
  }

  verifyEmail() {
    this.modalController.dismiss();
    this.router.navigate([`/verify-email`]);
  }

  closeModal() {
    this.modalController.dismiss();
  }
}

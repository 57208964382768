// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.insight_card {
  margin-top: 2%;
  margin-bottom: 2%;
}
.insight_card .insight_card_title {
  font-size: 1rem;
  margin-left: 3.5%;
  margin-top: 4%;
  font-weight: normal;
}
.insight_card .insight_card_content {
  padding-inline-start: 12px;
  padding-inline-end: 12px;
}
.insight_card .insight_card_content .insight_card_content_row {
  width: 100%;
}
.insight_card .insight_card_content .insight_card_content_row .ingisht_card_item {
  width: 100%;
  border-radius: 6px;
  --background: var(--bg-light-blue);
}
.insight_card .insight_card_content .insight_card_content_row .ingisht_card_item ion-input {
  color: var(--primary-ut-text);
  background: var(--bg-light-blue);
  margin-bottom: 8px;
  margin-top: 8px;
  border-radius: 6px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/result-tiktok-engagement/result-tiktok-engagement.component.scss"],"names":[],"mappings":"AAAA;EACI,cAAA;EACA,iBAAA;AACJ;AACI;EACI,eAAA;EACA,iBAAA;EACA,cAAA;EACA,mBAAA;AACR;AAEI;EACI,0BAAA;EACA,wBAAA;AAAR;AACQ;EACI,WAAA;AACZ;AACY;EACI,WAAA;EACA,kBAAA;EACA,kCAAA;AAChB;AAAgB;EACI,6BAAA;EACA,gCAAA;EACA,kBAAA;EACA,eAAA;EACA,kBAAA;AAEpB","sourcesContent":[".insight_card {\n    margin-top: 2%;\n    margin-bottom: 2%;\n\n    .insight_card_title {\n        font-size: 1rem;\n        margin-left: 3.5%;\n        margin-top: 4%;\n        font-weight: normal;\n    }\n\n    .insight_card_content {\n        padding-inline-start: 12px;\n        padding-inline-end: 12px;\n        .insight_card_content_row {\n            width: 100%;\n            // justify-content: center;\n            .ingisht_card_item {\n                width: 100%;\n                border-radius: 6px;\n                --background: var(--bg-light-blue);\n                ion-input {\n                    color: var(--primary-ut-text);\n                    background: var(--bg-light-blue);\n                    margin-bottom: 8px;\n                    margin-top: 8px;\n                    border-radius: 6px;\n                }\n            }\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-card ion-card-header ion-card-title {
  font-size: 1rem;
  font-family: "Poppins-bold";
}
ion-card ion-card-header ion-card-subtitle {
  font-family: "Poppins-regular";
}
ion-card ion-card-content ion-row {
  width: 100%;
}
ion-card ion-card-content ion-row .job_post_text {
  font-family: "Poppins-regular";
}
ion-card ion-card-content ion-row .job_post_text span {
  font-size: 1.2rem;
}
ion-card ion-card-content ion-row .price_per_month {
  color: var(--landing-text);
  font-family: "Poppins-regular";
}
ion-card ion-card-content ion-row .price_per_month span {
  font-size: 1.2rem;
}
ion-card ion-card-content ion-row .price_per_post {
  color: var(--landing-text);
  font-family: "Poppins-regular";
  font-style: italic;
}
ion-card ion-card-content ion-range {
  --knob-background: #00086a;
  --bar-height: 8px;
  --bar-background: #d6d9f0;
}`, "",{"version":3,"sources":["webpack://./src/app/components/custom-calculator/custom-calculator.component.scss"],"names":[],"mappings":"AAEI;EACE,eAAA;EACA,2BAAA;AADN;AAGI;EACE,8BAAA;AADN;AAKI;EACE,WAAA;AAHN;AAIM;EACE,8BAAA;AAFR;AAGQ;EACE,iBAAA;AADV;AAIM;EACE,0BAAA;EACA,8BAAA;AAFR;AAGQ;EACE,iBAAA;AADV;AAIM;EACE,0BAAA;EACA,8BAAA;EACA,kBAAA;AAFR;AAKI;EACE,0BAAA;EACA,iBAAA;EACA,yBAAA;AAHN","sourcesContent":["ion-card {\n  ion-card-header {\n    ion-card-title {\n      font-size: 1rem;\n      font-family: 'Poppins-bold';\n    }\n    ion-card-subtitle {\n      font-family: 'Poppins-regular';\n    }\n  }\n  ion-card-content {\n    ion-row {\n      width: 100%;\n      .job_post_text {\n        font-family: 'Poppins-regular';\n        span {\n          font-size: 1.2rem;\n        }\n      }\n      .price_per_month {\n        color: var(--landing-text);\n        font-family: 'Poppins-regular';\n        span {\n          font-size: 1.2rem;\n        }\n      }\n      .price_per_post {\n        color: var(--landing-text);\n        font-family: 'Poppins-regular';\n        font-style: italic;\n      }\n    }\n    ion-range {\n      --knob-background: #00086a;\n      --bar-height: 8px;\n      --bar-background: #d6d9f0;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

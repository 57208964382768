import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ErrorLogService } from 'src/app/error-logs/service/error-log.service';
import { StorageService } from 'src/app/services/storage/storage.service';

@Component({
  selector: 'app-landing-card',
  templateUrl: './landing-card.component.html',
  styleUrls: ['./landing-card.component.scss'],
})
export class LandingCardComponent implements OnInit {

  @Input() card: { header: string, subHeader: string, image: string; route: string };

  constructor(
    private router: Router,
    private logService: ErrorLogService,
    private storageService: StorageService
    ) { }

  ngOnInit() {
  }

  async openFlow(flow) {
    console.log('openFlow(): ', flow);
    // await this.storageService.set('interest', flow.header);
    this.router.navigate([flow.route]);
  }
}

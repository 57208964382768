// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Ion-Datetime Modal */
.datetime-ion-modal {
  --width: 290px;
  --height: 382px;
  --border-radius: 8px;
}

.datetime-ion-modal ion-datetime {
  height: 382px;
}

/* Ion-Datetime Modal END */`, "",{"version":3,"sources":["webpack://./src/app/components/date-picker/date-picker.component.scss"],"names":[],"mappings":"AAAA,uBAAA;AACA;EACI,cAAA;EACA,eAAA;EACA,oBAAA;AACJ;;AAEA;EACI,aAAA;AACJ;;AACA,2BAAA","sourcesContent":["/* Ion-Datetime Modal */\n.datetime-ion-modal {\n    --width: 290px;\n    --height: 382px;\n    --border-radius: 8px;\n}\n\n.datetime-ion-modal ion-datetime {\n    height: 382px;\n}\n/* Ion-Datetime Modal END */\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

import { Component, Input, OnInit } from '@angular/core';
@Component({
  selector: 'app-full-width-viewer',
  templateUrl: './full-width-viewer.component.html',
  styleUrls: ['./full-width-viewer.component.scss'],
})
export class FullWidthViewerComponent implements OnInit {

  /** Input image path */
  @Input('image') image: string;

  /** Input video path */
  @Input('video') video: string;

  /** Legacy content array */
  // @Input('contentArray') contentArray: ContentTypeResponse;
  @Input('contentArray') contentArray: any;

  /** Is the video currently being played back? */
  isVideoPlaying: boolean = false;
  /** Has video been interacted with once? */
  startedPlaying: boolean = false;

  /** Dynamically generate video id */
  playbackVideoIdbase: string = "playbackVideo";
  today: Date = new Date();

  constructor(

  ) { }

  ngOnInit() {
    if (this.contentArray) {
      console.log('contentArray: ', this.contentArray);
    }

    console.log('***** the video *****')
    console.log('image: ', this.video);
    console.log('***** the video *****')
  }

  startStopVideo() {
    console.log('startStopVideo()');
    this.startedPlaying = true;
    this.isVideoPlaying = this.isVideoPlaying ? false : true;

    setTimeout(() => {
      let vid = document.getElementById(this.getPlaybackId()) as HTMLVideoElement;
      console.log('vid: ', vid);
    }, 200);
  }

  getVideoSrc(srcUrl?: string): string {
    // let returnURL = "http://static.videogular.com/assets/videos/elephants-dream.mp4";
    // let returnURL = srcUrl ? srcUrl : "http://static.videogular.com/assets/videos/elephants-dream.mp4";
    // return `${returnURL}#t=0.01`;
    // return `${this.video}`;
    return `${this.video ? this.video : srcUrl}#t=0.1`;
  }

  getPlaybackId() {
    return `${this.playbackVideoIdbase}_${this.today.getMilliseconds()}`
  }



}

export interface ContentTypeResponse {
  extType: string;
  fileName: string;
  taskId: number;
  type: number;
  url: string;
}
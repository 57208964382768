// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Header in About Section */
.About_Profile_head {
  width: 100%;
  border-bottom: solid 6px var(--bg-light-blue);
  --background: transparent;
}
.About_Profile_head ion-avatar {
  margin-right: 10px;
  height: 60px;
  width: 60px;
}
.About_Profile_head ion-icon {
  font-size: 0.9375rem;
}
.About_Profile_head ion-label {
  font-size: 1.125rem;
  font-family: Poppins-Regular !important;
}
@media only screen and (min-width: 1200px) {
  .About_Profile_head {
    padding-left: 25%;
    padding-right: 25%;
  }
}

/* Header in About Section END */`, "",{"version":3,"sources":["webpack://./src/app/components/to-company-profile-row/to-company-profile-row.component.scss"],"names":[],"mappings":"AAAA,4BAAA;AACA;EACE,WAAA;EACA,6CAAA;EACA,yBAAA;AACF;AAAE;EACE,kBAAA;EACA,YAAA;EACA,WAAA;AAEJ;AAAE;EACE,oBAAA;AAEJ;AAAE;EACE,mBAAA;EACA,uCAAA;AAEJ;AAAE;EAhBF;IAiBI,iBAAA;IACA,kBAAA;EAGF;AACF;;AADA,gCAAA","sourcesContent":["/* Header in About Section */\n.About_Profile_head {\n  width: 100%;\n  border-bottom: solid 6px var(--bg-light-blue);\n  --background: transparent;\n  ion-avatar {\n    margin-right: 10px;\n    height: 60px;\n    width: 60px;\n  }\n  ion-icon {\n    font-size: 0.9375rem;\n  }\n  ion-label {\n    font-size: 1.125rem;\n    font-family: Poppins-Regular !important;\n  }\n  @media only screen and (min-width: 1200px) {\n    padding-left: 25%;\n    padding-right: 25%;\n  }\n}\n/* Header in About Section END */\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

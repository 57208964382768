import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { environment } from 'src/environments/environment';
import { DomSanitizer } from '@angular/platform-browser';
import DOMPurify from "dompurify";
import { CurrencyService } from 'src/app/services/currency/currency.service';
import { FirebaseService } from 'src/app/services/firebase/firebase.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-service-search-result',
  templateUrl: './service-search-result.component.html',
  styleUrls: ['./service-search-result.component.scss'],
})
export class ServiceSearchResultComponent implements OnInit {

  /** Input search result object */
  @Input('searchResult') searchResult;

  /** Tell the consumer that this search result has been selected */
  @Output('resultSelected') resultSelected = new EventEmitter;

  @Input('myCurrencyCode') myCurrencyCode;

  /** Dynamically generate image id */
  imageIdbase: string = "imageBase";
  today: Date = new Date();

  /** Show image loading spinner */
  showSpinner: boolean = true;

  /* My conversion rate */
  myConversionRate: Object;

  /** Has it changed? */
  myCurrencySubscription: any;

  constructor(
    private router: Router,
    private sanitizer: DomSanitizer,
    private currencyService: CurrencyService,
    private firebaseService: FirebaseService
  ) { }

  async ngOnInit() {
    let imageUrl = this.searchResult.pictureUrl !== "" && this.searchResult.pictureUrl ? this.searchResult.pictureUrl : "../../../assets/images/placeholder.jpeg";
    setTimeout(() => {
      if (document.getElementById(this.getImageId())) {
        this.showSpinner = false;
      }
    }, 200);
    this.getMyRates();
  }

  getFilledStars() {
    let filled = Math.floor(this.searchResult.rating.average / 1);
    return filled;
  }

  getPartialStar() {
    if (this.searchResult.rating - (Math.floor(this.searchResult.rating.average / 1)) > 0) {
      return true;
    } else {
      return false;
    }
  }

  getEmptyStars() {
    let emptyStars = 5;
    if (this.getPartialStar()) {
      emptyStars--;
    }
    emptyStars -= this.getFilledStars();

    return emptyStars;
  }

  getImageSource(image) {
    if (image && image.includes('http')) {
      return image;
    } else if (image == "string" || image == "") {
      return "../../../assets/images/placeholder.jpeg";
    } else {
      if (image.split(",")) {
        image = image.split(",")[0];
      }

      let availWidth = screen.availWidth;
      if (availWidth > 250) {
        availWidth = 240;
      }

      let imageWidth: number;
      let imageHeight: number;
      imageWidth = 2 * 0.95 * availWidth;
      imageHeight = 2 * 240;


      let cdnRequestObject = {
        bucket: "unitaskr-web-cdn",
        key: image, // "Screenshot 2021-11-17 at 11.10.24.png",
        edits: {
          resize: {
            width: imageWidth,
            height: imageHeight,
            fit: "cover"
          }
        }
      }

      let base64 = btoa(JSON.stringify(cdnRequestObject))
      return `${environment.uniTaskrApi.cdnBaseUrl}${base64}`;
    }
  }

  getImageId() {
    return `${this.imageIdbase}_${this.today.getMilliseconds()}`
  }

  getImage(imageUrl: string): string {
    if (imageUrl && imageUrl.length > 0) {
      return imageUrl;
    }
    else {
      return "../../../assets/images/placeholder.jpeg";
    }
  }


  getSanitized(htmlString: string) {
    let output = htmlString.replace("\n", "<br> <br>");
    const sanitizedContent = DOMPurify.sanitize(output);
    return this.sanitizer.bypassSecurityTrustHtml(sanitizedContent);
  }

  clickWholeCard() {

    if (this.firebaseService.myProfile) {
      this.router.navigate([`/view-service/${this.searchResult.serviceId}`]);
    } else {
      // this.router.navigate(['/reg-with-email/business']);
      this.router.navigate(['/getstarted-email']);
    }

    // this.resultSelected.emit(this.searchResult.serviceId);
  }

  getUrl() {
    return this.getImageSource(this.searchResult.pictureUrl);
  }

  getMyRates() {
    if (this.searchResult.currencyCode == '' || this.searchResult.currencyCode == null) {
      this.searchResult.currencyCode = 'GBP';
    }
    // console.log('search code: ', this.searchResult.currencyCode);
    // console.log('my code: ', this.myCurrencyCode);
    this.currencyService.getConversionRate(this.searchResult.currencyCode, this.myCurrencyCode).subscribe(rateRes => {
      this.myConversionRate = rateRes.rate ? rateRes.rate : 1;
    });
  }

  whichRate() {
    return this.myConversionRate;
  }
}

import { Injectable } from '@angular/core';
import { HttpService } from 'src/app/services/http/http.service';

@Injectable({
  providedIn: 'root'
})
export class TaskAlertsService {

  constructor(
    private http: HttpService
  ) { }

  getTaskAlerts() {
    return this.http.runHttpCall(
      "GET",
      "api/taskalerts",
      "application/json")
  }

  setAlerts(alertRequest: AlertRequest) {
    return this.http.runHttpCall(
      "PUT",
      "api/taskalerts",
      "application/json",
      alertRequest
    )
  }

  setEmailPushAlerts(pushAlerts: boolean, emailAlerts: boolean) {
    return this.http.runHttpCall(
      "PUT",
      "api/taskalerts/set-alerts",
      "application/json",
      {
        hasEmailAlerts: emailAlerts,
        hasPushAlerts: pushAlerts
      }
    )
  }

  getTaskAlertEmails() {
    return this.http.runHttpCall(
      "GET",
      "api/taskalerts/emails",
      "application/json",
    )
  }

  setTaskAlertEmails(reqBody) {
    return this.http.runHttpCall(
      "PUT",
      "api/taskalerts/emails",
      "application/json",
      reqBody
    )
  }

}

export interface AlertRequest {
  "alerts": string,
  "recruitAlerts": string,
  "location": string,
  "mainLocation": string,
  "lat": number,
  "lon": number,
  "shouterInstagram": string,
  "shouterAlertId": number
}
// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.page_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
  overflow: hidden;
  padding: 0 25px;
}
.page_wrapper.focus {
  color: #333;
  opacity: 1;
}

.profile_pic_row {
  width: 100%;
  justify-content: center;
}

.list_item ion-label {
  text-align: center;
  margin-inline: 8px;
}`, "",{"version":3,"sources":["webpack://./src/app/popovers/image-loading/image-loading/image-loading.component.scss"],"names":[],"mappings":"AAaA;EACI,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,YAAA;EACA,gBAAA;EACA,eAAA;AAZJ;AAaI;EACI,WAAA;EACA,UAAA;AAXR;;AAeA;EACI,WAAA;EACA,uBAAA;AAZJ;;AAgBI;EACI,kBAAA;EACA,kBAAA;AAbR","sourcesContent":["// :host {\n//     padding: 0;\n//     overflow: auto;\n//     position: fixed;\n//     top: 0;\n//     bottom: 0;\n//     // padding-top: 100px;\n//     min-height: 100%;\n//     // margin-bottom: 15%;\n//     width: 100%;\n//     scroll-behavior: smooth;\n// }\n\n.page_wrapper {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    height: 50vh;\n    overflow: hidden;\n    padding: 0 25px;\n    &.focus {\n        color: #333;\n        opacity: 1;\n    }\n}\n\n.profile_pic_row {\n    width: 100%;\n    justify-content: center;\n}\n\n.list_item {\n    ion-label {\n        text-align: center;\n        margin-inline: 8px;\n    }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

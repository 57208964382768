import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { getDoc, doc, query, orderBy, getFirestore, limit, collection } from '@firebase/firestore';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  environment: any = {
    uniTaskrApi: environment.uniTaskrApi,
    httpUrls: environment.httpUrls
  };
  constructor() { }

  async loadEnvConfig(): Promise<void> {
    // console.log('loadEnvConfig()');
    const db = getFirestore();
    const docRef = doc(db, "app-env", environment.envStore);
    const data = (await getDoc(docRef)).data();
    // console.log('configData', data);
    this.environment.uniTaskrApi.url = data.url;
    this.environment.uniTaskrApi.proUrl = data.proUrl;
    this.environment.httpUrls.uniUserUrl = data.uniUserUrl;
  }
}

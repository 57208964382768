// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Title row */
.head_item {
  width: 100%;
  margin-top: 4%;
  margin-bottom: 4%;
  --background: transparent;
}
.head_item ion-avatar {
  margin-right: 10px;
  height: 100px;
  width: 100px;
}
.head_item ion-icon {
  font-size: 0.9375rem;
}
.head_item ion-label {
  font-size: 1.188rem;
  font-weight: bold;
  font-family: Poppins-Regular !important;
}

/* Title row END */
/* Description row */
.description_section {
  width: 100%;
  justify-content: center;
}
.description_section .p {
  color: var(--ion-color-light-contrast);
  width: 90%;
}
.description_section .description_div {
  padding-left: 3.5%;
  padding-right: 3.5%;
  padding-top: 5.5%;
}
.description_section .heading-2 {
  margin-top: 5%;
  margin-left: 5%;
  text-align: left !important;
  font-weight: bold;
  width: 100%;
}

/* Description row END */
/* Stats Row */
.stats-row {
  margin-left: 2.5%;
  margin-right: 2.5%;
  margin-top: 3.5%;
}
.stats-row ion-chip {
  border-radius: 7px;
  background: var(--bg-light-blue);
}

/* Stats Row END */`, "",{"version":3,"sources":["webpack://./src/app/modals/company-profile-shout/company-profile-shout.page.scss"],"names":[],"mappings":"AAAA,cAAA;AACA;EACI,WAAA;EAEA,cAAA;EACA,iBAAA;EACA,yBAAA;AAAJ;AACI;EACI,kBAAA;EACA,aAAA;EACA,YAAA;AACR;AACI;EACI,oBAAA;AACR;AACI;EACI,mBAAA;EACA,iBAAA;EACA,uCAAA;AACR;;AAEA,kBAAA;AAEA,oBAAA;AACA;EACI,WAAA;EACA,uBAAA;AAAJ;AACI;EACI,sCAAA;EACA,UAAA;AACR;AAEI;EACI,kBAAA;EACA,mBAAA;EACA,iBAAA;AAAR;AAGI;EACI,cAAA;EACA,eAAA;EACA,2BAAA;EACA,iBAAA;EACA,WAAA;AADR;;AAIA,wBAAA;AAEA,cAAA;AACA;EACI,iBAAA;EACA,kBAAA;EACA,gBAAA;AAFJ;AAGI;EACI,kBAAA;EACA,gCAAA;AADR;;AAIA,kBAAA","sourcesContent":["/* Title row */\n.head_item {\n    width: 100%;\n    // --padding-start: 0px;\n    margin-top: 4%;\n    margin-bottom: 4%;\n    --background: transparent;\n    ion-avatar {\n        margin-right: 10px;\n        height: 100px;\n        width: 100px;\n    }\n    ion-icon {\n        font-size: 0.9375rem;\n    }\n    ion-label {\n        font-size: 1.188rem;\n        font-weight: bold;\n        font-family: Poppins-Regular !important;\n    }\n}\n/* Title row END */\n\n/* Description row */\n.description_section {\n    width: 100%;\n    justify-content: center;\n    .p {\n        color: var(--ion-color-light-contrast);\n        width: 90%;\n    }\n\n    .description_div {\n        padding-left: 3.5%;\n        padding-right: 3.5%;\n        padding-top: 5.5%;\n    }\n\n    .heading-2 {\n        margin-top: 5%;\n        margin-left: 5%;\n        text-align: left !important;\n        font-weight: bold;\n        width: 100%;\n    }\n}\n/* Description row END */\n\n/* Stats Row */\n.stats-row {\n    margin-left: 2.5%;\n    margin-right: 2.5%;\n    margin-top: 3.5%;\n    ion-chip {\n        border-radius: 7px;\n        background: var(--bg-light-blue);\n    }\n}\n/* Stats Row END */\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

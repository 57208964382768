import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HttpService } from 'src/app/services/http/http.service';

@Injectable({
  providedIn: 'root'
})
export class ActivityService {

  /** My active services count */
  activeServices: number = 0;
  /** My pending services count */
  pendingServices: number = 0;

  /** Subscription to trigger my activity blaze refresh. */
  shouldRefresh = new BehaviorSubject({
    shouldRefresh: false,
    spinner: undefined
  });

  constructor(
    private http: HttpService
  ) { }

  getAllActivity() {
    return this.http.runHttpCall(
      "POST",
      "api/activity/tasks/selling",
      "application/json",
      {
        take: 100,
        skip: 0
      }
    )
  }

  getSellingActivity(page: number) {
    return this.http.runHttpCall(
      "POST",
      "api/activity/tasks/selling",
      "application/json",
      {
        take: 10,
        skip: page * 10
      }
    )
  }

  getCompletedActivity() {
    return this.http.runHttpCall(
      "POST",
      "api/activity/tasks/list/sold",
      "application/json",
      {
        take: 10,
        skip: 0
      }
    )
  }

  getBuyingActivity() {
    return this.http.runHttpCall(
      "POST",
      "api/activity/tasks/list/buying",
      "application/json",
      {
        take: 10,
        skip: 0
      }
    )
  }

  getBoughtActivity() {
    return this.http.runHttpCall(
      "POST",
      "api/activity/tasks/list/bought",
      "application/json",
      {
        take: 10,
        skip: 0
      }
    )
  }

  getDraftActivity() {
    return this.http.runHttpCall(
      "POST",
      "api/activity/tasks/list/draft",
      "application/json",
      {
        take: 10,
        skip: 0
      }
    )
  }

  getHiringTasks() {
    return this.http.runHttpCall(
      "GET",
      "api/activity/hiring/tasks",
      "application/json",
      {
        take: 100,
        skip: 0
      }
    )
  }

  getHiringServices() {
    return this.http.runHttpCall(
      "GET",
      "api/activity/hiring/services",
      "application/json",
      {
        take: 100,
        skip: 0
      }
    )
  }
}

export interface ActivityResponse {
  commment: number;
  hired: number;
  isAccepted: boolean;
  isDeclined: number;
  isDel: number;
  isPaused: boolean;
  isRebook: number;
  isService: boolean;
  offerDT: string;
  offerId: any;
  offers: number;
  paid: number;
  parentTaskId: number;
  profilePictureUrl: string;
  questions: number;
  taskId: number;
  title: string;
  userId: number;
  userName: string;

}
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertController, LoadingController, ModalController, ToastController } from '@ionic/angular';
import { PriceChangeService } from '../chat/services/price-change/price-change.service';
import { StorageService } from '../services/storage/storage.service';

@Component({
  selector: 'app-price-change-respond',
  templateUrl: './price-change-respond.page.html',
  styleUrls: ['./price-change-respond.page.scss'],
})
export class PriceChangeRespondPage implements OnInit {

  @Input('offerId') offerId;
  @Input('pcrId') pcrId;
  modalMode: boolean = false;

  /** Am I allowed to respond? */
  canRespond: boolean = false;
  /** Focussed PCR */
  focussedPriceChange;

  /** Task Offer ID */
  taskOfferId: string;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private alertCtrl: AlertController,
    private toastController: ToastController,
    private loadingCtrl: LoadingController,
    private modalCtrl: ModalController,
    private priceChangeService: PriceChangeService,
    private storageService: StorageService
  ) { }

  ngOnInit() {
  }

  async ionViewDidEnter() {

    console.log('>>>>>>>>>>>>>>>>>>>>')
    console.log('Price Change Respond')
    console.log('offerId: ', this.offerId)
    console.log('pcrId: ', this.pcrId)
    console.log('>>>>>>>>>>>>>>>>>>>>')

    if (this.offerId && this.pcrId) {
      console.log('*** Modal Mode ***')
      this.modalMode = true;
      this.taskOfferId = this.offerId;
      this.getPriceChangeRequest(this.pcrId);
    } else {
      this.route.params.subscribe((params) => {
        console.log('PCR Respond params: ', params);
  
        this.taskOfferId = params.offerId;
        /**
         * Get Price Change Request details
         */
        this.getPriceChangeRequest(params.pcrId);
  
        /** 
         * Get the task offer details
         * Need to check if I'm allowed to accept the change...
         */
  
      })
    }
  }

  getPriceChangeRequest(priceChangeId: string) {
    this.priceChangeService.getPriceChangeById(priceChangeId).subscribe(pcrReq => {
      console.log('pcrReq: ', pcrReq);
      this.focussedPriceChange = pcrReq;
      if (this.storageService.uniTaskrUser.userId == pcrReq.userId) {
        this.canRespond = false;
      } else if (this.storageService.uniTaskrUser.userId !== pcrReq.userId) {
        this.canRespond = true;
      }
    })
  }

  async respondToPriceRequest(selection) {
    const loading = await this.loadingCtrl.create({
      message: 'Loading...',
      duration: 10000,
    });

    loading.present();
    switch (selection) {
      case -1:
        console.log('Decline!');
        this.priceChangeService.declinePriceChange(this.focussedPriceChange.id).subscribe(async declineRes => {
          console.log('declinePCR res: ', declineRes);
          loading.dismiss();
          const toast = await this.toastController.create({
            message: 'Price Change Declined',
            duration: 2000,
            position: 'middle',
            icon: 'close'
          });
          await toast.present();
          if (this.modalMode) {
            this.modalCtrl.dismiss({ response: 'declined' });
            // this.closeModal();
          } else {
            this.router.navigate([`${this.getBackUrl()}`])
          }
        }, err => {
          loading.dismiss();
          console.log('declinePCR res error: ', err);
        })
        break;
      case 1:
        console.log('Accept!');
        this.priceChangeService.acceptPriceChange(this.focussedPriceChange.id).subscribe(async acceptRes => {
          console.log('acceptPCR res: ', acceptRes);
          const toast = await this.toastController.create({
            message: 'Price Change Accepted',
            duration: 2000,
            position: 'middle',
            icon: 'check'
          });
          loading.dismiss();
          await toast.present();
          if (this.modalMode) {
            this.modalCtrl.dismiss({ response: 'accept' });
          } else {
            this.router.navigate([`${this.getBackUrl()}`])
          }
        }, async err => {
          loading.dismiss();
          console.log('acceptPCR res error: ', err);
          if (err.error.includes('Price Change has already been actioned')) {
            const alert = await this.alertCtrl.create({
              header: 'Whoops!',
              message: 'Price Request as already been responded to.',
              buttons: [{
                text: 'OK',
                role: 'confirm',
                handler: () => {
                  this.closeModal();
                },
              }],
            });
        
            await alert.present();
          }
        })
        break;
    }
  }

  getBackUrl() {
    // return `/messaging/${this.taskOfferId}`
    return `/messaging/${this.taskOfferId}`
  }
  
  closeModal() {
    this.modalCtrl.dismiss();
  }
}

import { Component, Input, OnInit, Output, EventEmitter, ViewChild } from '@angular/core';
import { AnimationController } from '@ionic/angular';
import { Observable } from 'rxjs';
import { ServicesService } from 'src/app/hiring-wizard/service/services.service';

@Component({
  selector: 'app-amazon-style-accordion',
  templateUrl: './amazon-style-accordion.component.html',
  styleUrls: ['./amazon-style-accordion.component.scss'],
})
export class AmazonStyleAccordionComponent implements OnInit {

  /** Input category information */
  @Input('categories') categoryList;

  /** How many columns do we want to have? */
  @Input('columnCount') columnCount: any = 2;

  /** Which option has the user selected? */
  @Output('selectedOption') selectedOption = new EventEmitter();

  /** Which sub category list has ben selected? */
  @Output('selectedSubCats') selectedSubCats = new EventEmitter();


  /** Which category is focussed and expanded? */
  focussedCategory;
  /** Which row should show the open list? */
  focussedRow;

  /** List of 2 column rows... */
  twoColumnRows = [];


  /** Base ID for dynamic IDs on the sub-cat card */
  catCardBaseId: string = "subCategoryCard";
  today: Date = new Date();

  /** Business category subcats results */
  // public results: Observable<SubCategoryListResponse[]>;
  public results: any[];

  constructor(
    private animationCtrl: AnimationController,
    private servicesService: ServicesService
  ) { }

  ngOnInit() {
    console.log('**** categoryList ****: ', this.categoryList);
    if (this.columnCount == 2) {
      this.sortTwoColumns();
    } else if (this.columnCount == 3) {
      this.sortThreeColumns();
    } else if (this.columnCount == 6) {
      this.sortSixColumns();
    }
  }

  getColumnSize(column, row) {
    // console.log('getColumnSize(): ', column);
    let index = row.indexOf(column);
    if (row.length == 1) {
      return 12;
    }
    // else if (index == row.length - 1 && this.twoColumnRows.indexOf(row) == this.twoColumnRows.length - 1) {
    //   let factor = this.columnCount - row.length;
    //   let colWith = 12 / this.columnCount;
    //   return 12 - colWith * factor;
    // } 
    else {
      return (12 / this.columnCount);
    }
  }

  getSubCategories(focussedCategory) {
    // console.log('getSubCategories()');
    this.servicesService.getSubCategories(focussedCategory.categoryId).subscribe(results => {
      console.log('results: ', results)
      if (results.length == 0) {
        this.selectedOption.emit(focussedCategory);
      } else {
        this.results = results;
      }
    })
  }

  sortTwoColumns() {
    let rowHolder;
    for (let i = 0; i < this.categoryList.length; i++) {
      let mod = i % 2;
      if (mod == 0) {
        // create new row
        rowHolder = [this.categoryList[i]];
      } else {
        // add to existing row
        rowHolder.push(this.categoryList[i]);
        this.twoColumnRows.push(rowHolder);
        rowHolder = undefined;
      }
    }
    if (rowHolder) {
      this.twoColumnRows.push(rowHolder);
    }
  }

  sortThreeColumns() {
    let rowHolder;
    for (let i = 0; i < this.categoryList.length; i++) {
      let mod = i % 3;
      if (mod == 0) {
        // create new row
        rowHolder = [this.categoryList[i]];
      } else {
        // add to existing row
        rowHolder.push(this.categoryList[i]);
      }

      if (mod == 2) {
        this.twoColumnRows.push(rowHolder);
        rowHolder = undefined;
      }
    }
    if (rowHolder) {
      this.twoColumnRows.push(rowHolder);
    }
  }

  sortSixColumns() {
    let rowHolder;
    for (let i = 0; i < this.categoryList.length; i++) {
      let mod = i % 6;
      if (mod == 0) {
        // create new row
        rowHolder = [this.categoryList[i]];
      } else {
        // add to existing row
        rowHolder.push(this.categoryList[i]);
      }

      if (mod == 6) {
        this.twoColumnRows.push(rowHolder);
        rowHolder = undefined;
      }
    }
    if (rowHolder) {
      this.twoColumnRows.push(rowHolder);
    }
  }

  focusCategory(category, row) {
    console.log('focusCategory()');
    console.log('category: ', category);

    // if (category.isBusiness) {
    if (this.focussedCategory == category) {
      setTimeout(async () => {

        this.animationCtrl.create()
          .addElement(document.getElementById(this.getSubCatCardId()))
          .duration(200)
          .iterations(1)
          .fromTo('width', '94vw', '48px')
          .fromTo('height', '100%', '48px')
          .play()
        setTimeout(async () => {
          this.focussedCategory = undefined;
        }, 199)
      }, 50)
    } else {
      this.getSubCategories(category);
      this.focussedCategory = category;
      this.focussedRow = row
      setTimeout(async () => {

        this.animationCtrl.create()
          .addElement(document.getElementById(this.getSubCatCardId()))
          .duration(220)
          .iterations(1)
          .fromTo('width', '48px', '94vw')
          .fromTo('height', '48px', '100%')
          .play()
      }, 50)
    }
  }

  selectSubCat(subCat, subCatList) {
    console.log('selectSubCat()')
    this.selectedOption.emit(subCat);
    this.selectedSubCats.emit(subCatList);
  }

  getSubCatCardId() {
    return `${this.catCardBaseId}_${this.today.getMilliseconds()}_${this.columnCount}`
  }
}

export interface SubCategoryListResponse {
  popularCarouselUrl: string;
  servicesCategoryId: number;
  servicesSubcategoryId: number;
  subcategoryName: string;
}
// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container-slides-row {
  width: 100%;
  align-content: center;
  overflow-x: scroll;
  flex-wrap: nowrap;
}
.container-slides-row .service-card {
  height: 30vh;
  margin-inline-start: 4px;
  margin-inline-end: 0px;
  margin-top: 0;
}
.container-slides-row .service-card .image_row {
  width: 100%;
  justify-content: center;
  height: 9.7rem;
  background-position: center;
  background-size: cover;
}
.container-slides-row .service-card img {
  height: 100%;
  width: 100%;
}
.container-slides-row .service-card .sub_title {
  font-size: 0.875rem;
  font-family: Poppins-Regular !important;
  text-transform: capitalize;
  font-weight: normal;
  padding-left: 12px;
  padding-right: 12px;
}
.container-slides-row .service-card .title {
  font-family: Poppins-Regular !important;
  font-size: 0.875rem;
  position: absolute;
  bottom: 12px;
  left: 12px;
  color: var(--unitaskr-color-blue);
}

.show-less {
  margin-top: 8px;
  height: 47px;
  font-size: 15px;
  overflow: hidden;
  width: 100%;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.show-more {
  margin-top: 15px;
  height: auto;
  font-size: 15px;
  letter-spacing: 0;
  line-height: 22px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/services-card-row/services-card-row.component.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,qBAAA;EACA,kBAAA;EACA,iBAAA;AACF;AACE;EAEE,YAAA;EACA,wBAAA;EAEA,sBAAA;EACA,aAAA;AADJ;AAGI;EACE,WAAA;EACA,uBAAA;EACA,cAAA;EACA,2BAAA;EACA,sBAAA;AADN;AAII;EACE,YAAA;EACA,WAAA;AAFN;AAKI;EACE,mBAAA;EACA,uCAAA;EACA,0BAAA;EACA,mBAAA;EAEA,kBAAA;EACA,mBAAA;AAJN;AAOI;EACE,uCAAA;EACA,mBAAA;EACA,kBAAA;EACA,YAAA;EACA,UAAA;EACA,iCAAA;AALN;;AAUA;EACE,eAAA;EACA,YAAA;EACA,eAAA;EACA,gBAAA;EACA,WAAA;EACA,oBAAA;EACA,qBAAA;EACA,4BAAA;AAPF;;AASA;EACE,gBAAA;EACA,YAAA;EACA,eAAA;EACA,iBAAA;EACA,iBAAA;AANF","sourcesContent":[".container-slides-row {\n  width: 100%;\n  align-content: center;\n  overflow-x: scroll;\n  flex-wrap: nowrap;\n\n  .service-card {\n    // height: 216px;\n    height: 30vh;\n    margin-inline-start: 4px;\n    // margin-inline-end: 4px;\n    margin-inline-end: 0px;\n    margin-top: 0;\n\n    .image_row {\n      width: 100%;\n      justify-content: center;\n      height: 9.7rem;\n      background-position: center;\n      background-size: cover;\n    }\n\n    img {\n      height: 100%;\n      width: 100%;\n    }\n\n    .sub_title {\n      font-size: 0.875rem;\n      font-family: Poppins-Regular !important;\n      text-transform: capitalize;\n      font-weight: normal;\n      // color: #000;\n      padding-left: 12px;\n      padding-right: 12px;\n    }\n\n    .title {\n      font-family: Poppins-Regular !important;\n      font-size: 0.875rem;\n      position: absolute;\n      bottom: 12px;\n      left: 12px;\n      color: var(--unitaskr-color-blue);\n    }\n  }\n}\n\n.show-less {\n  margin-top: 8px;\n  height: 47px;\n  font-size: 15px;\n  overflow: hidden;\n  width: 100%;\n  display: -webkit-box;\n  -webkit-line-clamp: 2;\n  -webkit-box-orient: vertical;\n}\n.show-more {\n  margin-top: 15px;\n  height: auto;\n  font-size: 15px;\n  letter-spacing: 0;\n  line-height: 22px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

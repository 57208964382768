import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AnimationController, ModalController, Platform } from '@ionic/angular';
import { take } from 'rxjs/operators';
import { CvService, JobTypeResponse } from 'src/app/cv-builder-new/service/cv.service';
import { CategoryInterface, ServicesService } from 'src/app/hiring-wizard/service/services.service';
import { TaskService } from 'src/app/home-earn/service/task.service';
import { UpgradePlanPage } from 'src/app/modals/upgrade-plan/upgrade-plan.page';
import { StorageService } from 'src/app/services/storage/storage.service';

@Component({
  selector: 'app-hire-card-categories',
  templateUrl: './hire-card-categories.component.html',
  styleUrls: ['./hire-card-categories.component.scss'],
})
export class HireCardCategoriesComponent implements OnInit {

  @Input() card: {
    header: string;
    subHeader: string;
    postTask?: boolean;
    image?: string;
    stepOne: string[];
    stepTwo: string[];
    stepThree: string[];
  };

  /** Which job type have we selected? */
  selectedJobType: 'task' | 'internship' | 'partTime' | 'fullTime' = 'task';

  /** How many students do we have on here? */
  liveTicker: number = 0;

  /** Are we looking at home or business tasks */
  selectedTaskType: 'Home' | 'Business' = 'Business';

  /** List of Business Categories */
  businessCategories: any[];

  /** List of Home Categories */
  homeCategories: {
    categoryId: number;
    description: string;
    isBusiness: boolean;
    name: string;
    shoutSrc: string;
    shouterInstagram: string;
    src: string;
    taskCategoryType: number;
  }[];

  /** Focussed Sub Categories */
  subCategoryResults;

  /** Which TASK Category is selected */
  focussedTaskCategory

  /** Selected sub category */
  selectedTaskSubCategory;

  /** Full list of job types / industries */
  jobTypes: JobTypeResponse[];

  /** Toggle all industries */
  showAllIndustries: boolean = false;

   /** For rotation */
   rotateArrow: boolean = false;

  constructor(
    private router: Router,
    public platform: Platform,
    private animationCtrl: AnimationController,
    private modalCtrl: ModalController,
    private taskService: TaskService,
    private servicesService: ServicesService,
    private cvService: CvService,
    private storageService: StorageService
  ) { }

  toggleShowAllIndustries() {
    this.showAllIndustries = !this.showAllIndustries;
    this.rotateArrow = !this.rotateArrow;
  }

  ngOnInit() {
    this.getLiveTicker();
    this.getAllCategories();
    this.getJobTypes();
  }

  selectSubCategory(category) {
    console.log('selectSubCategory()');
    console.log('category: ', category);

    if (this.focussedTaskCategory == category) {
      setTimeout(async () => {

        this.animationCtrl.create()
          .addElement(document.getElementById("put_the_subcats_here"))
          .duration(250)
          .iterations(1)
          // .fromTo('width', '94vw', '48px')
          .fromTo('height', '100%', '0px')
          .play()
        setTimeout(async () => {
          this.focussedTaskCategory = undefined;
        }, 199)
      }, 50)
    } else {
      this.getSubCategories(category);
      this.focussedTaskCategory = category;
      setTimeout(async () => {

        this.animationCtrl.create()
          .addElement(document.getElementById("put_the_subcats_here"))
          .duration(250)
          .iterations(1)
          // .fromTo('width', '48px', '100%')
          .fromTo('height', '0px', 'calc(100% - 10px)')
          .play()
      }, 50)
    }
  }

  getSubCategories(focussedCategory) {
    // console.log('getSubCategories()');
    this.servicesService.getSubCategories(focussedCategory.categoryId).subscribe(results => {
      console.log('results: ', results)
      if (results.length == 0) {
        console.log('Chosen a home category: ', focussedCategory);
        this.router.navigate(['/new-create-task/0'], {
          queryParams: {
            categoryInfo: JSON.stringify({
              type: this.selectedTaskType == 'Business' ? 'business' : 'home',
              category: focussedCategory.categoryId
            })
          }
        })

      } else {
        this.subCategoryResults = results;
      }
    })
  }

  getAllCategories() {
    console.log('getAllCategories()');
    this.servicesService.getAllCategories().subscribe(
      (catRes) => {
        // console.log('catRes: ', catRes);
        this.businessCategories = catRes.businessCategories;
        this.homeCategories = catRes.homeCategories;
        // this.getParameters();
      },
      (err) => {
        console.log('catRes error: ', err);
      }
    );
  }

  getJobTypes() {
    this.cvService.getBasicJobTypes().subscribe(
      (jobTypesRes) => {
        // console.log('**********************************')
        // console.log('jobTypesRes: ', jobTypesRes);
        // console.log('jobTypesRes: ', jobTypesRes);
        // console.log('jobTypesRes: ', jobTypesRes);
        // console.log('**********************************')
        this.jobTypes = jobTypesRes;
      },
      (err) => {
        console.log('jobTypesRes err!', err);
      }
    );
  }

  getLiveTicker() {
    setTimeout(() => {
      // console.log('getLiveTicker() home-hire;');
      this.taskService
        .getLiveTicker()
        .pipe(take(1))
        .subscribe((tickerRes) => {
          // console.log('tickerRes: ', tickerRes);
          this.liveTicker = tickerRes.all;
        });
    }, 1250);
  }

  updateJobTypesegment(ev) {
    // console.log('updateJobTypesegment(): ', ev)
    this.selectedJobType = ev.detail.value;
  }

  businessCategorySelected(subCat) {
    // console.log('homeCategorySelected(): ', subCat);
    this.selectedTaskSubCategory = subCat;
    this.router.navigate(['/new-create-task/0'], {
      queryParams: {
        categoryInfo: JSON.stringify({
          type: this.selectedTaskType == 'Business' ? 'business' : 'home',
          category: subCat.servicesCategoryId,
          subCategory: subCat.servicesSubcategoryId
        })
      }
    })
  }

  toggleTaskType() {
    this.selectedTaskType = this.selectedTaskType == 'Home' ? 'Business' : 'Home'
    this.subCategoryResults = undefined;
    this.focussedTaskCategory = undefined;
  }

  selectSubCat(subCat, subCatList) {
    // console.log('selectSubCat()')
    // console.log('subCat: ', subCat);
    if (this.selectedTaskType == 'Business') {
      this.businessCategorySelected(subCat);
    }
  }

  getRange(array: any[]): number[] {
    if (this.showAllIndustries) {
      return new Array(array.length - 1).fill(0).map((n, index) => index);
    } else {
      return new Array(array.length > 10 ? 9 : array.length).fill(0).map((n, index) => index);
    }
  }

  async selectJobType(jobType) {
    console.log('selectJobType(): ', jobType);

    /** Check if the user is logged in */
    if (this.storageService.uniTaskrUser) {
      let selectedJobType;
      switch (this.selectedJobType) {
        case 'partTime':
          selectedJobType = 'Part-time job';
          break;
        case 'fullTime':
          selectedJobType = 'Full-time job';
          break;
        case 'internship':
          selectedJobType = 'Internship';
          break;
      }
      this.router.navigate(['/create-job-form/0'], {
        queryParams: {
          jobType: selectedJobType,
          selectedJob: JSON.stringify(jobType)
        }
      })
    }
    else {
      const modal = await this.modalCtrl.create({
        component: UpgradePlanPage,
        componentProps: { upgradeType: jobType.jobIndustriesId  ? 'recruiting' : 'tasking' },
        initialBreakpoint: 0.5,
        breakpoints: [0, 0, 5],
        // swipeToClose: true,
      });
      modal.present();
    }


  }


}
// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.input_area {
  --padding-start: 0;
  border-radius: 6px;
  --padding-start: 10px !important;
  background: var(--dsk-white);
}

.continue_button {
  margin-top: 3vh;
  --background: linear-gradient(90deg, rgba(132, 22, 244, 1) 0%, rgba(7, 206, 213, 1) 100%);
  --color: #fff;
}`, "",{"version":3,"sources":["webpack://./src/app/components/adhoc-dateofbirth/adhoc-dateofbirth.component.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,kBAAA;EACA,gCAAA;EACA,4BAAA;AACJ;;AAEA;EACI,eAAA;EACA,yFAAA;EACA,aAAA;AACJ","sourcesContent":[".input_area {\n    --padding-start: 0;\n    border-radius: 6px;\n    --padding-start: 10px !important;\n    background: var(--dsk-white);\n}\n\n.continue_button {\n    margin-top: 3vh;\n    --background: linear-gradient(90deg, rgba(132, 22, 244, 1) 0%, rgba(7, 206, 213, 1) 100%);\n    --color: #fff;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
